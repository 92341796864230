const data = {
  // ********** Header ********** //
  english: "英語",
  japan: "日本",
  bahasa: "インドネシア",
  sign_out: "ログアウト",
  sign_in: "ログイン",
  sign_up: "サインイン",
  sign_in_to_hoterip: "ホットリサーチにサインインする",
  sign_up_with_hoterip: "ユーザー登録",
  sign_in_with_facebook: "Facebookにサインインする",
  sign_in_with_twitter: "ツイッターでサインイン",
  sign_in_with_google: "googleでログイン",
  forgot_password: "パスワードをリセット",
  create_account: "ユーザー登録",
  no_account_yet: "アカウントはまだありません",
  already_have_an_account: "すでにアカウントをお持ちですか",
  email_address: "メールアドレス",
  password: "パスワード",
  first_name: "名",
  last_name: "姓",
  or: "または",
  send_to_email: "電子メールに送る",
  back_to: "戻る",
  new_to_hoterip: "アカウントをお持ちではない。",
  sign_up_now: "アカウントを新規登録",

  // ********** Footer ********** //
  contact_us: "お問い合わせ",
  hotel_popular: "人気ホテル",
  help: "ヘルプ",
  payment_method: "支払方法",
  guarante_lowest_price: "最低価格保証",
  easy_to_search: "簡単検索、簡単予約",
  good_services: "安心サポート",
  faq: "よくある質問",
  term_condition: "利用条件",
  privacy_policy: "個人情報保護方針",
  hotel_partner: "ホテルパートナー",

  // ********** Home ********** //
  kota_area_tujuan: "都市・目的地・宿泊施設",
  find_city: "目的地・宿泊施設を入力してください",
  destination: "行き先",
  check_in: "チェックイン日",
  number_of_night: "宿泊数",
  check_out: "チェックアウト日",
  night: "泊",
  search_hotel: "ホテル検索",
  why_choose_hoterip_com: "だから、いつもHIS Hotelsーその理由は",
  around_bali: "バリ島で無料シャトル",
  around_bali_info: "無料シャトルバスでバリ島の人気スポットを巡回します",
  best_service: "安心なお客様サポート",
  best_service_info:
    "多数な支店でお客様に安心サポートをお届けいたします。HIS Hotels（ホテリップ）の予約カウンターやお客様サポートデスクはインドネシア大都市に多数あります",
  discount_with_hoterip: "Hoteripバウチャーで得しよう",
  discount_with_hoterip_info:
    "Hoterip（ホテリップ）バウチャーに記載するMAIMAIカードでショッピングやレストランでの食事の時に最大50%割引！",
  room_benefit: "ホテルの嬉しい特典が受けられます",
  room_benefit_info: "無料空港~ホテル間送迎や無料マッサージ等ホテルからの嬉しいい特典が受けれます",
  read_more: "詳細",
  best_deal_of_the_week: "今週のおすすめホテル",
  avg_pernight: "１泊平均料金",
  check_price: "空室確認",
  hotel_promo: "お得なホテル",
  see_all_promo: "全てのプロモーションを見る",
  view_all_promo: "全てのプロモーションを見る",
  per_night: "/泊",
  new_hotel_listing: "新しいホテルリスト",
  popular_destination: "人気エリア",
  hotels: "ホテル",
  see_all_destination: "全てのエリアを見る",
  hotel_in: "のホテル",
  last_booked_hotel: "最後に予約したホテル",
  get_the_best_deal: "割引やシークレット セールなどのお得情報を受け取る",
  to_accept_the_deal: "け取ろう ",
  subscribe: "送信",

  // ***** All Promotion hotel **** //
  all_promotion: "全ての プロモーション",

  // ***** Filter Sidebar hotel **** //
  filter_hotel_result: "絞り込み検索",
  star: "星",
  price: "料金",
  area: "エリア",
  hotel_reviews: "レビュー",
  hotel_type: "ホテルタイプ",
  all: "全ての",
  five_star: "5つ星",
  four_star: "4つ星",
  three_star: "3つ星",
  two_star: "2つ星",
  one_star: "1つ星",
  hotel: "ホテル",
  villa: "ヴィラ",
  apartment: "アパート",
  search_hotel_name: "ホテル名を指定して絞り込む",
  enter_hotel_name: "ホテル名をご記入ください",

  // ***** Search Result hotel **** //
  discount: "割引",
  sorting: "表示順",
  popular: "人気",
  name: "お名前",
  showing: "表示",
  hotels_in: "軒のホテルがあります",
  on: "に",
  no_result: "検索結果はありません",
  home: "ホーム",

  // ******* Hotel Detail ******* //
  foto: "写真",
  information: "ホテル説明",
  general_information: "一般情報",
  facility: "施設",
  services: "サービス",
  map: "地図",
  reviews: "レビュー",
  telephone: "電話番号",
  fax: "ファックス",
  renovation_year: "改装年",
  number_of_floors: "階数",
  time_from_airport: "空港からの時間",
  distance_from_airport: "空港からの距離",
  distance_from_city_center: "中心部からの距離",
  room_voltage: "電圧",
  pet_allowed: "ペットの宿泊",
  earliest_check_in: "チェックイン時間",
  children_guest: "子供の年齢",
  floors: "階",
  rooms: "部屋",
  minutes: "分",
  yes: "はい",
  no: "いいえ",
  hotel_announcement: "ホテルのお知らせ",
  show_more: "さらに表示",
  show_less: "もとに戻す",
  show_room_facility: "部屋ファシリティーをさらに表示",
  room_numbers: "部屋数",

  // ******* Hotel Facility ******* //
  banguet_room: "宴会場",
  car_parking: "駐車場",
  club_lounge: "クラブラウンジ",
  disabled_facilities: "バリアフリー",
  gym: "ジム",
  hair_salon: "ヘアサロン",
  kids_club: "キッズクラブ",
  luggage_room: "ラゲージルーム",
  private_beach: "プライベートビーチ",
  squash_court: "スカッシュコート",
  bars: "バー",
  casino: "カジノ",
  coffe_shop: "喫茶店",
  elevator: "エレベーター",
  gift_shop: "ギフトショップ",
  jacuzzi: "ジャグジー",
  kids_pool: "キッズプール",
  meeting_room: "会議室",
  poolside_bar: "プールサイドバー",
  sauna: "サウナ",
  steam_room: "スチーム",
  business_center: "ビジネスセンター",
  clinic: "医務室",
  departure_lounge: "出発ラウンジ",
  garden: "Garden",
  golf_course: "ゴルフ場",
  caraoke_room: "カラオケ",
  library: "ライブラリー",
  night_club: "ナイトクラブ",
  restaurant: "レストラン",
  spa: "スパ",
  swimming_pool: "スウィミングプール",
  smooking_area: "喫煙エリア",
  remarks: "備考",
  plug_type: "電源種別",
  type: "タイプ",
  multi_plug: "マルチプラグ",

  // ******* Hotel Services ******* //
  room_service_24hour: "24時間ルームサービス",
  baby_sitter: "ベビーシッター",
  laundry: "ランドリー",
  hotel_activities: "アクティビティ",
  rental_multi_plug: "マルチプラグ貸出",
  shuttle: "シャトルバス",
  paid_wifi: "有料Wifi",
  reception_24hour: "24時間レセプション",
  butler: "バトラー",
  massage: "マッサージ",
  rental_baby_content: "ベビーカー貸出",
  rental_wheel_chair: "車いす貸出",
  valet_parking: "バレットパーキング",
  airport_transfer: "空港送迎",
  concierge: "コンシェルジュ",
  money_changer: "両替",
  rental_biycle: "レンタサイクル",
  room_service: "ルームサービス",
  free_wifi: "無料Wifi",
  japanese_staff: "日本語スタッフ",
  english_staff: "英語スタッフ",
  rental_baby_bed: "幼児用ベッド貸し出し",
  rental_bed_guard: "幼児用ベッドガード貸し出し",

  // ******* Available Room ******* //
  update: "変更",
  book_now: "予約",
  room_remaining: "残りわずか",
  no_room: "部屋なし",
  breakfast_include: "朝食付き",
  // breakfast_include_for 	: 'Sarapan termasuk untuk',
  without_breakfast: "朝食なし",
  cancellation_policy: "取消規定",
  request_price: "料金を問い合わせる",
  no_available: "利用できません",
  leave_a_question_or_comment: "お問い合わせフォーム",
  send: "送信する",
  table_prices: "テーブル価格",
  sun: "日",
  mon: "月",
  tue: "火",
  wed: "水",
  thu: "木",
  fri: "金",
  sat: "土",
  room_facility: "ルーム設備",
  room_availibility: "空室状況",
  room_detail: "部屋の詳細",
  duration: "泊数",
  room: "部屋",
  adult: "大人",
  children: "子供",
  change: "変更",
  email: "メールアドレス",
  message: "お問い合せ内容",
  price_inclusive_of_tax: "税込み料金",
  discount_at_least_stay: "1泊以上、1部屋以上の予約",
  day: "日",
  price_and_stock: "価格と在庫",
  adult_breakfast_price: "大人の朝食価格",
  child_breakfast_price: "子供朝食価格",
  additional_cost: "追加費用",

  // Room Facility
  air_conditioner: "冷暖房",
  bathrobe: "バスローブ",
  cable_tv: "ケーブルテレビ",
  dvd_player: "DVDプレイヤー",
  independent_shower_room: "シャワーブース",
  iron: "アイロン",
  living_room: "リビングルーム",
  moveable_shower_head: "ハンドシャワー",
  shampoo: "シャンプー",
  toothbrush: "歯ブラシ",
  alarm_clock: "目覚まし時計",
  bathub: "バスタブ",
  coffe_maker: "コーヒーメーカー",
  hair_dryer: "ヘアドライヤー",
  safety_box: "セーフティボックス",
  mini_bar: "ミニバー",
  radio: "ラジオ",
  shaver: "シェーバー",
  towel: "タオル",
  balcony: "バルコニー",
  body_lotion: "ボディローション",
  cotton_bud: "綿棒",
  idd_telephone: "IDD電話",
  internet_jack: "インターネットジャック",
  kitchen_set: "キッチン",
  mosquito_equipment: "蚊取り器",
  room_wear: "部屋着",
  soap: "せっけん",
  tv: "テレビ",
  room_size: "部屋面積",
  bedrooms: "ベッド数",
  complimentary_water_bottle: "無料のミネラルウオーター",
  fan: "ファン",
  slipper: "スリッパ",
  body_shampoo: "ボディーシャンプー",
  toothpaste: "歯磨き粉",
  nhk_tv_channel: "衛星放送",
  electric_pot: "電気ポット",

  // ******* Booking Form ******* //
  booking_form: "予約フォーム",
  booking_information: "予約情報",
  title: "敬称",
  phone_number: "電話番号",
  nationality: "国籍",
  same_as_booker: "宿泊者は予約者と同様",
  guest_fullname: "ご宿泊されるお客様のお名前",
  special_request: "スペシャルリクエスト",
  additional_notes: "追加事項",
  payment_information: "お支払情報",
  total_payment: "支払合計額",
  total_room_price: "部屋代の合計",
  tax_and_service: "税金とサービス",
  process_payment: "支払処理",
  non_smooking_room: " 禁煙ルーム",
  large_bed: "大き目のベッド",
  late_check_in: "レイトチェックイン",
  twin_bed: "ツインベッド",
  early_check_in: "アーリーチェックイン",
  high_floor: "高層階",
  air_transfer: "空港送迎",
  i_have_special_request: "私は特別な要求があります",
  i_am_not_stay: "私はホテルに泊まっていません。 私は誰かのためにこの予約をします",
  optional: "オプション",
  term_agree_info: "このボタンをクリックすると、あなたはあなたが",
  and: "そして",
  of_hoterip: "Hoterip",
  agent_information: "ご予約エージェントの情報",
  please_fill_customer_data: "ご宿泊者の情報",

  // ******* Payment page ******* //
  paymentMethod2c2p: "クレジットカード／振り込み／Wallet、その他",
  credit_card: "クレジットカード",
  internet_banking: "ネットバンキング",
  pay_with: "で支払う",
  payNow: "今払う",
  indonesian_only: "インドネシア銀行のみ",
  name_on_card: "クレジットカード保有者名",
  card_number: "クレジットカード番号",
  expiry_month: "有効期限",
  expiry_year: "有効期限",
  cvv: "セキュリティコード",
  digit_credit_card: "16桁のクレジットカード",
  digit_cvv: "3桁のcvv",
  are_you_sure_to_pay: "払いで継続しますか？",
  cancel: "キャンセル",
  continue: "持続する",
  please_choose_bank_name: "銀行名を選択してください",
  insert_password: "パスワードを挿入する",

  // ******* Process payment page ******* //
  payment_code: "その他銀行へのペイメントコード",
  payment_code_bca: "BCA用のペイメントコード",
  transfer_amount: "支払合計金額 ",
  please_transfer_to: "に送金してください",
  payment_instruction: "支払手順",
  to_complete_payment: "です",
  you_have: "支払い期限まで残り",
  hours: "時間",
  seconds: "秒",
  payment_info: "支払い情報",
  payment_info_text:
    "お支払い完了いたしましたら、5−10分の間にお客様の登録したメールアドレスに予約情報とホテルバウチャーが送れれます。 ホテルバウチャーをプリントアウトしご予約のホテルへ提示してください。 ホテリップをご利用ありがとうございました。",
  customer_service: "顧客サービス",
  customer_service_text: "もしわからない場合はお気軽に+62 361-721064までお電話ください。(英語、インドネシア語のみ対応)",
  choose: "を選択",
  choose_text: "お客様のペイメントコードを電話番号の欄へ入力してください。",
  danamon_text: 'お客様のペイメントコードの上4桁を"Kode Wilayah"に入力してください。',
  your_booking_has_expired: "あなたの予約は切れています",
  your_payment_was_successfull: "支払は完了しました",
  your_payment_failed: "予約が失敗しました",
  your_payment_failed_text: "カスタマーサポートセンターまでお問い合わせください",

  // ******* Progress Booking ******* //
  complete_booking_form: "お客様情報",
  choose_payment_method: "支払い方法を選択する",
  voucher_send: "ホテルバウチャー送信",

  // ******* SideBar Booking ******* //
  booking_summary: "予約要約",
  breakfast: "朝食",
  room_name: "部屋名",
  extrabeds: "エクストラベッド",
  price_details: "料金の詳細",
  taxes: "税金",
  nights: "泊",
  booking_for: "ご予約",
  included: "含まれる",
  excluded: "含まない",
  persons: "人",
  tax: "税金とサービス料",

  // ***************** Help ******* //
  contact_person: "連絡先のお名前",
  hotel_name: "ホテル名",
  how_to_make_a_booking: "予約方法",
  about_hoterip: "運営会社",
  additional_benefit: "嬉しい特典",
  company_name: "名称",
  siup: "ライセンス番号",
  initial_capital: "資本金",
  opening_year: "設立",
  address: "住所",
  total_employee: "従業員数",
  iata_number: "加盟・加盟団体",
  bank_partner: "取引銀行",

  // ******** Member Page ********* //
  profile: "プロフィール",
  picture: "写真",
  display_name: "ユーザー名",
  home_phone: "固定電話番号",
  mobile_phone: "携帯電話番号",
  currency: "通貨",
  language: "言語",
  timezone: "タイムゾーン",
  newsletter: "送信",
  save: "保存",
  change_password: "パスワード変更",
  old_password: "既存のパスワード",
  new_password: "新しいパスワード",
  confirm_new_password: "新しいパスワード確認用",
  bookings: "予約",
  iwouldlike: "プロモーションなどのメールを受信します",
  confirmed: "コンファーム済み",
  cancelled: "キャンセル済み",
  booking_detail: "予約詳細",
  total: "合計",
  booking_id: "予約番号",
  guest_name: "宿泊者の名前",
  booking_date: "予約日",
  check_in_date: "チェックイン日",
  check_out_date: "チェックアウト日",
  number_of_rooms: "部屋数",
  you_can_send: "このフォームを利用してホテルにお問い合わせすることができます",
  write_message_here: "メッセージをご記入ください",

  // ******** SEO  ********* //
  lowest_price_and_special_promotion: "",
  in: "",
  minute_by_car_from_airport: "",
  etc: "",
  discount_hotel: ", discount hotel, ",
  online_reservation: " online booking, ",
  real_time_booking: " real time booking, ",
  cheap_hotel: " 最安値 ",
  special_promotion: ", スペシャルプロモーション ",

  // ************** Page City ******* //
  hotel_available: "軒の宿泊施設",
  // find_hotel_in 						: 'Find Hotel in',
  // popular_hotels_in 					: 'Popular Hotels in',
  // specialPromotion_hotel_in 			: 'Hotel Special Deal',
  // best_hotel_in_each_area 			: 'Best Hotel in Each Area in',
  // list_hotel_in 						: 'List Hotels in',
  // about 								: 'About',

  pax_passport: "国籍",
  city_destination_area_or_hotel_name: "目的地・宿泊施設を入力してください",
  recomended_hotel: "お勧めホテル",
  our_partnership: "パートナーシップ",
  deposit: "デポジット",
  my_bookings: "私の予約",
  alphabetical: "ホテル名順",
  enter_a_hotel_name: "ホテル名を入力してください",
  guest_review: "口コミ",
  number_of_nights: "宿泊数",
  fullname: "お名前",
  your_balance: "残高",
  cancel_this_booking: "この予約をキャンセル",
  deposit_information: "デポジット情報",
  deposit_currency: "デポジット通貨",
  total_deposit: "デポジット合計",
  used_deposit: "利用デポジット",
  balance_deposit: "残りのデポジット",
  user_detail: "ユーザーの詳細",
  travel_agent_information: "エージェント情報",
  contact_person_name: "連絡先のお名前",
  sex: "性別",
  male: "女性",
  female: "男性",
  position: "職種",
  iata_asita_number: "IATA/ASITA",
  please_select: "お選びください",
  company_address: "会社の住所",
  district: "地区",
  city: "都市",
  country: "国",
  website: "ウェーブサイト"
};

module.exports = { data };
