import * as Env from '../Env';

const updateQuery = (query) => {
    var data = {
        type: 'UPDATE_QUERY',
        id: 1,
        query: query
    }

    return data;
}

export { updateQuery }
