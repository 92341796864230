import React, { Component } from 'react';
import { Link } from 'react-router';
import Trans from '../../locale/Translate';

import Additional from '../../assets/images/icon/benefit_room_small.png';
import Support from '../../assets/images/icon/support_small.png';
import Hoterip from '../../assets/images/icon/his.png';
import Term from '../../assets/images/icon/term.png';
import Privacy from '../../assets/images/icon/privacy.png';

export default class TopNav extends Component {
    render() {
        const isActive = (url) => {
            return this.props.url === url ? 'active' : null;
        }

        return (
            <div className="menu-navigation">
                <div className="container">
                    <ul className="nav nav-pills nav-justified" style={{ display: 'block' }}>
                        <li role="presentation" className={isActive('/about-hoterip')}>
                            <Link to="/about-hoterip">
                                <img src={Hoterip} width={60} role="presentation" />
                                <div>{Trans.data.about_hoterip}</div>
                            </Link>
                        </li>
                        <li role="presentation" className={isActive('/term')}>
                            <Link to="/term">
                                <img src={Term} role="presentation" />
                                <div>{Trans.data.term_condition}</div>
                            </Link>
                        </li>
                        <li role="presentation" className={isActive('/privacy-policy')}>
                            <Link to="/privacy-policy">
                                <img src={Privacy} role="presentation" />
                                <div>{Trans.data.privacy_policy}</div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}