const data = {
  // ********** Header ********** //
  english: "Inggris",
  japan: "Jepang",
  bahasa: "Bahasa Indonesia",
  sign_out: "Keluar",
  sign_in: "LOGIN",
  sign_up: "Daftar",
  sign_in_to_hoterip: "Login ke Hoterip",
  sign_up_with_hoterip: "Daftar ke Hoterip",
  sign_in_with_facebook: "Login dengan Facebook",
  sign_in_with_twitter: "Login dengan Twitter",
  sign_in_with_google: "Login dengan Google",
  forgot_password: "Lupa Password?",
  create_account: "DAFTAR",
  no_account_yet: "Belum ada akun?",
  already_have_an_account: "Sudah punya akun?",
  email_address: "Alamat Email",
  password: "Kata Sandi",
  first_name: "Nama Depan",
  last_name: "Nama Belakang",
  or: "atau",
  send_to_email: "Kirim ke email",
  back_to: "Kembali ke",
  new_to_hoterip: "Baru di Hoterip?",
  sign_up_now: "Daftarkan sekarang",

  // ********** Footer ********** //
  lowest_price: "Harga Terendah",
  highest_price: "Harga Tertinggi",
  contact_us: "Hubungi Kami",
  hotel_popular: "Hotel Populer",
  help: "Bantuan",
  payment_method: "Metode Pembayaran",
  guarante_lowest_price: "Jaminan Harga Terbaik",
  easy_to_search: "Mudah Melihat Harga",
  good_services: "Layanan Terbaik",
  faq: "Pertanyaan Umum",
  term_condition: "Syarat dan Ketentuan",
  privacy_policy: "Kebijakan Privasi",
  career: "Karir",
  hotel_partner: "Hotel Rekanan",

  // ********** Home ********** //
  kota_area_tujuan: "Kota, Area Tujuan atau Nama Hotel",
  find_city: "Cari Kota, Daerah Tujuan atau Nama Hotel",
  destination: "Destinasi",
  check_in: "Check-in",
  number_of_night: "Jumlah Malam",
  check_out: "Check-out",
  night: "Malam",
  search_hotel: "Cari Hotel",
  why_choose_hoterip_com: "Mengapa Memilih HIS Hotels?",

  around_bali: "Keliling Bali Gratis",
  around_bali_info: "Dapatkan jalan-jalan keliling Bali gratis hanya dengan pesan hotel di Hoterip",

  best_service: "Layanan Terbaik",
  best_service_info:
    'Prioritas utama kami memberikan layanan terbaik sesuai slogan kami "Standar Internasional Tertinggi".',

  discount_with_hoterip: "Diskon Belanja dengan Voucher Hoterip",
  discount_with_hoterip_info: "Dapatkan kartu diskon belanja MAIMAI hingga 50% off.",

  room_benefit: "Benefit Kamar",
  room_benefit_info:
    "Dapatkan layanan antar jemput dari dan ke bandara atau pijat secara cuma-cuma, dan masih banyak benefit lainnya.",

  read_more: "Baca selanjutnya",

  best_deal_of_the_week: "Deal Terbaik Minggu ini",
  avg_pernight: "rata2/malam",
  check_price: "Lihat Harga",
  hotel_promo: "Hotel Promo",
  see_all_promo: "Lihat Semua Promo",
  view_all_promo: "Lihat semua promo",
  per_night: "/Malam",
  new_hotel_listing: "Daftar Hotel Baru",
  popular_destination: "Tujuan Populer",
  hotels: "Hotel",
  see_all_destination: "Lihat semua tujuan",
  hotel_in: "Hotel di",
  last_booked_hotel: "Pesan Hotel Terakhir",
  get_the_best_deal: "Dapatkan Penawaran dan Promo Hoterip",
  // to_accept_the_deal		: 'berlangganan sekarang',
  subscribe: "Berlangganan",

  // ***** All Promotion hotel **** //
  all_promotion: "Semua Promosi",

  // ***** Filter Sidebar hotel **** //
  filter_hotel_result: "Filter Pencarian Hotel",
  star: "Bintang",
  price: "Harga",
  area: "Area",
  hotel_reviews: "Ulasan Hotel",
  hotel_type: "Tipe Hotel",
  all: "Semua",
  five_star: "Bintang 5",
  four_star: "Bintang 4",
  three_star: "Bintang 3",
  two_star: "Bintang 2",
  one_star: "Bintang 1",
  hotel: "Hotel",
  villa: "Vila",
  apartment: "Apartemen",
  search_hotel_name: "Cari Nama Hotel",
  enter_hotel_name: "Masukan nama hotel",

  // ***** Search Result hotel **** //
  discount: "Diskon",
  sorting: "Urutkan",
  popular: "Populer",
  name: "Nama",
  showing: "Tampilkan",
  on: "pada",
  no_result: "Tidak ada hasil",

  // ******* Hotel Detail ******* //
  foto: "Foto",
  information: "Deskripsi Hotel",
  general_information: "Informasi Umum",
  facility: "Fasilitas",
  services: "Layanan",
  map: "Peta",
  reviews: "Ulasan",
  telephone: "Telepon",
  fax: "Fax",
  renovation_year: "Tahun Renovasi",
  number_of_floors: "Jumlah Lantai",
  number_of_rooms: "Jumlah Kamar",
  time_from_airport: "Waktu dari Bandara",
  distance_from_airport: "Jarak dari Bandara",
  distance_from_city_center: "Jarak dari Pusat Kota",
  room_voltage: "Voltase Ruang",
  pet_allowed: "Peliharaan Diperbolehkan",
  earliest_check_in: "Check-in Paling Awal",
  children_guest: "Tamu Anak-anak",
  floors: "Lantai",
  rooms: "Kamar",
  minutes: "Menit",
  yes: "YA",
  no: "TIDAK",
  guest_are_considered: "Tamu dianggap anak-anak mulai umur ",
  years_old: "tahun",
  hotel_announcement: "Pemberitahuan Hotel",
  show_more: "Tampilkan lebih banyak",
  show_less: "Tampilkan lebih sedikit",
  show_room_facility: "Tampilkan fasilitas kamar",
  price_start_from: "Harga Mulai Dari",
  room_numbers: "Jumlah kamar",
  remarks: "Catatan",
  plug_type: "Jenis Steker",
  type: "Jenis",
  multi_plug: "Berbagai-Steker",

  // ******* Hotel Facility ******* //
  banguet_room: "Ruang Banquet",
  car_parking: "Tempat Parkir",
  club_lounge: "Klub Lounge",
  disabled_facilities: "Fasilitas Orang Cacat",
  gym: "Gym",
  hair_salon: "Salon",
  kids_club: "Tempat Bermain Anak",
  luggage_room: "Tempat Penitipan Barang",
  private_beach: "Pantai Pribadi",
  squash_court: "Lapangan Squash",
  bars: "Bar",
  casino: "Kasino",
  coffe_shop: "Kedai Kopi",
  elevator: "Lift",
  gift_shop: "Toko Oleh-oleh",
  jacuzzi: "Jacuzzi",
  kids_pool: "Kolam Renang Anak",
  meeting_room: "Ruang Pertemuan",
  poolside_bar: "Bar Pinggir Kolam",
  sauna: "Sauna",
  steam_room: "Ruangan Mandi Uap",
  business_center: "Pusat Bisnis",
  clinic: "Klinik",
  departure_lounge: "Ruang Tunggu Keberangkatan",
  garden: "Garden",
  golf_course: "Lapangan Golf",
  caraoke_room: "Ruang Karaoke",
  library: "Perpustakaan",
  night_club: "Klub Malam",
  restaurant: "Restoran",
  spa: "Spa",
  swimming_pool: "Kolam Renang",
  smooking_area: "Tempat Merokok",

  // ******* Hotel Services ******* //
  room_service_24hour: "Layanan Kamar 24 jam",
  baby_sitter: "Penjaga Anak",
  laundry: "Binatu",
  hotel_activities: "Aktifitas Hotel",
  rental_multi_plug: "Penyewaan Bermacam Steker",
  shuttle: "Layanan Antar-jemput",
  paid_wifi: "Wi-fi Berbayar",
  reception_24hour: "Resepsionis 24 jam",
  butler: "Kepala Pelayan",
  massage: "Pijat",
  rental_baby_content: "Penyewaan Kereta Bayi",
  rental_wheel_chair: "Penyewaan Kursi Roda",
  valet_parking: "Layanan Parkir",
  airport_transfer: "Antar-jemput Airport",
  concierge: "Pelayan Tamu",
  money_changer: "Penukaran Uang",
  rental_biycle: "Penyewaan Sepeda",
  room_service: "Layanan Kamar",
  free_wifi: "Wi-fi Gratis",
  japanese_staff: "Staf Bahasa Jepang",
  english_staff: "Staf Bahasa Inggris",
  rental_baby_bed: "Sewa Tempat Tidur Bayi",
  rental_bed_guard: "Sewa Penjaga Tempat Tidur",

  // ******* Available Room ******* //
  update: "UBAH",
  book_now: "Pesan Sekarang",
  room_remaining: "Kamar Tersisa",
  no_room: "Tidak Ada Kamar",
  breakfast_include: "Termasuk sarapan",
  breakfast_include_for: "Sarapan termasuk untuk",
  persons: "orang",
  without_breakfast: "Tanpa sarapan",
  cancellation_policy: "Kebijakan Pembatalan",
  request_price: "Tanya Harga",
  no_available: "Tidak Tersedia",
  leave_a_question_or_comment: "Kirim pesan",
  send: "Kirim",
  table_prices: "Tabel Harga",
  sun: "Ming",
  mon: "Sen",
  tue: "Sel",
  wed: "Rab",
  thu: "Kam",
  fri: "Jum",
  sat: "Sab",
  room_facility: "Fasilitas Kamar",
  room_availibility: "Ketersediaan Kamar",
  room_detail: "Rincian Kamar",
  duration: "Malam",
  room: "Kamar",
  adult: "Dewasa",
  children: "Anak-anak",
  change: "Ubah",
  email: "Email",
  message: "Pesan",
  price_inclusive_of_tax: "Harga Termasuk Pajak",
  discount_at_least_stay: "Menginap minimal 1 malam dan memesan 1 kamar atau lebih.",
  day: "Hari",
  price_and_stock: "Harga & Stok",
  adult_breakfast_price: "Harga Sarapan Dewasa",
  child_breakfast_price: "Harga Sarapan Anak-anak",
  price_for: "Harga untuk",
  additional_cost: "Biaya Tambahan",

  // Room Facility
  air_conditioner: "Pendingin Ruangan",
  bathrobe: "Baju Mandi",
  cable_tv: "TV Kabel",
  dvd_player: "Alat Pemutar DVD",
  independent_shower_room: "Ruangan Pancuran Air Terpisah",
  iron: "Setrika",
  living_room: "Ruang Tamu",
  moveable_shower_head: "Pancuran Air Bergerak",
  shampoo: "Sampo",
  toothbrush: "Sikat Gigi",
  alarm_clock: "Jam Weker",
  bathub: "Bak Mandi",
  coffe_maker: "Alat Pembuat Kopi",
  hair_dryer: "Pengering Rambut",
  safety_box: "Brankas",
  mini_bar: "Mini Bar",
  radio: "Radio",
  shaver: "Alat Cukur",
  towel: "Handuk",
  balcony: "Teras",
  body_lotion: "Pelembab Tubuh",
  cotton_bud: "Pembersih Telinga",
  idd_telephone: "Telepon SLI",
  internet_jack: "Sambungan Internet",
  kitchen_set: "Perlengkapan Dapur",
  mosquito_equipment: "Alat Penghalau Nyamuk",
  room_wear: "Ruang Ganti",
  soap: "Sabun",
  tv: "TV",
  room_size: "Luas kamar",
  bedrooms: "Jumlah Tempat Tidur",
  complimentary_water_bottle: "Air Minum Gratis",
  fan: "Kipas",
  slipper: "Sandal",
  body_shampoo: "Shampo Tubuh",
  toothpaste: "Pasta Gigi",
  nhk_tv_channel: "Saluran Nhk Tv",
  electric_pot: "Pot Listrik",

  // ******* Booking Form ******* //
  booking_form: "Formulir Pemesanan",
  booking_information: "Informasi Pemesanan",
  title: "Titel",
  phone_number: "No Telepon",
  nationality: "Kewarganegaraan",
  same_as_booker: "Sama dengan pemesan",
  guest_fullname: "Nama Lengkap Tamu",
  special_request: "Permintaan Khusus",
  additional_notes: "Catatan Tambahan",
  payment_information: "Informasi Pembayaran",
  total_payment: "Total Pembayaran",
  total_room_price: "Total Harga Kamar",
  tax_and_service: "Pajak dan Layanan",
  process_payment: "Proses Pembayaran",
  non_smooking_room: "Kamar Bebas Rokok",
  large_bed: "Tempat Tidur Besar",
  late_check_in: "Check-in Telat",
  twin_bed: "Twin Bed",
  early_check_in: "Check-in Awal",
  high_floor: "Lantai Atas",
  air_transfer: "Antar-jemput Airport",
  i_have_special_request: "Saya punya permintaan khusus",
  i_am_not_stay: "Saya tidak menginap di hotel. Saya membuat pemesanan ini untuk orang lain",
  optional: "pilihan",
  term_agree_info: "Dengan mengklik tombol ini, Anda mengetahui bahwa Anda telah membaca dan menyetujui",
  and: "dan",
  of_hoterip: "Hoterip",
  choose: "Pilih",
  choose_text: "",
  danamon_text: "",
  your_booking_has_expired: "Pemesanan Anda telah habis masa berlakunya",
  agent_information: "Informasi Agen",
  please_fill_customer_data: "Mohon masukan data pelanggan",

  // ******* Payment page ******* //
  paymentMethod2c2p: 'Bank Kartu Kredit/Transfer/Wallet, lainnya',
  credit_card: "Kartu Kredit",
  internet_banking: "Bank Internet",
  pay_with: "Bayar menggunakan",
  payNow: "Bayar Sekarang",
  indonesian_only: "Hanya Indonesia",
  name_on_card: "Nama pemegang kartu kredit",
  card_number: "Nomor kartu kredit",
  expiry_month: "Bulan kadaluarsa",
  expiry_year: "Tahun kadaluarsa",
  cvv: "CVV",
  digit_credit_card: "16 angka kartu kredit",
  digit_cvv: "3 angka CVV",
  are_you_sure_to_pay: "Apakah Anda yakin membayar dengan",
  cancel: "Batal",
  continue: "Lanjutkan",
  please_choose_bank_name: "Pilih salah satu nama bank",
  insert_password: "Masukan kata sandi",

  // ******* Process payment page ******* //
  payment_code: "Kode Pembayaran",
  payment_code_bca: "Kode Pembayaran Bank BCA",
  transfer_amount: "Jumlah total pembayaran",
  please_transfer_to: "Silahkan Transfer ke",
  payment_instruction: "Instruksi Pembayaran",
  to_complete_payment: "Untuk menyelesaikan pembayaran",
  you_have: "Anda punya",
  hours: "Jam",
  seconds: "Detik",
  payment_info: "Informasi Pembayaran",
  payment_info_text:
    "Setelah anda menyelesaikan pembayaran, tidak lebih dari 5-10 menit, anda akan menerima e-mail yang dilengkapi dengan detail pemesanan anda dan voucher hotel ke alamat email yang telah anda registrasi sebelumnya. Mohon tunjukkan voucher hotel pada saat anda check-in.Terima kasih telah menggunakan HIS Hotels. Silahkan mengunjungi kami kembali.",
  customer_service: "Pelayanan Pelanggan",
  customer_service_text:
    "Jika anda masih kurang jelas, harap hubungi staff kami menggunakan online chat atau di 0361-721064.",
  your_payment_was_successfull: "Pembayaran Anda Telah Sukses",
  your_payment_failed: "Kami tidak dapat memproses pembayaran anda",
  your_payment_failed_text: "Silakan hubungi Layanan Pelanggan Hoterip untuk bantuan lebih lanjut",

  // ******* Progress Booking ******* //
  complete_booking_form: "Isi Data Lengkap",
  choose_payment_method: "Pilih Metode Pembayaran",
  voucher_send: "Voucher Terkirim",

  // ******* SideBar Booking ******* //
  booking_summary: "Ringkasan Pemesanan",
  breakfast: "Sarapan",
  room_name: "Tipe Kamar",
  extrabeds: "Tempat Tidur Tambahan",
  price_details: "Rincian Harga",
  taxes: "Pajak",
  nights: "malam",
  booking_for: "Pemesanan untuk",
  included: "Termasuk",
  excluded: "Tanpa Sarapan",
  tax: "Pajak",

  // ************** Help ******* //
  contact_person: "Narahubung",
  hotel_name: "Nama Hotel",
  how_to_make_a_booking: "Cara Pesan Hotel",
  about_hoterip: "Tentang HIS Hotels",
  additional_benefit: "Keuntungan Tambahan",
  company_name: "Nama Perusahaan",
  siup: "SIUP",
  initial_capital: "Modal Awal",
  opening_year: "Tahun Berdiri",
  address: "Alamat",
  total_employee: "Jumlah Karyawan",
  iata_number: "Nomor IATA",
  bank_partner: "Rekanan Bank",

  // ******** Member Page ********* //
  profile: "Profil",
  picture: "Gambar",
  display_name: "Nama Tampilan",
  home_phone: "Telepon Rumah",
  mobile_phone: "Telepon Genggam",
  currency: "Mata Uang",
  language: "Bahasa",
  timezone: "Zona Waktu",
  newsletter: "Berlangganan",
  save: "Simpan",
  change_password: "Ganti Kata Sandi",
  old_password: "Kata Sandi Lama",
  new_password: "kata Sandi Baru",
  confirm_new_password: "Konfirmasi Kata Sandi Baru",
  bookings: "Pemesanan",
  home: "Beranda",
  iwouldlike: "saya ingin menerima penawaran dan promosi spesial",
  confirmed: "Dikonfirmasi",
  cancelled: "Dicancel",
  booking_detail: "Rincian pemesanan",
  total: "Total",
  booking_id: "ID Pemesanan",
  guest_name: "Nama tamu",
  booking_date: "Tanggal pemesanan",
  check_in_date: "Tanggal check-in",
  check_out_date: "Tanggal check-out",
  you_can_send: "Anda dapat mengirim pesan ke hotel melalui form di bawah ini",
  write_message_here: "Ketik pesan anda disini..",

  // ************* SEO  *************** //
  lowest_price_and_special_promotion: "Harga Termurah dan Spesial Promosi untuk ",
  in: "di ",
  minute_by_car_from_airport: " menit dengan mobil dari bandara dengan ",
  etc: "dll",
  discount_hotel: ", diskon hotel, ",
  online_reservation: " pemesanan online, ",
  real_time_booking: " pemesanan cepat, ",
  cheap_hotel: " hotel murah ",
  special_promotion: ", special promosi ",

  // ************** Page City ******* //
  find_hotel_in: "Temukan Hotel di",
  hotel_available: "hotel tersedia",
  popular_hotels_in: "Hotel yang sedang populer di",
  specialPromotion_hotel_in: "Penawaran spesial hotel di",
  best_hotel_in_each_area: "Pilihan hotel terbaik di",
  list_hotel_in: "Daftar Hotel di",
  hotels_in: "Hotel di",
  about: "Tentang",

  pax_passport: "Pax Passport",
  city_destination_area_or_hotel_name: "Kota, Tujuan atau Nama Hotel",
  recomended_hotel: "Hotel Rekomendasi",
  our_partnership: "Kemitraan Kami",
  deposit: "Setoran",
  my_bookings: "Pemesanan Saya",
  alphabetical: "Alfabet",
  enter_a_hotel_name: "Masukan nama hotel",
  guest_review: "ulasan tamu",
  number_of_nights: "Jumlah Malam",
  fullname: "Nama Lengkap",
  your_balance: "Saldo anda",
  cancel_this_booking: "Batalkan Pemesanan",
  deposit_information: "Informasi Setoran",
  deposit_currency: "Mata uang Setoran",
  total_deposit: "Total Setoran",
  used_deposit: "Setoran yang digunakan",
  balance_deposit: "Saldo",
  user_detail: "Detail Pengguna",
  travel_agent_information: "Informasi Travel Agen",
  contact_person_name: "Nama",
  sex: "Jenis Kelamin",
  male: "Laki-laki",
  female: "Perempuan",
  position: "Jabatan",
  iata_asita_number: "No. IATA/ASITA",
  please_select: "Silahkan Pilih",
  company_address: "Alamat Perusahaan",
  district: "Daerah",
  city: "Kota",
  country: "Negara",
  website: "Website"
};

module.exports = { data };
