import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import '../../assets/css/select2.css';
import * as Env from '../../Env';
import $ from 'jquery';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Trans from '../../locale/Translate';
import { connect } from 'react-redux';
import { updateQuery } from '../../actions/search.js';
import axios from 'axios';
import { Link, useLocation } from 'react-router';
import { stringify } from 'query-string';

class SearchHotelBoxInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                alphabetical: '',
                capacities: JSON.stringify([{ numberOfAdults: 1, numberOfChildren: 0 }]),
                number_of_adults: 1,
                number_of_children: 0,
                childrenAges: [],
                check_in: moment().toDate(),
                check_out: moment().add(1, 'days').toDate(),
                destination: '',
                flag: '',
                hotel_slug: '',
                limit: 50,
                min_price: 0,
                max_price: 99999999,
                number_of_nights: 1,
                number_of_rooms: 1,
                pax_passport: 98,
                review: JSON.stringify([1, 2, 3, 4]),
                segment_market_id: 3,
                sort: 'NAME',
                star: JSON.stringify([1, 2, 3, 4, 5]),
                type: JSON.stringify([1, 2, 3]),
                sortLimit: null
            },
            show_modal_guest_room_detail: false,
            autocomplete: [],
            show_autocomplete: false,
            nationalities: [],
            hasError: false,
            isMobileView: false
        };
    }

  

    componentDidMount() {
        window.addEventListener("resize", this.updateViewMode);
        this.updateViewMode(); // Initialize view mode
        this.fetchNationalities();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps.data.query });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateViewMode);
    }
    
    
      updateViewMode = () => {
        this.setState({ isMobileView: window.innerWidth <= 660 });
    }

    handleClickSearchButton(event) {
        if (this.state.data.destination) {
            event.stopPropagation();

            let data = this.state.data;
            data.page = 1;

            this.setState({ hasError: false });

            this.props.dispatch(updateQuery(data));

            return true;
        } else {
            event.preventDefault();

            this.setState({ hasError: true });

            alert(`${Trans.data.city_destination_area_or_hotel_name} is required!`);

            return false;
        }
    }

    handleChangePaxPassport(event) {
        let data = this.state.data;
        data.pax_passport = event.target.value;
        data.segment_market_id = event.target.options[event.target.selectedIndex].getAttribute('segment_market_id');

        this.setState({ data: data });
    }

    handleChangeNumberOfRooms(event) {
        let data = this.state.data;
        data.number_of_rooms = event.target.value;

        let number_of_rooms = [];

        for (let i = 0; i < this.state.data.number_of_rooms; i++) {
            number_of_rooms.push({ 'numberOfAdults': 1, 'numberOfChildren': 0 });
        }

        data.capacities = JSON.stringify(number_of_rooms);

        this.setState({ data: data });
    }

    handleClickModalGuestRoomDetail() {
        this.setState({ show_modal_guest_room_detail: !this.state.show_modal_guest_room_detail });
    }

    fetchLocation() {
        if (this.state.data.destination.length >= 3) {
            var that = this;

            axios.get(Env.url.api_url + 'hotels/locations/search', {
                params: { q: this.state.data.destination },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accept-Language': localStorage.getItem('language'),
                    'Accept-Currency': localStorage.getItem('currency')
                }
            })
            .then(response => {
                if (response.data.data.length > 0) {
                    that.setState({
                        autocomplete: response.data.data,
                        show_autocomplete: true
                    });
                    // for(var i=0;i<response.data.data.length;i++){
                    //     if(!response.data.data[i].label.includes(this.state.data.destination)){
                    //         that.setState({
                    //             show_autocomplete: false
                    //         })
                    //     }
                    // }
                }  else {
                    that.setState({
                        autocomplete: [],
                        show_autocomplete: false
                    });
                }
            })
            .catch(error => {
                Env.expired(error);
            });
        }
    }

    handleChangeDestination(event) {
        let data = this.state.data;
        data.destination = event.target.value;

        this.setState({ data: data }, () => this.fetchLocation());
    }

    handleClickClearDestination() {
        let data = this.state.data;
        data.destination = "";

        this.setState({
            data: data,
            show_autocomplete: false,
        }, () => $('.input-location').focus());
    }

    handleClickAutocomplete(result) {
        this.setState({
            data: {
                alphabetical: this.state.data.alphabetical,
                capacities: this.state.data.capacities,
                check_in: this.state.data.check_in,
                check_out: this.state.data.check_out,
                city_id: result.city_id,
                city_slug: result.city_slug,
                childrenAges: [],
                number_of_adults: 1,
                number_of_children: 0,
                city_name: result.city_name,
                country_id: result.country_id,
                country_slug: result.country_slug,
                country_name: result.country_name,
                destination: result.label,
                district: result.selected_districts,
                flag: result.flag,
                hotel_name: (result.flag === 'hotel' ? result.label : ''),
                hotel_slug: result.url_segment,
                limit: this.state.data.limit,
                min_price: this.state.data.min_price,
                max_price: this.state.data.max_price,
                number_of_nights: this.state.data.number_of_nights,
                number_of_rooms: this.state.data.number_of_rooms,
                page: 1,
                pax_passport: this.state.data.pax_passport,
                review: this.state.data.review,
                segment_market_id: this.state.data.segment_market_id,
                sort: this.state.data.sort,
                star: this.state.data.star,
                type: this.state.data.type,
                sortLimit: JSON.stringify({
                    type: "NAME",
                    offset: 0,
                    limit: 5
                })
            },
            autocomplete: [],
            show_autocomplete: false,
        });
    }

    handleChangeCheckIn(date) {
        let data = this.state.data;

        let diff = parseFloat(moment().diff(moment(date)));

        if (diff < 0) {
            data.check_in = moment(date).toDate();
            data.check_out = moment(date).add(data.number_of_nights, 'days').toDate();
        } else {
            // data.check_in = moment(data.check_in).format("YYYY-MM-DD");
            // data.check_out = moment(data.check_out).format("YYYY-MM-DD");
            data.check_in = moment().toDate();
            data.check_out = moment(date).add(data.number_of_nights, 'days').toDate();
        }

        this.setState({ data: data });
    }

    handleChangeCheckOut(date) {
        let data = this.state.data;

        data.check_in = moment(data.check_in).format('YYYY-MM-DD');
        date = moment(date).format('YYYY-MM-DD');

        let diff = parseFloat(moment(data.check_in).diff(moment(date)));

        if (diff < 0) {
            let diff_days = moment(date).diff(moment(data.check_in), 'days');

            data.check_out = moment(date).toDate();
            data.number_of_nights = diff_days;
        } else {
            data.check_out = moment(data.check_out).toDate()
        }

        this.setState({ data: data });
    }

    handleChangeNumberOfNights(event) {
        let data = this.state.data;

        data.number_of_nights = event.target.value;
        data.check_out = moment(data.check_in).add(event.target.value, 'days').toDate();

        this.setState({ data: data });
    }

    fetchNationalities() {
        var that = this;

        axios.get(Env.url.api_url + 'hotels/nationalities', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Accept-Language': localStorage.getItem('language'),
                'Accept-Currency': localStorage.getItem('currency')
            }
        })
        .then(response => {
            if (response.data.meta.success) {
                that.setState({ nationalities: response.data.data });
            }
        })
        .catch(error => {
            Env.expired(error);
        });
    }

    renderSelectOptions(from, to, unit) {
        let options = [];

        for (let i = from; i <= to; i++) {
            options.push(<option value={i} key={i}>{i} {unit}</option>);
        }

        return options;
    }

    showAgeChild(number_of_display_age) {
        let display_ages = [];

        for (let i = 0; i < number_of_display_age; i++) {
            display_ages.push(
                <span className="input-group-btn col-md-2 no-padding" key={i}>
                    <select className={"form-control input-guest-detail-inmodal input-guest-detail--age"}>
                        {this.renderSelectOptions(0, 12, Trans.data.years_old)}
                    </select>
                </span>
            )
        }

        return display_ages;
    }

    prepareRowInputGuestRoomDetail(number_of_rooms) {
        let data_room_rows = [];

        for (let i = 0; i < number_of_rooms; i++) {
            var capacities = JSON.parse(this.state.data.capacities);
            var room_capacities = capacities[i];

            data_room_rows.push(
                <div className="row" value={this.state.rowInput} name={"rowInput_" + i} id={"rowInput_" + i} key={i}>
                    <div className="form-group col-md-12">
                        <div className="input-group">
                            <span className="input-group-btn col-md-2">
                                <label className="label-display-room">{i + 1}</label>
                            </span>
                            <span className="input-group-btn col-md-5">
                                <select onChange={this.handleChangeNumberOfAdults.bind(this, i)} value={room_capacities.number_of_adults} className="form-control input-guest-detail-inmodal input-guest-detail--adult">
                                    {this.renderSelectOptions(1, 9, Trans.data.people)}
                                </select>
                            </span>
                            <span className="input-group-btn col-md-5">
                                <select onChange={this.handleChangeNumberOfChildren.bind(this, i)} value={room_capacities.number_of_children} className="form-control input-guest-detail-inmodal input-guest-detail--child">
                                    {this.renderSelectOptions(0, 6, Trans.data.people)}
                                </select>
                            </span>
                            {/* {this.showAgeChild(2)} */}
                        </div>
                    </div>
                </div>
            )
        }

        return data_room_rows;
    }

    handleChangeNumberOfAdults(index, event) {
        let data = this.state.data;

        let capacities = JSON.parse(this.state.data.capacities);
        capacities[index].number_of_adults = parseInt(event.target.value, 10);

        data.capacities = JSON.stringify(capacities);

        this.setState({ data: data });
    }

    handleChangeNumberOfChildren(index, event) {
        let data = this.state.data;

        let capacities = JSON.parse(this.state.data.capacities);
        capacities[index].number_of_children = parseInt(event.target.value, 10);

        data.capacities = JSON.stringify(capacities);

        this.setState({ data: data });
    }

    render() {
        let { show_autocomplete, autocomplete, isMobileView } = this.state;
        let { check_in, check_out, city_slug, country_slug, hotel_slug, flag, destination, number_of_rooms, capacities, segment_market_id, pax_passport } = this.state.data;

        var lang = 'id';

        switch (localStorage.getItem('language')) {
            case "en-us":
                lang = 'en-gb';
                break;
            case "ja":
                lang = 'ja';
                break;
            default:
                lang = 'id';
                break;
        }

        return (
            <section className="search-hotel-list" style={{ padding: '30px 0 30px 0' }}>
                <div className="container d-flex justify-content-center flex-column">
                    <div className="row">
                        <div className="col-md-4 mv-search">
                            <label className="label-search">{Trans.data.pax_passport}</label>
                            <div className='form-group-x'>
                                <i className="fa fa-flag marker-custom" aria-hidden="true"></i>
                                <select value={this.state.data.pax_passport || ''} onChange={this.handleChangePaxPassport.bind(this)} name="segment_market_id" className="form-control input-lg" placeholder={Trans.data.pax_passport}>
                                    {this.state.nationalities.map((result, i) =>
                                        <option value={result.id} segment_market_id={result.segment_market_id} key={i}>{result.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-8 mv-search">
                            <label className="label-search">{Trans.data.city_destination_area_or_hotel_name}</label>
                            <div className='form-group-x'>
                                <i className="fa fa-map-marker marker-custom" aria-hidden="true"></i>
                                {this.state.data.destination.length >= 3 ? <i className="fa fa-times-circle clear-dst" style={{ right: '30px' }} onClick={this.handleClickClearDestination.bind(this)}></i> : null}
                                <input type="text" value={destination} onChange={this.handleChangeDestination.bind(this)} className="form-control input-lg input-location" style={{borderRadius: '5px !important'}} placeholder={Trans.data.city_destination_area_or_hotel_name} />
                            </div>
                            {this.state.data.destination.length >=3 && autocomplete.length && show_autocomplete ?
                                <div className="autocomplete-wrapper">
                                    <div className="autocomplete">
                                        <ul>
                                            {autocomplete.map((result, i) =>
                                                <li className="list-group-item" key={i} onClick={this.handleClickAutocomplete.bind(this, result)}>
                                                    <span className="badge">{result.flag}</span>
                                                    <strong className="label-name">{result.label}</strong>
                                                    {result.flag === 'district' ? <small>{result.city_name + ', ' + result.country_name}</small> : <small>{result.country_name}</small>}
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mv-search">
                            <div className="input-groups" style={{display:'block'}}>
                                <div className="null no-flex no-padding">
                                    <label className="label-search">{Trans.data.number_of_rooms}</label>
                                    <div className='form-group-x'>
                                        <i className="fa fa-bed marker-custom" aria-hidden="true"></i>
                                        <select className="form-control input-lg input-guest-detail" style={{borderRadius: '5px !important'}} onChange={this.handleChangeNumberOfRooms.bind(this)} value={this.state.data.number_of_rooms} name="number_of_rooms">
                                            {this.renderSelectOptions(1, 5, Trans.data.room)}
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="input-groups-btn" style={{display:'none'}}>
                                    <label style={{ color: 'transparent', marginBottom: '1px' }}>.</label>
                                    <div>
                                        <button type="button" onClick={this.handleClickModalGuestRoomDetail.bind(this)} className="btn btn-info btn-lg btn-guest-detail">
                                            <i className="fa fa-check-square-o" aria-hidden="true"></i> Set Room Detail
										</button>
                                    </div>
                                </div> */}
                            </div>
                            {/* <Modal show={this.state.show_modal_guest_room_detail} onHide={this.handleClickModalGuestRoomDetail.bind(this)}>
                                <Modal.Header style={{ display: "inline-block" }} closeButton>
                                    <Modal.Title style={{ color: "#fff" }} id="contained-modal-title-lg">{Trans.data.guest_room_detail}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="datainput-guest-detail">
                                    <div className="row" style={{ marginBottom: "15px" }}>
                                        <div className="col-md-12 no-padding">
                                            <h3 style={{ marginTop: "0px" }}>{Trans.data.enter_pax_quantity_for} <b>{this.state.data.number_of_rooms}</b> {Trans.data.room}</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-12" style={{ marginBottom: "3px" }}>
                                            <div className="input-group">
                                                <span className="input-group-btn col-md-2">
                                                    <label className="label-modal-guest">{Trans.data.room}</label>
                                                </span>
                                                <span className="input-group-btn col-md-5">
                                                    <label className="label-modal-guest">{Trans.data.adult}</label>
                                                </span>
                                                <span className="input-group-btn col-md-5">
                                                    <label className="label-modal-guest">{Trans.data.children}</label>
                                                </span>
                                                <span className="input-group-btn col-md-2 no-padding">
                                                    <label className="label-modal-guest">{Trans.data.age} 1</label>
                                                </span>
                                                <span className="input-group-btn col-md-2 no-padding">
                                                    <label className="label-modal-guest">{Trans.data.age} 2</label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {this.prepareRowInputGuestRoomDetail(this.state.data.number_of_rooms)}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className="form-group" onClick={this.handleClickModalGuestRoomDetail.bind(this)}>{Trans.data.close}</Button>
                                </Modal.Footer>
                            </Modal> */}
                        </div>
                        <div className="col-md-8 mv-search">
                            <div className="input-groups groups-srch">
                                <div className="col-2 null no-flex no-padding m-full">
                                    <label className="label-search">{Trans.data.check_in}</label>
                                    <div className='form-group-x'>
                                        <i className="fa fa-calendar marker-custom" aria-hidden="true"></i>
                                        <div className="react-datepicker-wrapper range-picker-mobile">
                                            <div className="react-datepicker__input-container">
                                                <DatePicker
                                                    // selected={""}
                                                    startDate={check_in}
                                                    endDate={check_out}
                                                    onChange={(e) => this.handleChangeCheckIn(e)}
                                                    minDate={moment()}
                                                    monthsShown={isMobileView ? 1 : 2}
                                                    locale={lang}
                                                    className="form-control input-lg radius-none-check-in"
                                                    value={moment(check_in).locale(lang).format('DD MMM YYYY')}
                                                    // value={""}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 no-flex no-padding m-full">
                                    <label className="label-search">{Trans.data.number_of_nights}</label>
                                    <div className='form-group-x'>
                                    <i className="fa fa-moon-o marker-custom" aria-hidden="true"></i>
                                    <select onChange={this.handleChangeNumberOfNights.bind(this)} value={this.state.data.number_of_nights} className="form-control input-lg radius-none-number-of-night">
                                        {this.renderSelectOptions(1, 14, Trans.data.night)}
                                    </select>
                                    </div>
                                </div>
                                <div className="col-2 no-flex no-padding m-full">
                                    <label className="label-search">{Trans.data.check_out}</label>
                                    <div className='form-group-x'>
                                    <i className="fa fa-calendar marker-custom" aria-hidden="true"></i>
                                    <div>
                                        <div className="react-datepicker-wrapper range-picker-mobile">
                                            <div className="react-datepicker__input-container">
                                                <DatePicker
                                                    // selected={""}
                                                    startDate={check_in}
                                                    endDate={check_out}
                                                    onChange={(e) => this.handleChangeCheckOut(e)}
                                                    minDate={moment()}
                                                    maxDate={moment(check_in).add(14, 'days')}
                                                    monthsShown={isMobileView ?  1 : 2}
                                                    locale={lang}
                                                    className="form-control input-lg radius-none-check-out"
                                                    value={moment(check_out).locale(lang).format('DD MMM YYYY')}
                                                    // value={""}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="input-groups-btn">
                                    <label style={{ color: 'transparent', marginBottom: '1px' }}>.</label>
                                    {this.props.typeSearch === 1 ?
                                        <div>{flag === 'hotel' ?
                                            <Link className="btn btn-warning btn-lg btn-block" style={{ borderRadius: '0px' }}
                                                to={{
                                                    pathname: `/${country_slug}/${city_slug}/${hotel_slug}`,
                                                    query: {
                                                        check_in: moment(this.state.data.check_in).format("YYYY-MM-DD"),
                                                        check_out: moment(this.state.data.check_out).format("YYYY-MM-DD"),
                                                        number_of_night: this.state.data.number_of_nights,
                                                        room: number_of_rooms,
                                                        capacities: capacities,
                                                        segment_market_id: segment_market_id,
                                                        pax_passport : pax_passport,
                                                    }
                                                }}>
                                                <i className="fa fa-search" aria-hidden="true"></i> {Trans.data.search_hotel}
                                            </Link>
                                            :
                                            <Link className="btn btn-warning btn-lg btn-search-hotel"
                                                onClick={this.handleClickSearchButton.bind(this)}
                                                to={{ pathname: '/hotels/search', query: this.state.data }}>
                                                <i className="fa fa-search" aria-hidden="true"></i> {Trans.data.search_hotel}
                                            </Link>
                                        }</div> :
                                        <div>{flag === 'hotel' ?
                                            <a href={'/' + country_slug + '/' + city_slug + '/' + hotel_slug + '?check_in=' + moment(this.state.data.check_in).format('YYYY-MM-DD') + '&check_out=' + moment(this.state.data.check_out).format('YYYY-MM-DD') + '&number_of_night=' + this.state.data.number_of_nights + '&room='+number_of_rooms+'&capacities='+capacities+'&segment_market_id='+segment_market_id+'&pax_passport='+pax_passport} className="btn btn-warning btn-lg btn-block" style={{ borderRadius: '0px' }}>
                                                <i className="fa fa-search" aria-hidden="true"></i> {Trans.data.search_hotel}
                                            </a>
                                            :
                                            <Link className="btn btn-warning btn-lg btn-search-hotel"
                                                onClick={this.handleClickSearchButton.bind(this)}
                                                to={{ pathname: '/hotels/search', query: this.state.data }}>
                                                <i className="fa fa-search" aria-hidden="true"></i> {Trans.data.search_hotel}
                                            </Link>
                                        }</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.search
    }
}

export default connect(mapStateToProps)(SearchHotelBoxInput);
