import * as Env from "../Env";

const initialState = {
	data: [],
	selectedCurrency: Env._default.currency_label,
	selectedCurrencySymbol: Env._default.currency_code
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'CURRENCY_SUCCESS':
			return {
				...state,
				data: action.data,
			}
		case 'SELECT_CURRENCY':

			let currencySymbol = action.data;
			if (action.data) {
				switch (action.data) {
					case "USD":
						currencySymbol = "$";
						break;
					case "JPY":
						currencySymbol = "¥";
						break;
					case "TWD":
						currencySymbol = "NT$";
						break;
					case "AUD":
						currencySymbol = "AU$";
						break;
					case "MYR":
						currencySymbol = "RM";
						break;
					case "NZD":
						currencySymbol = "NZ$";
						break;
					case "IDR":
						currencySymbol = "Rp";
						break;
					default:
						break;
				}
			}

			return {
				...state,
				selectedCurrency: action.data,
				selectedCurrencySymbol: currencySymbol
			}
		default:
			return state;
	}
}