import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Trans from '../../locale/Translate.js';

export default class ProgressBooking extends Component {

	render(){
		return(
			<div className="col-sm-12 col-md-12">
				<ul className="list-unstyled multi-steps">
				  	<li className={this.props.value <= 1 ? "is-active" : null}>{Trans.data.complete_booking_form}</li>
				  	<li className={this.props.value <= 2 ? "is-active" : null}>{Trans.data.choose_payment_method}</li>
				  	<li className={this.props.value <= 3 ? "is-active" : null}>{Trans.data.process_payment}</li>
				  	<li className={this.props.value <= 4 ? "is-active" : null}>{Trans.data.voucher_send}</li>
				</ul>
			</div>
		)
	}
}

ProgressBooking.defaultProps = {
  value: 1,
};

ProgressBooking.propTypes = {
  value: PropTypes.number,
}