import React, { Component } from 'react'
import { Layout } from '../../components'
import { connect } from 'react-redux';
import OverlayLoader from 'react-overlay-loading/lib/OverlayLoader'
import { registerActivation } from '../../actions/authActions';

class ActivationRegister extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            getNotif: '',
            getNotifType: 0,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ loading: false })

        if (nextProps.dataAuth.isSuccess === false) {
            window.scrollTo(0, 0);
            this.setState({ getNotifType: 0, getNotif: nextProps.dataAuth.notification });
        }
        if (nextProps.dataAuth.isSuccess === true) {
            window.scrollTo(0, 0);
            this.setState({ getNotifType: 1, getNotif: nextProps.dataAuth.notification });
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.setState({ loading: true })

        setTimeout(() => {
			this.props.dispatch(registerActivation(this.props.params.token));
		}, 2000)
    }

    render() {
        const { loading, getNotif, getNotifType } = this.state

        return (
            <div>{loading ?
                <OverlayLoader
                    color={'#159292'}
                    loader="ScaleLoader"
                    text="Activation user..."
                    active={this.state.loading}
                    backgroundColor={'#1B1B1B'}
                    opacity=".9"
                />
                :
                < Layout >
                    <div className="row">
                        <div className="col-xs-4"></div>
                        <div className="col-xs-4" style={{ marginTop: '60px', marginBottom: '40px' }}>
                            {getNotif ?
                                <div className={`alert ${getNotifType == 0 ? 'alert-danger' : 'alert-info'}`} role="alert">
                                    <strong>{getNotifType == 0 ? 'Alert' : 'Thank you'}</strong> {getNotif}
                                </div>
                                : null}
                        </div>
                        <div className="col-xs-4"></div>
                    </div>
                </Layout >}
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return { dataAuth: state.authReducer }
}

export default connect(mapStateToProps)(ActivationRegister)