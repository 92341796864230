import React, { Component } from 'react';
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Trans from '../../locale/Translate';
import { updateRoomDetail } from '../../actions/room_detail.js'
import { updateCapacities } from '../../actions/room_capacities.js'

import 'react-datepicker/dist/react-datepicker.css';

class CheckInBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                check_in: "",
                check_out: "",
                number_of_night: 1,
                room: 1,
            },
            capacities: [
                { numberOfAdults: 1, numberOfChildren: 0 },
            ]
        }

        this.handleCheckIn = this.handleCheckIn.bind(this);
        this.handleCheckOut = this.handleCheckOut.bind(this);
        this.handleChangeNumberOfNights = this.handleChangeNumberOfNights.bind(this);
        this.handleChangeRoom = this.handleChangeRoom.bind(this);
    }

    handleCheckIn(date) {
        let data = this.state.data;
        let diff = parseFloat(moment().diff(moment(date)))

        if (diff < 0) {
            data.check_in = moment(date).format('YYYY-MM-DD');
            data.check_out = moment(date).add(data.number_of_night, 'days').format('YYYY-MM-DD');
        } else {
            // data.check_in = moment(data.check_in).format("YYYY-MM-DD");
            // data.check_out = moment(data.check_out).format("YYYY-MM-DD");
            data.check_in = moment().format('YYYY-MM-DD');;
            data.check_out = moment(date).add(data.number_of_night, 'days').format('YYYY-MM-DD');
        }
        this.setState({ data: data })
    }

    handleCheckOut(date) {
        let data = this.state.data;

        data.check_in = moment(data.check_in).format('YYYY-MM-DD');
        date = moment(date).format('YYYY-MM-DD');
        
        let diff = parseFloat(moment(data.check_in).diff(moment(date)))

        if (diff < 0) {
            let diff_days = moment(date).diff(moment(data.check_in), 'days')
            
            data.check_out = moment(date).format('YYYY-MM-DD');
            data.number_of_night = diff_days

        } else {
            data.check_out = moment(data.check_out).format('YYYY-MM-DD');
        }
        this.setState({ data: data })
    }

    handleChangeNumberOfNights(event) {
        let data = this.state.data;
        data.number_of_night = event.target.value;
        data.check_out = moment(data.check_in).add(event.target.value, 'days').format("YYYY-MM-DD");

        this.setState({ data: data });
    }

    handleChangeRoom(event) {
        let data = this.state.data;
        data.room = event.target.value;

        let array = [];

        for (let i = 0; i < this.state.data.room; i++) {
            array.push({ 'numberOfAdults': 1, 'numberOfChildren': 0 })
        }

        this.setState({
            data: data,
            capacities: array
        })
    }

    handleChangeAdult(idx, event) {
        let adult = this.state.capacities;
        adult[idx].numberOfAdults = parseInt(event.target.value);

        this.setState({
            adult
        })
    }

    handleChangeChildren(idx, event) {
        let child = this.state.capacities;
        child[idx].numberOfChildren = parseInt(event.target.value);

        this.setState({
            child
        });
    }

    dispatch() {
        let data = this.state.data
        let capacities = JSON.stringify(this.state.capacities)

        this.props.dispatch(updateRoomDetail(data))
        this.props.dispatch(updateCapacities(capacities))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.room_detail !== this.props.room_detail) {
            this.setState({
                data: nextProps.room_detail,
                capacities: nextProps.room_capacities.capacities
            })
        }
    }

    render() {
        let { check_in, check_out, room } = this.state.data;

        var lang = 'id';
        switch (localStorage.getItem('language')) {
            case "en-us":
                lang = 'en-gb';
                break;
            case "ja":
                lang = 'ja';
                break;
            default:
                lang = 'id';
                break;
        }

        return (
            <div className="row">
                <form style={{ width: "100%" }}>
                    <div className="col-md-11 col-sm-11">
                        <div className="panel panel-default panel-searched">
                            <div className="panel-body">
                                <div className="col-sm-2">
                                    <label>{Trans.data.check_in}</label>
                                    <DatePicker
                                        selected={check_in ? moment(check_in).toDate() : ""}
                                        startDate={check_in ? moment(check_in).toDate() : ""}
                                        endDate={check_out ? moment(check_out).toDate() : ""}
                                        onChange={this.handleCheckIn}
                                        minDate={moment()}
                                        monthsShown={2}
                                        locale={lang}
                                        className="form-control"
                                        dateFormat={'dd MMM yyyy'}
                                        // value={moment(check_in).locale(lang).toDate()}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <label>{Trans.data.duration}</label>
                                    <select onChange={this.handleChangeNumberOfNights} value={this.state.data.number_of_night} className="form-control">
                                        <option value="1">1 {Trans.data.night}</option>
                                        <option value="2">2 {Trans.data.night}</option>
                                        <option value="3">3 {Trans.data.night}</option>
                                        <option value="4">4 {Trans.data.night}</option>
                                        <option value="5">5 {Trans.data.night}</option>
                                        <option value="6">6 {Trans.data.night}</option>
                                        <option value="7">7 {Trans.data.night}</option>
                                        <option value="8">8 {Trans.data.night}</option>
                                        <option value="9">9 {Trans.data.night}</option>
                                        <option value="10">10 {Trans.data.night}</option>
                                        <option value="11">11 {Trans.data.night}</option>
                                        <option value="12">12 {Trans.data.night}</option>
                                        <option value="13">13 {Trans.data.night}</option>
                                        <option value="14">14 {Trans.data.night}</option>
                                    </select>
                                </div>
                                <div className="col-sm-2">
                                    <label>{Trans.data.check_out}</label>
                                    <DatePicker
                                        selected={check_out ? moment(check_out).toDate() : ""}
                                        startDate={check_in}
                                        endDate={check_out}
                                        onChange={this.handleCheckOut}
                                        minDate={moment()}
                                        maxDate={moment().add(14, 'days')}
                                        monthsShown={2}
                                        locale={lang}
                                        className="form-control"
                                        dateFormat={'dd MMM yyyy'}
                                        // value={moment(check_out).locale(lang).toDate()}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <label>{Trans.data.rooms}</label>
                                    <select onChange={this.handleChangeRoom} value={room} className="form-control">
                                        <option value="1">1 {Trans.data.room}</option>
                                        <option value="2">2 {Trans.data.room}</option>
                                        <option value="3">3 {Trans.data.room}</option>
                                        <option value="4">4 {Trans.data.room}</option>
                                        <option value="5">5 {Trans.data.room}</option>
                                    </select>
                                </div>
                                <div className="col-sm-4">
                                    {
                                        this.state.capacities.map((v, idx) => (
                                            <div key={idx}>
                                                {room !== '1' ? <span>{Trans.data.room}-{idx + 1}</span> : null}
                                                <div className={room !== '1' ? 'fieldsArray' : null}>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <label>{Trans.data.adult}</label>
                                                            <select onChange={this.handleChangeAdult.bind(this, idx)} value={v.numberOfAdults} className="form-control">
                                                                <option value="1">1 {Trans.data.adult}</option>
                                                                <option value="2">2 {Trans.data.adult}</option>
                                                                <option value="3">3 {Trans.data.adult}</option>
                                                                <option value="4">4 {Trans.data.adult}</option>
                                                                <option value="5">5 {Trans.data.adult}</option>
                                                                <option value="6">6 {Trans.data.adult}</option>
                                                                <option value="7">7 {Trans.data.adult}</option>
                                                                <option value="8">8 {Trans.data.adult}</option>
                                                                <option value="9">9 {Trans.data.adult}</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <label>{Trans.data.children}</label>
                                                            <select onChange={this.handleChangeChildren.bind(this, idx)} value={v.numberOfChildren} className="form-control">
                                                                <option value="0">0 {Trans.data.children}</option>
                                                                <option value="1">1 {Trans.data.children}</option>
                                                                <option value="2">2 {Trans.data.children}</option>
                                                                <option value="3">3 {Trans.data.children}</option>
                                                                <option value="4">4 {Trans.data.children}</option>
                                                                <option value="5">5 {Trans.data.children}</option>
                                                                <option value="6">6 {Trans.data.children}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clearfix visible-sm-block"></div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1 col-sm-1">
                        <button onClick={() => this.dispatch()} type="button" className="btn btn-default btn-searched">{Trans.data.update}</button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        room_detail: state.room_detail.query,
        room_capacities: state.room_capacities
    }
}

export default connect(mapStateToProps)(CheckInBox);
