const cheap_campaign = (state = {}, action) => {
    switch (action.type) {
        case 'UPDATE_CHEAP':
            return {
                query: action.query
            }
        default:
            return state
    }
}

export default cheap_campaign;
