import React, { Component } from 'react'

import { connect } from 'react-redux'
import ProgressBooking from '../../modules/ProgressBooking'
import BookingSidebar from '../../components/BookingSidebar'
import Layout from '../../components/Layout'
import { Checkbox } from 'react-icheck';
import { Link } from 'react-router'
import Button from 'react-bootstrap-button-loader';
import Trans from '../../locale/Translate.js'
import * as Env from '../../Env'
import { updateCampaign } from '../../actions/campaign.js';
import { selectCurrencyAction } from '../../actions/currencyActions.js'

var moment = require('moment');
let axios = require('axios');

class BookingForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobileView: false,
            submitted: false,
            load_button: false,

            //agent data
            user_id: 0,
            title_id: 1,
            first_name: "",
            last_name: "",
            guest_fullname: "",
            email: "",
            phone_number: "",
            nationality_id: 0,

            nationalities: [],

            isSpecialRequest: false,
            data: {
                hotel: {
                    hotel_text: {
                        name: null
                    },
                    photo: null,
                    address: null,
                    telephone: null,
                    fax: null,
                    star: null,
                },
                selected_campaign: {
                    campaign: {
                        id: 0,
                        total_price_before_tax: 0,
                        total_tax_price: 0,
                        total_price: 0,
                        total_price_idr: 0,
                        items_data: [],
                        surcharges: [],
                    },
                    room: null,
                    cancellation: {},
                    items: {}
                },
                params: {
                    check_in: moment().format('YYYY-MM-DD'),
                    check_out: moment().add("1 days").format('YYYY-MM-DD'),
                    number_of_rooms: 0
                },
            },
            special_request: [
                { label: `${Trans.data.non_smooking_room}`, key: "non_smooking_room", checked: false },
                { label: `${Trans.data.large_bed}`, key: "large_bed", checked: false },
                { label: `${Trans.data.late_check_in}`, key: "late_check_in", checked: false },
                { label: `${Trans.data.twin_bed}`, key: "twin_bed", checked: false },
                { label: `${Trans.data.early_check_in}`, key: "early_check_in", checked: false },
                { label: `${Trans.data.high_floor}`, key: "high_floor", checked: false },
                { label: `${Trans.data.air_transfer}`, key: "air_transfer", checked: false },
            ],
            capacity: [
                { number_of_adults: 1, number_of_children: 0 },
            ],
            guest_names: [],
            guest_names_selected: [],
            check_in_date: null,
            check_out_date: null,
            number_of_rooms: 0,
            number_of_adults: 1,
            number_of_childs: 0,

            is_non_smoking_room_request: false,
            is_large_bed_request: false,
            is_late_check_in_request: false,
            is_twin_beds_request: false,
            is_early_check_in_request: false,
            is_high_floor_request: false,
            is_airport_transfer_request: false,
            request_note: "",
            number_of_points_used: 0,

            segment_market_id: 3,
            pax_passport: 98,

            rate_plan_data: {
                cancelations: [],
                cancellation: null,
                surcharges: [],
                surcharge_price_total: 0,
                price_total: 0,
                price_before_tax_total: 0,
                tax_total: 0
            },

            loading: true,
            error: false,
        }

        this.handleInputChange = this.handleInputChange.bind(this)

        this.reduceSurcharge = this.reduceSurcharge.bind(this)
        this.reduceCapacity = this.reduceCapacity.bind(this)
    }

    componentWillMount() {
        this.setState({
            data: this.props.location.state.data,
            capacity: this.props.location.state.data.capacities,
            segment_market_id: this.props.location.state.data.segment_market_id,
            pax_passport: this.props.location.state.data.pax_passport
        })
        window.removeEventListener("resize", this.updateViewMode);
        this.agentLogin();
        this.fetchNationalities();

    }
    updateViewMode = () => {
        this.setState({ isMobileView: window.innerWidth <= 660 });
    }


    componentDidMount() {
        window.addEventListener("resize", this.updateViewMode);
        this.updateViewMode(); // Initialize view mode
        this.fetchRatePlanRate();
    }


    reduceSurcharge(value, type) {
        let data;

        if (type === 'adult') {
            data = value.reduce((prevVal, currVal) => Number(prevVal) + Number(currVal.adult_price), 0);
        }

        if (type === 'child') {
            data = value.reduce((prevVal, currVal) => Number(prevVal) + Number(currVal.child_price), 0);
        }

        return data;
    }

    reduceCapacity(value, type) {
        let data;

        if (type === 'adults') {
            data = value.reduce((prevVal, currVal) => Number(prevVal) + Number(currVal.numberOfAdults), 0);
        }

        if (type === 'childs') {
            data = value.reduce((prevVal, currVal) => Number(prevVal) + Number(currVal.numberOfChildren), 0);
        }

        return data;
    }

    onChangeSpecialRequest(index) {
        let special_request = this.state.special_request;
        special_request[index].checked = !this.state.special_request[index].checked
        switch (index) {
            case 1:
                this.setState({ is_large_bed_request: special_request[index].checked })
                break;
            case 2:
                this.setState({ is_late_check_in_request: special_request[index].checked })
                break;
            case 3:
                this.setState({ is_twin_beds_request: special_request[index].checked })
                break;
            case 4:
                this.setState({ is_early_check_in_request: special_request[index].checked })
                break;
            case 5:
                this.setState({ is_high_floor_request: special_request[index].checked })
                break;
            case 6:
                this.setState({ is_airport_transfer_request: special_request[index].checked })
                break;
            default:
                this.setState({ is_non_smoking_room_request: special_request[index].checked })
                break;
        }

        this.setState({ special_request: special_request });
    }

    navigate(params, booking_pending_id, transaction_code) {
        let params_user = {
            title_id: this.state.title_id,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            guest_fullname: this.state.guest_fullname,
            email: this.state.email,
            phone_number: this.state.phone_number,
            nationality_id: this.state.nationality_id,
        }

        let data = {
            selected_campaign: this.state.rate_plan_data,
            hotel: this.state.data.hotel,
            params: this.state.data.params,
            booking_pending_id: booking_pending_id,
            transaction_code: transaction_code,
            data_user: params_user,
            capacity: this.state.capacity,
        }

        this.props.dispatch(updateCampaign(data));
        this.props.router.push({
            pathname: '/payment',
            state: {
                data: data
            }
        });
    }

    sendBooking(e) {
        e.preventDefault();

        this.setState({ submitted: true, load_button: true });

        var capacities = this.props.location.state.data.capacities;

        const adults = capacities.map((res) => res.numberOfAdults);
        const childs = capacities.map((res) => res.numberOfChildren);
        const reducer = (accumulator, currentValue) => Number(accumulator) + Number(currentValue);

        let new_capacities = [];
        for (let i = 0; i < capacities.length; i++) {
            new_capacities.push({ "number_of_adults": capacities[i].numberOfAdults, "number_of_children": capacities[i].numberOfChildren })
        }

        var that = this;
        let params = {

            hotel_id: that.state.data.hotel.id,
            campaign_id: that.state.data.selected_campaign.campaign.id,
            room_id: that.state.data.selected_campaign.room.id,

            check_in_date: that.state.data.params.check_in,
            check_out_date: that.state.data.params.check_out,
            number_of_rooms: that.state.data.params.number_of_rooms,

            number_of_adults: adults.reduce(reducer),
            number_of_childs: childs.reduce(reducer),
            capacities: new_capacities,
            guest_names: JSON.parse(that.state.guest_names_selected),

            currency: this.props.selectedCurrency,

            is_non_smoking_room_request: that.state.is_non_smoking_room_request,
            is_large_bed_request: that.state.is_large_bed_request,
            is_late_check_in_request: that.state.is_late_check_in_request,
            is_twin_beds_request: that.state.is_twin_beds_request,
            is_early_check_in_request: that.state.is_early_check_in_request,
            is_high_floor_request: that.state.is_high_floor_request,
            is_airport_transfer_request: that.state.is_airport_transfer_request,
            request_note: that.state.request_note,
            number_of_points_used: 0,

            segment_market_id: that.state.segment_market_id,
            pax_passport: that.state.pax_passport,
            order_price: that.state.rate_plan_data.price_total
        }

        // send booking to api
        axios.post(Env.url.api_url + 'hotels/bookings/' + this.state.data.hotel.url_segment + '/booking-new', params, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Accept-Language': localStorage.getItem('language'),
                'Accept-Currency': this.props.selectedCurrency
            }
        })
            .then(response => {
                if (response.data.success) {
                    that.navigate(params, response.data.data.booking_pending_id, response.data.data.transaction_code)
                } else {
                    alert(response.data.message);
                }
            })
            .catch(error => {
                Env.expired(error)
            });

        //that.navigate(params, 1, 1)
    }

    agentLogin() {
        let { user } = this.props

        var title_to_id = 1
        if (user.title_id === "Mr") {
            title_to_id = 1
        } else {
            title_to_id = 2
        }

        this.setState({
            user_id: user.user_id,
            title_id: title_to_id,
            first_name: user.first_name,
            last_name: user.last_name,
            guest_fullname: user.display_name,
            email: user.email,
            phone_number: user.home_phone,
            nationality_id: user.nationality_id,
        })
    }

    fetchNationalities() {
        var that = this
        axios.get(Env.url.api_url + 'hotels/nationalities', {
            headers: { 'Accept-Language': localStorage.getItem('language'), 'Accept-Currency': localStorage.getItem('currency') }
        })
            .then(response => {
                if (response.data.meta.success) {
                    that.setState({ nationalities: response.data.data })
                }
            })
            .catch(error => {

            });
    }

    fetchRatePlanRate() {
        var that = this

        const params = {
            hotel_id: this.state.data.hotel.id,
            room_id: this.state.data.selected_campaign.room.id,
            campaign_id: this.state.data.selected_campaign.campaign.id,
            check_in_date: this.state.data.params.check_in,
            check_out_date: this.state.data.params.check_out,
            number_of_rooms: this.state.data.params.number_of_rooms,
            capacities: this.state.data.capacities.map(capacity => ({
                number_of_adults: capacity.numberOfAdults,
                number_of_children: capacity.numberOfChildren
            })),
            segment_market: this.state.data.segment_market_id,
            currency: this.props.selectedCurrency
        }

        axios.post(`${Env.url.api_url}hotels/get-rate-plan`, params, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        }).then(response => {
            const data = response.data.data
            if (data) {
                const currencyCode = data.currency.code
                this.props.dispatch(selectCurrencyAction(currencyCode));
                this.setState({
                    loading: false,
                    rate_plan_data: data
                })
            } else if (response.data.success == false) {
                alert('Temporarily the room is not available');
                window.history.back();
                this.setState({
                    loading: false,
                    error: true
                })
            }
        })
            .catch(error => {
                alert('Temporarily the room is not available');
                window.history.back();
                this.setState({
                    loading: false,
                    error: true
                })
            });

    }

    handleInputChange(e) {
        e.preventDefault();

        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    renderCancellation() {

        let { cancellation, cancelations } = this.state.rate_plan_data;

        let level1 = [];
        let level2 = [];
        let level3 = [];

        if (cancellation) {
            if (cancellation.cancellation_rule_level_1) {
                level1.push(cancellation.cancellation_rule_level_1.name)
            }
            if (cancellation.cancellation_rule_level_2) {
                level2.push(cancellation.cancellation_rule_level_2.name)
            }
            if (cancellation.cancellation_rule_level_3) {
                level3.push(cancellation.cancellation_rule_level_3.name)
            }

            const text = () => (
                <ul className="cancellation-policy">
                    {level1.length ? <li>{level1}</li> : null}
                    {level2.length ? <li>{level2}</li> : null}
                    {level3.length ? <li>{level3}</li> : null}
                </ul>
            )
            return text()
        } else {
            const text = () => (
                <ul className="cancellation-policy">
                    {cancelations.map((item, i) => (
                        <li>{item.name}</li>
                    ))}
                </ul>
            )
            return text()
        }
    }

    handleChangeGuestProfile(posisi, subposisi, type, event) {
        let data = this.state;

        let guest_names = JSON.parse(this.state.guest_names);
        if (type === 1) {
            guest_names[posisi][subposisi].title_id = parseInt(event.target.value);
        }
        if (type === 2) {
            guest_names[posisi][subposisi].first_name = event.target.value;
        }
        if (type === 3) {
            guest_names[posisi][subposisi].last_name = event.target.value;
        }
        if (type === 4) {
            guest_names[posisi][subposisi].nationality_id = event.target.value;
        }
        data.guest_names = JSON.stringify(guest_names)
        data.guest_names_selected = JSON.stringify(guest_names)
    }
    generateRoom(capacity) {
        //generate array guest_name
        let _number_rooms = [];
        let _number_guest = [];
        for (let i = 0; i < capacity.length; i++) {
            for (let j = 0; j < capacity[i].numberOfAdults; j++) {
                _number_guest.push({ "title_id": 1, "first_name": "", "last_name": "", "nationality_id": localStorage.getItem('passport'), "is_adult": true })
            }
            for (let j = 0; j < capacity[i].numberOfChildren; j++) {
                _number_guest.push({ "title_id": 1, "first_name": "", "last_name": "", "nationality_id": localStorage.getItem('passport'), "is_adult": false })
            }
            _number_rooms.push(_number_guest)
            _number_guest = []
        }

        let data = this.state;
        data.guest_names = JSON.stringify(_number_rooms)

        let data_room_rows = []
        for (let i = 0; i < capacity.length; i++) {
            data_room_rows.push(
                <div key={i}>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>{Trans.data.room}-{i + 1}</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-2"><div className="form-group" style={{ marginBottom: '0' }}><label>{Trans.data.group}</label></div></div>
                        <div className="col-sm-2"><div className="form-group" style={{ marginBottom: '0' }}><label>{Trans.data.title}</label></div></div>
                        <div className="col-sm-3"><div className="form-group" style={{ marginBottom: '0' }}><label>{Trans.data.first_name}</label></div></div>
                        <div className="col-sm-3"><div className="form-group" style={{ marginBottom: '0' }}><label>{Trans.data.last_name}</label></div></div>
                        <div className="col-sm-2"><div className="form-group" style={{ marginBottom: '0' }}><label>{Trans.data.nationality}</label></div></div>
                    </div>
                    {this.generateRoomAdults(capacity[i].numberOfAdults, i)}
                    {this.generateRoomChilds(capacity[i].numberOfChildren, i, capacity[i].numberOfAdults)}
                    <hr className="guest-booking-separator" />
                </div>
            )
        }

        return data_room_rows
    }

    generateRoomMobile(capacity) {
        //generate array guest_name
        let _number_rooms = [];
        let _number_guest = [];
        for (let i = 0; i < capacity.length; i++) {
            for (let j = 0; j < capacity[i].numberOfAdults; j++) {
                _number_guest.push({ "title_id": 1, "first_name": "", "last_name": "", "nationality_id": localStorage.getItem('passport'), "is_adult": true })
            }
            for (let j = 0; j < capacity[i].numberOfChildren; j++) {
                _number_guest.push({ "title_id": 1, "first_name": "", "last_name": "", "nationality_id": localStorage.getItem('passport'), "is_adult": false })
            }
            _number_rooms.push(_number_guest)
            _number_guest = []
        }

        let data = this.state;
        data.guest_names = JSON.stringify(_number_rooms)

        let data_room_rows = []
        for (let i = 0; i < capacity.length; i++) {
            data_room_rows.push(
                <div key={i}>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>{Trans.data.room}-{i + 1}</label>
                            </div>
                        </div>
                    </div>
                    {this.generateRoomAdultsMobile(capacity[i].numberOfAdults, i)}
                    {this.generateRoomChildsMobile(capacity[i].numberOfChildren, i, capacity[i].numberOfAdults)}
                    <hr className="guest-booking-separator" />
                </div>
            )
        }

        return data_room_rows
    }

    generateRoomAdultsMobile(value, urutan) {
        let adults_room_row = []
        let { nationalities } = this.state
        for (let i = 0; i < value; i++) {
            adults_room_row.push(
                <div className="row" key={i}>
                
                     <div className='m-title'>
                        <div className='col-md-6'><div className="form-group" style={{ marginBottom: '0' }}><label>{Trans.data.group}</label></div></div>
                        <div className='col-md-6'>  
                        <label className="label-value">{Trans.data.adult}-{i + 1}</label>
                     </div>
                    </div>
                    <div className='m-title'>
                        <div className='col-md-6'><div className="form-group" style={{ marginBottom: '0' }}><label>{Trans.data.title}</label></div></div>
                        <div className='col-md-6'>  
                            <select name={"title_id" + i} className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 1)}>
                            <option value="1">Mr</option>
                            <option value="2">Mrs</option>
                            <option value="3">Ms</option>
                        </select>
                     </div>
                    </div>
                     <div className='m-title'>
                        <div className='col-md-6'><div className="form-group" style={{ marginBottom: '0' }}><label>{Trans.data.first_name}</label></div></div>
                        <div className='col-md-6'> 
                        <input required name={"first_name" + i} type="text" className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 2)} />
                        </div>
                    </div>
                    <div className='m-title'>
                        <div className='col-md-6'><div className="form-group" style={{ marginBottom: '0' }}><label>{Trans.data.last_name}</label></div></div>
                        <div className='col-md-6'> 
                        <input required name={"last_name" + i} type="text" className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 3)} />
                        </div>
                    </div>
                    <div className='m-title'>
                        <div className='col-md-6'><div className="form-group" style={{ marginBottom: '0' }}><label>{Trans.data.nationality}</label></div></div>
                        <div className='col-md-6'> 
                        {
                            i === 0 ? (
                                <select value={localStorage.getItem('passport')} required name={"nationality_id" + i} className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 4)} disabled>
                                    {nationalities.map((result, i) =>
                                        <option value={result.id} key={i}>{result.name}</option>
                                    )}
                                </select>
                            ) :
                                (
                                    <select required name={"nationality_id" + i} className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 4)}>
                                        {nationalities.map((result, i) =>
                                            <option value={result.id} key={i}>{result.name}</option>
                                        )}
                                    </select>
                                )
                        }
                        </div>
                    </div>
                </div>
                
            )
        }

        return adults_room_row
    }  
     generateRoomAdults(value, urutan) {
        let adults_room_row = []
        let { nationalities } = this.state
        for (let i = 0; i < value; i++) {
            adults_room_row.push(
                <div className="row" key={i}>
                    <div className="col-sm-2">
                        <div className="form-group">
                            <label className="label-value">{Trans.data.adult}-{i + 1}</label>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <select name={"title_id" + i} className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 1)}>
                            <option value="1">Mr</option>
                            <option value="2">Mrs</option>
                            <option value="3">Ms</option>
                        </select>
                    </div>
                    <div className="col-sm-3">
                        <input required name={"first_name" + i} type="text" className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 2)} />
                    </div>
                    <div className="col-sm-3">
                        <input required name={"last_name" + i} type="text" className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 3)} />
                    </div>
                    <div className="col-sm-2">
                        {
                            i === 0 ? (
                                <select value={localStorage.getItem('passport')} required name={"nationality_id" + i} className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 4)} disabled>
                                    {nationalities.map((result, i) =>
                                        <option value={result.id} key={i}>{result.name}</option>
                                    )}
                                </select>
                            ) :
                                (
                                    <select required name={"nationality_id" + i} className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 4)}>
                                        {nationalities.map((result, i) =>
                                            <option value={result.id} key={i}>{result.name}</option>
                                        )}
                                    </select>
                                )
                        }
                    </div>
                </div>
            )
        }

        return adults_room_row
    }

    generateRoomChildsMobile(value, urutan, total_adults) {
        let childs_room_row = []
        let { nationalities } = this.state
        let total_guests = parseInt(value) + parseInt(total_adults)
        let index = 1;
        for (let i = total_adults; i < total_guests; i++) {
            childs_room_row.push(
                <div className="row" key={i}>

                    <div className='m-title'>
                        <div className='col-md-6'><div className="form-group" style={{ marginBottom: '0' }}><label>{Trans.data.group}</label></div></div>
                        <div className='col-md-6'>  
                        <label className="label-value"><label className="label-value">{Trans.data.children}-{index++}</label>
                        </label>
                     </div>
                    </div>
                     <div className='m-title'>
                        <div className='col-md-6'><div className="form-group" style={{ marginBottom: '0' }}><label>{Trans.data.title}</label></div></div>
                        <div className='col-md-6'>  
                            <select name={"title_id" + i} className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 1)}>
                            <option value="1">Mr</option>
                            <option value="2">Mrs</option>
                            <option value="3">Ms</option>
                        </select>
                     </div>
                    </div>
                    <div className='m-title'>
                        <div className='col-md-6'><div className="form-group" style={{ marginBottom: '0' }}><label>{Trans.data.first_name}</label></div></div>
                        <div className='col-md-6'> 
                        <input required name={"first_name" + i} type="text" className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 2)} />
                        </div>
                    </div>
                    <div className='m-title'>
                        <div className='col-md-6'><div className="form-group" style={{ marginBottom: '0' }}><label>{Trans.data.last_name}</label></div></div>
                        <div className='col-md-6'> 
                        <input required name={"last_name" + i} type="text" className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 3)} />
                        </div>
                    </div>
                    <div className='m-title'>
                        <div className='col-md-6'><div className="form-group" style={{ marginBottom: '0' }}><label>{Trans.data.nationality}</label></div></div>
                        <div className='col-md-6'> 
                        <select required name={"nationality_id" + i} className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 4)}>
                            {nationalities.map((result, i) =>
                                <option value={result.id} key={i}>{result.name}</option>
                            )}
                        </select>
                        </div>
                    </div>
                </div>
            )
        }

        return childs_room_row
    } 
    
    generateRoomChilds(value, urutan, total_adults) {
        let childs_room_row = []
        let { nationalities } = this.state
        let total_guests = parseInt(value) + parseInt(total_adults)
        let index = 1;
        for (let i = total_adults; i < total_guests; i++) {
            childs_room_row.push(
                <div className="row" key={i}>
                    <div className="col-sm-2">
                        <div className="form-group">
                            <label className="label-value">{Trans.data.children}-{index++}</label>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <select name={"title_id" + i} className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 1)}>
                            <option value="1">Mr</option>
                            <option value="2">Mrs</option>
                            <option value="3">Ms</option>
                        </select>
                    </div>
                    <div className="col-sm-3">
                        <input required name={"first_name" + i} type="text" className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 2)} />
                    </div>
                    <div className="col-sm-3">
                        <input required name={"last_name" + i} type="text" className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 3)} />
                    </div>
                    <div className="col-sm-2">
                        <select required name={"nationality_id" + i} className="form-control" onChange={this.handleChangeGuestProfile.bind(this, urutan, i, 4)}>
                            {nationalities.map((result, i) =>
                                <option value={result.id} key={i}>{result.name}</option>
                            )}
                        </select>
                    </div>
                </div>
            )
        }

        return childs_room_row
    }

   

    render() {
        const {
            guest_fullname, request_note, isSpecialRequest, submitted, phone_number, nationality_id,
            user_id, title_id, first_name, last_name, email, capacity, load_button, rate_plan_data, isMobileView
        } = this.state;
        const { campaign } = this.state.data.selected_campaign;
        const { room } = this.state.data.selected_campaign;
        const { check_in, check_out, number_of_rooms } = this.state.data.params;
        const lang = localStorage.getItem('language');

        let surchargeDesc;
        if (campaign.surcharges.length) {
            surchargeDesc = campaign.surcharges[0].description;
        }

        return (
            <Layout>
                <div className="container">
                    <section className="booking">
                        <div className="row">
                            <ProgressBooking value={1} />
                        </div>

                        <div className="row">

                            <div className="col-sm-9 col-md-9" style={{ marginBottom: "10px" }}>
                                <h4 className="title-form">{Trans.data.booking_form} {this.props.selectedCurrency}
                                    <small>{Trans.data.booking_form_sub}</small>
                                    <small style={{ lineHeight: "0px" }}>({Trans.data.please_fill_customer_data})</small>
                                </h4>
                            </div>
                        </div>
                        <div className="row block">
                            <form onSubmit={this.sendBooking.bind(this)}>
                                <div className="col-sm-9 col-md-9">
                                    <div className="panel panel-customs-1">
                                        <div className="panel-body" style={{ padding: '15px' }}>
                                            {isMobileView  ?  this.generateRoomMobile(capacity) :this.generateRoom(capacity)}
                                        </div>
                                    </div>
                                    <div className="panel panel-customs">
                                        <div className="panel-body" style={{ padding: "15px" }}>
                                            <p style={{ fontSize: '18px', color: '#1b809e ' }}>{Trans.data.agent_data}</p>
                                            <input type="hidden" name="user_id" value={user_id} />
                                            <input type="hidden" name="title_id" value={title_id} />
                                            <input type="hidden" name="first_name" value={first_name} />
                                            <input type="hidden" name="last_name" value={last_name} />
                                            <input type="hidden" name="nationality_id" value={nationality_id} />
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <p style={{ fontSize: "18px", lineHeight: "0", marginBottom: "20px", color: "#1b809e", letterSpacing: ".15rem" }}>{Trans.data.agent_information}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className={`form-group ${submitted && !guest_fullname ? 'has-error' : null}`}>
                                                        <label>{Trans.data.fullname} <span className="required">*</span></label>
                                                        <input disabled value={guest_fullname} onChange={this.handleInputChange} name="guest_fullname" type="text" className="form-control" />
                                                        {submitted && !guest_fullname ? <span className="help-block">{Trans.data.fullname} is required!</span> : null}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className={`form-group ${submitted && !email ? 'has-error' : null}`}>
                                                        <label>{Trans.data.email} <span className="required">*</span></label>
                                                        <input disabled value={email} onChange={this.handleInputChange} name="email" type="email" className="form-control" />
                                                        {submitted && !email ? <span className="help-block">{Trans.data.email} is required!</span> : null}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className={`form-group ${submitted && !phone_number ? 'has-error' : null}`}>
                                                        <label>{Trans.data.phone_number} <span className="required">*</span></label>
                                                        <input disabled value={phone_number} onChange={this.handleInputChange} name="phone_number" type="text" className="form-control" />
                                                        {submitted && !phone_number ? <span className="help-block">{Trans.data.phone_number} is required!</span> : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <ul className="list-unstyled">
                                                        <li>
                                                            <div className="checkbox">
                                                                <label>
                                                                    <input type="checkbox"
                                                                        name="isSpecialRequest"
                                                                        checked={isSpecialRequest}
                                                                        onChange={this.handleInputChange}
                                                                    />
                                                                    {Trans.data.i_have_special_request}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div style={{ display: !isSpecialRequest ? 'none' : null }} >
                                                <div className="border"></div>
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12">
                                                        <h4>{Trans.data.special_request} ({Trans.data.optional})</h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-12 spc-request">
                                                        <ul className="specialRequest">
                                                            <li>
                                                                <Checkbox
                                                                    checkboxClass="icheckbox_square-green"
                                                                    increaseArea="20%"
                                                                    label={` ${this.state.special_request[0].label}`}
                                                                    checked={this.state.special_request[0].checked}
                                                                    disabled={false}
                                                                    onChange={() => this.onChangeSpecialRequest(0)}
                                                                />
                                                            </li>
                                                            <li>
                                                                <Checkbox
                                                                    checkboxClass="icheckbox_square-green"
                                                                    increaseArea="20%"
                                                                    label={` ${this.state.special_request[1].label}`}
                                                                    checked={this.state.special_request[1].checked}
                                                                    disabled={false}
                                                                    onChange={() => this.onChangeSpecialRequest(1)}
                                                                />
                                                            </li>
                                                            <li>
                                                                <Checkbox
                                                                    checkboxClass="icheckbox_square-green"
                                                                    increaseArea="20%"
                                                                    label={` ${this.state.special_request[2].label}`}
                                                                    checked={this.state.special_request[2].checked}
                                                                    disabled={false}
                                                                    onChange={() => this.onChangeSpecialRequest(2)}
                                                                />
                                                            </li>
                                                            <li>
                                                                <Checkbox
                                                                    checkboxClass="icheckbox_square-green"
                                                                    increaseArea="20%"
                                                                    label={` ${this.state.special_request[3].label}`}
                                                                    checked={this.state.special_request[3].checked}
                                                                    disabled={false}
                                                                    onChange={() => this.onChangeSpecialRequest(3)}
                                                                />
                                                            </li>
                                                            <li>
                                                                <Checkbox
                                                                    checkboxClass="icheckbox_square-green"
                                                                    increaseArea="20%"
                                                                    label={` ${this.state.special_request[4].label}`}
                                                                    checked={this.state.special_request[4].checked}
                                                                    disabled={false}
                                                                    onChange={() => this.onChangeSpecialRequest(4)}
                                                                />
                                                            </li>
                                                            <li>
                                                                <Checkbox
                                                                    checkboxClass="icheckbox_square-green"
                                                                    increaseArea="20%"
                                                                    label={` ${this.state.special_request[5].label}`}
                                                                    checked={this.state.special_request[5].checked}
                                                                    disabled={false}
                                                                    onChange={() => this.onChangeSpecialRequest(5)}
                                                                />
                                                            </li>
                                                            <li>
                                                                <Checkbox
                                                                    checkboxClass="icheckbox_square-green"
                                                                    increaseArea="20%"
                                                                    label={` ${this.state.special_request[6].label}`}
                                                                    checked={this.state.special_request[6].checked}
                                                                    disabled={false}
                                                                    onChange={() => this.onChangeSpecialRequest(6)}
                                                                />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="border"></div>
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12">
                                                        <h4>{Trans.data.additional_notes} ({Trans.data.optional})</h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="form-group">
                                                            <textarea value={request_note} onChange={this.handleInputChange} name="request_note" className="form-control" rows="2"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bs-callout bs-callout-warning">
                                        <h4>{Trans.data.cancellation_policy}</h4>
                                        {this.renderCancellation()}
                                    </div>

                                    <h4 className="title-form">
                                        {Trans.data.price_details}
                                        <small>({this.state.data.hotel.hotel_text.name})</small>
                                    </h4>
                                    <div className="panel panel-default">
                                        <div className="panel-body" style={{ padding: '0px' }}>
                                            {this.state.loading ?
                                                <>
                                                    <div className="row">
                                                        <div className="col-sm-10">
                                                            <div className="content-list-hotel">
                                                                <div className="animated-background facebook line3" />
                                                                <div className="animated-background facebook line1" />
                                                                <div className="animated-background facebook line2" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2" >
                                                            <div style={{ display: 'flex', alignItems: 'end', flexDirection: 'column' }}>
                                                                <div className="animated-background facebook line1" />
                                                                <div className="animated-background facebook line1" />
                                                                <div className="animated-background facebook line2" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : <>
                                                    <table className="table table-borderless">
                                                        <tbody>
                                                            <tr>
                                                                <td>{room.name}, ({number_of_rooms + ' ' + Trans.data.rooms + ' x ' + parseFloat(moment(check_out).diff(moment(check_in), 'days')) + ' ' + Trans.data.nights})</td>
                                                                <td className="text-right" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                    {this.state.data.selected_campaign.campaign.price_total !== rate_plan_data.price_total ?
                                                                        <p style={{ color: 'red', fontSize: '12px', margin: 0, marginRight: '20px', whiteSpace: 'nowrap' }}>
                                                                            The price has been updated.
                                                                        </p>
                                                                        : null}
                                                                    {Env.formatCurrency(rate_plan_data.price_total - rate_plan_data.surcharge_price_total - rate_plan_data.tax_total, this.props.selectedCurrency)}
                                                                </td>

                                                            </tr>
                                                            {rate_plan_data.surcharges.length ?
                                                                <tr>
                                                                    <td>
                                                                        {Trans.data.additional_cost + " " + surchargeDesc},
                                                                        ({this.reduceCapacity(capacity, 'adults')} {Trans.data.adult} x {Env.formatCurrency(this.reduceSurcharge(rate_plan_data.surcharges, 'adult'), this.props.selectedCurrency)})
                                                                        {this.reduceCapacity(capacity, 'childs') === 0 ? null : ` & ( ${this.reduceCapacity(capacity, 'childs')} ${Trans.data.children} x Rp ${Env.formatCurrency(this.reduceSurcharge(rate_plan_data.surcharges, 'child'), this.props.selectedCurrency)})`}
                                                                    </td>
                                                                    <td className="text-right">{Env.formatCurrency(rate_plan_data.surcharge_price_total, this.props.selectedCurrency)}</td>
                                                                </tr>
                                                                : null}
                                                            <tr>
                                                                <td>{Trans.data.tax}</td>
                                                                <td className="text-right">{Env.formatCurrency(rate_plan_data.tax_total, this.props.selectedCurrency)}</td>
                                                            </tr>
                                                            <tr className="border-top-table">
                                                                <td>{Trans.data.total_payment}</td>
                                                                <td className="text-right"><strong className="total-price">{Env.formatCurrency(rate_plan_data.price_total, this.props.selectedCurrency)}</strong></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    {this.state.error &&
                                                        <p style={{ color: 'red', fontSize: '12px', textAlign: 'end' }}>This room rate is not available.</p>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 text-right">
                                            <div className='mterm-policy'>


                                            {lang === 'ja' ?
                                                (<div>このボタンをクリックしたら私がHoterip（ホテリッピ）の<Link to="/term" target="_blank">利用条件</Link>と<Link to="/privacy-policy" target="_blank">個人情報保護方針</Link>を同意します</div>)
                                                :
                                                (<div><span className="term-condition">{Trans.data.term_agree_info} <Link to="/term" target="_blank">{Trans.data.term_condition}</Link> {Trans.data.and} <Link to="/privacy-policy" target="_blank">{Trans.data.privacy_policy}</Link> {Trans.data.of_hoterip}</span></div>)
                                            }
                                        </div>
                                            </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-sm-8 col-md-8"></div>
                                        <div className="col-sm-4 col-md-4 paym-btn">
                                            <Button style={{ color: '#FFF' }} type={this.state.error ? "button" : "submit"} className="btn btn-promo btn-lg" disabled={this.state.error} loading={load_button}>{Trans.data.choose_payment_method}</Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <BookingSidebar
                                dataBooking={this.props.location.state.data}
                                capacity={this.props.location.state.data.capacities}
                            />
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    const { user } = state.authReducer.user
    const { selectedCurrency } = state.currencyReducer
    return {
        user,
        selectedCurrency
    }
}

export default connect(mapStateToProps)(BookingForm)