export default (state = [], action) => {
	switch(action.type) {
		case 'LAST_BOOKED_SUCCESS':
			var data_last = []
			for(var i=0;i<action.data.length;i++){
				if(action.data[i].average_rate_per_night!=null){
					data_last.push(action.data[i])
				}
			}
			return { 
				data:data_last,
			}
		default:
			return state;
	}
}