import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateQuery } from '../../actions/search.js';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';
import Trans from '../../locale/Translate';

const history = createBrowserHistory();

class SearchHotelAlphabet extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                alphabetical: '',
            },
            content_alpha: ['All', '#', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps.data.query });
    }

    handleClickAlphabetical(value, event) {
        event.preventDefault();

        let data = this.state.data;
        data.alphabetical = (value === 'All' ? '' : value);
        data.page = 1;

        this.setState({ data: data }, () => {
            this.props.dispatch(updateQuery(this.state.data));

            const searchString = queryString.stringify(this.state.data);
            history.push({ pathname: '/hotels/search', search: searchString });
        });
    }

    renderList() {
        return (
            this.state.content_alpha.map((value, i) =>
                <li className="list-inline-item" key={i}>
                    {i === 1 ? <span className="hashtag">{value}</span> :
                        <a onClick={this.handleClickAlphabetical.bind(this, value)} href="" className={i === 0 ? 'all' + (this.state.data.alphabetical === '' || (typeof this.state.data.alphabetical === 'undefined') ? ' active' : '') : "alpha_" + value + (this.state.data.alphabetical === value ? ' active' : '')}>{value}</a>
                    }
                </li>
            )
        )
    }

    render() {
        return (
            <div className="filter-alpha-list-hotel">
                <div className="col-xs-12 alpa-filter">
                    <div className="row no-margin">
                        <ul className="list-inline alpha-filter">
                            <li className="list-inline-item title-alpha">{Trans.data.alphabetical} : </li>
                            {this.renderList()}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.search
    }
}

export default connect(mapStateToProps)(SearchHotelAlphabet);
