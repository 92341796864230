const campaign = (state = {}, action) => {
    switch (action.type) {
        case 'UPDATE_CAMPAIGN':
            return action.campaign_data

        default:
            return state
    }
}

export default campaign;