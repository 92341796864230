import React, { Component } from 'react'

import { Layout,RegisterForm } from '../../components'
import { Container, Row, Col } from 'reactstrap';

export default class Register extends Component {

    render() {
        return (
            <Layout>
                <section>
                    <Container>
                        <Row>
                            <Col xs="12">
                                <p style={{ fontSize:"18px",textAlign:"center",marginBottom:"20px" }}>Sign Up Now and Become Our Partner Agent.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <RegisterForm/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        )
    }
}