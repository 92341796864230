import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import imgOnLoading from '../../assets/images/imgOnLoading_large.jpg'
import Star from '../../modules/Star'
import { Link } from 'react-router'
import Img from 'react-image'
import Trans from '../../locale/Translate'

import * as Env from '../../Env';
var axios = require('axios');
var moment = require('moment');

class RecomendedHotel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content_loading: [0, 1, 2],
            best_deal_data: [],
            state_get_data: 1
        }
    }

    fetchData() {
        var that = this
        axios.get(Env.url.api_url + 'hotels/users/recommended-hotel', {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token'), 'Accept-Language': localStorage.getItem('language'), 'Accept-Currency': localStorage.getItem('currency') }
        })
            .then(function (response) {
                if (response.data.meta.success) {
                    let validasi_data = [];
                    for(let i=0; i<response.data.data.length; i++){
                        if(response.data.data[i].average_rate_per_night){
                            validasi_data.push(response.data.data[i])
                        }
                    }

                    that.setState({ best_deal_data: validasi_data })
                    if (!that.state.best_deal_data.length) {
                        that.setState({ state_get_data: 0 })
                    }
                }
            })
            .catch(function (error) {
                Env.expired(error)
            });
    }

    componentDidMount() {
        this.fetchData();
    }

    renderList() {
        if (!this.state.best_deal_data.length) {
            return (
                this.state.content_loading.map((result, i) =>
                    <div key={i} className="col-sm-4 col-md-4">
                        <div className="thumbnail no-content-wrapper">
                            <div className="animated-background facebook preload-img-hot-deal">
                                <div className=""></div>
                            </div>
                            <div className="caption">
                                <br />
                                <div className="animated-background facebook line1" />
                                <div className="animated-background facebook line3" />
                                <div className="animated-background facebook line3" />
                                <div className="animated-background facebook line3" />
                                <br />
                                <div className="footer-Bdeal">
                                    <div className="animated-background facebook line2" style={{ float: "left" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )
        }

        return (
            this.state.best_deal_data.map((result, i) =>
                <div className="col-sm-4 col-md-4" key={i}>
                    <Link className="BestDealHover" to={{
                        pathname: result.slug,
                        query: {
                            check_in: moment().format("YYYY-MM-DD"),
                            check_out: moment().add(1, 'days').format("YYYY-MM-DD"),
                            number_of_night: 1,
                            room: 1,
                            capacities: JSON.stringify([{ number_of_adults: 1, number_of_children: 0 }]),
                        }
                    }} >
                        <div className="thumbnail specialPromotion-thumbnail">
                            <Img src={result.image_url_large} unloader={<img src={imgOnLoading} className="imgBdeal" />} className="imgBdeal" alt={result.name} />
                            <div className="caption">
                                <h4>{result.name}</h4>
                                <Star value={result.star} />
                                &nbsp;
                                <p>{Env.substring(result.description, 170)}</p>
                                <p>
                                    <span className="text-left" style={{ fontSize: '14px' }}><i className="fa fa-map-marker"></i> {result.district_name + '-' + result.city_name}</span>
                                    <span className="text-right pull-right rate"> {Env.format(result.average_rate_per_night)} ~</span>
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            )
        );
    }

    render() {
        const { state_get_data } = this.state

        return (
            <div>
                {state_get_data == 1 ?
                    <section className="best-deal">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12"><h2>{Trans.data.recomended_hotel}</h2></div>
                            </div>
                            <div className="row" style={{ paddingBottom: '20px' }}>
                                {this.renderList()}
                            </div>
                        </div>
                    </section>
                    : null}
            </div>
        );
    }
}

export default RecomendedHotel