import React, { Component } from 'react'
import imgOnLoading from '../../assets/images/imgOnLoading_large.jpg'
import Star from '../../modules/Star'
import Trans from '../../locale/Translate.js'
import Img from 'react-image'

var moment = require('moment')

class BookingSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            roomName: '',
            data: {
                hotel: {
                    hotel_text: {
                        name: null
                    },
                    photo: null,
                    address: null,
                    fax: null,
                    star: null,
                },
                selected_campaign: {
                    campaign: {
                        total_price_before_tax: 0,
                        total_tax_price: 0,
                        total_price: 0,
                        benefit: [],
                    },
                    room: {
                        selected_text: { name: null },
                        photo_basename: null
                    },
                    cancellation: {},
                    items: {}
                },
                params: {
                    check_in: moment().format('YYYY-MM-DD'),
                    check_out: moment().add("1 days").format('YYYY-MM-DD'),
                    number_of_rooms: 0
                },
            },
            capacities: []
        };
    }

    componentDidMount() {
        this.setState({
            data: this.props.dataBooking,
            capacities: this.props.capacity
        })
    }

    render() {
        let { hotel_text, address, star } = this.state.data.hotel;
        let { check_in, check_out } = this.state.data.params;
        let { room, campaign } = this.state.data.selected_campaign;

        return (
            <div className="col-md-3 col-sm-3">
                <div className="panel panel-sidebar">
                    <div className="panel-heading">
                        <h3 className="panel-title">{Trans.data.booking_summary}</h3>
                    </div>
                    <div className="panel-body" style={{ padding: '15px' }}>
                        <div className="row">
                            <div className="col-sm-4" style={{ paddingRight: '0' }}>
                                <Img src={room.photo_basename} unloader={<img src={imgOnLoading} className="img-responsive" />} className="img-responsive" alt="" />
                            </div>
                            <div className="col-sm-8">
                                <div className="hotelName">{hotel_text.name}</div>
                                <Star value={star} />
                                <div className="address">{address}</div>
                            </div>
                        </div>
                        <div className="border" style={{ margin: '15px -15px 0 -15px' }}></div>
                        <h4>{Trans.data.rooms}</h4>
                        <div className="row">
                            <dl>
                                <dt className="col-sm-5">{Trans.data.duration}</dt>
                                <dd className="col-sm-7">{parseFloat(moment(check_out).diff(moment(check_in), 'days'))} {Trans.data.nights}</dd>
                            </dl>
                        </div>
                        <div className="row">
                            <dl>
                                <dt className="col-sm-5">{Trans.data.check_in}</dt>
                                <dd className="col-sm-7">{moment(check_in).format('MMM-DD, YYYY')}</dd>
                            </dl>
                        </div>
                        <div className="row">
                            <dl>
                                <dt className="col-sm-5">{Trans.data.check_out}</dt>
                                <dd className="col-sm-7">{moment(check_out).format('MMM-DD, YYYY')}</dd>
                            </dl>
                        </div>
                        {
                            this.state.capacities.map((data, i) =>
                                <div className="row" key={i}>
                                    <dl>
                                        <dt className="col-sm-5">{Trans.data.rooms + '-'}{i + 1}</dt>
                                        <dd className="col-sm-7">{data.number_of_adults} {Trans.data.adult}, {data.number_of_children} {Trans.data.children}</dd>
                                    </dl>
                                </div>
                            )
                        }
                        <div className="row">
                            <dl>
                                <dt className="col-sm-5">{Trans.data.room_name}</dt>
                                {<dd className="col-sm-7">{room.name}</dd>}
                            </dl>
                        </div>
                        <div className="row">
                            <dl>
                                <dt className="col-sm-5">{Trans.data.breakfast}</dt>
                                <dd className="col-sm-7">
                                    {room.is_breakfast_included ? Trans.data.included : Trans.data.excluded}
                                </dd>
                            </dl>
                        </div>
                        {this.state.data.selected_campaign.campaign.total_extrabeds >= 1 ?
                            <div className="row">
                                <dl>
                                    <dt className="col-sm-5">{Trans.data.extrabeds}</dt>
                                    <dd className="col-sm-7">{this.state.data.selected_campaign.campaign.total_extrabeds}</dd>
                                </dl>
                            </div>
                            : null}
                        {campaign.benefit ?
                            <div>
                                {campaign.benefit.length > 0 ?
                                    <div>
                                        <div className="border"></div>
                                        <h4>{Trans.data.additional_benefit}</h4>
                                        <div className="row">
                                            <ul>
                                                {campaign.benefit.map((list, i) =>
                                                    <li key={i} style={{ fontSize: '12px', fontWeight: 'normal' }}>{list.selected_text.name}</li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default BookingSidebar