const initialState = {
    capacities: [
        { numberOfAdults: 1, numberOfChildren: 0 },
    ]
}

const room_capacities = (state = initialState, action) => {
    
    switch (action.type) {
        case 'UPDATE_CAPACITIES':
            if(action.capacity){
                return {
                    capacities: JSON.parse(action.capacity)
                }
            } else {
                return {
                    capacities: [{ numberOfAdults: 1, numberOfChildren: 0 }]
                }
            }
        default:
            return state
    }
}

export default room_capacities;