import React, { Component } from 'react';

import { Layout } from '../../components'
import Trans from '../../locale/Translate.js'
import TopNav from '../../modules/TopNav'

export default class AboutHoterip extends Component {
    render() {

        let language = localStorage.getItem('language');

        return (
            <Layout>
                <div className="jumbotron jumbotron-help" style={{ marginTop: '-30px' }}>
                    <div className="container text-center">
                        <h1>{Trans.data.about_hoterip}</h1>
                    </div>
                </div>

                <TopNav
                    url={this.props.location.pathname}
                />

                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-sm-10 col-md-offset-1">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="blog-content">
                                        <p style={{ textIndent: '35px' }}>
                                            {language === 'en-us' ?
                                                'HIS Hotels is one of online division which its operational fully managed and operated by PT. HARUM INDAH SARI TOURS & TRAVEL (H.I.S Tours & Travel)'
                                                : language === 'ja' ?
                                                    '企業概要'
                                                    : 'HIS Hotels merupakan salah satu divisi online yang dikelola dan dijalankan sepenuhnya oleh PT. HARUM INDAH SARI TOURS & TRAVEL (H.I.S Tours & Travel)'
                                            }
                                        </p>
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">{Trans.data.company_name}</th>
                                                    <td>PT. HARUM INDAH SARI TOURS AND TRAVEL</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{Trans.data.siup}</th>
                                                    <td>484/T/PARPOSTEL/1996</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{Trans.data.initial_capital}</th>
                                                    <td>Rp 503,250,000</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{Trans.data.opening_year}</th>
                                                    <td>{language === 'en-us' ? '26 December 1995' : language === 'ja' ? '1995年12月26日' : '26 Desember 1995'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{Trans.data.address}</th>
                                                    <td>Jl. By Pass Ngurah Rai No.732 Denpasar 80222 BALI INDONESIA</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{Trans.data.total_employee}</th>
                                                    <td>{language === 'en-us' ? '533 person' : language === 'ja' ? '533人（2016年10月時点）' : '533 orang'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{Trans.data.iata_number}</th>
                                                    <td>153 10890</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{Trans.data.bank_partner}</th>
                                                    <td>Bank Mandiri, Permata Bank, BCA</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}