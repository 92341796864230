import React, { Component } from "react";

import { Layout } from "../../components";
import Trans from "../../locale/Translate.js";

export default class PaymentFailed extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout>
        <div className="container">
          <section className="booking">
            <div className="row ">
              <div className="col-md-8 col-md-offset-2">
                <div className="notifSuccess alert alert-danger" role="alert">
                  <i className="fa fa-times  fa-5x box-icon" style={{ color: "red" }}></i>
                  <h2 className="text-center">{Trans.data.your_payment_failed}</h2>
                  <h5 className="text-center">{Trans.data.your_payment_failed_text}</h5>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}
