import React, { Component } from 'react';
import Breadcrumb from '../../modules/Breadcrumb';
import { SearchHotelBoxInput, SearchHotelFilter, SearchHotelAlphabet, SearchHotelResult, LastBookedHotel } from '../../components';
import { connect } from 'react-redux';
import { updateQuery } from '../../actions/search.js';
import * as Env from '../../Env';
import Template from '../Layout';
import Trans from '../../locale/Translate';
import queryString from 'query-string';

class SearchHotel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                city_name: '',
                country_name: '',
            },
            breadcrumb_lists: [
                { title: 'Home', link: "/" },
                { title: "Hotel", link: "/" },
                { title: "Indonesia", link: "/" },
                { title: "Bali", link: "/" },
            ]
        };
    }

    componentDidMount() {
        let params = queryString.parse(this.props.location.search);
        this.props.dispatch(updateQuery(params));
    }

    componentWillReceiveProps(nextProps) {
        let breadcrumb_lists = [];

        breadcrumb_lists.push({ title: Trans.data.home, link: "/" });
        breadcrumb_lists.push({ title: Env.capitalizeFirst(nextProps.data.query.country_name), link: "/" });
        breadcrumb_lists.push({ title: Env.capitalizeFirst(nextProps.data.query.city_name), link: "/" });

        this.setState({ breadcrumb_lists: breadcrumb_lists });
    }

    render() {
        return (
            <Template>
                <section style={{ marginTop: '-30px', paddingBottom:'50px' }}>
                    <SearchHotelBoxInput typeSearch={1} />
                    <div className="container">
                        <Breadcrumb lists={this.state.breadcrumb_lists} />
                    </div>
                    <div className="container">
                        <section className="hotel-result">
                            <div className="row">
                                <div className="col-sm-4 col-md-3 search-hotel-flt">
                                    <SearchHotelFilter />
                                </div>
                                <div className="col-sm-8 col-md-9">
                                    <div className="row">
                                        <SearchHotelAlphabet />
                                        <SearchHotelResult />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <LastBookedHotel />
            </Template>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.search
    }
}

export default connect(mapStateToProps)(SearchHotel);
