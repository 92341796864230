import React, { Component } from 'react'
import Button from 'react-bootstrap-button-loader';
import * as Env from '../../Env'
import Trans from '../../locale/Translate'

let axios = require('axios');

class ChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            old_password: '',
            password: '',
            password_confirmation: '',
            getNotif: '',
            getNotifType: 0,
            submitted: false,
            load_button: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true, getNotif: '' });

        const { old_password, password, password_confirmation } = this.state
        if (password != password_confirmation) {
            this.setState({
                getNotif: 'Confirmation Password Not Match',
                getNotifType: 0
            });
        } else {
            if (old_password && password && password_confirmation) {
                this.setState({ load_button: true });

                var self = this;

                const data = {
                    old_password: old_password,
                    password: password,
                    password_confirmation: password_confirmation
                }

                axios.post(Env.url.api_url + 'hotels/users/profile/change-password', data, {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
                })
                    .then(response => {
                        this.setState({
                            load_button: false,
                            getNotifType: 1,
                            getNotif: response.data.meta.messages,
                            old_password: '',
                            password: '',
                            password_confirmation: '',
                            submitted: false,
                        });
                    })
                    .catch(error => {
                        Env.expired(error)
                        if (error.response !== undefined) {
                            if (error.response.status === 400) {
                                this.setState({
                                    load_button: false,
                                    getNotifType: 0
                                });
                                if (error.response.data.meta.messages.old_password[0]) {
                                    this.setState({
                                        getNotif: error.response.data.meta.messages.old_password[0]
                                    });
                                } else {
                                    this.setState({
                                        getNotif: error.response.data.meta.messages
                                    });
                                }
                            }
                        }
                    })
            }
        }
    }

    render() {
        const { getNotif, getNotifType, old_password, password, password_confirmation, submitted, load_button } = this.state

        return (
            <div className="col-md-4 col-sm-4">
                <div className="panel panel-default panel-personalinfo" style={{ border: '1px solid #ddd', borderRadius: '6px', overflow: 'hidden' }}>
                    <div className="panel-heading">
                        <h3 className="panel-title" style={{ fontSize: '18px' }}>{Trans.data.change_password}</h3>
                    </div>
                    <div className="panel-body" style={{ padding: '25px' }}>
                        {getNotif ?
                            <div className={`alert ${getNotifType == 0 ? 'alert-danger' : 'alert-info'}`} role="alert">
                                <strong>{getNotifType == 0 ? 'Alert' : 'Thank you'}</strong> {getNotif}
                            </div>
                            : null}
                        <form onSubmit={this.handleSubmit}>
                            <div className={`form-group row ${submitted && !old_password ? 'has-error' : ''}`}>
                                <label className="col-xs-12 col-form-label" style={{ textAlign: 'left', paddingRight: '0px' }}>{Trans.data.old_password}<span style={{ color: "#FF0000" }}>*</span></label>
                                <div className="col-xs-12">
                                    <input value={old_password} onChange={this.handleChange} className="form-control" type="password" name="old_password" />
                                    {submitted && !old_password ? <span className="help-block">Old Password is required</span> : null}
                                </div>
                            </div>
                            <br />
                            <div className={`form-group row ${submitted && !password ? 'has-error' : ''}`}>
                                <label className="col-xs-12 col-form-label" style={{ textAlign: 'left', paddingRight: '0px' }}>{Trans.data.new_password}<span style={{ color: "#FF0000" }}>*</span></label>
                                <div className="col-xs-12">
                                    <input value={password} onChange={this.handleChange} className="form-control" type="password" name="password" />
                                    {submitted && !password ? <span className="help-block">Password is required</span> : null}
                                </div>
                            </div>
                            <div className={`form-group row ${submitted && !password_confirmation ? 'has-error' : ''}`}>
                                <label className="col-xs-12 col-form-label" style={{ textAlign: 'left', paddingRight: '0px' }}>{Trans.data.confirm_new_password}<span style={{ color: "#FF0000" }}>*</span></label>
                                <div className="col-xs-12">
                                    <input value={password_confirmation} onChange={this.handleChange} className="form-control" type="password" name="password_confirmation" />
                                    {submitted && !password_confirmation ? <span className="help-block">Password Confirmation is required</span> : null}
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-xs-12">
                                    <Button type="submit" className="btn btn-success btn-block btn-lg btn-login-modal" style={{backgroundColor: '#f80', border: 'none' }} loading={load_button}>{Trans.data.update}</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default ChangePassword