import { combineReducers } from 'redux';

import lastBookedReducer from './lastBookedReducer'
import authReducer  from './authReducer';
import search from './search';
import cheap_campaign from './cheap_campaign';
import room_detail from './room_detail'
import room_capacities from './room_capacities'
import campaign from './campaign'
import currencyReducer from './currencyReducer'

const todoApp = combineReducers({
    lastBookedReducer,
    authReducer,
    search,
    cheap_campaign,
    room_detail,
    room_capacities,
    campaign,
    currencyReducer,
})

export default todoApp
