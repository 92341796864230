import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Trans from '../../locale/Translate'

export default class Star extends Component {
	constructor(props) {
		super(props);

		this.state = {

		}
	}
	componentDidMount() {
	}

	renderList() {
		var arr = Array.apply(null, { length: this.props.value }).map(Number.call, Number)

		return (
			arr.map((result, i) => <i key={result} className="fa fa-star" aria-hidden="true"></i>)
		);
	}

	renderListEmpty() {
		var arr_empty = Array.apply(null, { length: 5 - this.props.value }).map(Number.call, Number)

		return (
			arr_empty.map((result, i) => <i key={result} className="fa fa-star-o" aria-hidden="true"></i>)
		);
	}

	render() {
		return (
			<div className="rate">
				{this.renderList()}
				{this.renderListEmpty()}
				{this.props.show_rating &&
					<i><span>{this.props.rating} </span>of 10 <small>({this.props.total_review} {Trans.data.guest_review})</small></i>
				}
				{!this.props.show_rating &&
					<i><span>&nbsp;</span></i>
				}
			</div>
		)
	}
}


Star.defaultProps = {
	value: 0,
	rating: 0,
	total_review: 0,
	show_rating: false,
};

Star.propTypes = {
	value: PropTypes.number,
	rating: PropTypes.number,
	total_review: PropTypes.number,
	show_rating: PropTypes.bool,
}