import React, { Component } from "react";
import { Swiper, Slide } from "react-dynamic-swiper";
import imgOnLoading from "../../assets/images/imgOnLoading.jpg";
import support from "../../assets/images/icon/support.png";
import MaiMaiShutle from "../../assets/images/icon/maimaishuttle.png";
import MaiMaiCard from "../../assets/images/icon/maimaicard.png";
import BenefitRoom from "../../assets/images/icon/benefit_room.png";
import ServiceImg from "../../assets/images/service.jpeg";
import BenefitImg from "../../assets/images/benefit.jpeg";
import ApiImg from "../../assets/images/icon/api.png"
import MedalImg from "../../assets/images/icon/medal.png"
import CheckMarkImg from "../../assets/images/icon/check_mark.png"
import LikeImg from "../../assets/images/icon/like.png"
import ShortCutImg from "../../assets/images/icon/shortcut.png"
import TimeIsMoneyImg from "../../assets/images/icon/time_is_money.png"
import UpdateInstantlyImg from "../../assets/images/icon/update_instantly.png"
import AbilityImg from "../../assets/images/icon/ability.png"
import { Link } from "react-router";
import Trans from "../../locale/Translate";
var config = {
  nextButton: ".swiper-button-next",
  prevButton: ".swiper-button-prev",
  slidesPerView: 4,
  spaceBetween: 20,
  autoplay: 2500,
  paginationHide: true,
  pagination: null
};

class StaticBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content_loading: [0, 1],
      static_banner_data: []
    };
  }

  renderList() {
    if (!this.state.static_banner_data.length) {
      return this.state.content_loading.map((result, i) => (
        <Slide key={i} className="swiper-slide">
          <Link to="/">
            <div
              className="thumbnail hotelListing"
              style={{
                // backgroundImage: 'url(' + imgOnLoading + ') ',
                // backgroundPosition: 'center',
                // backgroundRepeat: 'no-repeat',
                // backgroundSize: 'cover',
                backgroundColor: "#23527c",
                height: "300px"
              }}
            >
              <div className="caption">
                <h3>
                  <div className="animated-background facebook line3" />
                </h3>
                <div className="animated-background facebook line2" />
                <div className="animated-background facebook line1" />
              </div>
            </div>
          </Link>
        </Slide>
      ));
    }

    return this.state.static_banner_data.map((result, i) => (
      <Slide key={i} className="swiper-slide">
        <Link to={result.banner_href}>
          <div
            className="thumbnail hotelListing"
            style={{
              // backgroundImage: 'url(' + imgOnLoading + ') ',
              // backgroundPosition: 'center',
              // backgroundRepeat: 'no-repeat',
              // backgroundSize: 'cover',
              backgroundColor: "#23527c",
              height: "300px"
            }}
          >
            <div className="caption">
              <h3 className="staticBannerTitle">{result.banner_name}</h3>
              <p className="staticBannerCaption">{result.banner_des}</p>
            </div>
          </div>
        </Link>
      </Slide>
    ));
  }

  componentDidMount() {
    this.setState({
      static_banner_data: [
        {
          banner_href: "/",
          banner_image: "/images/static1.jpg",
          banner_name: "Lorem Ipsum",
          banner_des:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
          banner_href: "/",
          banner_image: "/images/static1.jpg",
          banner_name: "Lorem Ipsum",
          banner_des:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
          banner_href: "/",
          banner_image: "/images/static1.jpg",
          banner_name: "Lorem Ipsum",
          banner_des:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
          banner_href: "/",
          banner_image: "/images/static1.jpg",
          banner_name: "Lorem Ipsum",
          banner_des:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        }
      ]
    });
  }

  render() {
    return (
        <div className="features-container">
        <div className="feature-item">
          <div className="feature-icon">
          <img src={MedalImg} alt="Instant Booking Confirmation Icon" className="feature-image" />
          </div>
          <div className="feature-content">
            <h3>Best Available Rate</h3>
            <p>
              We are a local agent with a long and close relationship with hotels.
              Get the best deal from advance purchase/non-refundable/discounted
              rates/last minute.
            </p>
          </div>
        </div>
        <div className="feature-item">
          <div className="feature-icon">
          <img src={CheckMarkImg} alt="Check Icon" className="feature-image" />
          </div>
          <div className="feature-content">
            <h3>Instant Booking Confirmation</h3>
            <p>
              Get an email confirmation as soon as your booking is generated.
            </p>
          </div>
        </div>
        <div className="feature-item">
          <div className="feature-icon">
          <img src={AbilityImg} alt="Ability Icon" className="feature-image" />
          </div>
          <div className="feature-content">
            <h3>Ability to Check and Monitor Own Bookings</h3>
            <p>
              Log into our portal website to monitor your bookings, double-check
              rates, cancellation policies, etc.
            </p>
          </div>
        </div>
        <div className="feature-item">
          <div className="feature-icon">
          <img src={UpdateInstantlyImg} alt="update Icon" className="feature-image" />
          </div>
          <div className="feature-content">
            <h3>Instantly Update Availability, Rate & Inventory</h3>
            <p>
              Direct update from hotels partners.
            </p>
          </div>
        </div>
        
        <div className="feature-item">
          <div className="feature-icon">
          <img src={ShortCutImg} alt="shortcut Icon" className="feature-image" />
          </div>
          <div className="feature-content">
            <h3>Short Cut of Date</h3>
            <p>
              Availability based on free sale that enable last minutes bookings.
            </p>
          </div>
        </div>
        <div className="feature-item">
          <div className="feature-icon">
              <img src={ApiImg} alt="Instant Booking Confirmation Icon" className="feature-image" />
          </div>
          <div className="feature-content">
            <h3>API</h3>
            <p>
              Enables online travel agencies access out hotels Inventories for integration into their reservation system or website.
            </p>
          </div>
        </div>
        <div className="feature-item">
          <div className="feature-icon">
          <img src={LikeImg} alt="Like Icon" className="feature-image" />
          </div>
          <div className="feature-content">
            <h3>Japan Standard Customer Support</h3>
            <p>
              100% booking confirmation prior tp check-in. No worry about no room upon arrival
            </p>
          </div>
        </div>
        <div className="feature-item">
          <div className="feature-icon">
          <img src={TimeIsMoneyImg} alt="flexibel payment Icon" className="feature-image" />
          </div>
          <div className="feature-content">
            <h3>Flexible Payment Term</h3>
            <p>
              Get credit facility for smooth booking and payment process.
            </p>
          </div>
        </div>
      </div>
    );
  };
}

export default StaticBanner;
