import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap-button-loader';
import { resetPasswordGetToken } from '../../actions/authActions';

class ForgotPasswordForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: '',
            password: '',
            password_confirmation: '',
            submitted: false,
            getNotif: '',
            getNotifType: 0,
            load_button: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true, getNotif: '' });

        const data = {
            token: this.state.token,
            password: this.state.password,
            password_confirmation: this.state.password_confirmation
        }

        if (data.token && data.password && data.password_confirmation) {
            this.props.dispatch(resetPasswordGetToken(data));
            this.setState({ load_button: true });
        }
    }

    componentDidMount() {
        this.setState({ token: this.props.token });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            load_button: false
        })

        if (nextProps.dataAuth.isSuccess === false) {
            this.setState({
                getNotifType: 0,
                getNotif: nextProps.dataAuth.notification
            });
        }
        if (nextProps.dataAuth.isSuccess === true) {
            this.setState({
                getNotifType: 1,
                getNotif: nextProps.dataAuth.notification
            });
        }
    }

    render() {
        const { password, password_confirmation, submitted, getNotif, getNotifType, load_button } = this.state;

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-4 col-md-offset-4">
                        <p style={{ fontSize: '14px' }}>Reset Password.</p>
                        {getNotif ?
                            <div className={`alert ${getNotifType == 0 ? 'alert-danger' : 'alert-info'}`} role="alert">
                                <strong>{getNotifType == 0 ? 'Alert' : 'Thank you'}</strong> {getNotif}
                            </div>
                            : null}
                        <form onSubmit={this.handleSubmit}>
                            <div className={`form-group ${submitted && !password ? 'has-error' : ''}`}>
                                <div className="input-group ">
                                    <span className="input-group-addon"><i className="fa fa-lock fa-fw"></i></span>
                                    <input value={password} onChange={this.handleChange} className="form-control input-group-custom input-lg" type="password" name="password" placeholder="Password" />
                                </div>
                                {submitted && !password ? <span className="help-block">Password is required</span> : null}
                            </div>
                            <div className={`form-group ${submitted && !password_confirmation ? 'has-error' : ''}`}>
                                <div className="input-group ">
                                    <span className="input-group-addon"><i className="fa fa-lock fa-fw"></i></span>
                                    <input value={password_confirmation} onChange={this.handleChange} className="form-control input-group-custom input-lg" type="password" name="password_confirmation" placeholder="Password Confirmation" />
                                </div>
                                {submitted && !password_confirmation ? <span className="help-block">Confirmation Password is required</span> : null}
                            </div>
                            <Button type="submit" className="btn btn-success btn-block btn-lg btn-login-modal" loading={load_button}>Change Password</Button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { dataAuth: state.authReducer }
}

export default connect(mapStateToProps)(ForgotPasswordForm)