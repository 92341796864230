import React, { Component } from 'react'

import { Layout, Login } from '../../components'
import { Container, Row, Col } from 'reactstrap';
import { Register } from '..';
import HisLogo from '../../assets/images/his-hotels.png';
import '../../assets/css/custom2.css'

export default class Auth extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
                <section>
                    <Container className='rcontainer'>          
                        <Row className='rwapper'>
                            <Col md={6} className='flex-rwp'>
                                <div className='lpf'>
                                    <img role="presentation" src={HisLogo} alt="logo" width={350} height={'auto'} style={{marginRight: '10px'}}/>			
                                        <p style={{ fontSize: '18px', lineHeight: '3vh', marginTop: '2vh' }}>
                                            Welcome to HIS Hotels website.<br />
                                            Please log in with your account to see our offers.
                                        </p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <Login />
                            </Col>
                        </Row>
                        <div style={{position: 'absolute', bottom: 0, width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <p style={{fontSize: '14px', marginBottom: '30px'}}>Copyright HIS Hotels 2024. All rights reserved</p>
                        </div>
                    </Container>
                </section>
        )
    }
}