import { browserHistory } from 'react-router';
import * as auth from '../utils/auth';
import { selectCurrencyAction } from './currencyActions';

const loginSuccess = (user) => {
	return {
		type: 'LOGIN_SUCCESS',
		user
	}
}

const loginFailure = (error) => {
	return {
		type: 'LOGIN_FAILURE',
		error
	}
}

const forgotPasswordRequestSuccess = (notification) => {
	return {
		type: 'FORGOT_PASSWORD_REQUEST_SUCCESS',
		notification
	}
}

const forgotPasswordRequestFailure = (notification) => {
	return {
		type: 'FORGOT_PASSWORD_REQUEST_FAILURE',
		notification
	}
}

const resetPasswordGetTokenSuccess = (notification) => {
	return {
		type: 'RESET_SUCCESS_GET_TOKEN',
		notification
	}
}

const resetPasswordGetTokenFailure = (notification) => {
	return {
		type: 'RESET_FAILURE_GET_TOKEN',
		notification
	}
}

const logoutSuccess = () => {
	return {
		type: 'LOGOUT_SUCCESS'
	}
}

const registerSuccess = (notification) => {
	return {
		type: 'REGISTER_SUCCESS',
		notification
	}
}

const registerError = (error) => {
	return {
		type: 'REGISTER_ERROR',
		error
	}
}

const registerActivationSuccess = (notification) => {
	return {
		type: 'REGISTER_ACTIVATION_SUCCESS',
		notification
	}
}

const registerActivationError = (error) => {
	return {
		type: 'REGISTER_ACTIVATION_ERROR',
		error
	}
}

const updateProfileSuccess = (user) => {
	return {
		type: 'UPDATE_PROFILE_SUCCESS',
		user
	}
}

const updateProfileError = (error) => {
	return {
		type: 'UPDATE_PROFILE_ERROR',
		error
	}
}

export const login = (credentials) => {
	return (dispatch) => {
		return auth.login(credentials)

			.then(user => {
				dispatch(loginSuccess(user));

				var currencyCode = "USD";
				if (user.user.agent_currencies && user.user.agent_currencies.length > 0) {
					const userCurrency = user.user.agent_currencies[0].currency
					currencyCode = userCurrency.transaction_code
				}

				dispatch(selectCurrencyAction(currencyCode));
				browserHistory.push(credentials.nextPath);
			}).catch(error => {
				dispatch(loginFailure(error));
			});
	};
}

export const forgotPasswordRequest = (credentials) => {
	return (dispatch) => {
		return auth.forgotPasswordRequest(credentials)
			.then(data => {
				dispatch(forgotPasswordRequestSuccess(data.meta));
			})
			.catch(error => {
				dispatch(forgotPasswordRequestFailure(error))
			});
	}
}

export const resetPasswordGetToken = (credentials) => {
	return (dispatch) => {
		return auth.resetPasswordGetToken(credentials)
			.then(data => {
				if (data.meta.success) {

					dispatch(resetPasswordGetTokenSuccess(data.meta));

					setTimeout(() => {
						browserHistory.push('/login');
					}, 1500);
				}
			})
			.catch(error => {
				dispatch(resetPasswordGetTokenFailure(error));
			});
	}
}

export const logout = (current_url) => {
	return (dispatch) => {
		return auth.logout()
			.then(() => {
				dispatch(logoutSuccess())
				browserHistory.push(current_url)
			})
			.catch((error) => {
				// 
			})
	}
}

export const register = (credentials) => {
	return (dispatch) => {
		return auth.register(credentials)
			.then(data => {
				dispatch(registerSuccess(data.meta));
			})
			.catch(error => {
				dispatch(registerError(error));
			});
	};
}

export const registerActivation = (token) => {
	return (dispatch) => {
		return auth.registerActivation(token)
			.then(data => {
				dispatch(registerActivationSuccess(data.meta));
			})
			.catch(error => {
				dispatch(registerActivationError(error));
			});
	};
}

export const updateProfile = (value) => {
	return (dispatch) => {
		return auth.updateProfile(value)
			.then(user => {
				dispatch(updateProfileSuccess(user));
			})
			.catch(error => {
				dispatch(updateProfileError(error));
			});
	};
}
