import React from 'react';
import ReactDOM from 'react-dom';

import * as auth from './utils/auth';
import SearchHotel from './components/SearchHotel';
import HotelDetail from './components/HotelDetail';
import BookingForm from './components/BookingForm';
import PaymentForm from './components/PaymentForm'

// containers
import {
    Auth,
    ForgotPassword,
    Register,
    ActivationRegister,
    Home,
    Profile,
    Deposit,
    MyBookings,
    BookingDetail,
    VoucherSend,
    PaymentFailed,
    AboutHoterip,
    AdditionalBenefit,
    GoodServices,
    PrivacyPolicy,
    Term,
    Page404
} from './containers/index'

//style
import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/style.css'
import './assets/css/custom.css'
import './assets/css/font-awesome.css'

//js
import 'bootstrap/dist/js/bootstrap.min.js'

import 'react-datepicker/dist/react-datepicker.css';

//react redux
import ReduxThunk from 'redux-thunk'
import { Router, Route, browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import hoteripApp from './reducers';
let store = createStore(
    hoteripApp,
    applyMiddleware(
        ReduxThunk
    )
)

function hashLinkScroll() {
    const { hash } = window.location;

    if (hash !== '') {
        setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) element.scrollIntoView();
        }, 0);
    }

    window.scrollTo(0, 0);
}

const requireAuth = (nextState, replace) => {
    if (!auth.isLoggedIn()) {
        replace({
            pathname: '/login',
            state: { nextPathName: nextState.location.pathname }
        });
    }
}

const isAlreadyAuth = (nextState, replace) => {
    if (auth.isLoggedIn()) {
        replace({
            pathname: '/',
            state: { nextPathName: nextState.location.pathname }
        });
    }
}

//default session
// localStorage.setItem('currency', 'USD');
if (!localStorage.getItem('currency')) {
    localStorage.setItem('currency', 'USD');
}

if (!localStorage.getItem('language')) {
    localStorage.setItem('language', 'en-us');
}

if (!localStorage.getItem('passport')) {
    localStorage.setItem('passport', 98);
}

ReactDOM.render(
    <Provider store={store}>
        <div className="App">
            <Router onUpdate={hashLinkScroll} history={browserHistory}>
                <Route onEnter={isAlreadyAuth}>
                    <Route path="/login" component={Auth} />
                    <Route path="/register" component={Register} />
                    <Route path="/reset-password/:token" component={ForgotPassword} />
                    <Route path="/activation/:token" component={ActivationRegister} />
                </Route>

                <Route onEnter={requireAuth}>
                    <Route path="/" component={Home} />
                    <Route path="/profile" component={Profile} />
                    <Route path="/deposit" component={Deposit} />
                    <Route path="/booking-history" component={MyBookings} />
                    <Route path="/booking-detail" component={BookingDetail} />
                    <Route path="/hotels/search" component={SearchHotel} />
                    <Route path="/:country/:city/:hotel" component={HotelDetail} />
                    <Route path="/booking" component={BookingForm} />
                    <Route path="/payment" component={PaymentForm} />
                    <Route path="/voucher-send" component={VoucherSend} />
                    <Route path="/payment-failed" component={PaymentFailed} />
                    <Route path="/about-hoterip" component={AboutHoterip} />
                    <Route path="/additional-benefit" component={AdditionalBenefit} />
                    <Route path="/good-services" component={GoodServices} />
                    <Route path="/term" component={Term} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                </Route>
                <Route path='*' component={Page404} />
            </Router>
        </div>
    </Provider>,
    document.getElementById('root')
);
