import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router'
import { connect } from 'react-redux'
import Img from 'react-image'
import { lastBookedAction } from '../../actions/lastBookedAction.js'
import imgNotAvilable from '../../assets/images/imgNotAvilable.jpg';
import Star from '../../modules/Star/index';
import * as Env from '../../Env'
import Trans from '../../locale/Translate'

var moment = require('moment');

class LastBookedHotel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lists: []
        };
    }

    componentDidMount() {
        this.props.dispatch(lastBookedAction());
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ lists: nextProps.lastBookedReducer })
    }

    renderList() {

        let temparray = [];
        var a = this.state.lists,
            chunk

        while (a.length > 0) {
            chunk = a.splice(0, 3);

            temparray.push(chunk);
        }

        return (
            temparray.map((newArray, h) =>
                <Carousel.Item key={h} className="item">
                    {
                        newArray.map((result, i) =>
                            <div key={i} className="col-lg-4 col-xs-4 col-md-4 col-sm-4">
                                {/* <Link to={{
                                    pathname: "/" + result.country_slug + '/' + result.city_slug + '/' + result.slug, query: {
                                        check_in: moment().format("YYYY-MM-DD"),
                                        check_out: moment().add(1, 'days').format("YYYY-MM-DD"),
                                        number_of_night: 1,
                                        room: 1,
                                        capacities: JSON.stringify([{ number_of_adults: 1, number_of_children: 0 }]),
                                    }
                                }}> */}
                                <a href={'/'+result.country_slug+'/'+result.city_slug+'/'+result.slug+'?check_in='+moment().format("YYYY-MM-DD")+'&check_out='+moment().add(1, 'days').format("YYYY-MM-DD")+'&number_of_night=1&room=1&capacities='+JSON.stringify([{ number_of_adults: 1, number_of_children: 0 }])}>
                                    <div className="panel panel-default list-lastBooked">
                                        <div className="panel-body">
                                            <div className="row" style={{ border: '1px solid #ddd', marginRight: '0px' }}>
                                                <div className="col-sm-4" style={{ padding: '0' }}>
                                                    <Img src={result.image_url_small} unloader={<img src={imgNotAvilable} />} alt={result.name} />
                                                </div>
                                                <div className="col-sm-8" style={{ padding: '0' }}>
                                                    <h5>{result.name}</h5>
                                                    <Star value={result.star} />
                                                    <div className="price">
                                                        <small></small> {Env.format(result.average_rate_per_night)} <small>{Trans.data.avg_pernight}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                {/* </Link> */}
                            </div>
                        )
                    }
                </Carousel.Item>
            )
        )
    }

    render() {
        return (
            <div>
                {this.state.lists.length === 0 ? <section style={{ marginBottom: "-50px" }}></section> :
                    <section className="last-booked" style={{ padding: '0' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12"><h4>{Trans.data.last_booked_hotel}</h4></div>
                            </div>
                            <div>
                                <Carousel id="myCarousel" controls={false}>
                                    {this.renderList()}
                                </Carousel>
                            </div>
                        </div>
                    </section>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lastBookedReducer: state.lastBookedReducer.data
    }
}

export default connect(mapStateToProps)(LastBookedHotel)