import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { ModalForgotPassword } from '../'
import { login } from '../../actions/authActions.js';
import Button from 'react-bootstrap-button-loader';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            getNotif: '',
            submitted: false,
            modalForgotPswd: false,
            load_button: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.openModalForgotPswd = this.openModalForgotPswd.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        localStorage.removeItem('isExpired');

        this.setState({ submitted: true });

        const { email, password } = this.state;

        let nextPath = '/';

        if (email && password) {
            this.setState({
                load_button: true
            })
            this.props.dispatch(login({ email, password, nextPath }));
        }
    }

    openModalForgotPswd() {
        this.setState({
            modalForgotPswd: true
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            load_button: false
        })

        if (nextProps.dataAuth.isLoggedIn === false) {
            this.setState({
                getNotif: nextProps.dataAuth.notification
            })
        }
    }

    render() {
        const { email, password, getNotif, submitted, load_button } = this.state;
        return (
            <div className='login-form'>
                <Row>
                    <Col xs="12">
                        {localStorage.getItem('isExpired') ?
                            <div className="alert alert-danger" role="alert">
                                <strong>Alert</strong> {localStorage.getItem('isExpired')}
                            </div>
                            : null}
                        {getNotif ?
                            <div className="alert alert-danger" role="alert">
                                <strong>Alert</strong> {getNotif}
                            </div>
                            : null}
                        <form onSubmit={this.handleSubmit}>
                            <div className={`form-group ${submitted && !email ? 'has-error' : ''}`}>
                                <div className="input-group ">
                                    <span className="input-group-addon"><i className="fa fa-envelope fa-fw"></i></span>
                                    <input value={email} onChange={this.handleChange} className="form-control input-group-custom input-lg radius-none-username" type="email" name="email" placeholder="Email Address" />
                                </div>
                                {submitted && !email ? <span className="help-block">Email is required</span> : null}
                            </div>
                            <div className={`form-group ${submitted && !password ? 'has-error' : ''}`}>
                                <div className="input-group ">
                                    <span className="input-group-addon"><i className="fa fa-lock fa-fw"></i></span>
                                    <input value={password} onChange={this.handleChange} className="form-control input-group-custom input-lg radius-none-password" type="password" name="password" placeholder="Password" />
                                </div>
                                {submitted && !password ? <span className="help-block">Password is required</span> : null}
                            </div>
                            <p className="forgot-pswd" style={{ marginTop: '-15px' }}><a onClick={this.openModalForgotPswd}>Forgot Password</a></p>
                            <Button type="submit" className="btn btn-primary btn-block btn-lg btn-login-modal" loading={load_button} style={{background: '#10069f !important'}}>Login</Button><br/>
                            <p style={{ fontSize: '14px' }}>Not a partner agent member yet?</p>
                            <a href="/register" className="btn btn-warning btn-block btn-lg btn-login-modal" style={{color:"#FFF"}}>Register</a>
                        </form>
                    </Col>
                </Row>
                <ModalForgotPassword
                    openForgotPswd={this.state.modalForgotPswd}
                    closeForgotPswd={() => this.setState({ modalForgotPswd: false })}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { dataAuth: state.authReducer }
}

export default connect(mapStateToProps)(Login)