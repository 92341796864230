const search = (state = {}, action) => {
    switch (action.type) {
        case 'UPDATE_QUERY':
            return {
                query: action.query
            }
        default:
            return state;
    }
}

export default search;
