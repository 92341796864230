import React, { Component } from 'react';
import $ from 'jquery';
import { Checkbox } from 'react-icheck';
import 'icheck/skins/all.css';
import * as Env from '../../Env';
import Trans from '../../locale/Translate';
import { connect } from 'react-redux';
import { updateQuery } from '../../actions/search.js';
import axios from 'axios';
import { DelayInput } from 'react-delay-input';
import moment from 'moment';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';
window.jQuery = $;

require('ion-rangeslider/css/ion.rangeSlider.min.css');
require('ion-rangeslider/js/ion.rangeSlider.min.js');

const history = createBrowserHistory();
const star_rating = [0, 5, 4, 3, 2, 1];
const review_score = [0, 4, 3, 2, 1];

class SearchHotelFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                check_in: moment().format("YYYY-MM-DD"),
                check_out: moment().add(1, 'days').format("YYYY-MM-DD"),
                city_slug: '',
                city_name: '',
                country_slug: '',
                hotel_name: '',
                min_price: Env._default.min_range,
                max_price: Env._default.max_range,
                review: JSON.stringify([1, 2, 3, 4]),
                star: JSON.stringify([1, 2, 3, 4, 5]),
                type: JSON.stringify([1, 2, 3]),
            },
            currency_label: Env._default.currency_code+' ',
            min_price: Env._default.min_range,
            max_price: Env._default.max_range,
            district: [{ id: 0, name: "All", total: 0, checked: true }],
            star: [false, false, false, false, false, false],
            review: [
                { label: Trans.data.all, checked: false },
                { label: '10 - 8', checked: false },
                { label: '8 - 6', checked: false },
                { label: '6 - 4', checked: false },
                { label: '4 - 0', checked: false },
            ],
            type: [
                { label: Trans.data.all, checked: false },
                { label: Trans.data.hotel, checked: false },
                { label: Trans.data.villa, checked: false },
                { label: Trans.data.apartment, checked: false },
            ],
            showDistrict: false,
            districtToShow: 6,
            totalDistrict: 0,
        };
    }

    componentDidMount() {
        this.setState({ data: this.state.data }, () => {
            this.fetchLocation();
            this.handleChangeDidMountType();
            this.handleChangeDidMountReview();
            this.handleChangeDidMountStar();
            this.priceRangeSlider();
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps.data.query }, () => {
            this.fetchLocation();
        });
    }

    handleChangeDidMountType() {
        let data_type = JSON.parse(this.state.data.type);
        let type = this.state.type;

        type.forEach((value, i) => {
            if (data_type.indexOf(i) > -1) type[i].checked = true;
        });

        if (data_type.length === type.slice(1).length) {
            type[0].checked = true;
        }

        this.setState({ type: type });
    }

    handleChangeDidMountReview() {
        let data_review = JSON.parse(this.state.data.review);
        let review = this.state.review;

        review.forEach((value, i) => {
            if (data_review.indexOf(i) > -1) review[i].checked = true;
        });

        if (data_review.length === review.slice(1).length) {
            review[0].checked = true;
        }

        this.setState({ review: review });
    }

    handleChangeDidMountStar() {
        let data_star = JSON.parse(this.state.data.star);
        let star = this.state.star;

        star.forEach((value, i) => {
            if (data_star.indexOf(i) > -1) star[i] = true;
        });

        if (data_star.length === star.slice(1).length) {
            star[0] = true;
        }

        this.setState({ star: star });
    }

    dispatch() {
        let data = this.state.data;

        const searchString = queryString.stringify(data);
        history.push({ pathname: '/hotels/search', search: searchString });

        this.props.dispatch(updateQuery(data));
    }

    handleChangeHotelName(event) {
        let data = this.state.data;

        data.hotel_name = event.target.value;
        data.page = 1;

        this.setState({ data: data }, () => {
            this.dispatch();
        });
    }

    priceRangeSlider() {
        var priceRangeSlider = $("#priceRangeSlider");

        priceRangeSlider.ionRangeSlider({
            type: "double",
            min: this.state.min_price,
            max: this.state.max_price,
            from: this.state.data.min_price,
            to: this.state.data.max_price,
            prefix: this.state.currency_label,
            onFinish: (data) => this.priceRangeSliderChange(data)
        });
    }

    priceRangeSliderChange(result) {
        let data = this.state.data;

        data.min_price = result.from;
        data.max_price = result.to;
        data.page = 1;

        this.setState({ data: data }, () => {
            this.dispatch();
        });
    }

    handleChangeStar(index) {
        let star = this.state.star;
        let data = this.state.data;
        let filter_star = [];

        if (!index) {
            if (star[0]) {
                star = [false, false, false, false, false, false];
            } else {
                star = [true, true, true, true, true, true];
            }
        } else {
            // if (!(star.indexOf(false) > -1)) {
                // star = [false, false, false, false, false, false];
                // star[index] = true;
            // } else {
                star[0] = false;
                star[index] = !star[index];
            // }
        }

        if (!(star.slice(1).indexOf(false) > -1)) {
            star[0] = true;
        }

        this.setState({ star: star }, () => {
            for (var i = 0; i < this.state.star.length; i++) {
                if (i && this.state.star[i]) {
                    filter_star.push(i);
                }
            }

            data.star = JSON.stringify(filter_star);
            data.page = 1;

            this.setState({ data: data }, () => {
                this.dispatch();
            });
        });
    }

    fetchLocation() {
        var that = this;

        axios.get(Env.url.api_url + 'hotels/locations/districts/' + that.state.data.city_slug, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Accept-Language': localStorage.getItem('language'),
                'Accept-Currency': localStorage.getItem('currency')
            }
        })
        .then(function (response) {
            that.setState({
                district: that.createDistrictList(response.data.data, true),
                totalDistrict: response.data.data.length
            });
        })
        .catch(function (error) {
            Env.expired(error)
        });
    }

    handleChangeDistrict(index) {
        let data = this.state.data;
        let district = this.state.district;
        let filter_district = JSON.parse(data.district);

        if (!index) {
            let clear_checked = false;

            if (district[index].checked || (!district[index].id && district[index].checked)) {
                clear_checked = true;
            }

            for (let i = 0; i < district.length; i++) {
                var filter_district_checking = filter_district.indexOf(district[i].id);

                if (clear_checked) {
                    if (filter_district_checking > -1) {
                        filter_district.splice(filter_district_checking, 1);
                    }
                } else if (i) {
                    if (filter_district_checking < 0) {
                        filter_district.push(district[i].id);
                    }
                } else {
                    district[0].checked = true;
                }
            }
        } else if (filter_district.indexOf(district[index].id) > -1) {
            // if (filter_district.length === district.length - 1) {
                // filter_district = [];
                // filter_district.push(district[index].id);
            // } else {
                filter_district.splice(filter_district.indexOf(district[index].id), 1);
            // }
        } else {
            if (filter_district.length === 0 && district[index].checked) {
                district.forEach((value, i) => {
                    if (i && value.checked && (value.id !== district[index].id)) {
                        filter_district.push(value.id);
                    }
                });
            } else {
                filter_district.push(district[index].id);
            }
        }

        data.district = JSON.stringify(filter_district);
        data.page = 1;

        this.setState({ data: data }, () => {
            this.setState({ district: this.createDistrictList(district, false) }, () => {
                this.dispatch();
            });
        });
    }

    createDistrictList(array, first_data = false) {
        let data = this.state.data;

        let filter_district = JSON.parse(data.district);

        let new_array = [];

        if (first_data) {
            new_array.push({
                id: 0,
                name: `${Trans.data.all}`,
                total: 0,
                checked: new_array.length === array.length ? true : false,
            });
        }

        var total_hotel = 0;

        for (let i = 0; i < array.length; i++) {
            let checked = false;

            if (filter_district.indexOf(array[i].id) > -1) {
                checked = true;
            }

            let name = (array[i].name !== undefined) ? array[i].name : array[i].text_selected.name;
            let total = (array[i].total !== undefined) ? array[i].total : array[i].hotel_count_agent;

            new_array.push({
                id: array[i].id,
                name: name,
                total: total,
                checked: checked,
            });

            if (array[i].id !== 0) {
                total_hotel += total;
            }
        }

        let filtered_array = new_array.filter(function (x) {
            return x.checked;
        });

        if (new_array.length === (filtered_array.length + 1)) {
            new_array[0].checked = true;
        }

        new_array[0].total = total_hotel;

        return new_array;
    }

    sliceDistrict() {
        if (this.state.showDistrict) {
            this.setState({ districtToShow: this.state.totalDistrict + 1 });
        } else {
            this.setState({ districtToShow: 6 });
        }
    }

    handleChangeReview(index) {
        let data = this.state.data;
        let review = this.state.review;
        let filter_review = [];

        let array_review = [];

        review.forEach(function (value) {
            array_review.push(value.checked);
        });

        if (!index) {
            review.forEach(function (value, index) {
                if (index) {
                    value.checked = !review[0].checked;
                }
            });
        } else {
            // if (!(array_review.indexOf(false) > -1)) {
                // review.forEach(function (value) {
                    // value.checked = false;
                // });

                // review[index].checked = true;
            // } else {
                review[index].checked = !review[index].checked;
            // }
        }

        review[0].checked = true;

        review.forEach(function (value, index) {
            if (index) {
                if (!value.checked) {
                    review[0].checked = false;
                }
            }
        });

        this.setState({ review: review }, () => {
            for (var i = 0; i < this.state.review.length; i++) {
                if (i && this.state.review[i].checked) {
                    filter_review.push(review_score[i]);
                }
            }

            data.review = JSON.stringify(filter_review);
            data.page = 1;

            this.setState({ data: data }, () => {
                this.dispatch();
            });
        });
    }

    handleChangeType(index) {
        let data = this.state.data;
        let type = this.state.type;
        let filter_type = [];

        let array_type = [];

        type.forEach(function (value) {
            array_type.push(value.checked);
        });

        if (!index) {
            type = [
                { label: Trans.data.all, checked: !type[0].checked },
                { label: Trans.data.hotel, checked: !type[0].checked },
                { label: Trans.data.villa, checked: !type[0].checked },
                { label: Trans.data.apartment, checked: !type[0].checked },
            ];
        } else {
            // if (!(array_type.indexOf(false) > -1)) {
                // type.map(function (value) {
                    // value.checked = false;
                // });

                // type[index].checked = true;
            // } else {
                type[index].checked = !type[index].checked;
            // }
        }

        type[0].checked = true;

        type.forEach(function (value, index) {
            if (index) {
                if (!value.checked) {
                    type[0].checked = false;
                }
            }
        });

        this.setState({ type: type }, () => {
            for (var i = 0; i < this.state.type.length; i++) {
                if (i && this.state.type[i].checked) {
                    filter_type.push(i);
                }
            }

            data.type = JSON.stringify(filter_type);
            data.page = 1;

            this.setState({ data: data }, () => {
                this.dispatch();
            });
        });
    }

    renderIconStar(value) {
        var stars = [];

        for (var i = 0; i < value; i++) {
            stars.push(<i className="fa fa-star" aria-hidden="true" key={i}></i>);
        }

        return stars;
    }

    render() {
        return (
            <div className="">
                <div className="sidebar-list-hotel">
                    <h3>{Trans.data.filter_hotel_results}</h3>
                    <hr/>
                    <div className="rangeprice">
                        <h4>{Trans.data.search_hotel_name}</h4>
                        <DelayInput value={this.state.data.hotel_name || ''} minLength={1} delayTimeout={2000} onChange={this.handleChangeHotelName.bind(this)} className="form-control" placeholder={Trans.data.enter_a_hotel_name}/>
                    </div>
                    <hr/>
                    <div className="rangeprice">
                        <h4>{Trans.data.price}</h4>
                        <input id="priceRangeSlider" ref="priceRangeSlider"/>
                    </div>
                    <hr/>
                    <div className="star-rating">
                        <h4>{Trans.data.star}</h4>
                        <ul className="list-unstyled">
                            {star_rating.map((value, i) =>
                                <li key={i} className='d-flex align-items-center'>
                                    <input type='checkbox' onChange={this.handleChangeStar.bind(this, value)} checked={this.state.star[value]} className='checkbox-blue mr-3' />
                                    {value ? <div className="star-filter">{this.renderIconStar(value)}</div> : ` ${Trans.data.all}`}
                                </li>
                            )}
                        </ul>
                    </div>
                    <hr/>
                    <div className="area">
                        <h4>{Trans.data.area}</h4>
                        <ul className="list-unstyled">
                            {this.state.district.slice(0, this.state.districtToShow).map((result, i) =>
                                // <li key={i}>
                                //     <Checkbox
                                //         checkboxClass="icheckbox_square-green"
                                //         increaseArea="20%"
                                //         label={` ${result.name} ${result.total ? '(' + result.total + ')' : '(0)'}`}
                                //         checked={this.state.district[i].checked}
                                //         disabled={false}
                                //         onChange={this.handleChangeDistrict.bind(this, i)}
                                //     />
                                // </li>
                                <li key={i} className='d-flex align-items-center'>
                                <input type='checkbox' onChange={this.handleChangeDistrict.bind(this, i)} checked={this.state.district[i].checked} className='checkbox-blue mr-3' />
                                {` ${result.name} ${result.total ? '(' + result.total + ')' : '(0)'}`}
                            </li>
                            )}
                        </ul>
                        <div className="show-area" onClick={() => this.setState(prev => ({ showDistrict: !prev.showDistrict }), () => this.sliceDistrict())}>
                            {this.state.showDistrict ? <div>{Trans.data.show_less} {" "}<i className="fa fa-caret-up"></i> </div> : <div>{Trans.data.show_more} {" "}<i className="fa fa-caret-down"></i></div>}
                        </div>
                    </div>
                    {/* <hr/>
                    <div className="hotel-review">
                        <h4>{Trans.data.hotel_reviews}</h4>
                        <ul className="list-unstyled">
                            {this.state.review.map((result, i) =>
                                <li key={i}>
                                    <Checkbox
                                        checkboxClass="icheckbox_square-green"
                                        increaseArea="20%"
                                        label={` ${result.label}`}
                                        checked={this.state.review[i].checked}
                                        disabled={false}
                                        onChange={this.handleChangeReview.bind(this, i)}
                                    />
                                </li>
                            )}
                        </ul>
                    </div> */}
                    <hr/>
                    <div className="hotel-type">
                        <h4>{Trans.data.hotel_type}</h4>
                        <ul className="list-unstyled">
                            {this.state.type.map((result, i) =>
                                // <li key={i}>
                                //     <Checkbox
                                //         checkboxClass="icheckbox_square-green"
                                //         increaseArea="20%"
                                //         label={` ${result.label}`}
                                //         checked={this.state.type[i].checked}
                                //         disabled={false}
                                //         onChange={this.handleChangeType.bind(this, i)}
                                //     />
                                // </li>
                                <li key={i} className='d-flex align-items-center'>
                                <input type='checkbox' onChange={this.handleChangeType.bind(this, i)} checked={this.state.type[i].checked} className='checkbox-blue mr-3' />
                                {` ${result.label}`}
                            </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.search,
    }
}

export default connect(mapStateToProps)(SearchHotelFilter);
