import React, { Component } from 'react';
import Iframe from 'react-iframe';
import Trans from '../../locale/Translate';
import { updateRoomDetail } from '../../actions/room_detail.js'
import { updateCapacities } from '../../actions/room_capacities.js'
import { connect } from 'react-redux'
import { Tab, Nav, NavItem, Breadcrumb, Button, Panel } from 'react-bootstrap';
import { Radio } from 'react-icheck';
import $ from 'jquery';
import * as Env from '../../Env';
import Star from '../../modules/Star';
import Template from '../../components/Layout';
import axios from 'axios';
import moment from 'moment';
import { CheckInBox, AvailableRoom, LastBookedHotel, SearchHotelBoxInput } from '../../components'
import { Row, Col } from 'reactstrap';
import { browserHistory } from 'react-router';

require('../../assets/js/lightslider/dist/js/reactLightSlider.js');

let google = window.google;

class HotelDetail extends Component {
	constructor(props) {
		super(props);

		this.state = {
			key: "photos",
			review_loaded: false,
			hotel_loaded: false,
			open: false,
			hotel_photo_data: [],
			hotel_review_data: [],
			hotel_review_meta: {
				"total": 0,
				"per_page": 0,
				"current_page": 0,
				"last_page": 0,
				"next_page_url": null,
				"prev_page_url": null,
				"from": 0,
				"to": 0,
				"summary_review": {},
				"last_review": {},
			},
			hotel_data: {
				id: null,
				city_id: null,
				district_id: null,
				currency_id: null,
				hotel_type_id: null,
				hotel_photo_id: null,
				url_segment: null,
				active: null,
				star: null,
				address: null,
				telephone: null,
				fax: null,
				opening_year: null,
				renovation_year: null,
				number_of_floors: null,
				number_of_rooms: null,
				distance_from_airport: null,
				time_from_airport: null,
				distance_from_city_center: null,
				distance_from_station: null,
				room_voltage: null,
				is_pet_allowed: null,
				check_in_time: null,
				check_out_time: null,
				child_age_from: null,
				child_age_until: null,
				coordinate: null,
				point_rate: null,
				tax_rate: null,
				old_rank: null,
				rank: null,
				basename: null,
				small_basename: null,
				timezone: null,
				is_commission_rate: null,
				is_seperate_email: null,
				seperate_email: null,
				segment_1: null,
				segment_2: null,
				segment_3: null,
				segment_4: null,
				segment_5: null,
				segment_6: null,
				segment_7: null,
				segment_8: null,
				ratePerNight: null,
				trip_advisior_hotel_id: 0,
				photo: { url: null },
				hotel_text: {
					id: null,
					hotel_id: null,
					language_id: null,
					name: null,
					description: null
				},
				facility: {
					id: null,
					hotel_id: null,
					is_banquet_room_available: false,
					is_bars_available: false,
					is_business_center_available: false,
					is_car_parking_available: false,
					is_casino_available: false,
					is_clinic_available: false,
					is_club_lounge_available: false,
					is_coffee_shop_available: false,
					is_departure_lounge_available: false,
					is_disabled_facilities_available: false,
					is_elevator_available: false,
					is_garden_available: false,
					is_gym_available: false,
					is_gift_shop_available: false,
					is_golf_course_available: false,
					is_hair_salon_available: false,
					is_jacuzzi_available: false,
					is_karaoke_room_available: false,
					is_kids_club_available: false,
					is_kids_pool_available: false,
					is_library_available: false,
					is_luggage_room_available: false,
					is_meeting_room_available: false,
					is_night_club_available: false,
					is_private_beach_available: false,
					is_poolside_bar_available: false,
					is_restaurant_available: false,
					is_safety_box_available: false,
					is_sauna_available: false,
					is_spa_available: false,
					is_squash_court_available: false,
					is_steam_room_available: false,
					is_swimming_pool_available: false,
					is_tennis_court_available: 0,
					is_smoking_area_available: 0,
				},
				service: {
					id: null,
					hotel_id: null,
					is_room_service_24hour_available: false,
					is_reception_24hour_available: false,
					is_airport_transfer_available: false,
					is_baby_sitter_available: false,
					is_butler_available: false,
					is_concierge_available: false,
					is_laundry_available: false,
					is_massage_available: false,
					is_money_changer_available: false,
					is_hotel_activities_available: false,
					is_rental_baby_car_available: false,
					is_rental_bicycle_available: false,
					is_rental_multi_plug_available: false,
					is_rental_wheel_chair_available: false,
					is_room_service_available: false,
					is_shuttle_available: false,
					is_valet_parking_available: false,
					is_free_wifi_available: false,
					is_paid_wifi_available: false,
					is_japanese_staff_available: 0,
            		is_english_staff_available: 0,
            		is_rental_baby_bed_available: 0,
            		is_rental_bed_guard_available: 0,
				},
				announcements: [],
				plug_type: [],
			},
			location: { lat: -8.715337, lng: 115.209137 },
			activeKey: null,
			lists: [],
			cheap_campaign: { total_price: null },
			segment_market_id: 3,
			pax_passport: 98,
			state_photo: 0,
			facility_service_remark: [],
			content_loading: [0, 1, 2],
		};

		this.handleSelect = this.handleSelect.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ cheap_campaign: nextProps.cheap_campaign.query });
	}

	componentDidMount() {
		if (this.props.location.query.segment_market_id) {
			this.setState({ segment_market_id: this.props.location.query.segment_market_id })
			localStorage.setItem('passport', this.props.location.query.pax_passport);
		}

		if(this.props.location.query.pax_passport) {
			this.setState({ pax_passport: this.props.location.query.pax_passport })
		}

		this.props.dispatch(updateRoomDetail(this.props.location.query));
		this.props.dispatch(updateCapacities(this.props.location.query.capacities));
		this.fetchData();
		this.fetchDataPhoto();
		this.fetchDataReview();

		setTimeout(() => {
			this.setState({ zoom: 15 })
		}, 500);
	}

	componentDidUpdate(nextProps, prevState) {
		if(localStorage.getItem('passport') !== nextProps.location.query.pax_passport){
			if (this.props.location.query.segment_market_id) {
				this.setState({ segment_market_id: this.props.location.query.segment_market_id })
				localStorage.setItem('passport', this.props.location.query.pax_passport);
			}

			if(this.props.location.query.pax_passport) {
				this.setState({ pax_passport: this.props.location.query.pax_passport })
			}
		}

		if (nextProps.location.pathname !== this.props.location.pathname) {
			if (this.props.location.query.segment_market_id) {
				this.setState({ segment_market_id: this.props.location.query.segment_market_id })
				localStorage.setItem('passport', this.props.location.query.pax_passport);
			}

			if(this.props.location.query.pax_passport) {
				this.setState({ pax_passport: this.props.location.query.pax_passport })
			}

			this.setState({state_photo: (this.state.state_photo+1)})

			this.props.dispatch(updateRoomDetail(this.props.location.query));
			this.props.dispatch(updateCapacities(this.props.location.query.capacities));
			this.fetchData();
			this.fetchDataPhoto();
			this.fetchDataReview();

			setTimeout(() => {
				this.setState({ zoom: 15 })
			}, 500);
		}
	}

	fetchData() {
		var that = this;

		axios.get(Env.url.api_url + 'hotels/' + this.props.params.hotel, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('token')}`,
				'Accept-Language': localStorage.getItem('language'),
				'Accept-Currency': localStorage.getItem('currency')
			}
		})
			.then(response => {
				if (response.data.meta.success) {
					that.setState({ hotel_data: response.data.data, hotel_loaded: true, facility_service_remark: response.data.data.facility_service_remark });

					if (response.data.data.coordinate) {
						let coordinate = response.data.data.coordinate.split(",");

						that.setState({
							location: {
								lat: parseFloat(coordinate[0]),
								lng: parseFloat(coordinate[1])
							}
						})
					}
				}
			})
			.catch(error => {
				Env.expired(error)
				if (error.response !== undefined) {
					if (error.response.status === 400 || error.response.status === 500) {
						browserHistory.push("/not-found")
					}
				}
			});
	}

	fetchDataReview() {
		var that = this
		axios.get(Env.url.api_url + 'hotels/' + this.props.params.hotel + '/reviews', {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('token')}`,
				'Accept-Language': localStorage.getItem('language'),
				'Accept-Currency': localStorage.getItem('currency')
			}
		})
			.then(function (response) {
				if (response.data.meta.success) {
					that.setState({ hotel_review_data: response.data.data, hotel_review_meta: response.data.meta });
				}

				that.setState({ review_loaded: true })
			})
			.catch(function (error) {

			});
	}

	fetchDataPhoto() {
		var that = this;

		axios.get(Env.url.api_url + 'hotels/' + this.props.params.hotel + '/photo', {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('token')}`,
				'Accept-Language': localStorage.getItem('language'),
				'Accept-Currency': localStorage.getItem('currency')
			}
		})
			.then(function (response) {
				if (response.data.meta.success) {
					that.setState({ hotel_photo_data: response.data.data }, () => that.loadJquery());
				}
			})
			.catch(function (error) {

			});
	}

	loadJquery() {
		for(var i=0;i<=5;i++){
			$('#imageGallery'+i).hide()
		}
		$('.lSSlideOuter').hide()
		if(this.state.state_photo<=5){
			$('#imageGallery'+this.state.state_photo).show()
			$('#imageGallery'+this.state.state_photo).lightSlider({
				gallery: true,
				item: 1,
				loop: false,
				auto: false,
				thumbItem: 10,
				slideMargin: false,
				enableDrag: false,
				currentPagerPosition: 'left',
			})
		}
	}

	activeKey(activeKey) {
		if (activeKey === "map") {
			try {
				var map = new google.maps.Map(document.getElementById('map'), {
					zoom: 15,
					center: this.state.location
				});

				var marker = new google.maps.Marker({
					position: this.state.location,
					map: map
				});

				setTimeout(() => {
					google.maps.event.trigger(map, 'resize');
					map.setCenter(marker.getPosition());
				}, 500);
			} catch (e) {
				//
			}
		}
	}

	renderReview() {
		if (!this.state.review_loaded) {
			return (
				<div id="review" style={{ padding: 20 }}>
					<p>Loading...</p>
				</div>
			)
		}

		if (this.state.review_loaded && !this.state.hotel_review_data.length) {
			return (
				<div id="review" style={{ padding: 20 }}>
					<h3>{Trans.data.no_result}</h3>
				</div>
			)
		}

		return (
			<div id="review">
				<div className="panel panel-default panel-review">
					<div className="panel-body">
						<div className="row" style={{ paddingTop: '30px' }}>
							<div className="col-sm-1" style={{ display: 'none' }}></div>
							<div className="col-sm-3" style={{ display: 'none' }}>
								<span className="badge">{this.state.hotel_review_meta.summary_review.score}</span>
								<span className="from">From {this.state.hotel_review_meta.total} people</span>
							</div>
							<div className="col-sm-3" style={{ paddingLeft: '30px' }}>
								<div className="service">Service</div>
								<dl className="dl-horizontal">
									<dt>Value For Money</dt>
									<dd>{this.state.hotel_review_meta.summary_review.value_for_money_good} <i className="fa fa-thumbs-up"></i></dd>
									<dt>Staff Performance</dt>
									<dd>{this.state.hotel_review_meta.summary_review.staff_performance_good} <i className="fa fa-thumbs-up"></i></dd>
									<dt>Room Comfort</dt>
									<dd>{this.state.hotel_review_meta.summary_review.room_comfort_good}  <i className="fa fa-thumbs-up"></i></dd>
								</dl>
							</div>
							<div className="col-sm-3">
								<div className="service">Hotel</div>
								<dl className="dl-horizontal">
									<dt>Select Location</dt>
									<dd>{this.state.hotel_review_meta.summary_review.select_location_good} <i className="fa fa-thumbs-up"></i></dd>
									<dt>Hotel Condition</dt>
									<dd>{this.state.hotel_review_meta.summary_review.hotel_condition_good} <i className="fa fa-thumbs-up"></i></dd>
									<dt>Food Dining</dt>
									<dd>{this.state.hotel_review_meta.summary_review.food_dining_good} <i className="fa fa-thumbs-up"></i></dd>
								</dl>
							</div>
						</div>
					</div>
				</div>
				<div className="amazing-reviews">
					{this.state.hotel_review_data.map((result, i) =>
						<blockquote key={i}>
							<header>
								<strong>{result.title}</strong>
								<span>, {moment(result.created).format("MMM D, YYYY")}</span>
								<span> By <em>{result.user}</em></span>
								<span className="label label-primary">{result.score}</span> <span>Good</span>
							</header>
							<p>{result.content}</p>
						</blockquote>
					)}
				</div>
			</div>
		)
	}

	renderGallery() {
		return (
			this.state.hotel_photo_data.map((result, i) =>
				<li key={i} data-thumb={result.image_url_small}>
					<div style={{ backgroundImage: 'url(' + result.image_url_large + ')' }} className="img-gallery"></div>
				</li>
			)
		);
	}

	handleSelect(key) {
		this.setState({ key: key });
	}

	windowScroll() {
		let element = document.getElementById('priceStarts');

		element.scrollIntoView({ behavior: "smooth", block: "start" });
	}

	render() {
		let { hotel_data } = this.state;
		let { segment_market_id } = this.state;
		let { pax_passport } = this.state;
		let { facility_service_remark } = this.state;
		let facility_service_remark_text = null;
		if(facility_service_remark && this.state.hotel_loaded){
			facility_service_remark.text.map((item, i) => {
				facility_service_remark_text = item.description;
			})
		}

		// Meta data
		let facility = {
			banguet_room: this.state.hotel_data.facility.is_banquet_room_available ? Trans.data.banguet_room + ', ' : '',
			bars: this.state.hotel_data.facility.is_bars_available ? Trans.data.bars + ', ' : '',
			business_center: this.state.hotel_data.facility.is_business_center_available ? Trans.data.business_center + ', ' : '',
			car_parking: this.state.hotel_data.facility.is_car_parking_available ? Trans.data.car_parking + ', ' : '',
			reception_24hour: this.state.hotel_data.service.is_reception_24hour_available ? Trans.data.reception_24hour + ', ' : '',
			airport_transfer: this.state.hotel_data.service.is_airport_transfer_available ? Trans.data.airport_transfer + ', ' : '',
			free_wifi: this.state.hotel_data.service.is_free_wifi_available ? Trans.data.free_wifi + ', ' : '',
			money_changer: this.state.hotel_data.service.is_money_changer_available ? Trans.data.money_changer + ', ' : ''
		}

		var lang;

		switch (localStorage.getItem('language')) {
			case "en-us":
				lang = 'en-us';
				break;
			case "ja":
				lang = 'ja';
				break;
			default:
				lang = 'id';
				break;
		}

		return (
			<Template>
				<Panel collapsible expanded={this.state.open}>
					<Row style={{ marginTop: '-30px' }}>
						<Col xs="12">
							<Row>
								<Col xs="12">
									<SearchHotelBoxInput
										typeSearch={2} />
								</Col>
							</Row>
						</Col>
					</Row>
				</Panel>
				{this.state.open ? <div>&nbsp;</div> : null}

				<div className="container">
					<div className="row">
						<div className="col-md-9 slug-hotel-detail">
							<Breadcrumb>
								<Breadcrumb.Item href="/">{Trans.data.home}</Breadcrumb.Item>
								<Breadcrumb.Item href="/">{Env.capitalizeFirst(this.props.params.country)}</Breadcrumb.Item>
								<Breadcrumb.Item href="/"> {Env.capitalizeFirst(this.props.params.city)}</Breadcrumb.Item>
								<Breadcrumb.Item active>{hotel_data.hotel_text.name}</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						{!this.state.open ?
							<div className="col-md-3" style={{ marginTop: '-30px' }}>
								<Button className="btn btn-changeSearch btnchange" onClick={() => this.setState({ open: !this.state.open })}>{Trans.data.search_hotel}</Button>
							</div>
							: null}
					</div>
				</div>

				<section className="hotel-info">
					<div className="container">
						<div className="row">
							<div className="col-sm-9">
								<div className="title-hotel">
									<h1>{hotel_data.hotel_text.name}</h1>
									<Star value={hotel_data.star} />
									<span className="address">{hotel_data.address}, {Env.capitalizeFirst(this.props.params.city) + ' - ' + Env.capitalizeFirst(this.props.params.country)}</span>
								</div>
							</div>
							<div className="col-sm-3">
								{this.state.cheap_campaign ?
									<div>
										{this.state.cheap_campaign.total_price ? <div className="priceStart">
											<span className="text">{Trans.data.price_start_from}</span>
											<span className="price">
												{Env.formatCurrency(this.state.cheap_campaign.total_price, this.props.selectedCurrency)}
												&mbsp;<small className="pernight"> {Trans.data.avg_pernight}</small>
											</span>
										</div> : null}
									</div>
									: null}
							</div>
						</div>

						<div className="no-gutter">
							<div className="col-md-9 col-sm-9">
								<Tab.Container onSelect={(activeKey) => this.activeKey(activeKey)} id="left-tabs-example" defaultActiveKey="photos" activeKey={this.state.key}>
									<div>
										<Nav id="tabsHotel" className="nav nav-tabs">
											<NavItem eventKey="photos" className="nav-item" onClick={() => this.handleSelect('photos')}>
												{Trans.data.foto}
											</NavItem>
											<NavItem eventKey="description" className="nav-item" onClick={() => this.handleSelect('description')}>
												{Trans.data.information}
											</NavItem>
											<NavItem eventKey="general" className="nav-item" onClick={() => this.handleSelect('general')}>
												{Trans.data.general_information}
											</NavItem>
											<NavItem eventKey="facilities" className="nav-item" onClick={() => this.handleSelect('facilities')}>
												{Trans.data.facility}
											</NavItem>
											<NavItem eventKey="services" className="nav-item" onClick={() => this.handleSelect('services')}>
												{Trans.data.services}
											</NavItem>
											<NavItem eventKey="map" className="nav-item" onClick={() => this.handleSelect('map')}>
												{Trans.data.map}
											</NavItem>
											<NavItem eventKey="comment" className="nav-item" onClick={() => this.handleSelect('comment')}>
												{Trans.data.reviews}
											</NavItem>
										</Nav>
										<Tab.Content animation>
											<Tab.Pane eventKey="photos">
												<ul id="imageGallery0">
													{this.renderGallery()}
												</ul>
												<ul id="imageGallery1">
													{this.renderGallery()}
												</ul>
												<ul id="imageGallery2">
													{this.renderGallery()}
												</ul>
												<ul id="imageGallery3">
													{this.renderGallery()}
												</ul>
												<ul id="imageGallery4">
													{this.renderGallery()}
												</ul>
												<ul id="imageGallery5">
													{this.renderGallery()}
												</ul>
											</Tab.Pane>
											<Tab.Pane eventKey="description">
												<div id="about">
													<p>{hotel_data.hotel_text.description}</p>
												</div>
											</Tab.Pane>
											<Tab.Pane eventKey="general">
												<div id="information">
													<dl className="row">
														<div className="col-md-12">
															<div className="row">
																<dt className="col-sm-3">{Trans.data.star}</dt>
																<dd className="col-sm-9">
																	<Star value={hotel_data.star} />
																</dd>
															</div>
															<div className="row">
																<dt className="col-sm-3">{Trans.data.address}</dt>
																<dd className="col-sm-9">{hotel_data.address}</dd>
															</div>
															<div className="row">
																<dt className="col-sm-3">{Trans.data.telephone}</dt>
																<dd className="col-sm-9">{hotel_data.telephone}</dd>
															</div>
															{hotel_data.fax === '' ? null :
																<div className="row">
																	<dt className="col-sm-3">{Trans.data.fax}</dt>
																	<dd className="col-sm-9">{hotel_data.fax}</dd>
																</div>
															}
															<div className="row">
																<dt className="col-sm-3">{Trans.data.opening_year}</dt>
																<dd className="col-sm-9">{hotel_data.opening_year}</dd>
															</div>
															{hotel_data.renovation_year ?
																<div className="row">
																	<dt className="col-sm-3">{Trans.data.renovation_year}</dt>
																	<dd className="col-sm-9">{hotel_data.renovation_year}</dd>
																</div>
																: null}
															<div className="row">
																<dt className="col-sm-3">{Trans.data.number_of_floors}</dt>
																<dd className="col-sm-9">{hotel_data.number_of_floors} {Trans.data.floors}</dd>
															</div>
															<div className="row">
																<dt className="col-sm-3">{Trans.data.number_of_rooms}</dt>
																<dd className="col-sm-9">{hotel_data.number_of_rooms} {Trans.data.rooms}</dd>
															</div>
															<div className="row">
																<dt className="col-sm-3">{Trans.data.distance_from_airport}</dt>
																<dd className="col-sm-9">{hotel_data.distance_from_airport} Km</dd>
															</div>
															<div className="row">
																<dt className="col-sm-3">{Trans.data.time_from_airport}</dt>
																<dd className="col-sm-9">{hotel_data.time_from_airport} {Trans.data.minutes}</dd>
															</div>
															<div className="row">
																<dt className="col-sm-3">{Trans.data.distance_from_city_center}</dt>
																<dd className="col-sm-9">{hotel_data.distance_from_city_center} Km</dd>
															</div>
															<div className="row">
																<dt className="col-sm-3">{Trans.data.room_voltage}</dt>
																<dd className="col-sm-9">{hotel_data.room_voltage} V</dd>
															</div>
															<div className="row">
																<dt className="col-sm-3">{Trans.data.pet_allowed}</dt>
																<dd className="col-sm-9">{hotel_data.is_pet_allowed ? `${Trans.data.yes}` : `${Trans.data.no}`}</dd>
															</div>
															<div className="row">
																<dt className="col-sm-3">{Trans.data.earliest_check_in}</dt>
																<dd className="col-sm-9">{hotel_data.check_in_time}</dd>
															</div>
															<div className="row">
																<dt className="col-sm-3">{Trans.data.check_out}</dt>
																<dd className="col-sm-9">{hotel_data.check_out_time}</dd>
															</div>
															<div className="row">
																<dt className="col-sm-3">{Trans.data.children_guest}</dt>
																{localStorage.getItem('language') === 'ja' ?
																	<dd className="col-sm-9">{hotel_data.child_age_from}歳から{hotel_data.child_age_until}歳まで</dd>
																	: <dd className="col-sm-9">{Trans.data.guest_are_considered} {hotel_data.child_age_from} - {hotel_data.child_age_until} {Trans.data.years_old}</dd>
																}
															</div>
															{facility_service_remark_text ?
																<div className="row">
																	<dt className="col-sm-3">{Trans.data.remarks}</dt>
																	<dd className="col-sm-9">{facility_service_remark_text}</dd>
																</div>
																:null}
														</div>
													</dl>
												</div>
											</Tab.Pane>
											<Tab.Pane eventKey="facilities">
												<div id="facilities">
													<div className="row">
														<div className="col-sm-12">
															{hotel_data.facility.is_banquet_room_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.banguet_room}`}
																/>
																: null}
															{hotel_data.facility.is_car_parking_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.car_parking}`}
																/>
																: null}
															{hotel_data.facility.is_club_lounge_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.club_lounge}`}
																/>
																: null}
															{hotel_data.facility.is_disabled_facilities_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.disabled_facilities}`}
																/>
																: null}
															{hotel_data.facility.is_gym_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.gym}`}
																/>
																: null}
															{hotel_data.facility.is_hair_salon_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.hair_salon}`}
																/>
																: null}
															{hotel_data.facility.is_kids_club_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.kids_club}`}
																/>
																: null}
															{hotel_data.facility.is_luggage_room_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.luggage_room}`}
																/>
																: null}
															{hotel_data.facility.is_private_beach_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.private_beach}`}
																/>
																: null}
															{hotel_data.facility.is_safety_box_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.safety_box}`}
																/>
																: null}
															{hotel_data.facility.is_squash_court_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.squash_court}`}
																/>
																: null}
															{hotel_data.facility.is_bars_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.bars}`}
																/>
																: null}
															{hotel_data.facility.is_casino_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.casino}`}
																/>
																: null}
															{hotel_data.facility.is_coffee_shop_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.coffe_shop}`}
																/>
																: null}
															{hotel_data.facility.is_elevator_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.elevator}`}
																/>
																: null}
															{hotel_data.facility.is_gift_shop_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.gift_shop}`}
																/>
																: null}
															{hotel_data.facility.is_jacuzzi_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.jacuzzi}`}
																/>
																: null}
															{hotel_data.facility.is_kids_pool_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.kids_pool}`}
																/>
																: null}
															{hotel_data.facility.is_meeting_room_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.meeting_room}`}
																/>
																: null}
															{hotel_data.facility.is_poolside_bar_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.poolside_bar}`}
																/>
																: null}
															{hotel_data.facility.is_sauna_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.sauna}`}
																/>
																: null}
															{hotel_data.facility.is_steam_room_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.steam_room}`}
																/>
																: null}
															{hotel_data.facility.is_business_center_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.business_center}`}
																/>
																: null}
															{hotel_data.facility.is_clinic_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.clinic}`}
																/>
																: null}
															{hotel_data.facility.is_departure_lounge_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.departure_lounge}`}
																/>
																: null}
															{hotel_data.facility.is_garden_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.garden}`}
																/>
																: null}
															{hotel_data.facility.is_golf_course_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.golf_course}`}
																/>
																: null}
															{hotel_data.facility.is_karaoke_room_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.caraoke_room}`}
																/>
																: null}
															{hotel_data.facility.is_library_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.library}`}
																/>
																: null}
															{hotel_data.facility.is_night_club_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.night_club}`}
																/>
																: null}
															{hotel_data.facility.is_restaurant_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.restaurant}`}
																/>
																: null}
															{hotel_data.facility.is_spa_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.spa}`}
																/>
																: null}
															{hotel_data.facility.is_swimming_pool_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.swimming_pool}`}
																/>
																: null}
															{hotel_data.facility.is_smoking_area_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.smooking_area}`}
																/>
																: null}
														</div>
													</div>
													{hotel_data.plug_type ?
														<div className="row">
															{localStorage.getItem('language') === 'ja' ?
															<div className="col-sm-12">
															<hr/>
															<strong>{Trans.data.plug_type}</strong>
															<br/><br/>
																{hotel_data.plug_type.is_plug_type_a_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` A ${Trans.data.type} <img role="presentation" src="/images/plug_types/plug_type_a.png" style="height:25px;margin-left:25px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_plug_type_b_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` B ${Trans.data.type} <img role="presentation" src="/images/plug_types/plug_type_b.png" style="height:25px;margin-left:25px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_plug_type_c_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` C ${Trans.data.type} <img role="presentation" src="/images/plug_types/plug_type_c.png" style="height:25px;margin-left:25px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_plug_type_se_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` SE ${Trans.data.type} <img role="presentation" src="/images/plug_types/plug_type_se.png" style="height:25px;margin-left:18px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_plug_type_b3_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` B3 ${Trans.data.type} <img role="presentation" src="/images/plug_types/plug_type_b3.png" style="height:25px;margin-left:17px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_plug_type_bf_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` BF ${Trans.data.type} <img role="presentation" src="/images/plug_types/plug_type_bf.png" style="height:25px;margin-left:20px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_plug_type_o_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` O ${Trans.data.type} <img role="presentation" src="/images/plug_types/plug_type_o.png" style="height:25px;margin-left:22px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_plug_type_o2_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` O2 ${Trans.data.type} <img role="presentation" src="/images/plug_types/plug_type_o2.png" style="height:25px;margin-left:16px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_plug_type_cb_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` CB ${Trans.data.type} <img role="presentation" src="/images/plug_types/plug_type_cb.png" style="height:25px;margin-left:18px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_multi_plug_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` ${Trans.data.multi_plug}`}
																	/>
																	: null}
															</div>
															:
															<div className="col-sm-12">
															<hr/>
															<strong>{Trans.data.plug_type}</strong>
															<br/><br/>
																{hotel_data.plug_type.is_plug_type_a_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` ${Trans.data.type} A <img role="presentation" src="/images/plug_types/plug_type_a.png" style="height:25px;margin-left:25px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_plug_type_b_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` ${Trans.data.type} B <img role="presentation" src="/images/plug_types/plug_type_b.png" style="height:25px;margin-left:25px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_plug_type_c_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` ${Trans.data.type} C <img role="presentation" src="/images/plug_types/plug_type_c.png" style="height:25px;margin-left:25px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_plug_type_se_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` ${Trans.data.type} SE <img role="presentation" src="/images/plug_types/plug_type_se.png" style="height:25px;margin-left:18px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_plug_type_b3_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` ${Trans.data.type} B3 <img role="presentation" src="/images/plug_types/plug_type_b3.png" style="height:25px;margin-left:17px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_plug_type_bf_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` ${Trans.data.type} BF <img role="presentation" src="/images/plug_types/plug_type_bf.png" style="height:25px;margin-left:20px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_plug_type_o_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` ${Trans.data.type} O <img role="presentation" src="/images/plug_types/plug_type_o.png" style="height:25px;margin-left:22px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_plug_type_o2_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` ${Trans.data.type} O2 <img role="presentation" src="/images/plug_types/plug_type_o2.png" style="height:25px;margin-left:16px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_plug_type_cb_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` ${Trans.data.type} CB <img role="presentation" src="/images/plug_types/plug_type_cb.png" style="height:25px;margin-left:18px;" />`}
																	/>
																	: null}
																{hotel_data.plug_type.is_multi_plug_available ?
																	<Radio
																		radioClass="iradio_square-green"
																		increaseArea="20%"
																		checked={true}
																		label={` ${Trans.data.multi_plug}`}
																	/>
																	: null}
															</div>
															}
														</div>
														:null}
												</div>
											</Tab.Pane>
											<Tab.Pane eventKey="services">
												<div id="services">
													<div className="row">
														<div className="col-sm-12">
															{hotel_data.service.is_room_service_24hour_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.room_service_24hour}`}
																/>
																: null}
															{hotel_data.service.is_baby_sitter_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.baby_sitter}`}
																/>
																: null}
															{hotel_data.service.is_laundry_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.laundry}`}
																/>
																: null}
															{hotel_data.service.is_hotel_activities_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.hotel_activities}`}
																/>
																: null}
															{hotel_data.service.is_rental_multi_plug_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.rental_multi_plug}`}
																/>
																: null}
															{hotel_data.service.is_shuttle_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.shuttle}`}
																/>
																: null}
															{hotel_data.service.is_paid_wifi_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.paid_wifi}`}
																/>
																: null}
															{hotel_data.service.is_reception_24hour_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.reception_24hour}`}
																/>
																: null}
															{hotel_data.service.is_butler_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.butler}`}
																/>
																: null}
															{hotel_data.service.is_massage_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.massage}`}
																/>
																: null}
															{hotel_data.service.is_rental_baby_car_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.rental_baby_content}`}
																/>
																: null}
															{hotel_data.service.is_rental_wheel_chair_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.rental_wheel_chair}`}
																/>
																: null}
															{hotel_data.service.is_valet_parking_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.valet_parking}`}
																/>
																: null}
															{hotel_data.service.is_airport_transfer_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.airport_transfer}`}
																/>
																: null}
															{hotel_data.service.is_concierge_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.concierge}`}
																/>
																: null}
															{hotel_data.service.is_money_changer_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.money_changer}`}
																/>
																: null}
															{hotel_data.service.is_rental_bicycle_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.rental_biycle}`}
																/>
																: null}
															{hotel_data.service.is_room_service_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.room_service}`}
																/>
																: null}
															{hotel_data.service.is_free_wifi_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.free_wifi}`}
																/>
																: null}
															{hotel_data.service.is_japanese_staff_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.japanese_staff}`}
																/>
																: null}
															{hotel_data.service.is_english_staff_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.english_staff}`}
																/>
																: null}
															{hotel_data.service.is_rental_baby_bed_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.rental_baby_bed}`}
																/>
																: null}
															{hotel_data.service.is_rental_bed_guard_available ?
																<Radio
																	radioClass="iradio_square-green"
																	increaseArea="20%"
																	checked={true}
																	label={` ${Trans.data.rental_bed_guard}`}
																/>
																: null}
														</div>
													</div>
												</div>
											</Tab.Pane>
											<Tab.Pane eventKey="map">
												<div ref="map" id="map" style={{ height: 467 }}></div>
											</Tab.Pane>
											<Tab.Pane eventKey="comment">
												{this.renderReview()}
											</Tab.Pane>
										</Tab.Content>
									</div>
								</Tab.Container>
							</div>
							<div className="col-md-3 col-sm-3">
								<div className="sidebar" style={{height:'205px'}}>
									<button className="btn btn-scroll btn-block" onClick={() => this.windowScroll()}>{Trans.data.book_now}</button>
									<br />
									<Iframe url={`https://www.tripadvisor.co.id/WidgetEmbed-selfserveprop?border=true&popIdx=true&iswide=false&locationId=${hotel_data.trip_advisior_hotel_id}&amp;display_version=2&amp;uniq=385&amp;rating=true&amp;lang=${lang}&amp;nreviews=5&amp;writereviewlink=false&display=true`}
										display="initial"
										position="relative"
										frameBorder="0"
										scrolling="auto"
									/>
								</div>
							</div>
						</div>
					</div>
				</section>

				<div className="container">
					<section className="hotel">
						{hotel_data.announcements.length ?
							<div className="row">
								<div className="col-sm-12 col-md-12">
									{hotel_data.announcements.map((result, i) =>
										<div key={i}>{result.data_announcement === null ? null :
											<div className="bs-callout bs-callout-danger" key={result.data_announcement.id}>
												<h4>{Trans.data.hotel_announcement}</h4>
												<p className="lead">{result.data_announcement.title}</p>
												<p>{result.data_announcement.text_selected.description}</p>
												<span><i className="fa fa-calendar-o"></i> {' ' + moment(result.announcement_start_date).format('ll')} - <i className="fa fa-calendar-o"></i> {moment(result.announcement_end_date).format('ll')}</span>
											</div>
										}</div>
									)}
								</div>
							</div>
							: null}

						<div className="row">
							<div className="col-sm-12 col-md-12">
								<CheckInBox />
								{!this.state.hotel_loaded ? (
									this.state.content_loading.map((result, i) =>
										<div key={i} style={{ paddingTop: '10px', paddingBottom: "10px" }}>
											<div className="row">
												<div className="col-sm-3 " >
													<div className="animated-background facebook" style={{ height: 140 }}>
													</div>
												</div>
												<div className="col-sm-5">
													<div className="content-list-hotel">
														<div className="animated-background facebook line3" />
														<div className="animated-background facebook line1" />
														<div className="animated-background facebook line2" />
														<div className="animated-background facebook line1" />
													</div>
												</div>
												<div className="col-sm-4">
													<div className="price-tag">
														<div className="animated-background facebook line1" />
														<div className="animated-background facebook line2" />
														<div className="animated-background facebook line2" style={{ height: 30 }} />
													</div>
												</div>
											</div>
										</div>
									)
								) : (
								<AvailableRoom
									params={this.props.params}
									hotel_data={hotel_data}
									segment_market_id={segment_market_id}
									pax_passport={pax_passport}
								/>
								)}
							</div>
						</div>
					</section>
				</div>

				<LastBookedHotel />
			</Template>
		)
	}
}

const mapStateToProps = state => {
	return {
		cheap_campaign: state.cheap_campaign,
		selectedCurrency: state.currencyReducer.selectedCurrency
	}
}

export default connect(mapStateToProps)(HotelDetail);
