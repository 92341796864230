import * as auth from '../utils/auth';

const initialState = {
	isLoggedIn: auth.getToken() ? true : false,
	token: auth.getToken() !== null ? `Bearer ${auth.getToken()}` : null,
	user_token: auth.getToken() !== null ? auth.getToken() : null,
	user: !auth.getUser() ? {} : auth.getUser(),
	notification: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'LOGIN_SUCCESS':
			return {
				...state,
				isLoggedIn: true,
				token: action.token,
				user: action.user,
			};
		case 'LOGIN_FAILURE':
			return {
				...state,
				isLoggedIn: false,
				notification: 'Invalid credentials.'
			};
		case 'LOGOUT_SUCCESS':
			return {
				...state,
				isLoggedIn: false,
				token: null,
				user: {},
			};
		case 'FORGOT_PASSWORD_REQUEST_SUCCESS':
			return {
				isSuccess: true,
				notification: action.notification.messages
			}
		case 'FORGOT_PASSWORD_REQUEST_FAILURE':
			return {
				isSuccess: false,
				notification: 'The selected email is invalid.'
			}
		case 'RESET_SUCCESS_GET_TOKEN':
			return {
				isSuccess: true,
				notification: action.notification.messages
			}
		case 'RESET_FAILURE_GET_TOKEN':
			return {
				isSuccess: false,
				notification: 'The token invalid.'
			}
		case 'REGISTER_SUCCESS':
			return {
				isSuccess: true,
				notification: action.notification.messages
			}
		case 'REGISTER_ERROR':
			return {
				isSuccess: false,
				notification: 'System Busy, Please Try Again Later.'
			}
		case 'REGISTER_ACTIVATION_SUCCESS':
			return {
				isSuccess: true,
				notification: action.notification.messages
			}
		case 'REGISTER_ACTIVATION_ERROR':
			return {
				isSuccess: false,
				notification: 'Token Invalid, Please Register Again.'
			}
		case 'UPDATE_PROFILE_SUCCESS':
			return {
				isSuccess: true,
				notification: 'Update Profile Successfully.',
				isLoggedIn: true,
				user: action.user,

			}
		case 'UPDATE_PROFILE_ERROR':
			return {
				isSuccess: false,
				notification: 'System Busy, Please Try Again Later.'
			}
		default:
			return state
	}
}