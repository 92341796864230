import React, { Component } from 'react';
import { connect } from 'react-redux';

import bca from "../../assets/images/bca.png";
import bri from "../../assets/images/bri.png";
import danamon from "../../assets/images/danamon.png";
import mandiri from "../../assets/images/mandiri.png";
import indomaret from "../../assets/images/indomaret.png";
import bni from "../../assets/images/bni.png";
import mastercard from "../../assets/images/mastercard.png";
import visa from "../../assets/images/visa.png";
import jcb from "../../assets/images/jcb.png";
import moment from 'moment';
import { Link } from 'react-router'
import { updateQuery } from '../../actions/search.js';
import Trans from '../../locale/Translate.js'

class Footer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: {
				alphabetical: "",
				capacities: JSON.stringify([
				  { number_of_adults: 1, number_of_children: 0 },
				]),
				check_in: moment().toDate(),
				check_out: moment()
				  .add(1, "days")
				  .toDate(),
				city_slug: "",
				city_name: "",
				country_slug: "",
				childrenAges: [],
				district: JSON.stringify([]),
				hotel_name: "",
				limit: 5,
				min_price: 0,
				max_price: 99999999,
				number_of_nights: 1,
				number_of_rooms: 1,
				page: 1,
				review: JSON.stringify([1, 2, 3, 4]),
				segment_market_id: 0,
				pax_passport: 0,
				sortLimit: {
				  type: "NAME",
				  offset: 5,
				  limit: 5
				}
			  },
			pax_passport: ''
		};
	}

	handleLinkSearch(value, event) {
		event.stopPropagation();

		let data = this.state.data
		if (value === "Bali") {
			data.city_id = 2
			data.district = '[29,30,31,32,33,34,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,58,59,60,126,127,128,129,130,222,256,384,413,424,428,439,440,441,509]'
		}
		if (value === "Bandung") {
			data.city_id = 3
			data.district = '[61,62,63,64,65,66,67,68,89,140,204,206,217,339,380,407,409,410,434,457,458]'
		}
		if (value === "Jakarta") {
			data.city_id = 1
			data.district = '[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,117,156,187,219,239,274,318,336,340,342,345,357,370,373,397,398,400,404,405,411,412,427,429,430,431,432,433,437,454,455,456,500]'
		}
		if (value === "Lombok") {
			data.city_id = 8
			data.district = '[94,95,96,97,98,99,100,101,102,103,104,123,233,242,341,367,438]'
		}
		if (value === "Malang") {
			data.city_id = 15
			data.district = '[133,137,402]'
		}
		if (value === "Medan") {
			data.city_id = 7
			data.district = '[91,92,93,199,200,202,203,248,253,372]'
		}
		if (value === "Surabaya") {
			data.city_id = 5
			data.district = '[79,80,81,82,83,84,118,119,143,154,263,265,266,267,268,270,365,383,459]'
		}
		if (value === "Yogyakarta") {
			data.city_id = 4
			data.district = '[69,70,71,72,73,74,75,76,77,78,218,292,293,294,295,296,297,299,300,302,303,304,305,306,307,326,387,390,415,425,461,462,463,464]'
		}

		data.city_name = value
		data.city_slug = value.toLowerCase()
		data.destination = value

		this.setState({ data: data })

		this.props.dispatch(updateQuery(this.state.data));

		return true
	}

	render() {
		return (
			<footer className="footerhoterip">
				<div className="container">
					<div className="row">
						<div className="col-md-3 col-sm-3">
							<h4>{Trans.data.contact_us}</h4>
							{/* <ul className="list-inline social-media"> */}
								{/* <li><a href="https://www.facebook.com/hoterip.indonesia" target="_blank"><i className="fa fa-facebook" ></i></a></li> */}
								{/* <li><a href="https://twitter.com/hoterip_id" target="_blank"><i className="fa fa-twitter" ></i></a></li> */}
								{/* <li><a href="https://www.linkedin.com/company/hoterip-com" target="_blank"><i className="fa fa-linkedin"></i></a></li> */}
								{/* <li><a href="https://plus.google.com/114437233209899460913" target="_blank"><i className="fa fa-google-plus" ></i></a></li> */}
								{/* <li><a href="https://www.instagram.com/hoterip/" target="_blank"><i className="fa fa-instagram"></i></a></li> */}
							{/* </ul> */}
							<ul className="list-unstyled">
								<li><i className="fa fa-phone"></i> +62 361 728177</li>
								<li><i className="fa fa-whatsapp"></i> +62 81 1380 1919</li>
								<li><i className="fa fa-envelope-o"></i> cs-idn@his-hotels.com</li>
							</ul>
						</div>
						{this.props.isLoggedIn ?
							<div className="col-md-3 col-sm-3">
								<h4>{Trans.data.hotel_popular}</h4>
								<ul className="list-unstyled">
									<li><Link onClick={this.handleLinkSearch.bind(this, "Bali")} to={{ pathname: '/hotels/search', query: this.state.data }}>Bali</Link></li>
									<li><Link onClick={this.handleLinkSearch.bind(this, "Bandung")} to={{ pathname: '/hotels/search', query: this.state.data }}>Bandung</Link></li>
									<li><Link onClick={this.handleLinkSearch.bind(this, "Jakarta")} to={{ pathname: '/hotels/search', query: this.state.data }}>Jakarta</Link></li>
									<li><Link onClick={this.handleLinkSearch.bind(this, "Lombok")} to={{ pathname: '/hotels/search', query: this.state.data }}>Lombok</Link></li>
									<li><Link onClick={this.handleLinkSearch.bind(this, "Malang")} to={{ pathname: '/hotels/search', query: this.state.data }}>Malang</Link></li>
									<li><Link onClick={this.handleLinkSearch.bind(this, "Manado")} to={{ pathname: '/hotels/search', query: this.state.data }}>Manado</Link></li>
									<li><Link onClick={this.handleLinkSearch.bind(this, "Medan")} to={{ pathname: '/hotels/search', query: this.state.data }}>Medan</Link></li>
									<li><Link onClick={this.handleLinkSearch.bind(this, "Surabaya")} to={{ pathname: '/hotels/search', query: this.state.data }}>Surabaya</Link></li>
									<li><Link onClick={this.handleLinkSearch.bind(this, "Yogyakarta")} to={{ pathname: '/hotels/search', query: this.state.data }}>Yogyakarta</Link></li>
								</ul>
							</div>
							: null}
						<div className="col-md-3 col-sm-3">
							<h4>{Trans.data.help}</h4>
							<ul className="list-unstyled">
								<li><Link to="/about-hoterip">{Trans.data.about_hoterip}</Link></li>
								{/* <li><Link to="/additional-benefit">{Trans.data.additional_benefit}</Link></li> */}
								{/* <li><Link to="/good-services">{Trans.data.best_service}</Link></li> */}
								<li><Link to="/privacy-policy">{Trans.data.privacy_policy}</Link></li>
								<li><Link to="/term">{Trans.data.term_condition}</Link></li>
							</ul>
						</div>
						{!this.props.isLoggedIn ?
							<div className="col-md-3 col-sm-3"></div>
							: null}
						<div className="col-md-3 col-sm-3">
							<h4>{Trans.data.payment_method}</h4>
							<ul className="list-unstyled list-inline icon-payment">
								<li><img role="presentation" src={bca} /></li>
								<li><img role="presentation" src={bri} /></li>
								<li><img role="presentation" src={danamon} /></li>
								<li><img role="presentation" src={mandiri} /></li>
								<li><img role="presentation" src={bni} /></li>
								<li><img role="presentation" src={mastercard} /></li>
								<li><img role="presentation" src={visa} /></li>
								<li><img role="presentation" src={jcb} /></li>
								<li><img role="presentation" src={indomaret} /></li>
							</ul>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 col-sm-12"><p>Copyright HIS Hotels 2024. All rights reserved</p></div>
					</div>
				</div>
			</footer>
		)
	}
}

const mapStateToProps = state => {
	const { isLoggedIn } = state.authReducer
	return {
		isLoggedIn
	}
}

export default connect(mapStateToProps)(Footer)
