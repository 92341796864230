import React, { Component } from 'react';

import { Layout } from '../../components'
import Trans from '../../locale/Translate.js'
import TopNav from '../../modules/TopNav'

export default class Term extends Component {
    render() {

        let language = localStorage.getItem('language');

        return (
            <Layout>
                <div className="jumbotron jumbotron-help" style={{ marginTop: '-30px' }}>
                    <div className="container text-center">
                        <h1>{Trans.data.term_condition}</h1>
                    </div>
                </div>

                <TopNav
                    url={this.props.location.pathname}
                />

                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-sm-10 col-md-offset-1">
                            <div className="row">
                                <div className="col-sm-12">
                                    {language === 'en-us' ?
                                        (
                                            <div className="blog-content">
                                                <h3>1. Company</h3>
                                                <p>HIS Hotels (Hoterip) is managed and fully supported by PT. Harum Indah Sari Tours & Travel, which addressed at Jl. Bypass Ngurah Rai No. 732, Pesanggaran, Denpasar, Bali.</p>

                                                <h3>2. Services</h3>
                                                <p>HIS Hotels is an online booking platform for Accommodation such as Hotel, Villa, Apartment and also Airlines ticket. Customer can make a booking which the service is fully managed and control by Hotel or Airlines companies and or other services which have collaborates with HIS Hotels. Any booking which made through website HIS Hotels will get instant confirmation through a registered e-mail. We also have a right to reject any transaction which indicated as suspicious transaction, as stated below.</p>
                                                <p>Even we will use our expertise in providing the service carefully however we will not verify and not guarantee that all the information which provided and available are accurate, complete, correct or updated and we are not responsible of any mistake (including any mistake of wrong placement or typing), disturbance (temporary and/or partial, any damage, correction and increment to the website or conversely), incorrect information, mislead or fake or not accomplish information.</p>
                                                <p>In case any trouble is occurred, the Customer Support will endeavor to assist and answer all the questions from the customer through telephone, e-mail or live chat during office hours from 09:00am -05.00pm (GMT+8).</p>
                                                <p>We have a right to refuse any customer or booking (or in certain special cases to cancel the booking confirmation) unilateral based on our policy and for any reason and without give any reason to refuse/rejecting/cancelling. The reason to refusing the customer or booking or cancelling the booking confirmation, including but not limited to : Violation of this terms & condition, trading or economic sanction by national or global authority, embargo, restricted of the rule, deception or robbing (or indication or assessment of deception or robbing), assessment of criminal activities, suspicious booking, unavailable service or not provided by the Supplier anymore, the user provide inappropriate, wrong, mislead information, have problem with e-credit card, information or transaction, improper behavior, threatening, indignity, refuse to provide any information, practice resistance, difficulty or jamming of communication, real mistake (furthermore as explain below), history of terms & condition violation or its refusing, or included in every “black list” or “supervision list” by the Government or International Organization. In case the booking is rejected and cancelled by HIS Hotels and payment has been done, HIS Hotels will refund the money after deducting some fees which appear such as the bank transfer fee. We can delete the membership of the user of this website anytime, temporarily or permanently. For those membership who has been deleted is unable to use this website anymore either using other name or through another user name.</p>

                                                <h3>3. Payment, Cancellation, Additional Charge & Refund</h3>
                                                <p className="lead">PAYMENT</p>
                                                <p>All payment transaction on HIS Hotels is using payment system which will guarantee the customer data information is securely and confidentially, especially for the payment transaction using a credit card. HIS Hotels is using Secure Socket Layer (SSL), a system technology which created to encrypted all information which used by the customer to payment transaction using a credit card or other online transaction.</p>
                                                <p>If the payment transaction which made is indicated using improper credit card or fake, HIS Hotels has fully right to cancel the booking which has been done directly without given any reason to the customer. </p>
                                                <p className="lead">CANCELLATION</p>
                                                <p>By made a booking or reservation through HIS Hotels, the customer has accept and agree with the terms and condition which valid from each Hotel partner, including which related to the cancellation policy and/or no-show, or in terms of any special request from the customer to the Hotel. HIS Hotels is not responsible for every violation of the terms and condition which agreed between Hotel partner and the customer, or which is based on customer special request, therefore the customer shall read the terms & condition which may apply carefully.</p>
                                                <p className="lead">ADDITIONAL CHARGE & REFUND</p>
                                                <p>Every rate which featuring on HIS Hotels available with certain terms & condition and it can be change anytime depend on the room availability, duration of booking and/or period of stay. The rate is including tax and service charge and other charges if any. The customer is agree that they are responsible to double check the total charge which has to be paid and other terms and condition as stated on the booking form before settle the payment. The rate is featuring in details so the customer can see total charge which should be paid, other fee which may arise caused by using the credit card or bank transfer fee will be imposed to customer.</p>
                                                <p>In case the customer cancel the booking without any charge, HIS Hotels will refund the money based on the total amount which has been paid after deducting the admin bank fee or bank transfer fee which may applied to HIS Hotels. Refund process is maximum within 14 working days after the refund request from the customer is accept by HIS Hotels</p>
                                                <p>HIS Hotels is not responsible or obliged for any issuing e-voucher, e-ticket, or other transaction which release caused the total amount which transferred is not accurate. Payment settlement which over the time limit will be caused the booking released automatically. Time limit to settle the payment using Bank transfer or ATM is 120 minutes or 2 hours after the booking form is submitted. While the payment using credit card will be receive booking confirmation instantly. </p>
                                                <p>The refund will be process with certain condition as follow : </p>
                                                <ul>
                                                    <li>If the cancellation is made before the time limit and there is no any cancellation charge is applied.</li>
                                                    <li>If the payment transaction is made through Bank transfer or ATM, therefore the money will be refund to the Bank account which has been used by the customer.</li>
                                                    <li>If the payment transaction is made using credit card, therefore the money will be refund to the credit card number which suitable and used previously.</li>
                                                    <li>If the payment transaction is made using Hoterip reward point, therefore the point will be use covering the cancellation fee which may applied.</li>
                                                    <li>Bank administration fee will be imposed to the customer based on the valid fee from each Bank.</li>
                                                </ul>

                                                <h3>4. Other Information</h3>
                                                <p>The information of this terms and condition can be added or amend at anytime without any notification in advance. And also the data of Hoterip can be amend without any notification, therefore HIS Hotels is not responsible for any misinformation or any disadvantage of the customer which caused by the amandement.</p>
                                                <p className="lead"></p>
                                            </div>
                                        )

                                        : language === 'ja' ?
                                            (
                                                <div className="blog-content">
                                                    <h3>1. HOTERIP条件規定書の意義</h3>
                                                    <ol>
                                                        <li>HOTERIPとは、所在地をJl. Bypass Ngurah Rai 732 Pesanggaran Denpasar - Baliに置くPT. Harum Indah Sari Tours & Travel（当社）が運営するホテル予約サイトhttp://www.HIS Hotels （以下、当サイトといいます。） をいいます。当サイトにおける条件規定書はすべて、HISに著作権があります。</li>
                                                        <li>お客様（以下、会員といいます）が当サイトを利用して、当社に旅行手配のお申込みをされる場合、当社は当サイトに適用される条件規定に従い、その申込みを引き受ける用意があります。</li>
                                                    </ol>

                                                    <h3>2. サイト利用規約</h3>
                                                    <ol>
                                                        <li>当サイトのご利用には、会員は、本人登録を行い、当サイトに適用される条件規定に従い、当サイト上で登録される会員として同意していただきます。</li>
                                                        <li>当サイトは、法域の異なる全世界の国々・地域からアクセスすることができますが、会員と当社は、当サイトの利用に関してはインドネシアの法律に従うものとします。</li>
                                                        <li>当サイトのご利用は、会員の自由意思によって行われるものとし、HOTERIPによって会員名義で購入された運送・宿泊機関等の商品の不成立、もしくはこれへの不服（第12項の規定を含みますがこれに限りません）については、当社はその責任を一切負うものではありません。</li>
                                                        <li>会員に有用な情報・サービスをご提供するために、当サイトから他のウェブサイトを紹介している場合（第三者へのウェブサイトリンク）がありますが、そのウェブサイトの運営や管理に当社は関与しておりません。したがって、当該ウェブサイトの利用上におけるコンテンツ、個人情報の取扱い、ウィルス、その他の運用にも当社は責任を負うことはできません。</li>
                                                        <li>会員自身が個人情報を当サイト上で公開したり、第三者が閲覧可能なサービスに登録をしたりした場合には、第三者が、お客様ご自身が意図しない目的に、これらの情報を利用する可能性がありますのでご注意下さい。</li>
                                                        <li>当サイトが会員に対して必要な情報を送付する必要がある場合、会員は当社が当サイト上での掲示という電子的な手段によりご通知をする場合があること、また当社が当該通知を当サイトに掲示した時点でより効力を発することに同意するものとします。</li>
                                                        <li>当社は、事前に会員の了承を得ることなく条件規定及び規約を変更することがあり、この変更は当サイトに変更内容が掲示された時点より効力を発するものとします。</li>
                                                        <li>会員は、当サイトに含まれるすべてのコンテンツ（ソフトウェア、データ、情報、写真、ビデオ、アイコン、地図、その他の素材を含む）の無断転載が禁止されている事、またこれらコンテンツが当社の知的財産であり、著作権を有する作品であることに同意するものとします。</li>
                                                        <li>会員は、当サイトに提供されたすべてのコメント、フィードバック及び投稿写真が、当社の個人情報保護方針の範囲で、あらゆる媒体で利用される事に同意する用意があるものとします。</li>
                                                        <li>当サイトは、会員の支払い金額に応じて所定の割合でポイントを付与します。ポイントの利用方法は別途サイト内にて公開し、その利用方法に記載のない換金、交換、他会員への譲渡はできません。</li>
                                                    </ol>

                                                    <h3>3. 手配旅行契約</h3>
                                                    <ol>
                                                        <li>この旅行は、当社が手配する旅行であり、当社に旅行の手配をお申込みになる会員は当社と手配旅行契約（以下「旅行契約」といいます。）を締結することになります。</li>
                                                        <li>旅行契約とは、当社HISが会員の委託により、会員のために代理、媒介又は取次をすることなどにより、会員が運送・宿泊機関等の提供する運送、宿泊その他の旅行に関するサービス（以下「旅行サービス」といいます。）の提供を受けることができるように手配することを引き受ける契約をいいます。</li>
                                                        <li>旅行契約の内容・条件は、本利用条件書当サイトの条件規定書及び旅行業約款における手配旅行契約の部（以下「当社HIS 約款」といいます。）によります。</li>
                                                        <li>当サイトの条件規定書及びHIS約款に従い、当社が善良な管理者の注意をもって旅行サービスの手配をしたときは、旅行契約に基づく当社の債務の履行は終了致します。したがって、運送・宿泊機関等との間で旅行サービスの提供をする契約を締結できなかった場合でも、当社がその義務を果たした時には、当サイトの条件規定書及びHIS約款に従い、当社が定める旅行業務取扱料金（以下「取扱料金」といいます。）をお支払いいただきます。</li>
                                                    </ol>

                                                    <h3>4. 旅行のお申込みと旅行契約の成立時期</h3>
                                                    <ol>
                                                        <li>当サイトを利用して当社と旅行契約を締結しようとする会員は、当サイトのページ上で、所定の事項を入力の上お申込みいただきます。</li>
                                                        <li> 当社は、当社が提携するクレジットカード会社（以下「提携会社」といいます。）のカード会員である会員より当サイトによるお申込みを受けて、提携会社のカードにより所定の支払証明伝票への会員の署名なくして旅行代金・取消手続料等のお支払いを受けることを明記する手配旅行契約（以下「通信契約」といいます。）を締結することがあります。</li>
                                                        <li>当社と通信契約を締結される会員は、お申込みに際し、会員の有するクレジットカードの「会員番号」「有効期間」、当サイトによりお申込みをされる旅行サービスの「内容」「日程」その他当社が定める事項を通知していただきます。</li>
                                                        <li>当社は、会員のお申込みに対する承諾をe-mail 等の電子承諾通知の方法により通知する用意があります。</li>
                                                        <li>旅行契約は、当社が契約の締結を承諾し、第6項「旅行代金、空港諸税等のお支払い」(3)(4)の規定により振込まれた旅行代金を受理したときに成立するし、有効となるものとします。</li>
                                                        <li>通信契約は、上記 (5)の規定に係わらず、会員のお申し込み受諾する旨を会員が登録したメールアドレスに発信し、会員のメールアドレスを保有するサーバにメールが到達した時点より効力を発するものとします。</li>
                                                        <li> お申し込み及び申込書への記入において氏名（スペル）はご旅行に使用されるパスポートの記載どおりにお申し込みください。</li>
                                                        <li>当サイトに掲載された商品・価格・サービス・情報について、当社はその信憑性／正確性を保証することはありません。</li>
                                                    </ol>

                                                    <h3>5. 契約締結の拒否</h3>
                                                    <ol>
                                                        <li>当社は、当社の業務上の都合により、会員との旅行契約の締結をお断りする場合があります。</li>
                                                        <li>通信契約を締結しようとする場合であって、会員の有するクレジットカードが無効である等の理由により、会員が旅行代金・取消手続料等の一部又は全部を提携会社のカードによって決済できないときは、当社は、会員との通信契約の締結をお断りする権利を有します。</li>
                                                    </ol>

                                                    <h3>6. 旅行代金、空港諸税等のお支払い</h3>
                                                    <ol>
                                                        <li>旅行代金とは、当社が旅行サービスを手配するために、運賃・宿泊料その他の運送・宿泊機関等に対して支払う費用及び当社所定の取扱料金（変更・取消手続料を除きます。）をいいます。</li>
                                                        <li>航空券発券時に徴収となります空港諸税、空港施設使用料、航空保険料及び燃油特別付加運賃等（以下総称して「空港諸税等」といいます。）は旅行代金には含まれていないため、旅行契約成立時点に別途お支払いいただきます。</li>
                                                        <li> ホテル滞在中のチップ、電話代、お部屋のミニバー代等、現地にてかかる費用は旅行代金には含まれていないため、ご利用いただいた場合は直接ホテルにお支払いいただきます。</li>
                                                        <li>旅行契約に関係する旅行代金、空港諸税等は、当社所定の期日までに、当社所定の支払い方法によってお支払いただきます。</li>
                                                        <li>旅行契約締結発効後から、旅行開始前において、運送・宿泊機関等の運賃・料金の改訂、為替相場の変動、空港諸税等の新設・徴収額の変更その他当社の関与しえない事由により旅行代金、空港諸税等に変動が生じた場合は、当社は旅行代金、空港諸税等を変更することがあります。この場合において旅行代金、空港諸税等の変動による金額の差異は会員の負担とさせていただきます。</li>
                                                        <li>燃油サーチャージの値上げを理由とした旅行契約解除の場合は所定の取消手続料を申し受けます。</li>
                                                        <li>通信契約を締結したときは、当社は、提携会社のカードにより所定の支払証明伝票への会員の署名なくして旅行代金、空港諸税等のお支払いを申し受けます。この場合、会員が旅行代金、空港諸税等のお支払いを履行すべき日は、当社が確定した旅行サービスの内容や航空券料金・空港諸税等の額（変動後の金額を含む）を会員が登録したメールアドレスに発信し、会員のメールアドレスを保有するサーバにメールが到達した時点より効力を発するものとします。</li>
                                                        <li>通信契約において、会員が負担すべき費用等が生じたときは、当社は、提携会社のカードにより所定の支払証明伝票への会員の署名なくして当該費用等のお支払いを申し受けます。この場合、会員が当該費用等のお支払いを履行すべき日は、当社が当該費用等の額を会員が登録したメールアドレスに発信し、会員のメールアドレスを保有するサーバにメールが到達した日を以て起算します。お支払い後、支払確認をお送りいただき、又は速やかに当サイトから当社にご連絡いただきます。但し、第9 項「契約の解除」（2）の規定により当社が旅行契約を解除した場合は、会員が負担することになる費用等を、当社所定の期日までにお支払いいただきます。</li>
                                                        <li>旅行代金、空港諸税等及び会員が負担することになる費用等のお支払いに要する費用は、会員の負担とさせていただきます。</li>
                                                    </ol>

                                                    <h3>7. 旅行代金の精算</h3>
                                                    <ol>
                                                        <li>当社は、旅行サービスを手配するために運送・宿泊機関等に対して支払った費用で会員の負担に帰すべきもの及び取扱料金と会員から旅行代金として既に収受した金額とが合致しない場合には、旅行終了後、速やかに旅行代金の精算をいたします。</li>
                                                        <li>航空券発券時に徴収となります空港諸税、空港施設使用料、航空保険料及び燃油特別付加運賃等（以下総称して「空港諸税等」といいます。）は旅行代金には含まれていないため、旅行契約成立時点に別途お支払いいただきます。</li>
                                                    </ol>

                                                    <h3>8. 契約内容の変更</h3>
                                                    <p>当サイトによる旅行契約の成立後、会員が旅行日程、旅行サービスの内容その他の旅行契約の内容を変更される場合は、旅行契約を一旦解除した後、再度新規契約としてお申込み頂きます。この場合、契約の解除に関する取扱いは、第9項「契約の解除」（1）の規定に準ずるものとします。</p>

                                                    <h3>9. 契約の解除</h3>
                                                    <ol>
                                                        <li>
                                                            会員による任意解除 会員は、下記費用をお支払いいただくことにより、いつでも予約内容確認・変更・取消画面上で旅行契約を解除することができます。但し、予約取消ボタンが表示されない場合は、直接当サイトのカスタマーサポートへご連絡下さい。
														<ul>
                                                                <li>会員が既に提供を受けた旅行サービスの対価として、又はいまだ提供を受けていない旅行サービスに係わる取消手続料・違約料として、運送・宿泊機関等に対して既に支払い、又はこれから支払う費用。 </li>
                                                                <li>当社所定の取消手続料金。但し、PEX 航空券については航空会社毎に取消の規定が異なるため、契約解除に伴う取消手続料は各航空会社の規定に準ずるものとします。 </li>
                                                                <li>当社が旅行契約を履行することによって得られるはずであった取扱料金。</li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            会員の責に帰すべき事由による解除通信契約を締結した場合であって、会員の有するクレジットカードの無効、与信等の理由により、会員が旅行代金・取消手続料等の一部又は全部を提携会社のカードによって決済できなくなったとき、当社は、旅行契約を解除することがあります。これらの場合、下記費用は会員の負担とさせていただきます。
														<ul>
                                                                <li>会員がいまだ提供を受けていない旅行サービスに係わる取消手続料・違約料として運送・宿泊機関等に対して既に支払い、又はこれから支払う費用。 </li>
                                                                <li>当社定の取消手続料金。但し、PEX航空券については航空会社毎に取消の規定が異なるため、契約解除に伴う取消手続料は各航空会社の規定に準ずるものとします。 </li>
                                                                <li> 当社が旅行契約を履行することによって得られるはずであった取扱料金。</li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            当社の責に帰すべき事由による解除
														<ul>
                                                                <li>当社の責に帰すべき事由により旅行サービスの手配が不可能となったときは、会員は旅行契約を解除する権利を有します。この場合、当社は、旅行代金から会員が既にその提供を受けた旅行サービスの対価として、運送・宿泊機関等に対して既に支払い、又はこれから支払わなければならない費用を控除した残金を会員に払い戻すものとします。</li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            会員が本項
														<ul>
                                                                <li>の規定により旅行契約を解除したときは、当社は、会員より収受した旅行代金から会員の負担となる費用を控除した残金を会員に払い戻します。この場合、払い戻しに要する費用は会員の負担とさせていただきます。</li>
                                                            </ul>
                                                        </li>
                                                        <li>通信契約を締結した場合であって、旅行契約の解除等により当社が会員に払い戻すべき旅行代金等が生じたときは、当社は、提携会社のカードにより旅行代金等を会員に払い戻します。この場合、当社が払戻債務を履行すべき日は、当社が会員に払い戻すべき額を会員が登録したメールアドレスに発信し、会員のメールアドレスを保有するサーバにメールが到達した日を以て起算します。</li>
                                                    </ol>

                                                    <h3>10. 変更・取消手続料</h3>
                                                    <ol>
                                                        <li>各ホテル、時期により変更・取消手続料が異なります。ご予約内容の確認時にご案内する「変更・取消手続料」が適用となりますので、必ずご確認ください。また適用の変更・取消手続料は、予約後も予約内容確認･変更･取消画面よりご確認いただけます。</li>
                                                        <li>お取消のご連絡のないまま宿泊日を過ぎた場合（ノーショウ）、会員に全泊分の取消手続料がかかります。</li>
                                                        <li>当サイトご利用の予約に関しまして、下記のご予約内容の変更は一旦取消後の新規予約となります。
														<ul>
                                                                <li>ホテルの変更 </li>
                                                                <li>宿泊期間の変更 </li>
                                                                <li>部屋数、部屋カテゴリー、部屋タイプの変更 </li>
                                                                <li>宿泊者の変更</li>
                                                            </ul>
                                                        </li>
                                                        <li>居住国出発後の変更・取消は、原則としてできません。やむを得ず変更・取消をされる場合は、速やかに当サイトから又はホテルオンラインサポートデスクへご連絡の上、当該旅行サービスを提供する運送・宿泊機関などにもご連絡ください。日本居住国出発後に関しましては、規定の変更・取消手続料が適用とならず、全泊分の料金が発生する場合がございます。</li>
                                                        <li>運送機関の運休、遅延等でご宿泊ができない場合も、原則として取消手続料の対象となります。</li>
                                                        <li>上記変更・取消手続料は、当社が手続きを受理した時から起算した日時を基準といたします。当サイトでの受付期間終了の後、サポートデスクに取消のご連絡いただく際に営業時間外であった場合には、翌日以降手続きが可能となった日時を基準といたします。当サイトでの受付期間終了の後、カスタマーサポートに取消のご連絡をいただく際に 営業時間外であった場合には、翌日以降手続きが可能となった日から起算した日時を計算基礎といたします。</li>
                                                    </ol>

                                                    <h3>11. 団体・グループ契約</h3>
                                                    <ol>
                                                        <li>当社は、同じ行程を同時に旅行する複数の旅行者が、その責任ある代表者（以下「契約責任者」といいます。）を定めて申込んだ手配旅行契約の締結については、本項の規定を適用します。</li>
                                                        <li>当社は、特約を結んだ場合を除き、契約責任者はその団体・グループを構成する旅行者（以下「構成者」といいます。）の手配旅行契約し、委任状に記載されている一切の代理権を有しているものとみなし、当該団体・グループに関する取引は、当該責任者との間で行います。</li>
                                                        <li>契約責任者は、当社が定める日までに、構成者の名簿を当社に提出していただきます。</li>
                                                        <li>当社は、契約責任者が構成者に対して現に負い、又は将来負うことが予測される債務又は義務については、何らの責任を負うものではありません。</li>
                                                        <li>当社は、契約責任者が団体・グループに同行しない場合、旅行開始後において、あらかじめ契約責任者が選任した構成者を契約責任者とみなします。</li>
                                                        <li>当社は、契約責任者から構成者変更のお申出があった場合、迅速にこれに応じますが、変更によって生じる旅行代金の増加及び変更に要する費用は、契約責任者に帰属するものとします。</li>
                                                    </ol>

                                                    <h3>12. 免責事項</h3>
                                                    <p>会員が、以下に例示するような事由によって損害を被られた場合、当社はその損害を賠償する責任を負いません。</p>
                                                    <ol>
                                                        <li>天災地変、戦乱、暴動、航空機の遅延、ストライキ等により出発便が取り消された、又は旅行日程が変更された場合。 </li>
                                                        <li>運送・宿泊機関の過剰予約受付（オーバーブッキング）により、予約を取り消された、又は搭乗並びに宿泊を拒否された場合。</li>
                                                        <li>会員がご出発（帰路便）の72時間前までに予約の再確認（リコンファーム）及び出発時間の確認を怠ったために、予約を取り消され、航空券が無効になった場合。 </li>
                                                        <li>会員が集合時間（通常は出発の2時間前）に遅れて搭乗できなかった場合。</li>
                                                        <li>会員が航空券等の紛失又は盗難に遭われた場合。 </li>
                                                        <li>当社及び外部手配代行者の直接的支配の及ばない事由により、会員が損害を被られた場合。 </li>
                                                        <li>旅券（パスポート）の残存有効期間の不足及び査証（ビザ）の不備の為、当該国の出入国管理法により、搭乗、出入国が出来ない場合。</li>
                                                        <li>パスポート記載の名前と航空券記載の名前が違っている場合。</li>
                                                        <li>会員のご都合にてご予約された予定便に搭乗されず、以降の予約が取り消され航空券が無効になった場合。 </li>
                                                        <li>行程中の疾病（食中毒を含む）及び傷害。 </li>
                                                        <li>会員が当社から電子メールにて配信した契約内容に関する重要なお知らせを開封しなかった場合。かつ取消手続料が発生した場合。 </li>
                                                        <li>外部手配代行者により当サイトに掲載された商品・価格・サービス・情報について、誤字が含まれていた場合、また正確性を欠いていた場合。 </li>
                                                        <li>外部手配代行者により提供されたサービスが、作為または過失に関わらず、会員に損害を与えた場合。 </li>
                                                        <li>会員による本条件規定書において言及される事柄への違反、法律違反または第三者の権利の侵害その他当サイトの不適切な利用があった場合。</li>
                                                    </ol>

                                                    <h3>13. 会員の責任</h3>
                                                    <ol>
                                                        <li>会員の故意、過失、法令・公序良俗に反する行為、もしくは会員が当サイトの条件規定書及び当社約款の規定を守らないことにより当社が損害を被ったときは、会員はこの損害に対して補償することに同意するものとします。</li>
                                                        <li>会員は、当社と旅行契約を締結するに際して、当サイトの条件規定書、当社約款及び当社から提供された情報をに従い、会員自身の権利義務その他の旅行契約の内容について理解するよう努めなければなりません。</li>
                                                        <li>会員は、旅行開始後において、契約書面記載の旅行サービスを円滑に受領するため、契約書面と異なる旅行サービスが提供されたと認識したときは、旅行地において速やかにその旨を当社、当社の手配代行者又は当該旅行サービス提供者 に申し出なければなりません。</li>
                                                    </ol>

                                                    <h3>14. 個人情報取扱い</h3>
                                                    <ol>
                                                        <li>当社は、個人情報を適切かつ慎重に保管・管理し、漏洩、滅失または毀損等の危険を防止するために、技術及び管理の両面から適切かつ合理的な安全対策の実施に努めます。</li>
                                                        <li>
                                                            当社は、ご提供いただいた個人情報を明示した利用目的の範囲内で取り扱います。原則として第三者に開示または提供いたしませんが、以下の場合において個人情報を開示する場合があります。
														<ul>
                                                                <li>会員の同意がある場合 </li>
                                                                <li>法令による指示など正当な理由がある場合 </li>
                                                                <li>個人を特定できない情報など </li>
                                                                <li>当社が提供する各種サービスを会員が利用する場合</li>
                                                            </ul>
                                                        </li>
                                                    </ol>

                                                    <h3>15. 旅行条件・旅行代金の基準</h3>
                                                    <p>この旅行条件は2011年6月1日を基準としています。また、旅行代金は2011年6月1日現在有効なものとして 公示されている航空運賃・適用規則を基準として算出しています。</p>

                                                    <h3>16. その他ご注意</h3>
                                                    <ol>
                                                        <li>旅行代金の返金に関するご注意 当サイトに記載されている当社の規定に基づき、会員のご都合による取り消しの場合、及び返金が生じた場合、返金に伴う取扱い手数料は、会員のご負担とさせていただきます。あらかじめご了承ください。</li>
                                                        <li>航空会社のマイレージについて 航空会社のマイレージサービスについては、会員と航空会社との会員プログラムにつき、サービスに関してのお問い合わせ、登録等は会員ご自身で航空会社へ直接ご申告願います。またマイレージに関しての責任は当社では負いかねますのでご了承ください。</li>
                                                        <li>航空会社の受託手荷物について 航空会社の受託手荷物については、無料で預かれる手荷物の量に制限があります。制限を超えると超過手荷物料金が必要です。方面及び航空会社ごとに異なりますので航空会社にご確認ください。</li>
                                                        <li>接続便のご利用について 接続便（ON-TO）については航空券のご予約国において予約確認されていても、各国の通信事情その他の理由により現地で乗継ぎができない場合もありますので、ご旅行に際してはできる限り余裕を持ったスケジュールをお立て下さい。</li>
                                                        <li>航空券の利用条件について 格安航空券の基本利用条件として、飛行ルートの変更、払い戻し、他航空会社への乗換えができない（指定された航空会社しか利用できない）、途中降機（ストップオーバー）の制限等の制約がございます。ご利用の際は商品の詳細をご覧下さい。</li>
                                                        <li>お申込みのお名前について お申込みのお名前はパスポートに記載されているものと同じであることを、必ず確認するものとします。ご搭乗者氏名のスペルの訂正、大人・子供の種別、性別の修正、旅行者の交替は変更ではなく取消扱いとなり、取消手続料の対象となりますのでご注意ください。</li>
                                                        <li> 搭乗手続きについて 航空機への搭乗手続きは余裕を持って行なってください。また、予告なしに出発時刻が変更される場合がありますので、ご利用航空会社へ出発・搭乗手続き時刻等をご確認ください。</li>
                                                        <li>
                                                            ご予約のお部屋に関しまして
														<ul>
                                                                <li>シングル利用のお部屋の場合でも、ベッドが2台あるお部屋となる場合がございます。 </li>
                                                                <li>ツインルームにはベッドが2台ある部屋と、キングサイズかクィーンサイズの大型ベッドが１台のみの部屋があり、ご希望を伺うことができますが、ホテルやご宿泊の時期によっては希望にそえないこともありますのでご了承下さい。 </li>
                                                                <li>トリプルルームの場合、ホテルへはエキストラベッドの希望を連絡しますが、当日のホテルの状況及び所有台数によりますので、必ずしもベッド数が3台になるとは限りませんので予めご了承下さい。 </li>
                                                                <li>4〜6人部屋：そのお部屋をご利用いただくことができる最大入室可能人数となります。必ずしもベッド数とは一致しませんので、ベッドをご利用いただけない場合もあります。ご了承ください。</li>
                                                                <li>エキストラベッドにはソファベッドまたは折りたたみ式の簡易ベッドが使用されますのでお部屋が手狭になります。快適にお過ごしいただくために、部屋の追加予約をお薦めいたします。</li>
                                                                <li>ホテルによりソファベッドがソファーのままの状態である、簡易ベッドの搬入が遅くなる（通常は夕方の搬入）などの場合がございますので、ご不審の点はホテルフロントにご確認下さい。</li>
                                                                <li>ホテルによっては追加ベッド料金が別途必要な場合がございます。会員ご自身で直接、現地にてホテルにお支払いいただく場合もございます。 </li>
                                                                <li>現地において追加の同宿者が発生した場合は、ホテル規定の追加料金をお支払いいただく事があります。 ※お部屋やエキストラベッドに関するご要望に関しましてはホテルに対し会員のご希望をリクエストさせて頂きますが、ホテルやご宿泊の時期によってはご希望に添えない場合もございます。予めご了承下さい。</li>
                                                            </ul>
                                                        </li>
                                                        <li>海外からのお電話について 海外から当社へのお電話は必ずパーソナルコール（当サイトに記載されている連絡先）でお願いいたします。コレクトコールはお受けいたしませんのでご了承下さい。当社の責と認められる場合には帰国後通話料金をお支払いいたします。</li>
                                                    </ol>
                                                </div>
                                            )
                                            :
                                            (
                                                <div className="blog-content">
                                                    <h3>1. Perusahaan</h3>
                                                    <p>HIS Hotels (Hoterip) dikelola dan didukung sepenuhnya oleh PT. Harum Indah Sari Tours & Travel, yang beralamat di Jl. Bypass Ngurah Rai No. 732, Pesanggaran, Denpasar, Bali.</p>

                                                    <h3>2. Layanan</h3>
                                                    <p>HIS Hotels menyediakan situs sebagai wadah untuk Pemesanan/Reservasi untuk Akomodasi seperti Hotel, Villa, Apartemen hingga Tiket Pesawat secara online. Pengguna dapat melakukan pemesanan layanan yang sepenuhnya dikelola dan diatur secara langsung oleh pihak hotel serta maskapai penerbangan dan/atau penyedia jasa layanan lainnya yang bekerjasama dengan HIS Hotels. Setiap pemesanan yang dilakukan melalui situs HIS Hotels akan mendapatkan konfirmasi secara langsung melalui e-mail yang terdaftar. Kami juga berhak secara penuh untuk menolak setiap pemesanan yang telah dilakukan apabila dianggap menggunakan transaksi mencurigakan, sebagaimana ketentuan yang tercantum dibawah ini.</p>
                                                    <p>Meskipun kami akan menggunakan keahlian kami dengan hati-hati dalam memberikan layanan, namun kami tidak memverifikasi dan tidak menjamin, bahwa semua informasi yang diberikan dan yang tersedia adalah akurat, lengkap, benar atau terbaru dan kami tidak bertanggung jawab atas setiap kesalahan (termasuk kesalahan penempatan dan pengetikan), gangguan (baik sementara dan/atau parsial, kerusakan, perbaikan atau peningkatan ke situs atau sebaliknya), informasi yang tidak akurat, menyesatkan atau palsu atau informasi yang tidak tersampaikan.</p>
                                                    <p>Dalam hal terjadi masalah, Layanan Pelanggan akan berusaha untuk membantu dan menjawab semua pertanyaan yang diajukan oleh pelanggan melalui jaringan telepon, e-mail atau percakapan langsung secara online setiap hari (kecuali hari Minggu), selama jam kerja sejak pukul 09:00-17:00 WITA (GMT+8).</p>
                                                    <p>Kami memiliki hak untuk tidak menerima setiap pengguna atau pemesanan (atau dalam kasus-kasus tertentu membatalkan konfirmasi pemesanan) secara sepihak berdasarkan kebijakan kami dan untuk alasan apa pun serta tanpa memberikan alasan penolakan/penampikan/pembatalan tersebut. Alasan untuk menolak pengguna atau pemesanan atau membatalkan konfirmasi pemesanan dapat termasuk namun tidak terbatas pada: pelanggaran atas syarat & ketentuan ini, perdagangan atau sanksi ekonomi oleh otoritas global atau nasional, embargo, larangan dalam peraturan, penipuan atau pencurian (atau indikasi atau dugaan penipuan atau pencurian), dugaan kegiatan kriminal, pemesanan yang mencurigakan, layanan yang tidak tersedia atau tidak lagi disediakan oleh supplier, pengguna memberikan informasi yang tidak akurat, keliru atau menyesatkan, bermasalah dengan komunikasi kartu kredit elektronik, informasi atau transaksi, perilaku yang tidak pantas, ancaman, hinaan, penolakan untuk memberikan informasi, hambatan praktis, kesulitan atau kemacetan komunikasi, Kesalahan Nyata (selanjutnya dijelaskan di bawah ini), sejarah pelanggaran syarat atau penolakan tersebut, atau dimasukkan pada setiap "daftar hitam" atau "daftar pengamatan" oleh pemerintah atau organisasi internasional. Dalam kasus pemesanan ditolak atau dibatalkan oleh HIS Hotels dan pembayaran telah dilakukan, HIS Hotels akan mengembalikan uang sejumlah yang telah dibebankan setelah memotong biaya-biaya yang timbul seperti biaya transfer antar bank. Kami dapat menghilangkan atau menghapus keanggotaan pengguna situs ini sewaktu-waktu, baik sementara atau permanen. Pengguna yang telah dihapus keanggotaannya, dilarang untuk mencoba menggunakan situs dengan nama lain atau melalui nama pengguna lainnya (selanjutnya dijelaskan dalam ketentuan Mekanisme Pencegahan Penipuan).</p>

                                                    <h3>3. Pembayaran, Pembatalan dan Pengembalian Dana</h3>
                                                    <p className="lead">PEMBAYARAN</p>
                                                    <p>Semua transaksi pembayaran pada website HIS Hotels menggunakan sistem pembayaran yang menjamin keamanan informasi dan kerahasiaan data pelanggan sewaktu melakukan pembayaran dengan menggunakan kartu kredit. Hoterip menggunakan Secure Socket Layer (SSL) yaitu sistem teknologi untuk mengeknripsi semua informasi yang digunakan untuk pembayaran dengan menggunakan kartu kredit dan transaksi online.</p>
                                                    <p>Apabila transaksi pembayaran yang dilakukan terindikasi menggunakan kartu kredit yang tidak sesuai atau palsu, HIS Hotels berhak secara penuh untuk langsung dapat melakukan pembatalan atas pemesanan yang telah dilakukan tersebut secara sepihak tanpa perlu memberikan alasan apapun kepada pelanggan. </p>
                                                    <p className="lead">PEMBATALAN</p>
                                                    <p>Biaya pembatalan dibebankan berdasarkan kebijakan pembatalan yang diterapkan oleh masing-masing Hotel Rekanan. Pelanggan diberi kesempatan untuk memeriksa kembali kebijakan pembatalan sebelum melakukan pemesanan serta menyelesaikan pembayaran. Tidak ada biaya tambahan selain biaya pembatalan yang berlaku sesuai kebijakan pembatalan hotel atas pembatalan yang dilakukan pelanggan.</p>
                                                    <p className="lead">BIAYA TAMBAHAN & PENGEMBALIAN DANA</p>
                                                    <p>Setiap harga yang tercantum pada website HIS Hotels hanya tersedia sesuai dengan syarat & ketentuan tertentu dan harga tersebut dapat berubah sewaktu-waktu tergantung pada ketersediaan kamar, lamanya pemesanan dan/atau periode yang diminta. Harga yang tersedia dapat mencakup biaya pajak dan layanan dan biaya lainnya. </p>
                                                    <p>Pelanggan setuju bahwa mereka bertanggung jawab untuk memeriksa kembali total biaya yang harus dibayarkan dan persyaratan lainnya yang tercantum pada formulir pemesanan sebelum melakukan pembayaran. Harga yang ditampilkan terperinci sehingga pelanggan dapat melihat total harga yang harus dibayarkan, biaya tambahan lainnya yang muncul yang disebabkan oleh penggunaan kartu kredit atau biaya transfer antar bank yang dikenakan untuk biaya pengiriman</p>
                                                    <p>Dalam hal pelanggan membatalkan pemesanan dan tidak ada biaya pembatalan yang dikenakan, HIS Hotels akan mengembalikan dana sesuai total harga yang dibayarkan setelah dikurangi biaya yang dikeluarkan oleh HIS Hotels seperti biaya administrasi bank atau biaya transfer antar bank. Jika pelanggan memiliki keraguan tentang layanan ini, dapat menghubungi layanan pelanggan HIS Hotels selama jam kerja. Proses pengembalian dana maksimum dalam masa 14 hari kerja terhitung sejak adanya permintaan untuk pengembalian dana dari pelanggan yang diterima oleh HIS Hotels</p>
                                                    <p>HIS Hotels tidak bertanggung jawab atau berkewajiban atas setiap penerbitan e-tiket, e-voucher hotel, atau transaksi lainnya yang digugurkan akibat jumlah dana yang ditransfer oleh pelanggan tidak akurat. Pembayaran yang dilakukan melebihi batas waktu akan mengakibatkan pemesanan digugurkan secara otomatis. Batas waktu penyelesaian pembayaran dengan menggunakan transfer Bank atau ATM adalah 120 menit atau 2 jam terhitung sejak formulir pemesanan dikirimkan. Sedangkan pembayaran dengan menggunakan kartu kredit akan mendapatkan konfirmasi pemesanan secara langsung.</p>
                                                    <p>
                                                        HIS Hotels akan mengembalikan dana pelanggan atas pembatalan yang telah dilakukan dengan ketentuan :
													<ul>
                                                            <li>Pembatalan dilakukan sebelum waktu yang telah ditentukan dan tidak ada biaya pembatalan yang dikenakan.</li>
                                                            <li>Apabila transaksi pembayaran dilakukan melalui Transfer antar Bank atau ATM, maka pemgembalian dana akan dilakukan ke nomor rekening pelanggan.</li>
                                                            <li>Apabila transaksi pembayaran dilakukan menggunakan Kartu Kredit, maka pemgembalian uang akan dilakukan ke kartu kredit sesuai nomor kartu kredit yang telah digunakan.</li>
                                                            <li>Apabila pembayaran dilakukan dengan menggunakan Hoterip reward point yang telah dikumpulkan maka poin tersebut akan digunakan sebagai biaya pembatalan.</li>
                                                            <li>Biaya administrasi bank akan dibebankan kepada pelanggan sesuai ketentuan yang berlaku dari masing-masing Bank.</li>
                                                        </ul>
                                                    </p>

                                                    <h3>4. Lain - lain</h3>
                                                    <p>Ketentuan ini sewaktu-waktu dapat ditambah atau diubah tanpa pemberitahuan terlebih dahulu. Begitupun dengan data dari Hoterip dapat diubah tanpa pemberitahuan terlebih dahulu, oleh karena itu Hoterip tidak bertanggung jawab atas informasi yang berbeda atau kerugian yang muncul pada pelanggan yang disebabkan oleh adanya perubahan tersebut.</p>
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}