import React, { Component } from 'react'
import { Layout } from '../../components'

export default class Page404 extends Component {
    render() {
        return(
            <Layout>
                <div className="container">
					<img style={{margin:"auto",display:'block'}} role="presentation"  src="/images/404.png" />
				</div>
            </Layout>
        )
    }
}