import React, { Component } from "react";

import { Breadcrumb } from "react-bootstrap";
import { Layout, MemberSidebar } from "../../components";
import * as Env from "../../Env";
import { Pagination } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Trans from "../../locale/Translate";
import { Row, Col } from 'reactstrap';

let axios = require("axios");
let moment = require("moment");

export default class BookingDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      data: [],
      nationalities: []
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(number) {
    this.setState({
      currentPage: number,
      loaded: true
    });

    this.getBookingList(number);
  }

  renderLoading() {
    const list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    if (!this.state.loaded) {
      return list.map((result, i) => (
        <div key={i}>
          <br />
          <div className="list-perhotel">
            <div className="row">
              <div className="col-sm-12">
                <div className="">
                  <div className="animated-background facebook line1" style={{ height: 30, width: '100%' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  }

  getBookingList() {
    this.setState({ loaded: false });
    const self = this;
    const params = {
      booking_code: this.props.location.query.id
    }

    axios
      .get(Env.url.api_url + "hotels/users/booking-histories", {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Accept-Language": localStorage.getItem("language"),
          "Accept-Currency": localStorage.getItem("currency")
        }
      })
      .then(response => {
        if (response.status === 200) {
          self.setState({
            data: response.data.data.data[0],
            loaded: true
          });
        }
      })
      .catch(error => {
        console.log(error)
      });
  }

  handleCancel = e => {
    e.preventDefault();

    let token = localStorage.getItem("token");
    let booking_id = this.props.location.query.id;

    if (window.confirm("Are you sure to cancel this booking?")) {
      axios({
        method: "post",
        url: Env.url.api_url + "hotels/users/" + booking_id + "/cancel",
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          Authorization: "Bearer " + token
        }
      })
        .then(response => {
          if (response.data.meta.success) {
            if (window.confirm("success cancel this booking")) {
              window.location.reload(true);
            }
          }
        })
        .catch(error => { });
    }
  };

  fetchNationalities() {
    var that = this
    axios.get(Env.url.api_url + 'hotels/nationalities', {
      headers: { 'Accept-Language': localStorage.getItem('language'), 'Accept-Currency': localStorage.getItem('currency') }
    })
      .then(response => {
        if (response.data.meta.success) {
          that.setState({ nationalities: response.data.data })
        }
      })
      .catch(error => {
        console.log(error)
      });
  }

  componentDidMount() {
    this.getBookingList();
    this.fetchNationalities();
  }

  render() {

    var lang = 'id';
    switch (localStorage.getItem('language')) {
      case "en-us":
        lang = 'en-gb';
        break;
      case "ja":
        lang = 'ja';
        break;
      default:
        lang = 'id';
        break;
    }

    const { loaded, data, nationalities } = this.state;

    return (
      <Layout>
        <div className="container">
          <div className="row">
            <div className="col-md-9" style={{ marginTop: "-30px" }}>
              <Breadcrumb>
                <Breadcrumb.Item href="/">{Trans.data.home}</Breadcrumb.Item>
                <Breadcrumb.Item href="/booking-history">{Trans.data.my_bookings}</Breadcrumb.Item>
                <Breadcrumb.Item href={`/booking-detail?id=${data.booking_code}`}>Booking Details</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div className="container">
          <section className="member">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className="panel panel-default panel-personalinfo"
                  style={{ border: "1px solid #ddd", borderRadius: "6px", overflow: "hidden", minHeight: "700px" }}
                >
                  <div className="panel-heading">
                    <h3 className="panel-title" style={{ fontSize: "18px" }}>
                      Booking Details
                    </h3>
                  </div>
                  <div className="panel-body" style={{ padding: "30px" }}>
                  {this.renderLoading()}                
                  {loaded && <>
                    <Row>
                      <Col xs='3'>
                        <Row>
                          <Col xs='12'>
                            <div style={{borderRight: 'solid #f5f5f5 2px'}}>
                              <p> Booking ID</p>
                              <p style={{color: '#12407c', marginTop: '-15px'}}>{data.booking_code}</p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs='3'>
                        <Row>
                          <Col xs='12'>
                            <p>Partner Booking ID</p>
                            <p style={{color: '#12407c', marginTop: '-15px'}}>{data.partner_booking_code}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr style={{borderStyle: 'none', borderTop: '2px dashed', borderColor: '#f5f5f5', marginTop: '10px'}}/>
                    <Row>
                      <Col xs='12'>
                        <div style={{paddingBottom: '20px'}}>
                          <p>{data.hotel.selected_text.name}</p>
                        </div>
                      </Col>
                        <Col xs='2'>
                          <div style={{borderRight: 'solid #f5f5f5 2px'}}>
                            <p style={{fontSize: '1.1em'}}>Check-In</p>
                            <p style={{fontSize: '1.1em'}}>{data.check_in_date}</p>
                          </div>                        
                        </Col>
                        <Col xs='2'>
                          <div style={{borderRight: 'solid #f5f5f5 2px'}}>
                            <p style={{fontSize: '1.1em'}}>Check-Out</p>
                            <p style={{fontSize: '1.1em'}}>{data.check_out_date}</p>
                          </div>
                        </Col>
                        <Col xs='2'>
                          <div style={{borderRight: 'solid #f5f5f5 2px'}}>
                            <p style={{fontSize: '1.1em'}}>Length of Stay</p>
                            <p style={{fontSize: '1.1em'}}>{data.number_of_night} night(s)</p>
                          </div>
                        </Col>
                        <Col xs='2'>
                          <div style={{borderRight: 'solid #f5f5f5 2px'}}>
                            <p style={{fontSize: '1.1em'}}>Nationality</p>
                            <p style={{fontSize: '1.1em'}}>{data.nationality.name}</p>
                          </div>
                        </Col>
                        <Col xs='2'>
                          <div style={{borderRight: 'solid #f5f5f5 2px'}}>
                            <p style={{fontSize: '1.1em'}}>Total Room</p>
                            <p style={{fontSize: '1.1em'}}>{data.number_of_rooms} Room(s), {data.total_adults} Adult(s), {data.total_children} Children</p>
                          </div>
                        </Col>
                        <Col xs='2'>
                          <p style={{fontSize: '1.1em'}}>Booking Status</p>
                          <p style={{backgroundColor: data.is_canceled === 0 ? '#12407c': '#ff7676', color: 'white', borderRadius: '9px', fontSize: '1.1em', display: 'inline-block', padding: '2px 7px'}}>{data.is_canceled === 0 ? 'Confirmed' : 'Cancelled'}</p>
                        </Col>
                    </Row>
                    <Row>
                      <Col xs='12'>
                        <p style={{color: '#12407c', fontSize: '1.5em'}}>Details</p>
                        <table className="table custom-table">
                          <thead  style={{ backgroundColor: '#E6E7E8' }}>
                            <tr>
                              <th>Room No.</th>
                              <th>Guest Name</th>
                              <th>Child Age(s)</th>
                              <th>Room Name</th>
                              <th>Room Price for {data.number_of_night} Night(s)</th>
                            </tr>                    
                          </thead>
                          <tbody>
                            {data.user_ouccupancies_and_prices.rooms.map((item, i) => {
                              const guestListText = data.price_rooms[i].guest_list_text;
                              let formattedGuestList;
                              let childAgeList;
                              let formattedChildAgeList;
                            
                              if (guestListText) {
                                const guests = guestListText.split(',').map(guest => guest.trim());
                                childAgeList = Array(guests.length).fill("N/A");
                                formattedChildAgeList = childAgeList.join('<br>');
                                formattedGuestList = guests.join('<br>');

                              } else {
                                formattedChildAgeList = "N/A";
                                formattedGuestList = data.guest_fullname;
                              }
                              
                              return (
                                <tr style={{borderBottom: '1px solid #ddd'}} key={i}>
                                  <td>{i + 1}</td>
                                  <td dangerouslySetInnerHTML={{ __html: formattedGuestList }}></td>
                                  <td dangerouslySetInnerHTML={{ __html: formattedChildAgeList }}></td>
                                  <td style={{ width: '300px' }}>{data.room.selected_text.name} ({item.number_of_adult} Adults, {item.number_of_child} Children) - {data.is_breakfast_included ? 'Breakfast Included' : 'Breakfast Excluded'}</td>
                                  <td style={{color: '#faa543', fontWeight: 'bold'}}>{Env.formatCurrency(item.summary_price, data.user_ouccupancies_and_prices.currency)}</td>
                                </tr>
                              );
                            })}
                            <tr style={{borderBottom: '1px solid #ddd'}}>
                              <td colSpan={4} align="right">Total Price</td>
                              <td style={{color: '#faa543', fontWeight: 'bold'}} colSpan={1} align="left">{Env.formatCurrency(data.user_ouccupancies_and_prices.amount_paid_by_guest_transaction, data.user_ouccupancies_and_prices.currency)}</td>
                            </tr>
                            <tr>
                              <td colSpan={2} style={{ borderBottom: '1px solid #ddd'}}>
                                <p style={{ fontSize: '1.1em', fontWeight: 'bold' }}>Special Request</p>
                                <p style={{ fontSize: '1em' }}>{data.request_note !== '' ? data.request_note : '-'}</p>
                              </td>                        
                            </tr>
                            <tr>
                              <td colSpan={4}>
                                <p style={{ fontSize: '1.1em', fontWeight: 'bold', marginBottom: '0' }}>Cancellation Policy</p>
                                  {data.cancelation_rates.map((item, i) => (
                                    <>
                                    <div style={{ display: 'flex', width: '100%' }}>
                                      <div style={{ flex: '0 0 50%', paddingRight: '10px' }}>
                                        <p style={{ fontSize: '1em', marginBottom: '0' }}>Cancel the booking from {item.date}</p>                                    
                                      </div>
                                      <div style={{ flex: '0 0 50%', paddingLeft: '10px' }}>
                                        <p style={{ fontSize: '1em', marginBottom: '0' }}>
                                          <span style={{ fontWeight: 'bold' }}>{Env.formatCurrency(item.rate, data.user_ouccupancies_and_prices.currency)}</span> charged
                                        </p>
                                      </div>
                                    </div>                          
                                    </>
                                  ))}  
                              </td>             
                            </tr>
                          </tbody>
                        </table>
                        {data.is_canceled === 0 && moment().format("YYYY-MM-DD") <= data.check_out_date  ? 
                        <Col xs='12' className="text-right">      
                          <form onSubmit={this.handleCancel}>
                            <button type="submit" className="btn btn-danger">
                              {Trans.data.cancel_this_booking}
                            </button>
                          </form>          
                        </Col>
                        : null}
                      </Col>
                    </Row>
                  </>}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}
