import * as Env from '../Env';
var axios = require('axios');


const lastBookedSuccess = (data) => {
    return {
        type: 'LAST_BOOKED_SUCCESS',
        data
    }
}

export const lastBookedAction = () => {
    return (dispatch) => {
        axios.get(Env.url.api_url + 'hotels/users/last-booked-hotel', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Accept-Language': localStorage.getItem('language'),
                'Accept-Currency': localStorage.getItem('currency')
            }
        })
            .then(response => {
                if (response.data.meta.success) {
                    dispatch(lastBookedSuccess(response.data.data))
                }
            })
            .catch(error => {
                Env.expired(error)
            });
    }
}