import React, { Component } from "react";

import { Breadcrumb } from "react-bootstrap";
import { Layout, MemberSidebar } from "../../components";
import * as Env from "../../Env";
import { Pagination } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Trans from "../../locale/Translate";
import { Link } from 'react-router';

let axios = require("axios");
let moment = require("moment");


const ListBookingHistory = (props) => {
  const lists = props.data ? props.data : []

  return <table className="table custom-table tabel-responsive-bo" {...props}>
    <thead style={{ backgroundColor: '#E6E7E8' }}>
      <tr>
        <th>Booking ID</th>
        <th>Partner Booking ID</th>
        <th>Agent Email</th>
        <th>Booking Date</th>
        <th>Guest Name</th>
        <th>Hotel Name</th>
        <th>Check-In Date</th>
        <th>Check-Out Date</th>
        <th>Status</th>
        <th>Price</th>
      </tr>
    </thead>
    <tbody>
      {lists.length ? lists.map((result, idx) => {
      // console.log(result.booking_code.replace(/\D/g, '') )
        return <tr key={idx}>
          <td> <Link to={{ pathname: '/booking-detail', query: { id: result.booking_code ? String(result.booking_code).replace(/\D/g, '') : result.booking_code } }}>
          {result.booking_code}
          </Link>
        </td>
          <td>{result.partner_booking_code}</td>
          <td>{result.agent_email}</td>
          <td>{moment(result.booking_date).format('YYYY-MM-DD')}</td>
          <td>{result.guest_fullname}</td>
          <td>{result.hotel.selected_text.name}</td>
          <td>{moment(result.check_in_date).format('YYYY-MM-DD')}</td>
          <td>{moment(result.check_out_date).format('YYYY-MM-DD')}</td>
          <td><p style={{ backgroundColor: result.is_canceled === 0 ? '#12407c' : '#ff7676', color: 'white', borderRadius: '9px', fontSize: '10pt', display: 'inline-block', padding: '2px 7px' }}>{result.is_canceled === 1 ? Trans.data.cancelled : Trans.data.confirmed}</p></td>
          <td>{Env.formatCurrency(result.user_ouccupancies_and_prices.amount_paid_by_guest_transaction, result.user_ouccupancies_and_prices.currency)}</td>
        </tr>
      }) : <td colSpan={9} className='text-center'><p style={{ textAlign: 'center' }}>There is no data</p></td>}
    </tbody>
  </table>
}

export default class MyBookings extends Component {
  constructor(props) {
    super(props);

    const today = new Date();

    this.state = {
      loaded: false,
      isLoadingExport: false,
      data: [],
      formFilterData: {
        booking_code: null,
        booking_partner_code: '',
        country_id: '',
        check_in_date: null,
        check_out_date: null,
        hotel_name: '',
        booking_agent: null
      },
      userGroup: [],
      nationalities: [],
      currentPage: 1,
      totalPage: 1,
      startDate: today,
      endDate: today,
      startDateCheckIn: null,
      endDateCheckIn: null,
      startDateCheckOut: null,
      endDateCheckOut: null,
      isMobileView: window.innerWidth <= 660
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({
      formFilterData: {
        ...this.state.formFilterData,
        [name]: value
      }
    });
  }

  handleInputDateChange(name, date) {
    const [start, end] = date

    if (name == 'booking_date') {
      this.setState({ startDate: start, endDate: end })
    } else if (name == 'check_in_date') {
      this.setState({ startDateCheckIn: start, endDateCheckIn: end })
    } else {
      this.setState({ startDateCheckOut: start, endDateCheckOut: end })
    }

  }

  handleSelect(number) {
    this.setState({
      currentPage: number,
      loaded: true
    });

    this.getBookingList(number);
  }

  renderLoading() {
    const list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    if (!this.state.loaded) {
      return list.map((result, i) => (
        <div key={i}>
          <br />
          <div className="list-perhotel">
            <div className="row">
              <div className="col-sm-12">
                <div className="">
                  <div className="animated-background facebook line1" style={{ height: 30, width: '100%' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  }

  getBookingList(page) {
    this.setState({ loaded: false });

    const self = this;
    const params = {
      ...this.state.formFilterData,
      page: page || 1
    }

    

    if (this.state.formFilterData.booking_code) {
      params.booking_code = String(this.state.formFilterData.booking_code).replace(/B/g, '');
    }
    
    if (this.state.startDate) {
      params.booking_date = moment(this.state.startDate).format("YYYY-MM-DD") + " - " + moment(this.state.endDate).format("YYYY-MM-DD");
    }

    if (this.state.startDateCheckIn) {
      params.check_in_date = moment(this.state.startDateCheckIn).format("YYYY-MM-DD") + " - " + moment(this.state.endDateCheckIn).format("YYYY-MM-DD");
    }

    if (this.state.startDateCheckOut) {
      params.check_out_date = moment(this.state.startDateCheckOut).format("YYYY-MM-DD") + " - " + moment(this.state.endDateCheckOut).format("YYYY-MM-DD");
    }

    axios
      .get(Env.url.api_url + "hotels/users/booking-histories", {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Accept-Language": localStorage.getItem("language"),
          "Accept-Currency": localStorage.getItem("currency")
        }
      })
      .then(response => {
        if (response.status === 200) {
          self.setState({
            data: response.data.data.data,
            currentPage: response.data.data.current_page,
            totalPage: response.data.data.last_page,
            loaded: true
          });
        }
      })
      .catch(error => {
        console.log(error)
        // Env.expired(error);
      });
  }

  fetchNationalities() {
    var that = this
    axios.get(Env.url.api_url + 'hotels/nationalities', {
      headers: { 'Accept-Language': localStorage.getItem('language'), 'Accept-Currency': localStorage.getItem('currency') }
    })
      .then(response => {
        if (response.data.meta.success) {
          that.setState({ nationalities: response.data.data })
        }
      })
      .catch(error => {
        console.log(error)
      });
  }

  fetchUserGroup() {
    var that = this;
    axios
      .get(Env.url.api_url + "hotels/users/list-user-group", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Accept-Language": localStorage.getItem("language"),
          "Accept-Currency": localStorage.getItem("currency"),
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        if (response.data.meta.success) {
          that.setState({ userGroup: response.data.data.users });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }


  handleClickSearchButton() {
    this.getBookingList(1)
  }

  handleClickExportButton() {
    this.setState({ isLoadingExport: true });

    const params = {
      ...this.state.formFilterData
    }
    if (this.state.formFilterData.booking_code) {
      params.booking_code = String(this.state.formFilterData.booking_code).replace(/B/g, '');
    }

    if (this.state.startDate) {
      params.booking_date = moment(this.state.startDate).format("YYYY-MM-DD") + " - " + moment(this.state.endDate).format("YYYY-MM-DD");
    }

    if (this.state.startDateCheckIn) {
      params.check_in_date = moment(this.state.startDateCheckIn).format("YYYY-MM-DD") + " - " + moment(this.state.endDateCheckIn).format("YYYY-MM-DD");
    }

    if (this.state.startDateCheckOut) {
      params.check_out_date = moment(this.state.startDateCheckOut).format("YYYY-MM-DD") + " - " + moment(this.state.endDateCheckOut).format("YYYY-MM-DD");
    }

    const self = this;
    axios
      .post(Env.url.api_url + "hotels/users/export-booking-histories", params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Accept-Language": localStorage.getItem("language"),
          "Accept-Currency": localStorage.getItem("currency"),
          "Accept": "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        if (response.status === 200) {
          self.handleDownload(response.data)
        }

        self.setState({ isLoadingExport: false });
      })
      .catch(error => {
        self.setState({ isLoadingExport: false });
        alert(error)
      });
  }

  handleDownload(url) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', url.substr(url.lastIndexOf('/') + 1));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  componentWillUnmount() {
    window.removeEventListener("resize", this.updateViewMode);
  }

  updateViewMode = () => {
    this.setState({ isMobileView: window.innerWidth <= 660 });
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateViewMode);
    this.updateViewMode(); 
    this.getBookingList();
    this.fetchNationalities();
    this.fetchUserGroup();
  }

  render() {

    var lang = 'id';
    switch (localStorage.getItem('language')) {
      case "en-us":
        lang = 'en-gb';
        break;
      case "ja":
        lang = 'ja';
        break;
      default:
        lang = 'id';
        break;
    }

    const { loaded, data, formFilterData, currentPage: current_page, totalPage: total_page, nationalities, userGroup, startDate, endDate, startDateCheckIn, endDateCheckIn, startDateCheckOut, endDateCheckOut, isMobileView } = this.state;

    console.log(isMobileView)
    return (
      <Layout>
        <div className="container">
          <div className="row">
            <div className="col-md-9" style={{ marginTop: "-30px" }}>
              <Breadcrumb>
                <Breadcrumb.Item href="/">{Trans.data.home}</Breadcrumb.Item>
                <Breadcrumb.Item href="/booking-history">{Trans.data.my_bookings}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div className="container">
          <section className="member">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className="panel panel-default panel-personalinfo"
                  style={{ border: "1px solid #ddd", borderRadius: "6px", overflow: "hidden", minHeight: "700px" }}
                >
                  <div className="panel-heading">
                    <h3 className="panel-title" style={{ fontSize: "18px" }}>
                      {Trans.data.my_bookings}
                    </h3>
                  </div>
                  <div className="panel-body" style={{ padding: "25px" }}>


                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        
                        <div className="form-group">
                        <label>Agent Email</label>
                        <select
                          className="form-control"
                          name="booking_agent"
                          value={formFilterData.booking_agent}
                          onChange={this.handleInputChange}
                        >
                          <option value="">All</option>
                          {userGroup.map((result, i) => (
                            <option value={result.id} key={i}>
                              {result.email}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                          <label>Booking ID</label>
                          <input value={formFilterData.bookingCode} onChange={this.handleInputChange} className="form-control" type="text" name="booking_code" />
                        </div>
                        
                        <div className="form-group">
                          <label>Country</label>
                          <select className="form-control" name="country_id" value={formFilterData.country_id} onChange={this.handleInputChange}>
                            <option value="">All</option>
                            {nationalities.map((result, i) =>
                              <option value={result.id} key={i}>{result.name}</option>
                            )}
                          </select>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label>Booking Date</label>
                              <i className="fa fa-calendar marker-custom-bo" aria-hidden="true"></i>
                              <div className="customDatePickerWidth">
                                <DatePicker
                                  selected={new Date()}
                                  onChange={(e) => this.handleInputDateChange('booking_date', e)}
                                  startDate={startDate}
                                  monthsShown={isMobileView ? 1 : 2}
                                  endDate={endDate}
                                  locale={lang}
                                  selectsRange
                                  dateFormat="yyyy-MM-dd"
                                  className="form-control"
                                  isClearable
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12" style={{marginTop: '73px'}}>
                        <div className="form-group">
                          <label>Partner Booking ID</label>
                          <input value={formFilterData.booking_partner_code} onChange={this.handleInputChange} className="form-control" type="text" name="booking_partner_code" />
                        </div>
                        <div className="form-group">
                          <label>Hotel Name</label>
                          <input value={formFilterData.hotel_name} onChange={this.handleInputChange} className="form-control" type="text" name="hotel_name" />
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Check-In Date</label>
                              <i className="fa fa-calendar marker-custom-bo" aria-hidden="true"></i>
                              <div className="customDatePickerWidth">
                                <DatePicker
                                  onChange={(e) => this.handleInputDateChange('check_in_date', e)}
                                  monthsShown={isMobileView ? 1 : 2}
                                  startDate={startDateCheckIn}
                                  endDate={endDateCheckIn}
                                  locale={lang}
                                  dateFormat="yyyy-MM-dd"
                                  selectsRange
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Check-Out Date</label>
                              <i className="fa fa-calendar marker-custom-bo" aria-hidden="true"></i>
                              <div className="customDatePickerWidth">
                                <DatePicker
                                  onChange={(e) => this.handleInputDateChange('check_out_date', e)}
                                  monthsShown={isMobileView ? 1 : 2}
                                  startDate={startDateCheckOut}
                                  endDate={endDateCheckOut}
                                  locale={lang}
                                  selectsRange
                                  dateFormat="yyyy-MM-dd"
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <button type="button" className="btn btn-warning" onClick={() => this.handleClickSearchButton()}>Search</button>
                      </div>
                    </div>

                    {this.renderLoading()}

                    {loaded ? <>
                      <div className="row text-right" style={{ marginBottom: '10px' }}>
                        <div className="col-sm-12">
                          <button type="button" className="btn btn-info" disabled={this.state.isLoadingExport} onClick={() => this.handleClickExportButton()}>Export</button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                        <div className="table-responsive">
                          <div className="listbookinghis">
                          <ListBookingHistory data={data} />
                          </div>
                          </div>
                        </div>
                      </div>

                    </> : null}

                    {total_page > 1 ? (
                      <div className="row" style={{ display: "block", textAlign: "right", padding: "0 15px 0 15px" }}>
                        <Pagination
                          bsSize="medium"
                          items={total_page}
                          activePage={current_page}
                          onSelect={this.handleSelect}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}
