import React, { Component } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

export default class Breadcrumb extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lists: this.props.lists
        }
    }

    render() {
        return(
            <ol className="breadcrumb">
            {this.props.lists.map((result, i) =>
                <li key={i} className={this.props.lists.length - 1 === i ? "active" : null}>
                    {this.props.lists.length - 1 === i ? result.title : <Link to={result.link}>{result.title}</Link>}
                </li>
            )}
            </ol>
        )
    }
}

Breadcrumb.defaultProps = {
    lists: [{title: "home", link: "/"}],
};

Breadcrumb.propTypes = {
    lists: PropTypes.array,
}
