import React, { Component } from 'react'

import { Breadcrumb } from 'react-bootstrap';
import { Layout, MemberSidebar } from '../../components'
import * as Env from '../../Env'
import Trans from '../../locale/Translate'

var axios = require('axios');

export default class Deposit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currency: 'IDR',
            total: 0,
            used: 0,
            balance: 0
        }
    }

    fetchDeposit() {
        var that = this
        axios.get(Env.url.api_url + 'hotels/users/profile', {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        })
            .then(function (response) {
                if (response.data.meta.success) {
                    that.setState({
                        currency: response.data.data.data.deposit_currency_code,
                        total: response.data.data.data.floating_deposit,
                        used: response.data.data.data.used_deposit,
                        balance: response.data.data.data.balance_deposit
                    })
                }
            })
            .catch(function (error) {
                Env.expired(error)
            });
    }

    componentDidMount() {
        this.fetchDeposit()
    }

    render() {
        const { currency, total, used, balance } = this.state

        return (
            <Layout>
                <div className="container">
                    <div className="row">
                        <div className="col-md-9" style={{ marginTop: '-30px' }}>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/">{Trans.data.home}</Breadcrumb.Item>
                                <Breadcrumb.Item href="/deposit">{Trans.data.deposit}</Breadcrumb.Item>
                            </Breadcrumb></div>
                    </div>
                </div>
                <div className="container">
                    <section className="member">
                        <div className="row">
                            {/* <MemberSidebar
                                value={1} /> */}
                            <div className="col-md-12 col-sm-12">
                                <div className="panel panel-default panel-personalinfo" style={{ border: '1px solid #ddd', borderRadius: '6px', overflow: 'hidden' }}>
                                    <div className="panel-heading">
                                        <h3 className="panel-title" style={{ fontSize: '18px' }}>{Trans.data.deposit_information}</h3>
                                    </div>
                                    <div className="panel-body" style={{ padding: '25px' }}>
                                        <div className="row" style={{ marginBottom: '10px' }}>
                                            <div className="col-sm-6"><strong>{Trans.data.deposit_currency}</strong></div>
                                            <div className="col-sm-6" style={{ textAlign: 'right' }}>{currency}</div>
                                        </div>
                                        <div className="row" style={{ marginBottom: '10px' }}>
                                            <div className="col-sm-6"><strong>{Trans.data.total_deposit}</strong></div>
                                            <div className="col-sm-6" style={{ textAlign: 'right' }}>{Env.formatWithLabel(total,currency)}</div>
                                        </div>
                                        <div className="row" style={{ marginBottom: '10px' }}>
                                            <div className="col-sm-6"><strong>{Trans.data.used_deposit}</strong></div>
                                            <div className="col-sm-6" style={{ textAlign: 'right' }}>{Env.formatWithLabel(used,currency)}</div>
                                        </div>
                                        <hr />
                                        <div className="row" style={{ marginBottom: '10px' }}>
                                            <div className="col-sm-6"><strong>{Trans.data.balance_deposit}</strong></div>
                                            <div className="col-sm-6" style={{ textAlign: 'right' }}>{Env.formatWithLabel(balance,currency)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}