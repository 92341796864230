import React, { Component } from "react";

import { Layout } from "../../components";
import ProgressBooking from "../../modules/ProgressBooking";
import Trans from "../../locale/Translate.js";
import { browserHistory } from "react-router";

export default class VoucherSend extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {}
    };
  }

  componentDidMount() {
    this.setState({ user: this.props.location.query });
  }

  render() {
    return (
      <Layout>
        <div className="container">
          <section className="booking">
            <div className="row">
              <ProgressBooking value={4} />
            </div>
            <div className="row ">
              <div className="col-md-8 col-md-offset-2">
                <div className="notifSuccess alert alert-success" role="alert">
                  <i className="fa fa-check-circle  fa-5x box-icon"></i>
                  <h2 className="text-center">{Trans.data.your_payment_was_successfull}</h2>
                  <h5 className="text-center">
                    {localStorage.getItem("language") === "en-us"
                      ? `Booking details has been send to ${this.state.user.email}`
                      : localStorage.getItem("language") === "ja"
                      ? `${this.state.user.email}に予約詳細を送信しました`
                      : `Rincian pemesanan telah dikirim ke ${this.state.user.email}`}
                  </h5>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}
