import React, { Component } from 'react';
import { Header, Footer } from '../';

export default class Template extends Component {
	render() {
		return (
			<div>
				<Header />
				<div style={{ marginTop: '30px', marginBottom: '50px' }}>
					{this.props.children}
				</div>
				<Footer />
			</div>
		);
	}
}