import React, { Component } from 'react';

import { Layout } from '../../components'
import Trans from '../../locale/Translate.js'
import TopNav from '../../modules/TopNav'
import { Link } from 'react-router'
import Iframe from 'react-iframe'

import Additional from '../../assets/images/icon/benefit_room_small.png';
import MaimaiShutle from '../../assets/images/icon/maimai_shutle.jpg';
import MaimaiBeachwalk from '../../assets/images/icon/maimai_beachwalk.jpg';
import MaimaiUbud from '../../assets/images/icon/maimai_ubud.jpg';
import MaimaiAyana from '../../assets/images/icon/maimai_ayana.jpg';
import MaimaiKuta from '../../assets/images/icon/lippo_mall.jpg';
import MaimaiSeminyak from '../../assets/images/icon/maimai_seminyak.jpg';
import MaimaiLounge from '../../assets/images/icon/maimai_lounge.jpg';
import MaimaiLounge2 from '../../assets/images/icon/maimai_lounge2.jpg';

export default class AdditionalBenefit extends Component {
    render() {

        let language = localStorage.getItem('language');

        return (
            <Layout>
                <div className="jumbotron jumbotron-help" style={{ marginTop: '-30px' }}>
                    <div className="container text-center">
                        <h1>{Trans.data.additional_benefit}</h1>
                    </div>
                </div>

                <TopNav
                    url={this.props.location.pathname}
                />

                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-sm-10 col-md-offset-1">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="blog-content">

                                        <section id="room-benefit">
                                            <h3>{Trans.data.room_benefit}</h3>
                                            <p>
                                                {language === 'en-us' ?
                                                    (<div>Get various Additional Benefits for selected hotel booking on Hoterip, such as Fresh Welcome Drink upon check-in, Romantic Dinner, etc. These benefits will be marked by <img src={Additional} role="presentation" /> and only with one click, all the details will be displayed.</div>)
                                                    : language === 'ja' ?
                                                        'HIS Hotels（ホテリップ）からホテルを予約したら、ホテルに到着時に爽やかなウェルカムドリンクや無料空港～ホテル送迎や無料のマッサージやロマンチックなディナーなどホテルから嬉しい特典をお楽しみください。これからの嬉しい特典がマークにて表示され、ワンクリックで特典内容を確認することができます。'
                                                        :
                                                        (<div>Dapatkan beragam keuntungan tambahan saat pesan kamar hotel di Hoterip, seperti minuman selamat datang yang menyegarkan saat tiba di hotel, layanan antar jemput dari dan ke bandara atau pijat secara cuma-cuma, hingga makan malam romantis ala artis ternama. Keuntungan tambahan ini ditandai dengan <img src={Additional} role="presentation" /> dan semua detail akan terlihat dengan satu kali klik saja.</div>)
                                                }
                                            </p>
                                        </section>

                                        <section id="voucher-hoterip">
                                            <h3>{Trans.data.discount_with_hoterip}</h3>
                                            <p>
                                                {language === 'en-us' ?
                                                    'Hoterip.com is collaborated with souvenir shops, restaurants, theater shows, spas and other business partners.  Enjoy the discount up to 50% off by showing the MAIMAI CARD as additional discount coupon which available on every Hoterip Voucher.'
                                                    : language === 'ja' ? 'Hoterip（ホテリップ）はお土産屋さんやレストランや劇場やスパなど多くのお店と提携しています。Hoterip（ホテリップ）バウチャーに印字されるMAIMAIカードを提携しているお店に提示すると最大50%引きを受けることができます。提携するお店の一覧を見ます'
                                                        : 'Hoterip bekerja sama dengan toko oleh-oleh, restoran, pertunjukan teater, spa dan berbagai merchant seru lainnya. Nikmati diskon hingga 50% dengan hanya menunjukkan potongan MAIMAI CARD sebagai kupon diskon tambahan yang tertera pada voucher Hoterip di '
                                                }
                                                <span><Link to="/maimai-membershop">MaiMai Member Shop</Link></span>
                                            </p>
                                        </section>

                                        <section id="around-bali">
                                            <h3>{Trans.data.around_bali}</h3>
                                            <p>
                                                {language === 'en-us' ?
                                                    'Enjoy the tour around Bali by using our free shuttle service.  For further information in regards the tour itinerary, schedule and booking are only available at some of our branches as follow :'
                                                    : language === 'ja' ? 'バリ島で旅するお客様はシャトルバス乗り放題無料！シャトルの時刻表やルートや申し込み方法は下記のカスタマーサービスデスクまでお越しください。' : 'Nikmati layanan shuttle gratis yang kami sediakan khusus untuk Anda yang sedang berlibur di Bali. Untuk informasi rute perjalanan, jadwal, serta pemesanan hanya dapat dilakukan di kantor cabang kami antara lain :'
                                                }
                                            </p>

                                            <div className="row">
                                                <div className="col-xs-6 col-md-6">
                                                    <a href="#" className="thumbnail">
                                                        <img src={MaimaiShutle} role="presentation" />
                                                    </a>
                                                </div>
                                            </div>
                                        </section>

                                        <ul className="list-unstyled">
                                            <li>
                                                <dl>
                                                    <dt>MaiMai Tourist Information Beachwalk</dt>
                                                    <dd>{Trans.data.address}: Beachwalk Shopping Mall 3F AL-2,3,4,5 Jl. Pantai Kuta, Legian, Kuta</dd>
                                                    <dd>
                                                        <div className="row">
                                                            <div className="col-xs-6 col-md-6">
                                                                <a href="#" className="thumbnail">
                                                                    <img src={MaimaiBeachwalk} role="presentation" />
                                                                </a>
                                                            </div>
                                                            <div className="col-xs-6 col-md-6">
                                                                <Iframe url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15774.946991381948!2d115.1695527!3d-8.7165295!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xccb9747a29fe3f82!2sMai+Mai+Tour+Desk+Beachwalk!5e0!3m2!1sid!2sid!4v1517390686983"
                                                                    width="445"
                                                                    height="330"
                                                                    allowFullScreen
                                                                    frameBorder="0"
                                                                />
                                                            </div>
                                                        </div>
                                                    </dd>
                                                </dl>
                                            </li>
                                            <li>
                                                <dl>
                                                    <dt>MaiMai Tourist Information Ubud</dt>
                                                    <dd>{Trans.data.address}: Puri Kumuda Sari Ubud, Jl. Raya Ubud (Sebelah Timur Pasar Ubud)</dd>
                                                    <dd>
                                                        <div className="row">
                                                            <div className="col-xs-6 col-md-6">
                                                                <a href="#" className="thumbnail">
                                                                    <img src={MaimaiUbud} role="presentation" />
                                                                </a>
                                                            </div>
                                                            <div className="col-xs-6 col-md-6">
                                                                <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15783.656763708675!2d115.26413560000005!3d-8.507712600000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2926601978290970!2sH.I.S.+MaiMai+Ubud!5e0!3m2!1sid!2sid!4v1517391310357"
                                                                    width="445"
                                                                    height="330"
                                                                    allowFullScreen
                                                                    frameBorder="0"
                                                                />
                                                            </div>
                                                        </div>
                                                    </dd>
                                                </dl>
                                            </li>
                                            <li>
                                                <dl>
                                                    <dt>MaiMai Tourist Information Rimba by AYANA</dt>
                                                    <dd>{Trans.data.address}: Rimba Jimbaran Bali by Ayana, Karang Mas Estate, Jl. Karang Mas Sejahtera, Jimbaran</dd>
                                                    <dd>
                                                        <div className="row">
                                                            <div className="col-xs-6 col-md-6">
                                                                <a href="#" className="thumbnail">
                                                                    <img src={MaimaiAyana} role="presentation" />
                                                                </a>
                                                            </div>
                                                            <div className="col-xs-6 col-md-6">
                                                                <Iframe url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15771.695255944122!2d115.1394672!3d-8.7932283!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8c7bac6ba2659d50!2sMai+Mai+Tourist+Information+Rimba!5e0!3m2!1sid!2sid!4v1517391469309"
                                                                    width="445"
                                                                    height="330"
                                                                    allowFullScreen
                                                                    frameBorder="0"
                                                                />
                                                            </div>
                                                        </div>
                                                    </dd>
                                                </dl>
                                            </li>
                                            <li>
                                                <dl>
                                                    <dt>MaiMai Tourist Information Seminyak</dt>
                                                    <dd>{Trans.data.address}: Jl. Kayu Aya 156b, Seminyak</dd>
                                                    <dd>
                                                        <div className="row">
                                                            <div className="col-xs-6 col-md-6">
                                                                <a href="#" className="thumbnail">
                                                                    <img src={MaimaiSeminyak} role="presentation" />
                                                                </a>
                                                            </div>
                                                            <div className="col-xs-6 col-md-6">
                                                                <Iframe url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15776.286483754053!2d115.1568926!3d-8.6847394!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3d7960bd059b0096!2sMaiMai+Tourist+Information+Seminyak!5e0!3m2!1sid!2sid!4v1517391609796"
                                                                    width="445"
                                                                    height="330"
                                                                    allowFullScreen
                                                                    frameBorder="0"
                                                                />
                                                            </div>
                                                        </div>
                                                    </dd>
                                                </dl>
                                            </li>
                                            <li>
                                                <dl>
                                                    <dt>MaiMai Tour Desk Watermark</dt>
                                                    <dd>{Trans.data.address}: Watermark Hotel, Jl. Uluwatu, Kedongan, Jimbaran</dd>
                                                </dl>
                                            </li>
                                            <li>
                                                <dl>
                                                    <dt>MaiMai Tour Desk Lippo Mall</dt>
                                                    <dd>{Trans.data.address}: Lippo Mall, Jl. Kartika Plaza, Lingk. Segara, Kuta</dd>
                                                    <dd>
                                                        <div className="row">
                                                            <div className="col-xs-6 col-md-6">
                                                                <a href="#" className="thumbnail">
                                                                    <img src={MaimaiKuta} role="presentation" />
                                                                </a>
                                                            </div>
                                                            <div className="col-xs-6 col-md-6">
                                                                <Iframe url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15774.149550734297!2d115.1666581!3d-8.7354006!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x675abeb8e311a95a!2sMai+Mai!5e0!3m2!1sid!2sid!4v1517391667654"
                                                                    width="445"
                                                                    height="330"
                                                                    allowFullScreen
                                                                    frameBorder="0"
                                                                />
                                                            </div>
                                                        </div>
                                                    </dd>
                                                </dl>
                                            </li>
                                        </ul>
                                        <h3>
                                            {language === 'en-us' ?
                                                'Free MAIMAI Lounge Usage'
                                                : language === 'ja' ? 'MAIMAIラウンジ利用無料' : 'Gratis Penggunaan MAIMAI Lounge'
                                            }
                                        </h3>
                                        <p>
                                            {language === 'en-us' ?
                                                'Your hotel room is not ready yet? Or your flight is delay? Don’t worry! You are still can enjoy and spend your time at our MaiMai Lounge in Beachwalk Mall, Kuta, Bali even only just for relax with Free Wi-Fi available.'
                                                : language === 'ja' ? 'ホテルの部屋は準備ができていない、出発時間まで時間が余る？待っている間にリラックスして無料のWi-Fiサービス付きでおしゃれなラウンジをご利用いただけます。 ' : 'Kamar hotel Anda belum siap atau waktu keberangkatan anda masih lama? Manfaatkan fasilitas MaiMai Lounge yang kami sediakan untuk sekedar bersantai dan menikmati layanan Wi-Fi gratis saat anda menunggu.  Fasilitas ini hanya tersedia di MaiMai Tourist Information, Lantai 3, Beachwalk, Bali.'
                                            }

                                        </p>
                                        <div className="row">
                                            <div className="col-xs-6 col-md-6">
                                                <a href="#" className="thumbnail">
                                                    <img src={MaimaiLounge} role="presentation" />
                                                </a>
                                            </div>
                                            <div className="col-xs-6 col-md-6">
                                                <a href="#" className="thumbnail">
                                                    <img src={MaimaiLounge2} role="presentation" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}