import React, { Component } from 'react';
import Button from 'react-bootstrap-button-loader';
import * as Env from '../../Env'
import { connect } from 'react-redux';
import { register } from '../../actions/authActions';

// css react icheck
import 'icheck/skins/all.css';
import { Checkbox, Radio, RadioGroup } from 'react-icheck';


let axios = require('axios');

class RegisterForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: '',
            load_button: false,
            email: '',
            password: '',
            display_name: '',
            sex: '',
            position: '',
            company_name: '',
            iata_number: '',
            destination: 0,
            address: '',
            district_area: '',
            city: '',
            country: 0,
            home_phone: '',
            fax: '',
            mobile_phone: '',
            website: '',
            language_id: 1,
            currency_id: 3,
            title_id: 1,
            is_subscribe_newsletter: 0,
            timezone: 'Asia/Jakarta',
            deposit_currency: 3,
            isAgree: false,
            getNationalities: [],
            getNotif: '',
            getNotifType: 0,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeIsAgree = this.onChangeIsAgree.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });

        if (name === 'sex') {
            if (value === 'M') {
                this.setState({ title_id: 1 });
            }
            if (value === 'F') {
                this.setState({ title_id: 2 });
            }
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true, getNotif: '' });

        const { isAgree, email, password, display_name, sex, position, company_name, address, district_area, city, country, home_phone } = this.state

        const data = {
            email: this.state.email,
            password: this.state.password,
            display_name: this.state.display_name,
            sex: this.state.sex,
            position: this.state.position,
            company_name: this.state.company_name,
            iata_number: this.state.iata_number,
            destination: this.state.destination,
            address: this.state.address,
            district_area: this.state.district_area,
            city: this.state.city,
            country: this.state.country,
            home_phone: this.state.home_phone,
            fax: this.state.fax,
            mobile_phone: this.state.mobile_phone,
            website: this.state.website,
            language_id: 1,
            currency_id: 3,
            title_id: this.state.title_id,
            is_subscribe_newsletter: 0,
            timezone: 'Asia/Jakarta',
            deposit_currency: 3,
        };

        if (email && password && display_name && sex && position && company_name && address && district_area && city && country && home_phone && isAgree) {
            this.props.dispatch(register(data));
            this.setState({ load_button: true });
        }
    }

    onChangeIsAgree(event) {
        if (this.state.isAgree === false) {
            this.setState({ isAgree: true });
        }
        if (this.state.isAgree === true) {
            this.setState({ isAgree: false });
        }
    }

    fetchNationalities() {
        var that = this
        axios.get(Env.url.api_url + 'hotels/nationalities', {
            headers: { 'Accept-Language': localStorage.getItem('language'), 'Accept-Currency': localStorage.getItem('currency') }
        })
            .then(response => {
                if (response.data.meta.success) {
                    that.setState({ getNationalities: response.data.data })
                }
            })
            .catch(error => {
                // 
            });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ load_button: false });

        if (nextProps.dataAuth.isSuccess === false) {
            window.scrollTo(0, 0);
            this.setState({ getNotifType: 0, getNotif: nextProps.dataAuth.notification });
        }
        if (nextProps.dataAuth.isSuccess === true) {
            window.scrollTo(0, 0);
            this.setState({ getNotifType: 1, getNotif: nextProps.dataAuth.notification });
        }
    }

    componentDidMount() {
        this.fetchNationalities();
    }

    render() {
        const { getNotif, getNotifType, load_button, submitted, email, password, display_name, sex, position, company_name, iata_number, destination, address, district_area, city, country, home_phone, fax, mobile_phone, website, isAgree, getNationalities } = this.state

        return (
            <div>
                {getNotif ?
                    <div className={`alert ${getNotifType == 0 ? 'alert-danger' : 'alert-info'}`} role="alert">
                        <strong>{getNotifType == 0 ? 'Alert' : 'Thank you'}</strong> {getNotif}
                    </div>
                    : null}
                <form onSubmit={this.handleSubmit}>
                    <p style={{ width: '90px', borderBottom: '1px solid #23527c' }}>User detail</p>
                    <div className={`form-group row ${submitted && !email ? 'has-error' : ''}`}>
                        <label className="col-xs-2 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>User Email<span style={{ color: "#FF0000" }}>*</span></label>
                        <div className="col-xs-6">
                            <input value={email} onChange={this.handleChange} className="form-control" type="email" name="email" />
                            {submitted && !email ? <span className="help-block">User Email is required</span> : null}
                        </div>
                    </div>
                    <div className={`form-group row ${submitted && !password ? 'has-error' : ''}`}>
                        <label className="col-xs-2 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>Password<span style={{ color: "#FF0000" }}>*</span></label>
                        <div className="col-xs-6">
                            <input value={password} onChange={this.handleChange} className="form-control" type="password" name="password" />
                            {submitted && !password ? <span className="help-block">Password is required</span> : null}
                        </div>
                    </div>
                    <p style={{ width: '200px', borderBottom: '1px solid #23527c' }}>Travel Agent Information</p>
                    <div className={`form-group row ${submitted && !display_name ? 'has-error' : ''}`}>
                        <label className="col-xs-2 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>Contact Person Name<span style={{ color: "#FF0000" }}>*</span></label>
                        <div className="col-xs-4">
                            <input value={display_name} onChange={this.handleChange} className="form-control" type="text" name="display_name" />
                            {submitted && !display_name ? <span className="help-block">Contact Person Name is required</span> : null}
                        </div>
                        <label className="col-xs-1 col-form-label" style={{ textAlign: 'right', paddingRight: '0px', paddingLeft: "0px" }}>Sex<span style={{ color: "#FF0000" }}>*</span></label>
                        <div className="col-xs-3" style={{ paddingTop: "5px" }}>
                            <RadioGroup name="sex" onChange={this.handleChange}>
                                <Radio
                                    value="M"
                                    radioClass="iradio_square-blue"
                                    increaseArea="20%"
                                    label="&nbsp;&nbsp;Male&nbsp;&nbsp;"
                                />
                                <Radio
                                    value="F"
                                    radioClass="iradio_square-blue"
                                    increaseArea="20%"
                                    label="&nbsp;&nbsp;Female&nbsp;&nbsp;"
                                />
                            </RadioGroup>
                            {submitted && !sex ? <span className="help-block">Sex is required</span> : null}
                        </div>
                    </div>
                    <div className={`form-group row ${submitted && !position ? 'has-error' : ''}`}>
                        <label className="col-xs-2 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>Position<span style={{ color: "#FF0000" }}>*</span></label>
                        <div className="col-xs-6">
                            <input value={position} onChange={this.handleChange} className="form-control" type="text" name="position" />
                            {submitted && !position ? <span className="help-block">Position is required</span> : null}
                        </div>
                    </div>
                    <div className={`form-group row ${submitted && !company_name ? 'has-error' : ''}`}>
                        <label className="col-xs-2 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>Company Name<span style={{ color: "#FF0000" }}>*</span></label>
                        <div className="col-xs-6">
                            <input value={company_name} onChange={this.handleChange} className="form-control" type="text" name="company_name" />
                            {submitted && !company_name ? <span className="help-block">Company Name is required</span> : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xs-2 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>IATA/ASITA Number</label>
                        <div className="col-xs-6">
                            <input value={iata_number} onChange={this.handleChange} className="form-control" type="text" name="iata_number" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xs-2 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>Destination</label>
                        <div className="col-xs-6">
                            <select className="form-control" name="destination" onChange={this.handleChange}>
                                <option value="0">Please Select</option>
                                {getNationalities.map((result, i) =>
                                    <option value={result.id} key={i}>{result.name}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xs-2 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>Company Address<span style={{ color: "#FF0000" }}>*</span></label>
                        <div className={`col-xs-4 ${submitted && !address ? 'has-error' : ''}`}>
                            <input value={address} onChange={this.handleChange} className="form-control" type="text" name="address" />
                            {submitted && !address ? <span className="help-block">Company Address is required</span> : null}
                        </div>
                        <label className="col-xs-1 col-form-label" style={{ textAlign: 'right', paddingRight: '0px', paddingLeft: "0px" }}>District<span style={{ color: "#FF0000" }}>*</span></label>
                        <div className={`col-xs-3 ${submitted && !district_area ? 'has-error' : ''}`}>
                            <input value={district_area} onChange={this.handleChange} className="form-control" type="text" name="district_area" />
                            {submitted && !district_area ? <span className="help-block">District is required</span> : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xs-2 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>City<span style={{ color: "#FF0000" }}>*</span></label>
                        <div className={`col-xs-4 ${submitted && !city ? 'has-error' : ''}`}>
                            <input value={city} onChange={this.handleChange} className="form-control" type="text" name="city" />
                            {submitted && !city ? <span className="help-block">City is required</span> : null}
                        </div>
                        <label className="col-xs-1 col-form-label" style={{ textAlign: 'right', paddingRight: '0px', paddingLeft: "0px" }}>Country<span style={{ color: "#FF0000" }}>*</span></label>
                        <div className={`col-xs-3 ${submitted && !country ? 'has-error' : ''}`}>
                            <select className="form-control" name="country" onChange={this.handleChange}>
                                <option value="0">Please Select</option>
                                {getNationalities.map((result, i) =>
                                    <option value={result.id} key={i}>{result.name}</option>
                                )}
                            </select>
                            {submitted && !country ? <span className="help-block">Country is required</span> : null}
                        </div>
                    </div>
                    <div className={`form-group row ${submitted && !home_phone ? 'has-error' : ''}`}>
                        <label className="col-xs-2 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>Phone<span style={{ color: "#FF0000" }}>*</span></label>
                        <div className="col-xs-6">
                            <input value={home_phone} onChange={this.handleChange} className="form-control" type="text" name="home_phone" />
                            {submitted && !home_phone ? <span className="help-block">Phone is required</span> : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xs-2 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>Fax</label>
                        <div className="col-xs-6">
                            <input value={fax} onChange={this.handleChange} className="form-control" type="text" name="fax" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xs-2 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>Mobile</label>
                        <div className="col-xs-6">
                            <input value={mobile_phone} onChange={this.handleChange} className="form-control" type="text" name="mobile_phone" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xs-2 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>Website</label>
                        <div className="col-xs-6">
                            <input value={website} onChange={this.handleChange} className="form-control" type="text" name="website" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-xs-6 col-xs-offset-2">
                            <Checkbox
                                checkboxClass="icheckbox_square-blue"
                                increaseArea="20%"
                                label="&nbsp;&nbsp;&nbsp;&nbsp;Agreed With Terms and Conditions"
                                onChange={() => this.onChangeIsAgree()}
                            />
                            {submitted && !isAgree ? <span className="help-block" style={{ color: '#a94442' }}>Please Check to Agree With the Terms and Conditions</span> : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-xs-6 col-xs-offset-2">
                            <Button type="submit" className="btn btn-success btn-block btn-lg btn-login-modal" loading={load_button}>Register</Button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { dataAuth: state.authReducer }
}

export default connect(mapStateToProps)(RegisterForm)
