import indonesia from "./assets/images/indonesia.png";
import japan from "./assets/images/japan.png";
import english from "./assets/images/english.png";
import { browserHistory } from "react-router";

import formatMoney from "./modules/accounting-js/lib/formatMoney.js";

var api_url = "https://staging.hoterip.com/api/v1/b2b/";
// var api_url = "http://localhost:8000/api/v1/b2b/";
var api_static = "https://s3-ap-southeast-1.amazonaws.com/hoterip-s3/hoterip/";
var veritrans_url = "https://api.sandbox.veritrans.co.id/v2/token";
var veritrans_client_key = "VT-client-6_OKoRlUVMsppsQCzmIj1vgN";
var payment_request_2c2p = "https://demo2.2c2p.com/2C2PFrontEnd/RedirectV3/payment";
var dev = true;

if (process.env.NODE_ENV === "production") {
  api_url = "https://api.hoterip.com/api/v1/b2b/";
  api_static = "https://s3-ap-southeast-1.amazonaws.com/hoterip-s3/hoterip/";
  veritrans_url = "https://api.veritrans.co.id/v2/token";
  veritrans_client_key = "VT-client-i1vyCHt8t9ar7J6Y82SXJNfy";
  payment_request_2c2p = "https://payid.2c2p.com/RedirectV3/payment";
  dev = false;
}

var lang_header = english;
var lang_label = "ENG";
var currency_code = "$";
var currency_label = "USD";
var default_currency_code = "$";
var default_currency_label = "USD";

var min_range = 0;
var max_range = 5000000;

if (localStorage.getItem("language")) {
  switch (localStorage.getItem("language")) {
    case "en-us":
      lang_header = english;
      lang_label = "ENG";
      break;
    case "ja":
      lang_header = japan;
      lang_label = "JP";
      break;
    default:
      lang_header = indonesia;
      lang_label = "ID";
      break;
  }
}

if (localStorage.getItem('user')) {
  const userStorage = JSON.parse(localStorage.getItem('user'))
  if (userStorage.user.agent_currencies && userStorage.user.agent_currencies.length > 0) {
    const userCurrency = userStorage.user.agent_currencies[0].currency

    default_currency_code = userCurrency.symbol
    default_currency_label = userCurrency.transaction_code

    currency_code = userCurrency.symbol
    currency_label = userCurrency.transaction_code
  }
}

if (localStorage.getItem("currency")) {
  switch (localStorage.getItem("currency")) {
    case "USD":
      currency_code = "$";
      currency_label = "USD";
      max_range = 10000;
      break;
    case "JPY":
      currency_code = "¥";
      currency_label = "JPY";
      max_range = 100000;
      break;
    case "TWD":
      currency_code = "NT$";
      currency_label = "NT$";
      max_range = 100000;
      break;
    case "AUD":
      currency_code = "AU$";
      currency_label = "AU$";
      max_range = 100000;
      break;
    case "MYR":
      currency_code = "RM";
      currency_label = "RM";
      max_range = 100000;
      break;
    case "HKD":
      currency_code = "HKD";
      currency_label = "HKD";
      max_range = 100000;
      break;
    case "GBP":
      currency_code = "GBP";
      currency_label = "GBP";
      max_range = 100000;
      break;
    case "THB":
      currency_code = "THB";
      currency_label = "THB";
      max_range = 100000;
      break;
    case "SGD":
      currency_code = "SGD";
      currency_label = "SGD";
      max_range = 100000;
      break;
    case "VND":
      currency_code = "VND";
      currency_label = "VND";
      max_range = 100000;
      break;
    case "KRW":
      currency_code = "KRW";
      currency_label = "KRW";
      max_range = 100000;
      break;
    case "PHP":
      currency_code = "PHP";
      currency_label = "PHP";
      max_range = 100000;
      break;
    case "MOP":
      currency_code = "MOP";
      currency_label = "MOP";
      max_range = 100000;
      break;
    case "EUR":
      currency_code = "EUR";
      currency_label = "EUR";
      max_range = 100000;
      break;
    case "NZD":
      currency_code = "NZ$";
      currency_label = "NZ$";
      max_range = 100000;
      break;
    default:
      currency_code = "Rp";
      currency_label = "IDR";
      max_range = 100000000;
      break;
  }
}

export const url = {
  api_url,
  veritrans_url,
  veritrans_client_key,
  payment_request_2c2p
};

export const capitalizeFirst = string => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

export const substring = (str, limit) => {
  if (str) {
    if (str.length < limit) {
      return str;
    }

    return str.substring(0, limit) + "...";
  } else {
    return null;
  }
};

export const _default = {
  lang_header,
  currency_code,
  currency_label,
  default_currency_code,
  default_currency_label,
  min_range,
  max_range
};

export const expired = error => {
  if (error.response !== undefined) {
    if (error.response.status === 401) {
      localStorage.setItem("isExpired", "Your token expired please try login again.");

      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem('currency');
      localStorage.removeItem('language');
      localStorage.removeItem('passport');

      browserHistory.push("/login");
    }
  } else {
    localStorage.setItem("isExpired", "Your token expired please try login again.");

    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem('currency');
    localStorage.removeItem('language');
    localStorage.removeItem('passport');

    browserHistory.push("/login");
  }
};

export const isLoggedIn = () => {
  if (localStorage.getItem("token")) {
    return true;
  } else {
    return false;
  }
};

export const format = num => {
  return currency_label === "IDR"
    ? formatMoney(num, {
      symbol: currency_code + " ",
      precision: 0,
      thousand: ",",
      decimal: ","
    })
    : currency_label === "JPY"
      ? formatMoney(Math.round(num), {
        symbol: currency_code + " ",
        precision: 0,
        thousand: ",",
        decimal: "."
      })
      : currency_label === "THB"
        ? formatMoney(Math.round(num), {
          symbol: currency_code + " ",
          precision: 0,
          thousand: ",",
          decimal: "."
        })
        : formatMoney(num, {
          symbol: currency_code + " ",
          precision: 2,
          thousand: ",",
          decimal: "."
        });
};

export const formatCurrency = (num, currencyCode = "USD") => {

  let currencySymbol = '$';
  switch (currencyCode) {
    case "USD":
      currencySymbol = "$";
      break;
    case "IDR":
      currencySymbol = "Rp";
      break;
    case "JPY":
      currencySymbol = "¥";
      break;
    case "TWD":
      currencySymbol = "NT$";
      break;
    case "AUD":
      currencySymbol = "AU$";
      break;
    case "MYR":
      currencySymbol = "RM";
      break;
    case "HKD":
      currencySymbol = "HKD";
      break;
    case "GBP":
      currencySymbol = "GBP";
      break;
    case "THB":
      currencySymbol = "THB";
      break;
    case "SGD":
      currencySymbol = "SGD";
      break;
    case "VND":
      currencySymbol = "VND";
      break;
    case "KRW":
      currencySymbol = "KRW";
      break;
    case "PHP":
      currencySymbol = "PHP";
      break;
    case "MOP":
      currencySymbol = "MOP";
      break;
    case "EUR":
      currencySymbol = "EUR";
      break;
    case "NZD":
      currencySymbol = "NZ$";
      break;

    default:
      break;
  }

  return currencyCode === "IDR"
    ? formatMoney(num, {
      symbol: currencySymbol + " ",
      precision: 0,
      thousand: ",",
      decimal: ","
    })
    : currencyCode === "JPY"
      ? formatMoney(Math.round(num), {
        symbol: currencySymbol + " ",
        precision: 0,
        thousand: ",",
        decimal: "."
      })
      : currencyCode === "THB"
        ? formatMoney(Math.round(num), {
          symbol: currencySymbol + " ",
          precision: 0,
          thousand: ",",
          decimal: "."
        })
        : formatMoney(num, {
          symbol: currencySymbol + " ",
          precision: 2,
          thousand: ",",
          decimal: "."
        });
};

export const formatWithLabel = (num, label) => {
  return label === "IDR"
    ? formatMoney(num, {
      symbol: "Rp" + " ",
      precision: 0,
      thousand: ",",
      decimal: ","
    })
    : label === "JPY"
      ? formatMoney(Math.round(num), {
        symbol: "¥" + " ",
        precision: 0,
        thousand: ",",
        decimal: "."
      })
      : label === "THB"
        ? formatMoney(Math.round(num), {
          symbol: "THB" + " ",
          precision: 0,
          thousand: ",",
          decimal: "."
        })
        : formatMoney(num, {
          symbol: "$" + " ",
          precision: 2,
          thousand: ",",
          decimal: "."
        });
};
