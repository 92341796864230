import * as Env from '../Env';
var axios = require('axios');

const currencySuccess = (data) => {
	return {
		type: 'CURRENCY_SUCCESS',
		data
	}
}

const selectCurrency = (data) => {
	return {
		type: 'SELECT_CURRENCY',
		data
	}
}

export const currencyAction = () => {
	return (dispatch) => {
		axios.get(Env.url.api_url + 'hotels/currencies', {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('token'),
				'Accept': 'application/json'
			}
		})
			.then(response => {
				if (response.data.meta.success) {
					dispatch(currencySuccess(response.data.data))
				}
			})
			.catch(error => {
				// 
			});
	}
}

export const selectCurrencyAction = (currency) => {
	return (dispatch) => {
		localStorage.setItem("currency", currency);
		dispatch(selectCurrency(currency))
	};
}
