import * as Env from '../Env';
var axios = require('axios');
var Promise = require('promise');

export const setCurrencyByUser = (user) => {
	var currencyCode = "USD";
	if (user.agent_currencies && user.agent_currencies.length > 0) {
		const userCurrency = user.agent_currencies[0].currency
		currencyCode = userCurrency.transaction_code
	}

	localStorage.setItem("currency", currencyCode);
}

export const setToken = (token) => {
	localStorage.setItem('token', token);
}

export const getToken = () => localStorage.getItem('token');

export const setUser = (user) => {
	localStorage.setItem('user', JSON.stringify(user));
}

export const getUser = () => {
	const user = localStorage.getItem('user');
	return JSON.parse(user);
}

export const isLoggedIn = () => {
	return getToken();
}

const post = (endpoint, data) => {
	return axios({
		method: 'post',
		url: Env.url.api_url + endpoint,
		data: data,
		headers: { 'Accept': 'application/json' },
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

const postWithHeader = (endpoint, data) => {
	return axios({
		method: 'post',
		url: Env.url.api_url + endpoint,
		data: data,
		headers: { 'Accept': 'application/json', 'Authorization': 'Bearer ' + getToken() },
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			Env.expired(error)
		});
}

export const login = (user) => {
	return new Promise((resolve, reject) => {
		if (isLoggedIn()) {
			resolve(getUser());
		}
		return post('frontend/login', user)
			.then(response => {
				if (response.data.meta.success) {
					setToken(response.data.data.token);
					setUser(response.data.data);
					setCurrencyByUser(response.data.data.user)

					resolve(response.data.data);
				}
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const forgotPasswordRequest = (email) => {
	return new Promise((resolve, reject) => {
		return post('hotels/users/reset-password/request', email)
			.then(response => {
				if (response.data.meta.success) {
					resolve(response.data);
				}
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const resetPasswordGetToken = (data) => {
	return new Promise((resolve, reject) => {
		return post('hotels/users/reset-password/' + data.token, data)
			.then(response => {
				if (response.data.meta.success) {
					resolve(response.data);
				}
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const logout = () => {
	return new Promise((resolve, reject) => {
		try {
			localStorage.removeItem('token');
			localStorage.removeItem('user');
			localStorage.removeItem('currency');
			localStorage.removeItem('language');
			localStorage.removeItem('passport');
			resolve(true);
		} catch (error) {
			reject(error);
		}
	});
}

export const register = (data) => {
	return new Promise((resolve, reject) => {
		return post('hotels/users/register', data)
			.then(response => {
				if (response.data.meta.success) {
					resolve(response.data);
				}
			})
			.catch(error => {
				reject(error);
			})
	});
}

export const registerActivation = (token) => {
	return new Promise((resolve, reject) => {
		return post('hotels/users/activation/' + token)
			.then(response => {
				if (response.data.meta.success) {
					resolve(response.data);
				}
			})
			.catch(error => {
				reject(error);
			})
	});
}

export const updateProfile = (data) => {
	return new Promise((resolve, reject) => {
		return postWithHeader('hotels/users/profile/update', data)
			.then(response => {
				if (response.data.meta.success) {
					const newUser = {
						user: response.data.data,
						token: getToken()
					}
					setUser(newUser);
					resolve(newUser);
				}
			})
			.catch(error => {
				reject(error)
			})
	})
}