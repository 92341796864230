const room_detail = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_ROOM':
      return {
        query: action.query,
      }
    default:
      return state
  }
}

export default room_detail;