import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { forgotPasswordRequest } from '../../actions/authActions.js'
import Button from 'react-bootstrap-button-loader';

class ModalForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            submitted: false,
            getNotif: '',
            getNotifType: 0,
            load_button: false,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true, getNotif: '' });

        const data = {
            email: this.state.email,
        };

        if (data.email) {
            this.props.dispatch(forgotPasswordRequest(data));
            this.setState({ load_button: true });
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            load_button: false
        })

        if (nextProps.dataAuth.isSuccess === false) {
            this.setState({
                getNotifType: 0,
                getNotif: nextProps.dataAuth.notification
            });
        }
        if (nextProps.dataAuth.isSuccess === true) {
            this.setState({
                getNotifType: 1,
                getNotif: nextProps.dataAuth.notification
            });
        }
    }

    render() {
        let { email, submitted, getNotif, getNotifType, load_button } = this.state;

        return (
            <Modal
                show={this.props.openForgotPswd}
                onHide={this.props.closeForgotPswd}
                dialogClassName="modal-auth"
                backdrop='static'>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-lg">Forgot Password ?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {getNotif ?
                        <div className={`alert ${getNotifType == 0 ? 'alert-danger' : 'alert-info'}`} role="alert">
                            <strong>{getNotifType == 0 ? 'Alert' : 'Thank you'}</strong> {getNotif}
                        </div>
                        : null}
                    <form onSubmit={this.handleSubmit}>
                        <div className={`form-group ${submitted && !email ? 'has-error' : ''}`}>
                            <div className="input-group ">
                                <span className="input-group-addon"><i className="fa fa-envelope fa-fw"></i></span>
                                <input value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className="form-control input-group-custom input-lg" type="email" placeholder='Email Address' />
                            </div>
                            {submitted && !email ? <span className="help-block">Email Address is required</span> : null}
                        </div>
                        <Button type="submit" className="btn btn-success btn-block btn-lg btn-login-modal" loading={load_button}>Send to email</Button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <p style={{ fontSize: '15px' }}>Back to <a onClick={this.props.closeForgotPswd}>LOGIN</a></p>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return { dataAuth: state.authReducer }
}

export default connect(mapStateToProps)(ModalForgotPassword)