import React, { Component } from 'react'
import { Layout, ForgotPasswordForm } from '../../components'

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            token: this.props.params.token
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        const { token } = this.state

        return (
            <Layout>
                <ForgotPasswordForm token={token} />
            </Layout>
        )
    }
}