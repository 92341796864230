import React, { Component } from 'react';

import { Layout } from '../../components'
import Trans from '../../locale/Translate.js'
import TopNav from '../../modules/TopNav'

export default class GoodServices extends Component {
    render() {

        let language = localStorage.getItem('language');

        return (
            <Layout>
                <div className="jumbotron jumbotron-help" style={{ marginTop: '-30px' }}>
                    <div className="container text-center">
                        <h1>{Trans.data.best_service}</h1>
                    </div>
                </div>

                <TopNav
                    url={this.props.location.pathname}
                />

                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-sm-10 col-md-offset-1">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="blog-content">
                                        <p style={{ textIndent: '35px' }}>
                                            {language === 'en-us' ?
                                                'Hoterip is one of online division of PT. Harum Indah Sari Tours & Travel, which part of H.I.S Co.Ltd. Japan, which is based in Tokyo, Japan.'
                                                : language === 'ja' ? 'Hoterip（ホテリップ）はPT Harum Indah Sari Tours & Travelにて運営されます。Hoterip.com（ホテリップ）の予約カウンターやお客様サポートデスクはインドネシア大都市に多数存在し、お客様に安心サポートをお届けいたします。ホテルの予約や旅中にお困りことがありましたら、近くの支店までお越しください。'
                                                    : 'Hoterip merupakan salah satu divisi online yang berada sepenuhnya dibawah naungan PT. Harum Indah Sari Tours & Travel, yang merupakan bagian dari H.I.S Co.Ltd Japan, yang berpusat di Tokyo, Jepang.'
                                            }
                                        </p>
                                        <p>
                                            {language === 'en-us' ? 'As part of H.I.S International group with their motto is the “Highest International Standard”, we prioritize on our service by provide the best service to our customer therefore the customer satisfaction is number one.  These services are valid at all of our branch offices which spread out in some big cities in Indonesia.' : null}
                                            {language === 'id' ? 'Sebagai bagian dari grup H.I.S Internasional yang dengan semboyannya “Highest International Standard”, prioritas layanan kami adalah memberikan layanan yang terbaik kepada para pelanggan sehingga kepuasan pelanggan menjadi yang utama. Layanan ini berlaku di seluruh kantor-kantor cabang kami yang  tersebar di beberapa kota besar di Indonesia.' : null}
                                        </p>
                                        <p>
                                            {language === 'en-us' ? 'For further information, please check on the link below :' : null}
                                            {language === 'id' ? 'Untuk pemesanan dan layanan pelanggan, Silahkan hubungi kantor cabang terdekat kami.' : null}
                                        </p>
                                        <h3>Jakarta</h3>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS Travel Wonderland MidPlaza</strong><br />
                                                    Mid Plaza 1 Building, 1st BS Floor, <br />
                                                    Jl. Jend Sudirman Kav, 10-11 Jakarta 10220<br />
                                                    <abbr title="Telephone">T:</abbr> +62.21-579-52052
												</address>
                                            </div>
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS Travel Wonderland Dharmawangsa</strong><br />
                                                    Darmawangsa Square, Ground Floor Jl. Darmawangsa VI No.39, RT.5/RW.1, Pulo, Kby. Baru, <br />
                                                    Kota Jakarta Selatan, Jakarta 12160<br />
                                                    <abbr title="Telephone">T:</abbr> +62.21-7278 8371
												</address>
                                            </div>
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS FX Mall Sudirman</strong><br />
                                                    FX Mall F2 unit #15,16, <br />
                                                    Jl. Jenderal Sudirman, Pintu Satu Senayan, Jakarta 10270<br />
                                                    <abbr title="Telephone">T:</abbr> +62.21.2911 0901
												</address>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS Mall Taman Anggrek Branch</strong><br />
                                                    Mall Taman Anggrek<br />
                                                    Basement Level Lot F12, F12B<br />
                                                    Jl. Jend. S.Parman Kav.21, Jakarta 11470<br />
                                                    <abbr title="Telephone">T:</abbr> +62.21.5639551
												</address>
                                            </div>
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS Lippo Mall Puri</strong><br />
                                                    Lippo Mall Puri - Lantai LG-30<br />
                                                    Jl Puri Indah Raya, Blok U 1,<br />
                                                    Puri Indah, CBD - Jakarta Barat 11610<br />
                                                    <abbr title="Telephone">T:</abbr> +62.21-3049-3063
												</address>
                                            </div>
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS Lippo Mall - Kemang Branch</strong><br />
                                                    Lippo Mall Kemang Lantai INF-G1-01<br />
                                                    Kemang Village, 36 P. Antasari, Jakarta 12150<br />
                                                    <abbr title="Telephone">T:</abbr> +62.21.29528516
												</address>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS Gandaria City</strong><br />
                                                    Gandaria City Mall, lantai 2.<br />
                                                    Jl. Sultan Iskandar Muda, Jakarta Selatan 12240<br />
                                                    <abbr title="Telephone">T:</abbr> +62.21. 29236421
												</address>
                                            </div>
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS Lippo Mall Pluit Village Branch</strong><br />
                                                    Lippo Mall Pluit Village - Lantai 1, No Unit. 93<br />
                                                    Jl. Pluit Indah Raya, Jakarta (14450)<br />
                                                    <abbr title="Telephone">T:</abbr> +62.21 66603001
												</address>
                                            </div>
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>H.I.S. MALL OF INDONESIA BRANCH</strong><br />
                                                    Mall Of Indonesia - 2nd Floor, Unit 2F C11C<br />
                                                    Jl. Boulevard Bar. Raya, Klp. Gading Bar., Kelapa Gading, Kota Jakarta Utara, Daerah Khusus Ibukota Jakarta (14240)<br />
                                                    <abbr title="Telephone">T:</abbr> +62.21. 2245 8881
												</address>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS Bintaro Jaya Xchange Branch</strong><br />
                                                    Bintaro Jaya Xchange Mall - Lantai Basement, Blok BS1-B18.B<br />
                                                    Boulevard Bintaro Jaya Blok 0-2 Bintaro Jaya Sektor VII Tangerang 15227<br />
                                                    <abbr title="Telephone">T:</abbr> +62.21 22214171
												</address>
                                            </div>
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS Wisma BNI46</strong><br />
                                                    Wisma 46 - Lyman Wing 1st floor <br />
                                                    Kota BNI Jl. Jendral Sudirman Kav 1, Jakarta Pusat 10220<br />
                                                    <abbr title="Telephone">T:</abbr> +62.21 572 7561
												</address>
                                            </div>
                                        </div>

                                        <h3>TANGERANG</h3>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS AEON Mall BSD Branch</strong><br />
                                                    BSD Aeon Mall,<br />
                                                    Jl. BSD Raya Utama, Desa Pagedangan<br />
                                                    Kec. Pagedangan, Kabupaten Tanggerang 15345<br />
                                                    <abbr title="Telephone">T:</abbr> +62.21 291 68351
												</address>
                                            </div>
                                        </div>

                                        <h3>BEKASI</h3>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS MM2100 Branch</strong><br />
                                                    Kawasan industri MM2100<br />
                                                    Ruko Mal Bekasi Fajar, Blok A-18 (Lantai 1 - 3)<br />
                                                    Cikarang Barat-Bekasi 17842<br />
                                                    <abbr title="Telephone">T:</abbr> +62.21 899 84041
												</address>
                                            </div>
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS Cikarang Branch</strong><br />
                                                    EJIP Center 2nd Floor, EJIP Industrial Park Plot 3A <br />
                                                    Cikarang Selatan, Bekasi 17550 - INDONESIA<br />
                                                    <abbr title="Telephone">T:</abbr> +62.21 897 0427
												</address>
                                            </div>
                                        </div>

                                        <h3>BANDUNG</h3>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS Bandung Branch</strong><br />
                                                    Jl. Ir H Juanda No.81-A, <br />
                                                    Bandung, 40132 [NEW Office]<br />
                                                    <abbr title="Telephone">T:</abbr> +62.22.204 54100
												</address>
                                            </div>
                                        </div>

                                        <h3>SURABAYA</h3>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS Surabaya Branch</strong><br />
                                                    Jl. Darmo Permai Selatan No. 3, Papaya Fresh Gallery Lt.2,  <br />
                                                    Surabaya, Jawa Timur (60226)<br />
                                                    <abbr title="Telephone">T:</abbr> +62.31 732 4841 / +62.31 732 4842
												</address>
                                            </div>
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS Surabaya 2 Branch</strong><br />
                                                    Jl. Prof. Dr. Moestopo No 17<br />
                                                    Rt 01 / Rw 05 Kel. Pasar Keling<br />
                                                    Kec. Tambak Sari, Surabaya, 60131<br />
                                                    <abbr title="Telephone">T:</abbr> +62.31 505 7711
												</address>
                                            </div>
                                        </div>

                                        <h3>YOGYAKARTA</h3>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS Jogjakarta Branch</strong><br />
                                                    Ruko Saphir Square No. 5 <br />
                                                    Jl. Laksda Adisucipto 36 Jogjakarta - 55221 <br />
                                                    <abbr title="Telephone">T:</abbr> +62.274 554191
												</address>
                                            </div>
                                        </div>

                                        <h3>BALI</h3>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>PT. Harum Indah Sari Tours & Travel (H.I.S) – Kantor Pusat</strong><br />
                                                    Jl. By Pass Ngurah Rai No. 732,  <br />
                                                    Pesanggaran, Denpasar, Bali, Indonesia <br />
                                                    <abbr title="Telephone">T:</abbr> +62.361.721064, +62.361.728177 <br />
                                                    <abbr title="Mobile">M:</abbr> +62 81 1380 1919
												</address>
                                            </div>
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>HIS Bali Branch</strong><br />
                                                    Jl. By Pass Ngurah Rai No. 9E (Simpang Dewa Ruci),   <br />
                                                    Kuta-Denpasar, 80361 <br />
                                                    <abbr title="Telephone">T:</abbr> +62.361 849 6251 <br />
                                                </address>
                                            </div>
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>MaiMai Tourist Information </strong><br />
                                                    Beachwalk Shopping Center AL 2-5, <br />
                                                    Jl. Pantai Kuta No.1, Legian, Kuta, Kabupaten Badung, Bali <br />
                                                    <abbr title="Telephone">T:</abbr> +62.361.4727951 <br />
                                                </address>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>MaiMai Tourist Ubud</strong><br />
                                                    Puri Kumuda Sari Ubud,   <br />
                                                    Jl. raya Ubud (Sebelah Timur Pasar Ubud) <br />
                                                    <abbr title="Telephone">T:</abbr> +62.361 972621 <br />
                                                </address>
                                            </div>
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>MaiMai Tourist Seminyak</strong><br />
                                                    Jl. Kayu Aya No. 156b    <br />
                                                    Seminyak <br />
                                                </address>
                                            </div>
                                            <div className="col-sm-4">
                                                <address>
                                                    <strong>MaiMai Tourist RIMBA</strong><br />
                                                    Rimba Jimbaran by AYANA Resort, n<br />
                                                    Jl. Karang Mas Sejahtera, Jimbara <br />
                                                    <abbr title="Telephone">T:</abbr> +62.361.8468491<br />
                                                </address>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}