import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router'
import { logout } from '../../actions/authActions.js'
import Trans from '../../locale/Translate'

class MemberSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photo: '',
            display_name: ''
        }

        this._logout = this._logout.bind(this);
    }

    _logout() {
        let current_url = '/login';
        this.props.dispatch(logout(current_url));
    }

    componentDidMount() {
        if (this.props.isLoggedIn) {
            this.setState({
                photo: this.props.user.user.photo,
                display_name: this.props.user.user.display_name
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isLoggedIn) {
            this.setState({
                display_name: nextProps.user.user.display_name
            })
        }
    }

    render() {
        const { photo, display_name } = this.state

        return (
            <div className="col-md-3 col-sm-3">
                <div className="panel panel-default panelNav" style={{ border: '1px solid #ddd' }}>
                    <div className="panel-body">
                        <div className="member-img" style={{ padding: '25px' }}>
                            <img src={photo} className="img-circle img-responsive" /><br />
                            <h3>{display_name}</h3>
                        </div>
                        <ul className="nav nav-pills nav-stacked" style={{ display: 'block' }}>
                            <li role="presentation" className={this.props.value === 0 ? "active" : null}>
                                <Link to="/profile"><i className="fa fa-user"></i> {Trans.data.profile}</Link>
                            </li>
                            <li role="presentation" className={this.props.value === 1 ? "active" : null}>
                                <Link to="/deposit"><i className="fa fa-money"></i> {Trans.data.deposit}</Link>
                            </li>
                            <li role="presentation" className={this.props.value === 2 ? "active" : null}>
                                <Link to="/booking-history"><i className="fa fa-shopping-cart"></i> {Trans.data.my_bookings}</Link>
                            </li>
                            <li role="presentation">
                                <a style={{ cursor: 'pointer' }} onClick={this._logout}><i className="fa fa-sign-out"></i> {Trans.data.sign_out}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { isLoggedIn, user } = state.authReducer
    return {
        isLoggedIn,
        user
    }
}

export default connect(mapStateToProps)(MemberSidebar)