import React, { Component } from 'react'

import { Breadcrumb } from 'react-bootstrap';
import { Layout, MemberSidebar, ChangePassword } from '../../components'
import * as Env from '../../Env'
import Button from 'react-bootstrap-button-loader';
import { connect } from 'react-redux';
import { updateProfile } from '../../actions/authActions'
import Trans from '../../locale/Translate'

// css react icheck
import 'icheck/skins/all.css';
import { Radio, RadioGroup } from 'react-icheck';

let axios = require('axios');

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: '',
            load_button: false,
            id: '',
            email: '',
            password: '',
            display_name: '',
            sex: '',
            position: '',
            company_name: '',
            iata_number: '',
            destination: 0,
            address: '',
            district_area: '',
            city: '',
            country: 0,
            home_phone: '',
            fax: '',
            mobile_phone: '',
            website: '',
            language_id: 1,
            currency_id: 3,
            title_id: 1,
            is_subscribe_newsletter: 0,
            timezone: 'Asia/Jakarta',
            deposit_currency: 3,
            isAgree: false,
            getNationalities: [],
            getNotif: '',
            getNotifType: 0,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });

        if (name === 'sex') {
            if (value === 'M') {
                this.setState({ title_id: 1 });
            }
            if (value === 'F') {
                this.setState({ title_id: 2 });
            }
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true, getNotif: '' });

        const { id, email, display_name, sex, position, company_name, address, district_area, city, country, home_phone } = this.state

        const data = {
            title_id: this.state.title_id,
            sex: this.state.sex,
            display_name: this.state.display_name,
            position: this.state.position,
            avatar: null,
            company_name: this.state.company_name,
            iata_number: this.state.iata_number,
            destination: this.state.destination,
            address: this.state.address,
            district_area: this.state.district_area,
            city: this.state.city,
            country: this.state.country,
            home_phone: this.state.home_phone,
            fax: this.state.fax,
            mobile_phone: this.state.mobile_phone,
            website: this.state.website,
            is_subscribe_newsletter: this.state.is_subscribe_newsletter
        }

        if (email && display_name && sex && position && company_name && address && district_area && city && country && home_phone) {
            this.props.dispatch(updateProfile(data));
            this.setState({ load_button: true });
        }
    }

    fetchNationalities() {
        var that = this
        axios.get(Env.url.api_url + 'hotels/nationalities', {
            headers: { 'Accept-Language': localStorage.getItem('language'), 'Accept-Currency': localStorage.getItem('currency') }
        })
            .then(response => {
                if (response.data.meta.success) {
                    that.setState({ getNationalities: response.data.data })
                }
            })
            .catch(error => {
                // 
            });
    }

    componentDidMount() {
        this.fetchNationalities();
        this.setState({
            email: this.props.user.user.email,
            display_name: this.props.user.user.display_name,
            sex: this.props.user.user.sex,
            position: this.props.user.user.position,
            company_name: this.props.user.user.company_name,
            iata_number: this.props.user.user.iata_number,
            destination: this.props.user.user.destination,
            address: this.props.user.user.address,
            district_area: this.props.user.user.district_area,
            city: this.props.user.user.city,
            country: this.props.user.user.country,
            home_phone: this.props.user.user.home_phone,
            fax: this.props.user.user.fax,
            mobile_phone: this.props.user.user.mobile_phone,
            website: this.props.user.user.website,
            id: this.props.user.user.id,
            language_id: this.props.user.user.language_id,
            currency_id: this.props.user.user.currency_id,
            deposit_currency: this.props.user.user.deposit_currency,
            is_subscribe_newsletter: this.props.user.user.is_subscribe_newsletter,
            timezone: this.props.user.user.timezone
        })
        if (this.props.user.user.sex === "M") {
            this.setState({ title_id: 1 })
        } else {
            this.setState({ title_id: 2 })
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ load_button: false });

        if (nextProps.dataAuth.isSuccess === false) {
            window.scrollTo(0, 0);
            this.setState({ getNotifType: 0, getNotif: nextProps.dataAuth.notification });
        }
        if (nextProps.dataAuth.isSuccess === true) {
            window.scrollTo(0, 0);
            this.setState({ getNotifType: 1, getNotif: nextProps.dataAuth.notification });
        }
    }

    render() {
        const { getNotif, getNotifType, load_button, submitted, email, password, display_name, sex, position, company_name, iata_number, destination, address, district_area, city, country, home_phone, fax, mobile_phone, website, getNationalities, language_id, currency_id, timezone } = this.state

        return (
            <Layout>
                <div className="container">
                    <div className="row">
                        <div className="col-md-9" style={{ marginTop: '-30px' }}>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/">{Trans.data.home}</Breadcrumb.Item>
                                <Breadcrumb.Item href="/profile">{Trans.data.profile}</Breadcrumb.Item>
                            </Breadcrumb></div>
                    </div>
                </div>
                <div className="container">
                    <section className="member">
                        <div className="row">
                            {/* <MemberSidebar
                                value={0} /> */}
                            <div className="col-md-8 col-sm-8">
                                <div className="panel panel-default panel-personalinfo" style={{ border: '1px solid #ddd', borderRadius: '6px', overflow: 'hidden' }}>
                                    <div className="panel-heading">
                                        <h3 className="panel-title" style={{ fontSize: '18px' }}>{Trans.data.profile}</h3>
                                    </div>
                                    <div className="panel-body" style={{ padding: '25px' }}>
                                        {getNotif ?
                                            <div className={`alert ${getNotifType == 0 ? 'alert-danger' : 'alert-info'}`} role="alert">
                                                <strong>{getNotifType == 0 ? 'Alert' : 'Thank you'}</strong> {getNotif}
                                            </div>
                                            : null}
                                        <form onSubmit={this.handleSubmit}>
                                            <p style={{ width: '90px', borderBottom: '1px solid #23527c' }}>{Trans.data.user_detail}</p>
                                            <div className={`form-group row ${submitted && !email ? 'has-error' : ''}`}>
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>{Trans.data.email_address}<span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className="col-xs-9">
                                                    <input value={email} className="form-control" type="email" name="email" disabled />
                                                    {submitted && !email ? <span className="help-block">User Email is required</span> : null}
                                                </div>
                                            </div>
                                            {/* <div className="form-group row">
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>Language</label>
                                                <div className="col-xs-9">
                                                    <select value={language_id || ''} name="language_id" onChange={this.handleChange} className="form-control">
                                                        <option value="1">English</option>
                                                        <option value="2">日本語</option>
                                                        <option value="3">Bahasa Indonesia</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>Currency</label>
                                                <div className="col-xs-9">
                                                    <select value={currency_id || ''} name="currency_id" onChange={this.handleChange} className="form-control">
                                                        <option value="1">$ US Dollar</option>
                                                        <option value="2">¥ Japan Yen</option>
                                                        <option value="3">Rp Indonesia Rupiah</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>Timezone</label>
                                                <div className="col-xs-9">
                                                    <select value={timezone || ''} name="timezone" onChange={this.handleChange} className="form-control">
                                                        <option value="Pacific/Apia">(UTC-11:00) Samoa (Western)</option>
                                                        <option value="Pacific/Pago_Pago">(UTC-11:00) Midway Island, Samoa</option>
                                                        <option value="America/Adak">(UTC-10:00) Hawaii-Aleutian</option>
                                                        <option value="Pacific/Honolulu">(UTC-10:00) Hawaii</option>
                                                        <option value="Pacific/Marquesas">(UTC-09:30) Marquesas Islands</option>
                                                        <option value="Pacific/Gambier">(UTC-09:00) Gambier Islands</option>
                                                        <option value="America/Anchorage">(UTC-09:00) Alaska</option>
                                                        <option value="America/Santa_Isabel">(UTC-08:00) Baja California</option>
                                                        <option value="Pacific/Pitcairn">(UTC-08:00) Pitcairn Islands</option>
                                                        <option value="America/Los_Angeles">(UTC-08:00) Pacific Time (US &amp; Canada)</option>
                                                        <option value="America/Phoenix">(UTC-07:00) Arizona</option>
                                                        <option value="America/Mazatlan">(UTC-07:00) Chihuahua, La Paz, Mazatlan</option>
                                                        <option value="America/Denver">(UTC-07:00) Mountain Time (US &amp; Canada)</option>
                                                        <option value="America/Guatemala">(UTC-06:00) Central America, Saskatchewan, </option>
                                                        <option value="America/Chicago">(UTC-06:00) Central Time (US &amp; Canada)</option>
                                                        <option value="Pacific/Easter">(UTC-06:00) Easter Island</option>
                                                        <option value="America/Mexico_City">(UTC-06:00) Guadalajara, Mexico City, Monterrey</option>
                                                        <option value="America/New_York">(UTC-05:00) Eastern Time (US &amp; Canada)</option>
                                                        <option value="America/Havana">(UTC-05:00) Cuba</option>
                                                        <option value="America/Bogota">(UTC-05:00) Bogota, Lima, Quito, Rio Branco</option>
                                                        <option value="America/Caracas">(UTC-04:30) Caracas</option>
                                                        <option value="America/Goose_Bay">(UTC-04:00) Atlantic Time (Goose Bay)</option>
                                                        <option value="America/Halifax">(UTC-04:00) Atlantic Time (Canada)</option>
                                                        <option value="America/Campo_Grande">(UTC-04:00) Brazil</option>
                                                        <option value="Atlantic/Stanley">(UTC-04:00) Faukland Islands</option>
                                                        <option value="America/Santo_Domingo">(UTC-04:00) Georgetown, La Paz, Manaus, San Juan</option>
                                                        <option value="America/Santiago">(UTC-04:00) Santiago</option>
                                                        <option value="America/St_Johns">(UTC-03:30) Newfoundland</option>
                                                        <option value="America/Sao_Paulo">(UTC-03:00) Brasilia</option>
                                                        <option value="America/Argentina/Buenos_Aires">(UTC-03:00) Buenos Aires</option>
                                                        <option value="America/Godthab">(UTC-03:00) Greenland</option>
                                                        <option value="America/Montevideo">(UTC-03:00) Montevideo</option>
                                                        <option value="America/Miquelon">(UTC-03:00) Miquelon, St. Pierre</option>
                                                        <option value="America/Noronha">(UTC-02:00) Mid-Atlantic</option>
                                                        <option value="America/Araguaina">(UTC-02:00) UTC-2</option>
                                                        <option value="Atlantic/Azores">(UTC-01:00) Azores</option>
                                                        <option value="Atlantic/Cape_Verde">(UTC-01:00) Cape Verde Is.</option>
                                                        <option value="UTC">(UTC) UTC</option>
                                                        <option value="Europe/London">(UTC) Dublin, Edinburgh, Lisbon, London</option>
                                                        <option value="Africa/Abidjan">(UTC) Monrovia, Reykjavik</option>
                                                        <option value="Africa/Casablanca">(UTC) Casablanca</option>
                                                        <option value="Europe/Berlin">(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockho</option>
                                                        <option value="Europe/Belgrade">(UTC+01:00) Belgrade, Bratislava, Budapest, Ljublj</option>
                                                        <option value="Europe/Brussels">(UTC+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                                                        <option value="Europe/Sarajevo">(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                                                        <option value="Africa/Lagos">(UTC+01:00) West Central Africa</option>
                                                        <option value="Africa/Windhoek">(UTC+01:00) Windhoek</option>
                                                        <option value="Europe/Istanbul">(UTC+02:00) Athens, Bucharest, Istanbul</option>
                                                        <option value="Asia/Beirut">(UTC+02:00) Beirut</option>
                                                        <option value="Africa/Cairo">(UTC+02:00) Cairo</option>
                                                        <option value="Asia/Gaza">(UTC+02:00) Gaza</option>
                                                        <option value="Africa/Johannesburg">(UTC+02:00) Harare, Johannesburg, Pretoria</option>
                                                        <option value="Asia/Jerusalem">(UTC+02:00) Jerusalem</option>
                                                        <option value="Europe/Minsk">(UTC+02:00) Minsk</option>
                                                        <option value="Asia/Damascus">(UTC+02:00) Damascus</option>
                                                        <option value="Africa/Baghdad">(UTC+03:00) Baghdad, Kuwait, Riyadh, Nairobi</option>
                                                        <option value="Europe/Moscow">(UTC+03:00) Moscow, St. Petersburg, Volgograd</option>
                                                        <option value="Asia/Tehran">(UTC+03:30) Tehran</option>
                                                        <option value="Asia/Dubai">(UTC+04:00) Abu Dhabi, Muscat</option>
                                                        <option value="Asia/Baku">(UTC+04:00) Baku</option>
                                                        <option value="Asia/Yerevan">(UTC+04:00) Yerevan</option>
                                                        <option value="Asia/Kabul">(UTC+04:30) Kabul</option>
                                                        <option value="Asia/Yekaterinburg">(UTC+05:00) Ekaterinburg</option>
                                                        <option value="Asia/Karachi">(UTC+05:00) Islamabad, Karachi, Tashkent</option>
                                                        <option value="Asia/Kolkata">(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                                                        <option value="Asia/Kathmandu">(UTC+05:45) Kathmandu</option>
                                                        <option value="Asia/Dhaka">(UTC+06:00) Astana, Dhaka</option>
                                                        <option value="Asia/Omsk">(UTC+06:00) Omsk, Novosibirsk</option>
                                                        <option value="Asia/Rangoon">(UTC+06:30) Yangon (Rangoon)</option>
                                                        <option value="Asia/Jakarta">(UTC+07:00) Bangkok, Hanoi, Jakarta</option>
                                                        <option value="Asia/Krasnoyarsk">(UTC+07:00) Krasnoyarsk</option>
                                                        <option value="Asia/Shanghai">(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                                                        <option value="Asia/Irkutsk">(UTC+08:00) Irkutsk, Ulaan Bataar</option>
                                                        <option value="Australia/Perth">(UTC+08:00) Perth</option>
                                                        <option value="Asia/Kuala_Lumpur">(UTC+08:00) Singapore, Kuala Lumpur</option>
                                                        <option value="Australia/Eucla">(UTC+08:45) Eucla</option>
                                                        <option value="Asia/Tokyo">(UTC+09:00) Osaka, Sapporo, Tokyo</option>
                                                        <option value="Asia/Seoul">(UTC+09:00) Seoul</option>
                                                        <option value="Asia/Yakutsk">(UTC+09:00) Yakutsk</option>
                                                        <option value="Australia/Adelaide">(UTC+09:30) Adelaide</option>
                                                        <option value="Australia/Darwin">(UTC+09:30) Darwin</option>
                                                        <option value="Australia/Brisbane">(UTC+10:00) Brisbane</option>
                                                        <option value="Australia/Sydney">(UTC+10:00) Canberra, Melbourne, Sydney</option>
                                                        <option value="Australia/Hobart">(UTC+10:00) Hobart</option>
                                                        <option value="Asia/Vladivostok">(UTC+10:00) Vladivostok</option>
                                                        <option value="Australia/Lord_Howe">(UTC+10:30) Lord Howe Island</option>
                                                        <option value="Pacific/Noumea">(UTC+11:00) Solomon Is., New Caledonia</option>
                                                        <option value="Asia/Kamchatka">(UTC+11:00) Kamchatka, Magadan</option>
                                                        <option value="Pacific/Norfolk">(UTC+11:30) Norfolk Island</option>
                                                        <option value="Pacific/Tarawa">(UTC+12:00) Kiribati</option>
                                                        <option value="Pacific/Auckland">(UTC+12:00) Auckland, Wellington</option>
                                                        <option value="Pacific/Fiji">(UTC+12:00) Fiji</option>
                                                        <option value="Pacific/Chatham">(UTC+12:45) Chatham Islands</option>
                                                        <option value="Pacific/Tongatapu">(UTC+13:00) Nukualofa</option>
                                                        <option value="Pacific/Kiritimati">(UTC+14:00) Kiritimati</option>
                                                    </select>
                                                </div>
                                            </div> */}
                                            <p style={{ width: '200px', borderBottom: '1px solid #23527c' }}>{Trans.data.travel_agent_information}</p>
                                            <div className={`form-group row ${submitted && !display_name ? 'has-error' : ''}`}>
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>{Trans.data.contact_person_name}<span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className="col-xs-9">
                                                    <input value={display_name} onChange={this.handleChange} className="form-control" type="text" name="display_name" />
                                                    {submitted && !display_name ? <span className="help-block">Contact Person Name is required</span> : null}
                                                </div>
                                            </div>
                                            <div className={`form-group row ${submitted && !sex ? 'has-error' : ''}`}>
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>{Trans.data.sex}<span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className="col-xs-9" style={{ paddingTop: "5px" }}>
                                                    <RadioGroup name="sex" onChange={this.handleChange} value={sex}>
                                                        <Radio
                                                            value="M"
                                                            radioClass="iradio_square-blue"
                                                            increaseArea="20%"
                                                            label={'&nbsp;&nbsp;'+Trans.data.male+'&nbsp;&nbsp;'}
                                                        />
                                                        <Radio
                                                            value="F"
                                                            radioClass="iradio_square-blue"
                                                            increaseArea="20%"
                                                            label={'&nbsp;&nbsp;'+Trans.data.female+'&nbsp;&nbsp;'}
                                                        />
                                                    </RadioGroup>
                                                    {submitted && !sex ? <span className="help-block">Sex is required</span> : null}
                                                </div>
                                            </div>
                                            <div className={`form-group row ${submitted && !position ? 'has-error' : ''}`}>
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>{Trans.data.position}<span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className="col-xs-9">
                                                    <input value={position} onChange={this.handleChange} className="form-control" type="text" name="position" />
                                                    {submitted && !position ? <span className="help-block">Position is required</span> : null}
                                                </div>
                                            </div>
                                            <div className={`form-group row ${submitted && !company_name ? 'has-error' : ''}`}>
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>{Trans.data.company_name}<span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className="col-xs-9">
                                                    <input value={company_name} onChange={this.handleChange} className="form-control" type="text" name="company_name" />
                                                    {submitted && !company_name ? <span className="help-block">Company Name is required</span> : null}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>{Trans.data.iata_asita_number}</label>
                                                <div className="col-xs-9">
                                                    <input value={iata_number} onChange={this.handleChange} className="form-control" type="text" name="iata_number" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>{Trans.data.destination}</label>
                                                <div className="col-xs-9">
                                                    <select value={destination || ''} className="form-control" name="destination" onChange={this.handleChange}>>
                                                        <option value="0">{Trans.data.please_select}</option>
                                                        {getNationalities.map((result, i) =>
                                                            <option value={result.id} key={i}>{result.name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>{Trans.data.company_address}<span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className={`col-xs-9 ${submitted && !address ? 'has-error' : ''}`}>
                                                    <input value={address} onChange={this.handleChange} className="form-control" type="text" name="address" />
                                                    {submitted && !address ? <span className="help-block">Company Address is required</span> : null}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px', paddingLeft: "0px" }}>{Trans.data.district}<span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className={`col-xs-9 ${submitted && !district_area ? 'has-error' : ''}`}>
                                                    <input value={district_area} onChange={this.handleChange} className="form-control" type="text" name="district_area" />
                                                    {submitted && !district_area ? <span className="help-block">District is required</span> : null}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>{Trans.data.city}<span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className={`col-xs-9 ${submitted && !city ? 'has-error' : ''}`}>
                                                    <input value={city} onChange={this.handleChange} className="form-control" type="text" name="city" />
                                                    {submitted && !city ? <span className="help-block">City is required</span> : null}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px', paddingLeft: "0px" }}>{Trans.data.country}<span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className={`col-xs-9 ${submitted && !country ? 'has-error' : ''}`}>
                                                    <select value={country || ''} className="form-control" name="country" onChange={this.handleChange}>
                                                        <option value="0">Please Select</option>
                                                        {getNationalities.map((result, i) =>
                                                            <option value={result.id} key={i}>{result.name}</option>
                                                        )}
                                                    </select>
                                                    {submitted && !country ? <span className="help-block">Country is required</span> : null}
                                                </div>
                                            </div>
                                            <div className={`form-group row ${submitted && !home_phone ? 'has-error' : ''}`}>
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>{Trans.data.phone_number}<span style={{ color: "#FF0000" }}>*</span></label>
                                                <div className="col-xs-9">
                                                    <input value={home_phone} onChange={this.handleChange} className="form-control" type="text" name="home_phone" />
                                                    {submitted && !home_phone ? <span className="help-block">Phone is required</span> : null}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>{Trans.data.fax}</label>
                                                <div className="col-xs-9">
                                                    <input value={fax} onChange={this.handleChange} className="form-control" type="text" name="fax" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>{Trans.data.mobile_phone}</label>
                                                <div className="col-xs-9">
                                                    <input value={mobile_phone} onChange={this.handleChange} className="form-control" type="text" name="mobile_phone" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xs-3 col-form-label" style={{ textAlign: 'right', paddingRight: '0px' }}>{Trans.data.website}</label>
                                                <div className="col-xs-9">
                                                    <input value={website} onChange={this.handleChange} className="form-control" type="text" name="website" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-xs-9 col-xs-offset-3">
                                                    <Button type="submit" className="btn btn-success btn-block btn-lg btn-login-modal" style={{backgroundColor: '#f80', border: 'none' }} loading={load_button}>{Trans.data.update}</Button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <ChangePassword />
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    const { user } = state.authReducer
    return {
        user,
        dataAuth: state.authReducer
    }
}

export default connect(mapStateToProps)(Profile)
