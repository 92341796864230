import React, { Component } from "react";
import { Layout, RecomendedHotel, LastBookedHotel, StaticBanner, SearchHotelBoxInput } from "../../components";
import { Container, Row, Col } from "reactstrap";
import Trans from "../../locale/Translate";
import * as Env from "../../Env";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      check_in_count: "",
      check_out_count: "",
      stay_over_count: "",
      confirmed_today_count: "",
      cancelled_free_today_count: "",
      cancelled_charge_today_count: "",
      searchInput: ""
    };
  }

  componentDidMount() {
    axios
      .get(Env.url.api_url + "hotels/users/todays-booking", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Accept-Language": localStorage.getItem("language"),
          "Accept-Currency": localStorage.getItem("currency")
        }
      })
      .then(response => {
        if (response.data.meta.success) {
          this.setState({
            data: response.data.data.todays_booking.data,
            check_in_count: response.data.data.check_in_today,
            check_out_count: response.data.data.check_out_today,
            stay_over_count: response.data.data.stay_over_today,
            confirmed_today_count: response.data.data.confirmed_today,
            cancelled_charge_today_count: response.data.data.cancelled_charged,
            cancelled_free_today_count: response.data.data.cancelled_free
          });
        }
      })
      .catch(error => {});
  }

  handleSearchInputChange = event => {
    this.setState({ searchInput: event.target.value });
  };

  render() {
    const {
      searchInput,
      data,
      check_in_count,
      check_out_count,
      stay_over_count,
      confirmed_today_count,
      cancelled_free_today_count,
      cancelled_charge_today_count
    } = this.state;

    const filteredData = data
      ? data.filter(item =>
          item.booking_code
            .toString()
            .toLowerCase()
            .trim()
            .includes(searchInput.toLocaleLowerCase())
        )
      : null;

    const dataTodays = filteredData
      ? filteredData.map((data, index) => (
          <tr key={index}>
            <td>
              <Link
                to={{
                  pathname: "/booking-detail",
                  query: { id: data.booking_code ? String(data.booking_code).replace(/\D/g, "") : data.booking_code }
                }}
              >
                {data.booking_code}
              </Link>
            </td>
            <td>{data.is_canceled === 1 ? Trans.data.cancelled : Trans.data.confirmed}</td>
            <td>{data.guest_fullname}</td>
            <td>{data.check_in_date}</td>
            <td>{data.check_out_date}</td>
            <td>{data.number_of_rooms}</td>
            <td>{moment(data.booking_date).format("YYYY-MM-DD")}</td>
            <td>
              {Env.formatCurrency(
                data.user_ouccupancies_and_prices.amount_paid_by_guest_transaction,
                data.user_ouccupancies_and_prices.currency
              )}
            </td>
          </tr>
        ))
      : null;

    return (
      <Layout>
        <Row style={{ marginTop: "-30px" }}>
          <Col>
            <SearchHotelBoxInput typeSearch={2} />
          </Col>
        </Row>

        <section className="best-deal" style={{ backgroundColor: "#FFF" }}>
          <div className="container">
            <div className="col-md-12 col-sm-12">
              <h3>{Trans.data.why_choose_hoterip_com}</h3>
            </div>
          </div>
          <Container>
            <StaticBanner />
            <h3>My Today's Bookings</h3>
            <div className="row" style={{ marginRight: "45px", marginLeft: "45px" }}>
              <div className="col-md-6 col-sm-6">
                <div className="card-mytoday-booking">
                  <div className="card-body">
                    <div className="row">
                      <div
                        className="col-4 border-right"
                        style={{ borderRight: "2px solid #292828", paddingRight: "10px" }}
                      >
                        <h5>Check In Today</h5>
                        <h1>{check_in_count ? check_in_count : 0}</h1>
                      </div>
                      <div
                        className="col-4 border-right"
                        style={{ borderRight: "2px solid #292828", paddingRight: "10px" }}
                      >
                        <h5>Check Out Today</h5>
                        <h1>{check_out_count ? check_out_count : 0}</h1>
                      </div>
                      <div className="col-4">
                        <h5>Stay Over</h5>
                        <h1>{stay_over_count ? stay_over_count : 0}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6">
                <div className="card-mytoday-booking">
                  <div className="card-body">
                    <div className="row">
                      <div
                        className="col-4 border-right"
                        style={{ borderRight: "2px solid #292828", paddingRight: "10px" }}
                      >
                        <h5>Cancelled Charged</h5>
                        <h1>{cancelled_charge_today_count ? cancelled_charge_today_count : 0}</h1>
                      </div>
                      <div
                        className="col-4 border-right"
                        style={{ borderRight: "2px solid #292828", paddingRight: "10px" }}
                      >
                        <h5>Cancelled Free</h5>
                        <h1>{cancelled_free_today_count ? cancelled_free_today_count : 0}</h1>
                      </div>
                      <div className="col-4">
                        <h5>Confirmed</h5>
                        <h1>{confirmed_today_count ? confirmed_today_count : 0}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="lastbooking">
              <div className="card-lastbooking">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="search-today-bo">
                      <TextField
                        id="standard-basic"
                        style={{ width: "100%", paddingTop: "10px" }}
                        value={searchInput}
                        onChange={this.handleSearchInputChange}
                      />
                      <button
                        className="btn btn-sm btn-primary"
                        style={{ color: "rgb(255, 255, 255)", backgroundColor: "rgb(18, 64, 124)", marginLeft: "10px" }}
                        type="button"
                      >
                        Search
                      </button>
                    </div>
                  </div>

                  <div className="col-md-6"></div>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped custom-table">
                    <thead style={{ backgroundColor: "#E6E7E8" }}>
                      <tr>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Booking ID
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Status
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Guest Name
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Check In
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Check Out
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Total Rooms
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Booking Date
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Total Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataTodays && dataTodays.length ? (
                        dataTodays
                      ) : (
                        <tr>
                          <td colSpan={8} className="text-center">
                            <p style={{ textAlign: "center" }}>There is no data</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <LastBookedHotel />
      </Layout>
    );
  }
}
