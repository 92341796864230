import en from './En'
import id from './Id';
import ja from './Ja';

var data = en
if(localStorage.getItem('language')) {
	switch(localStorage.getItem('language')){
		case "en-us":
			data = en;
		break;
		case "ja":
			data = ja;
		break;
		default:
			data = id;
		break;
	}
}

export default data