import React, { Component } from 'react';

import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import * as Env from '../../Env'
import { Radio } from 'react-icheck';
import Trans from '../../locale/Translate.js';

import { updateCheapCampaign } from '../../actions/cheap_campaign.js';
import { updateCampaign } from '../../actions/campaign.js';
import moment from 'moment';
import { RoomPromotions } from '../../components'

import room_icon from '../../assets/images/icon_room/hotel.png';
import bathub_icon from '../../assets/images/icon_room/bathtub.png';
import balcony_icon from '../../assets/images/icon_room/balcony.png';
import bed_icon from '../../assets/images/icon_room/bed.png';
import minibar_icon from '../../assets/images/icon_room/minibar.png';
import jacuzzi_icon from '../../assets/images/icon_room/jacuzzi.png';
import dryer_icon from '../../assets/images/icon_room/dryer.png';
import safety_box_icon from '../../assets/images/icon_room/safe_box.png';
import imgOnLoading from '../../assets/images/imgOnLoading_large.jpg'

var axios = require('axios');
var Qs = require('qs');

class AvailableRoom extends Component {
	constructor(props) {
		super(props);

		this.state = {
			available_lists: null,
			loaded: false,
			content_loading: [0, 1, 2],
			hotel_id: "",
			data: {
				check_in: "",
				check_out: "",
				number_of_night: 1,
				room: 1,
			},
			segment_market_id: 3,
			pax_passport: 98,
			capacities: [],
			selected_check_in: "",
			selected_check_out: "",
			selected_number_of_night: 1,
			selected_room: 1,
			selected_capacities: [],
		}

		this.navigate = this.navigate.bind(this)
	}

	renderList() {
		if (!this.state.loaded) {
			return (
				this.state.content_loading.map((result, i) =>
					<div key={i} style={{ paddingTop: '10px', paddingBottom: "10px" }}>
						<div className="row">
							<div className="col-sm-3 " >
								<div className="animated-background facebook" style={{ height: 140 }}>
								</div>
							</div>
							<div className="col-sm-5">
								<div className="content-list-hotel">
									<div className="animated-background facebook line3" />
									<div className="animated-background facebook line1" />
									<div className="animated-background facebook line2" />
									<div className="animated-background facebook line1" />
								</div>
							</div>
							<div className="col-sm-4">
								<div className="price-tag">
									<div className="animated-background facebook line1" />
									<div className="animated-background facebook line2" />
									<div className="animated-background facebook line2" style={{ height: 30 }} />
								</div>
							</div>
						</div>
					</div>
				)
			)
		}

		if (this.state.loaded && !this.state.available_lists.rooms.length) {
			return (<h3>{Trans.data.no_room}</h3>)
		}
		const sorting = this.state.available_lists.rooms.sort((a, b) => (a.lowest_price) - (b.lowest_price));
		sorting.sort((a, b) => (a.lowest_price) - (b.lowest_price))
		return (
			sorting.map((result, i) => {
				return (
					<div className="list-perhotel">
						<div className="row">
							<div className="col-sm-12"><h4>{result.name}</h4></div>
						</div>
						<div className="row">
							<div className="col-sm-2">
								<img src={result.photo ? result.photo.basename_url : imgOnLoading}
									className="img-responsive"
									alt={result.photo ? result.photo.basename : 'image not available'}
								/>
								<a className="more-facility" data-toggle="collapse" href={`#colaps-${result.id}`} aria-expanded="false">{Trans.data.show_room_facility}</a>
								<ul className="list-facility">
									<li><img src={room_icon} alt="room icon" /> {Trans.data.room_size} {result.size}m²</li>
									{result.number_of_rooms ? null : <li><img src={bed_icon} alt="room icon" /> {Trans.data.bedrooms} {result.number_of_beds}</li>}
									{result.facility.is_shampoo_available ? <li><img src={bathub_icon} alt="room icon" /> {Trans.data.bathub}</li> : null}
									{result.facility.is_balcony_available ? <li><img src={balcony_icon} alt="room icon" /> {Trans.data.balcony}</li> : null}
									{result.facility.is_mini_bar_available ? <li><img src={minibar_icon} alt="room icon" /> {Trans.data.mini_bar}</li> : null}
									{result.facility.is_jacuzzi_available ? <li><img src={jacuzzi_icon} alt="room icon" /> {Trans.data.jacuzzi}</li> : null}
									{result.facility.is_hair_dryer_available ? <li><img src={dryer_icon} alt="room icon" /> {Trans.data.hair_dryer}</li> : null}
									{result.facility.is_safety_box_available ? <li><img src={safety_box_icon} alt="room icon" /> {Trans.data.safety_box}</li> : null}
								</ul>
							</div>
							<div className="col-sm-10">
								<RoomPromotions
									result={result}
									onNavigate={this.navigate}
								/>
							</div>
						</div>
						<div id={`colaps-${result.id}`} className="collapse">
							<div className="row">
								<div className="col-sm-12">
									<span className="title">{Trans.data.room_facility}</span>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-4">
									{result.facility.is_air_conditioner_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.air_conditioner}`}
										/>
										: null}
									{result.facility.is_bathrobe_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.bathrobe}`}
										/>
										: null}
									{result.facility.is_body_shampoo_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.body_shampoo}`}
										/>
										: null}
									{result.facility.is_cable_tv_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.cable_tv}`}
										/>
										: null}
									{result.facility.is_complimentary_water_bottle_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.complimentary_water_bottle}`}
										/>
										: null}
									{result.facility.is_dvd_player_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.dvd_player}`}
										/>
										: null}
									{result.facility.is_electric_pot_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.electric_pot}`}
										/>
										: null}
									{result.facility.is_independent_shower_room_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.independent_shower_room}`}
										/>
										: null}
									{result.facility.is_iron_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.iron}`}
										/>
										: null}
									{result.facility.is_living_room_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.living_room}`}
										/>
										: null}
									{result.facility.is_moveable_shower_head_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.moveable_shower_head}`}
										/>
										: null}
									{result.facility.is_shampoo_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.shampoo}`}
										/>
										: null}
									{result.facility.is_toothbrush_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.toothbrush}`}
										/>
										: null}
								</div>
								<div className="col-sm-4">
									{result.facility.is_alarm_clock_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.alarm_clock}`}
										/>
										: null}
									{result.facility.is_bathtub_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.bathub}`}
										/>
										: null}
									{result.facility.is_coffee_maker_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.coffe_maker}`}
										/>
										: null}
									{result.facility.is_fan_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.fan}`}
										/>
										: null}
									{result.facility.is_hair_dryer_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.hair_dryer}`}
										/>
										: null}
									{result.facility.is_safety_box_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.safety_box}`}
										/>
										: null}
									{result.facility.is_jacuzzi_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.jacuzzi}`}
										/>
										: null}
									{result.facility.is_mini_bar_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.mini_bar}`}
										/>
										: null}
									{result.facility.is_radio_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.radio}`}
										/>
										: null}
									{result.facility.is_shaver_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.shaver}`}
										/>
										: null}
									{result.facility.is_toothpaste_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.toothpaste}`}
										/>
										: null}
									{result.facility.is_towel_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.towel}`}
										/>
										: null}
								</div>
								<div className="col-sm-4">
									{result.facility.is_balcony_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.balcony}`}
										/>
										: null}
									{result.facility.is_body_lotion_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.body_lotion}`}
										/>
										: null}
									{result.facility.is_cotton_bud_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.cotton_bud}`}
										/>
										: null}
									{result.facility.is_idd_telephone_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.idd_telephone}`}
										/>
										: null}
									{result.facility.is_internet_jack_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.internet_jack}`}
										/>
										: null}
									{result.facility.is_kitchen_set_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.kitchen_set}`}
										/>
										: null}
									{result.facility.is_mosquito_equipment_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.mosquito_equipment}`}
										/>
										: null}
									{result.facility.is_nhk_tv_channel_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.nhk_tv_channel}`}
										/>
										: null}
									{result.facility.is_room_wear_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.room_wear}`}
										/>
										: null}
									{result.facility.is_slipper_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.slipper}`}
										/>
										: null}
									{result.facility.is_soap_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.soap}`}
										/>
										: null}
									{result.facility.is_tv_available ?
										<Radio
											radioClass="iradio_square-green"
											increaseArea="20%"
											checked={true}
											label={` ${Trans.data.tv}`}
										/>
										: null}
								</div>
							</div>
						</div>
					</div>
				)
			}

			))
	}

	navigate(result, room, cancellation) {
		let data = {
			selected_campaign: {
				campaign: result,
				room: room,
				cancellation: cancellation
			},
			hotel: this.props.hotel_data,
			params: {
				check_in: this.state.selected_check_in,
				check_out: this.state.selected_check_out,
				number_of_rooms: this.state.selected_room,
			},
			capacities: this.state.selected_capacities,
			segment_market_id: this.state.segment_market_id,
			pax_passport: this.state.pax_passport
		}

		this.props.dispatch(updateCampaign(data))
		this.props.router.push({
			pathname: '/booking',
			state: {
				data: data
			}
		});
	}

	fetchData() {
		this.setState({
			loaded: false,
			selected_check_in: this.state.data.check_in,
			selected_check_out: this.state.data.check_out,
			selected_number_of_night: this.state.data.number_of_night,
			selected_room: this.state.data.room,
		})

		let that = this

		let capacityToString = JSON.stringify(this.state.capacities)
		this.setState({
			selected_capacities: JSON.parse(capacityToString)
		})

		let capacities = JSON.parse(capacityToString.replace(/{"capacities":|}$/g, ''))
		let params = {
			hotelId: that.state.hotel_id,
			checkInDate: moment(that.state.data.check_in).format('YYYY-MM-DD'),
			checkOutDate: moment(that.state.data.check_out).format('YYYY-MM-DD'),
			numberOfRooms: parseInt(that.state.data.room),
			capacities: capacities,
			childrenAges: [],
			segmentMarket: parseInt(that.state.segment_market_id),
			currency: localStorage.getItem('currency')
		};

		axios.post(
			`${Env.url.api_url}hotels/search-room-cache`,
			params,
			{
			  headers: {
				'Content-Type': 'application/json',
				'Accept': '*/*',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			  }
			}
		  )
		  .then(response => {
			if(response.data.data) {
				this.setState({
					loaded: true,
					available_lists: response.data.data
				});
			} else {
				this.setState({
					loaded: true,
					available_lists: {
						rooms: []
					}
				})
			}
		  })
		  .catch(error => {
			console.error('There was an error!', error);
		  });

	}

	componentDidMount() {
		this.setState({
			data: this.props.room_detail.query,
			capacities: this.props.room_capacities.capacities,
			segment_market_id: this.props.segment_market_id,
			pax_passport: this.props.pax_passport,
			hotel_id: this.props.hotel_data.id
		},
			() => {
				this.fetchData()
			});
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.room_detail.query !== this.props.room_detail.query ||
			nextProps.room_capacities.capacities !== this.props.room_capacities.capacities ||
			nextProps.segment_market_id !== this.props.segment_market_id ||
			nextProps.pax_passport !== this.props.pax_passport ||
			nextProps.hotel_data.id !== this.props.hotel_data.id) {
	
			this.setState({
				data: nextProps.room_detail.query,
				capacities: nextProps.room_capacities.capacities,
				segment_market_id: nextProps.segment_market_id,
				pax_passport: nextProps.pax_passport,
				hotel_id: nextProps.hotel_data.id
			}, () => {
				this.fetchData();
			});
		}
	}
	

	render() {
		return (
			<div>
				<h4 id="priceStarts">{Trans.data.room_availibility}</h4>
				<div className="list-hotel clearfix">
					<div className="row">
						<div className="col-md-12 col-sm-12">
							{this.renderList()}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		room_capacities: state.room_capacities,
		room_detail: state.room_detail
	}
}

export default connect(mapStateToProps)(withRouter(AvailableRoom));