import React, { Component } from "react";
import list_hotel_img from "../../assets/images/img_default.jpg";
import Star from "../../modules/Star";
import { connect } from "react-redux";
import * as Env from "../../Env";
import { updateQuery } from "../../actions/search.js";
import Trans from "../../locale/Translate";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router";
import queryString from "query-string";
import { createBrowserHistory } from "history";
import jquery, { error } from "jquery";
import color from "@material-ui/core/colors/amber.js";

const history = createBrowserHistory();
const list_loading_contents = [0, 1, 2, 3, 4];

const defSortLimit = { type: "NAME", offset: 0, limit: 5 }

class SearchHotelResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        alphabetical: "",
        capacities: JSON.stringify([
          { numberOfAdults: 1, numberOfChildren: 0 },
        ]),
        number_of_adults: 1,
        number_of_children: 0,
        check_in: moment().toDate(),
        check_out: moment().add(1, "days").toDate(),
        city_id: "",
        country_id: "",
        children_ages: [],
        district: JSON.stringify([]),
        hotel_name: "",
        limit: 5,
        min_price: 0,
        max_price: 5000000,
        number_of_nights: 1,
        number_of_rooms: 1,
        review: JSON.stringify([1, 2, 3, 4]),
        segment_market_id: 0,
        pax_passport: 0,
        sort_limit: JSON.stringify(defSortLimit),
        star: null,
        type: null,
      },
      isLoadingMore: false,
      loadingMoreContentCount: 5,
      loading: true,
      initialLoad: true,
      hasMore: true,
      searchLists: [],
      tempSearchLists: [],
      currentPage: 1,
      perPage: 10,
      sortLimit: defSortLimit,
      axiosCancelToken: null,
    };
    this.scrollListener = this.scrollListener.bind(this);

    this.lastScrollTop = 0
  }
  cancelRequestAxios() {
    if (this.state.axiosCancelToken) {
      this.state.axiosCancelToken.cancel('Operation canceled by the user.')
    }
  }


  async componentWillReceiveProps(nextProps) {
    const oldData = this.state.data
    this.setState({ data: { ...oldData, ...nextProps.data.query } }, async () => {
      this.cancelRequestAxios();
      this.setState(
        { initialLoad: true },
        async () => {
          await this.fetchDataWithLimit(true)
        }
      );
    });
    window.addEventListener('scroll', this.scrollListener);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener);
  }

  async scrollListener() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const footerHeight = jquery('.footerhoterip')[0].scrollHeight

    if (this.state.isLoadingMore || this.state.initialLoad) return

    if (scrollTop > this.lastScrollTop) {
      if (scrollTop + windowHeight >= (documentHeight - footerHeight)) {
        await this.fetchDataWithLimit()
      }
    }

    this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
  }

  handleClickSort(event) {
    this.cancelRequestAxios();

    const sortLimit = {
      ...JSON.parse(this.state.data.sort_limit),
      type: event.target.value,
      offset: 0
    };

    this.setState({
      loading: true,
      initialLoad: true,
      searchLists: [],
      data: {
        ...this.state.data,
        sort_limit: JSON.stringify(sortLimit)
      },
      sortLimit
    }, async () => {
      await this.fetchDataWithLimit(true)
    });
  }

  getSearchParams(type) {
    const sortLimit = JSON.parse(this.state.data.sort_limit)
    switch (type) {
      case "nextPage":
        sortLimit.offset = sortLimit.offset + sortLimit.limit
        break;
      case "initial":
        sortLimit.offset = 0
        break;

      default:
        break;
    }

    return {
      checkInDate: moment(this.state.data.check_in).format('YYYY-MM-DD'),
      checkOutDate: moment(this.state.data.check_out).format('YYYY-MM-DD'),
      numberOfRooms: parseInt(this.state.data.number_of_rooms),
      numberOfAdults: parseInt(this.state.data.number_of_adults),
      numberOfChildren: parseInt(this.state.data.number_of_children),
      childrenAges: [],
      segmentMarket: parseInt(this.state.data.segment_market_id),
      currency: this.props.selectedCurrency,
      countryId: parseInt(this.state.data.country_id),
      cityId: parseInt(this.state.data.city_id),
      districtId: JSON.parse(this.state.data.district),
      minPrice: parseInt(this.state.data.min_price),
      maxPrice: parseInt(this.state.data.max_price),
      sortLimit: sortLimit,
      star: JSON.parse(this.state.data.star),
      hotelTypeId: JSON.parse(this.state.data.type),
      alphabetical: this.state.data.alphabetical,
      hotelName: this.state.data.hotel_name,
    };
  }

  async fetchDataWithLimit(initialPage = false) {
    const source = axios.CancelToken.source()

    this.setState({ axiosCancelToken: source })

    if (initialPage) {
      this.setState({ initialLoad: true })
    }

    try {
      const params = this.getSearchParams(initialPage ? "initial" : "nextPage");

      this.setState({ isLoadingMore: initialPage == false, initialLoad: initialPage == true })

      const { data } = await axios.post(`${Env.url.api_url}hotels/search-cache`, params, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }, cancelToken: source.token
      });

      const lists = data.data

      if (data.success === false) {
        throw new Error
      }

      const sortLimit = {
        ...this.state.sortLimit,
        ...params.sortLimit
      }

      const searchLists = initialPage ? lists : [...this.state.searchLists, ...lists]

      if (initialPage) {
        this.setState({ initialLoad: false })
      }

      this.setState({
        data: {
          ...this.state.data,
          sort_limit: JSON.stringify({
            ...this.state.sortLimit,
            ...params.sortLimit
          }),
        },
        sortLimit: sortLimit,
        currentPage: (sortLimit.offset / sortLimit.limit) + 1,
        searchLists: searchLists,
        tempSearchLists: [...this.state.tempSearchLists, ...lists],
        loading: false,
        isLoadingMore: false,

      }, () => {

        if (this.state.tempSearchLists.length < this.state.perPage) {
          this.setState({
            isLoadingMore: true
          }, async () => {
            await this.fetchDataWithLimit()
          })
        } else {
          this.setState({
            tempSearchLists: [],
            isLoadingMore: false
          })
        }
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        if (initialPage) {
          this.setState({ initialLoad: false })
        }

        this.setState({
          tempSearchLists: [],
          isLoadingMore: false
        })
      }
    }
  }


  renderList() {
    const {
      capacities,
      number_of_rooms,
      segment_market_id,
      pax_passport,
    } = this.state.data;

    const loadingElement = (
      <div className="list-hotel-search">
        <div className="row">
          <div className="col-sm-3">
            <div
              className="animated-background facebook"
              style={{ height: 140 }}
            />
          </div>
          <div className="col-sm-5">
            <div className="content-list-hotel" style={{ marginTop: "10px" }}>
              <div className="animated-background facebook line3" />
              <div className="animated-background facebook line1" />
              <div className="animated-background facebook line2" />
              <div className="animated-background facebook line1" />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="price-tag">
              <div className="animated-background facebook line1" />
              <div className="animated-background facebook line2" />
              <div
                className="animated-background facebook line2"
                style={{ height: 30 }}
              />
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <>
        {this.state.initialLoad && list_loading_contents.map((result, i) => loadingElement)}
        {!this.state.searchLists.length && !this.state.isLoadingMore && !this.state.initialLoad && <p className="text-center">There is no room available.</p>}
        {!this.state.initialLoad && this.state.searchLists.map((result, i) => (
          <div key={i}>
            <div className="list-hotel-search">
              <div className="row" style={{ borderRadius: "6px !important" }}>
                <div className="col-sm-3">
                  <div className="list-hotel-img">
                  <img
                    style={{
                      borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px", height: "100%",
                      objectFit: "cover"
                    }}
                    onError={(e) => (e.target.src = list_hotel_img)}
                    src={result.photo ? result.photo : null}
                    className="img-responsive"
                    alt=""
                  />

                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="content-list-hotel">
                    {result.lowest_price_discount_value !== 0 ? (
                      <div className="discountSearch">
                        <span className="fa-stack">
                          <i className="fa fa-circle fa-stack-2x" />
                          <i className="fa fa-tag fa-stack-1x fa-inverse" />
                        </span>{" "}
                        <strong>
                          {Trans.data.discount} {result.lowest_price_discount_value}%
                        </strong>
                      </div>
                    ) : null}
                    <h4>
                      <Link
                        to={{
                          pathname: `/${result.country.url_segment}/${result.city.url_segment}/${result.hotel.url_segment}`,
                          query: {
                            check_in: moment(this.state.data.check_in).format("YYYY-MM-DD"),
                            check_out: moment(this.state.data.check_out).format("YYYY-MM-DD"),
                            number_of_night: this.state.data.number_of_nights,
                            room: number_of_rooms,
                            capacities: capacities,
                            segment_market_id: segment_market_id,
                            pax_passport: pax_passport,
                          },
                        }}
                        target="_blank"
                      >
                        {result.hotel.name}
                      </Link>
                    </h4>
                    <Star
                      value={result.hotel.star}
                      rating={
                        result.review &&
                          result.review.score &&
                          typeof result.review.score === "number"
                          ? result.review.score
                          : 0
                      }
                      total_review={
                        result.review ? result.review.total_review : 0
                      }
                      show_rating={false}
                    />
                    <span className="location">
                      <i className="fa fa-map-marker" /> {result.district.name},{" "}
                      {result.city.name}
                    </span>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="price-tag">
                    <div>
                      {result.lowest_price_average !== 0 && result.lowest_price_base_average !== null ? (
                        <del style={{
                          display: 'block',
                          transform: 'translateY(18px)'
                        }}>
                          {Env.formatCurrency(result.lowest_price_base_average, this.props.selectedCurrency)}
                        </del>
                      ) : null}
                      <h4>
                        <h3>{Env.formatCurrency(result.lowest_price_average, this.props.selectedCurrency)} <small style={{ color: "#777777", textTransform: "lowercase" }}>{Trans.data.avg_pernight}</small></h3>

                      </h4>
                      <div style={{ marginTop: "-5px", marginBottom: "10px" }}>
                        <span className="text-primary">
                          {Trans.data.price_inclusive_of_tax}
                        </span>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname: `/${result.country.url_segment}/${result.city.url_segment
                          }/${result.hotel.url_segment}`,
                        query: {
                          check_in: moment(this.state.data.check_in).format(
                            "YYYY-MM-DD"
                          ),
                          check_out: moment(this.state.data.check_out).format(
                            "YYYY-MM-DD"
                          ),
                          number_of_night: this.state.data.number_of_nights,
                          room: number_of_rooms,
                          capacities: capacities,
                          segment_market_id: segment_market_id,
                          pax_passport: pax_passport,
                        },
                      }}
                      className="btn btn-promo"
                      role="button"
                      target="_blank"
                      style={{ width: "100%", marginRight: "10px", marginBottom: "16px" }}
                    >
                      {Trans.data.check_price}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {(() => {
          if (this.state.isLoadingMore) {
            const loadingHtmls = [];
            for (
              let index = 0;
              index < this.state.loadingMoreContentCount;
              index++
            ) {
              loadingHtmls.push(loadingElement);
            }

            return loadingHtmls.map((element) => element);
          }
        })()}
      </>
    );
  }

  render() {
    const { type: sortType } = this.state.sortLimit;

    return (
      <div className="col-xs-12">
        <div className="sorting-list-hotel">
          <div className="row">
            <div className="col-md-1">
              <label>{Trans.data.sorting}</label>
            </div>
            <div className="col-md-6">
              <div className="btn-group btn-sorting" role="group">
                <button
                  type="button"
                  className={`btn btn-default ${sortType === 0 || sortType === "RANK" ? "active" : null
                    }`}
                  value="RANK"
                  onClick={this.handleClickSort.bind(this)}
                >
                  {Trans.data.popular}
                </button>
                <button
                  type="button"
                  className={`btn btn-default ${sortType === 1 || sortType === "LOWEST_PRICE" ? "active" : null
                    }`}
                  value="LOWEST_PRICE"
                  onClick={this.handleClickSort.bind(this)}
                >
                  {Trans.data.lowest_price}
                </button>
                <button
                  type="button"
                  className={`btn btn-default ${sortType === 1 || sortType === "HIGHEST_PRICE" ? "active" : null
                    }`}
                  value="HIGHEST_PRICE"
                  onClick={this.handleClickSort.bind(this)}
                >
                  {Trans.data.highest_price}
                </button>
                <button
                  type="button"
                  className={`btn btn-default ${sortType === 2 || sortType === "NAME" ? "active" : null
                    }`}
                  value="NAME"
                  onClick={this.handleClickSort.bind(this)}
                >
                  {Trans.data.name}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="list-hotel crearfix">
          <div className="col-md-12 col-sm-12">{this.renderList()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.search,
    selectedCurrency: state.currencyReducer.selectedCurrency
  };
};

export default connect(mapStateToProps)(SearchHotelResult);
