const data = {
  // ********** Header ********** //
  english: "English",
  japan: "Japan",
  bahasa: "Bahasa Indonesia",
  sign_out: "Sign Out",
  sign_in: "SIGN IN",
  sign_up: "Sign up",
  sign_in_to_hoterip: "Sign in to Hoterip",
  sign_up_with_hoterip: "Sign up with Hoterip",
  sign_in_with_facebook: "Sign in with Facebook",
  sign_in_with_twitter: "Sign in with Twitter",
  sign_in_with_google: "Sign in with Google",
  forgot_password: "Forgot password?",
  create_account: "CREATE ACCOUNT",
  no_account_yet: "No account yet?",
  already_have_an_account: "Already have an account?",
  email_address: "Email Address",
  password: "Password",
  first_name: "First Name",
  last_name: "Last Name",
  or: "or",
  send_to_email: "Send to email",
  back_to: "Back to",
  new_to_hoterip: "New to Hoterip?",
  sign_up_now: "Sign up now",

  // ********** Footer ********** //
  contact_us: "Contact Us",
  hotel_popular: "Hotel Popular",
  help: "Help",
  payment_method: "Payment Method",
  guarante_lowest_price: "Best Price Guarantee",
  easy_to_search: "Easy to Search and Book",
  good_services: "Good Services",
  faq: "FAQ",
  term_condition: "Term and Conditions",
  privacy_policy: "Privacy Policy",
  career: "Career",
  hotel_partner: "Hotel Partner",

  // ********** Home ********** //
  kota_area_tujuan: "City, Destination Area or Hotel Name",
  find_city: "Find City, Destination Area or Hotel Name",
  destination: "Destination",
  check_in: "Check-in",
  number_of_night: "Number of Nights",
  check_out: "Check-out",
  night: "Night",
  search_hotel: "Search Hotel",
  why_choose_hoterip_com: "Why Choose HIS Hotels?",

  around_bali: "Free Tour Around Bali",
  around_bali_info: "Get a free tour around Bali.",

  best_service: "Best Service",
  best_service_info:
    'Our priority is provide the best service according to our motto "Highest International Standard".',

  discount_with_hoterip: "Additional Discount with Hoterip Voucher",
  discount_with_hoterip_info: "Get MAIMAI shopping discount card up to 50% Off.",

  room_benefit: "Room Benefit",
  room_benefit_info: "Get free airport transfer or Massage service, etc",

  read_more: "Read more",

  best_deal_of_the_week: "Best Deal Of The Week ",
  avg_pernight: "avg/night",
  check_price: "Check Availability",
  hotel_promo: "Hotel Promo",
  see_all_promo: "See All Promo",
  view_all_promo: "View all promo",
  per_night: "/Night",
  new_hotel_listing: "New Hotel Listing",
  popular_destination: "Popular Destination",
  hotels: "Hotels",
  see_all_destination: "See All Destination",
  hotel_in: "Hotel in",
  last_booked_hotel: "Last Booked hotel",
  get_the_best_deal: "Get The Best Deals & Promo",
  to_accept_the_deal: "To accept the deal and latest promo",
  subscribe: "Subscribe",

  // ***** All Promotion hotel **** //
  all_promotion: "All Promotion",

  // ***** Filter Sidebar hotel **** //
  filter_hotel_result: "Filter Hotel Results",
  star: "Star",
  price: "Price",
  area: "Area",
  hotel_reviews: "Hotel Reviews",
  hotel_type: "Hotel Type",
  all: "All",
  five_star: "5 Star",
  four_star: "4 Star",
  three_star: "3 Star",
  two_star: "2 Star",
  one_star: "1 Star",
  hotel: "Hotel",
  villa: "Villa",
  apartment: "Apartment",
  search_hotel_name: "Search Hotel Name",
  enter_hotel_name: "Enter a hotel name",
  lowest_price: "Lowest Price",
  highest_price: "Highest Price",

  // ***** Search Result hotel **** //
  discount: "Discount",
  sorting: "Sorting",
  popular: "Popular",
  name: "Name",
  showing: "Showing",
  on: "on",
  no_result: "No Results",

  // ******* Hotel Detail ******* //
  foto: "Photo",
  information: "Hotel Description",
  general_information: "General Information",
  facility: "Facilities",
  services: "Services",
  map: "Map",
  reviews: "Reviews",
  telephone: "Telephone",
  fax: "Fax",
  renovation_year: "Renovation Year",
  number_of_floors: "Number of Floors",
  number_of_rooms: "Number of Rooms",
  time_from_airport: "Time from Airport",
  distance_from_airport: "Distance from Airport",
  distance_from_city_center: "Distance from City Center",
  room_voltage: "Room Voltage",
  pet_allowed: "Pets Allowed",
  earliest_check_in: "Earliest Check-in",
  children_guest: "Children Guest",
  floors: "Floors",
  rooms: "Rooms",
  minutes: "Minutes",
  yes: "YES",
  no: "NO",
  guest_are_considered: "Guest are considered children between",
  years_old: "years old",
  hotel_announcement: "Hotel Announcement",
  show_more: "Show more",
  show_less: "Show less",
  show_room_facility: "Show room facility",
  price_start_from: "Price Start From",
  room_numbers: "Rooms",
  remarks: "Remarks",
  plug_type: "Plug Type",
  type: "Type",
  multi_plug: "Multi-Plug",

  // ******* Hotel Facility ******* //
  banguet_room: "Banquet Room",
  car_parking: "Car Parking",
  club_lounge: "Club Lounge",
  disabled_facilities: "Disabled Facilities",
  gym: "Gym",
  hair_salon: "Hair Salon",
  kids_club: "Kids Club",
  luggage_room: "Luggage Room",
  private_beach: "Private Beach",
  squash_court: "Squash Court",
  bars: "Bars",
  casino: "Casino",
  coffe_shop: "Coffee Shop",
  elevator: "Elevator",
  gift_shop: "Gift Shop",
  jacuzzi: "Jacuzzi",
  kids_pool: "Kids Pool",
  meeting_room: "Meeting Room",
  poolside_bar: "Poolside Bar",
  sauna: "Sauna",
  steam_room: "Steam Room",
  business_center: "Business Center",
  clinic: "Clinic",
  departure_lounge: "Departure Lounge",
  garden: "Garden",
  golf_course: "Golf Course",
  caraoke_room: "Karaoke Room",
  library: "Library",
  night_club: "Night Club",
  restaurant: "Restaurant",
  spa: "Spa",
  swimming_pool: "Swimming Pool",
  smooking_area: "Smooking Area",

  // ******* Hotel Services ******* //
  room_service_24hour: "Room Service 24hour",
  baby_sitter: "Baby Sitter",
  laundry: "Laundry",
  hotel_activities: "Hotel Activities",
  rental_multi_plug: "Rental Multi Plug",
  shuttle: "Shuttle",
  paid_wifi: "Paid Wifi",
  reception_24hour: "Reception 24hour",
  butler: "Butler",
  massage: "Massage",
  rental_baby_content: "Rental Baby Content",
  rental_wheel_chair: "Rental Wheel Chair",
  valet_parking: "Valet Parking",
  airport_transfer: "Airport Transfer",
  concierge: "Concierge",
  money_changer: "Money Changer",
  rental_biycle: "Rental Bicycle",
  room_service: "Room Service",
  free_wifi: "Free Wifi",
  japanese_staff: "Japanese Staff",
  english_staff: "English Staff",
  rental_baby_bed: "Rental Baby Bed",
  rental_bed_guard: "Rental Bed Guard",

  // ******* Available Room ******* //
  update: "UPDATE",
  book_now: "Book Now",
  room_remaining: "Room Remaining",
  no_room: "No Room",
  breakfast_include: "Breakfast include",
  breakfast_include_for: "Breakfast included for",
  persons: "Persons",
  without_breakfast: "Without breakfast",
  cancellation_policy: "Cancellation Policy",
  request_price: "Request Price",
  no_available: "No Available",
  leave_a_question_or_comment: "Leave a question",
  send: "Send",
  table_prices: "Table Prices",
  sun: "Sun",
  mon: "Mon",
  tue: "Tue",
  wed: "Wed",
  thu: "Thu",
  fri: "Fri",
  sat: "Sat",
  room_facility: "Room Facility",
  room_availibility: "Room Availibility",
  room_detail: "Room Detail",
  duration: "Duration",
  room: "Room",
  adult: "Adult",
  children: "Children",
  change: "Change",
  email: "Email",
  message: "Message",
  price_inclusive_of_tax: "Price Inclusive of Tax",
  discount_at_least_stay: "Stay at least 1 nights and book 1 or more rooms.",
  day: "Day",
  price_and_stock: "Price & Stock",
  adult_breakfast_price: "Adult Breakfast Price",
  child_breakfast_price: "Child Breakfast Price",
  price_for: "Price for",
  additional_cost: "Additional Cost",

  // Room Facility
  air_conditioner: "Air Conditioner",
  bathrobe: "Bathrobe",
  cable_tv: "Cable Tv",
  dvd_player: "Dvd Player",
  independent_shower_room: "Independent Shower Room",
  iron: "Iron",
  living_room: "Living Room",
  moveable_shower_head: "Moveable Shower Head",
  shampoo: "Shampoo",
  toothbrush: "Toothbrush",
  alarm_clock: "Alarm Clock",
  bathub: "Bathtub",
  coffe_maker: "Coffee Maker",
  hair_dryer: "Hair Dryer",
  safety_box: "Safety Box",
  mini_bar: "Mini Bar",
  radio: "Radio",
  shaver: "Shaver",
  towel: "Towel",
  balcony: "Balcony",
  body_lotion: "Body Lotion",
  cotton_bud: "Cotton Bud",
  idd_telephone: "Idd Telephone",
  internet_jack: "Internet Jack",
  kitchen_set: "Kitchen Set",
  mosquito_equipment: "Mosquito Equipment",
  room_wear: "Room Wear",
  soap: "Soap",
  tv: "Tv",
  room_size: "Room Size",
  bedrooms: "No of Beds",
  complimentary_water_bottle: "Complimentary Water Bottle",
  fan: "Fan",
  slipper: "Slipper",
  body_shampoo: "Body Shampoo",
  toothpaste: "Toothpaste",
  nhk_tv_channel: "Nhk Tv Channel",
  electric_pot: "Electric Pot",

  // ******* Booking Form ******* //
  booking_form: "Booking Form",
  booking_information: "Booking Information",
  title: "Title",
  phone_number: "Phone Number",
  nationality: "Nationality",
  same_as_booker: "Same as booker",
  guest_fullname: "Guest Full Name",
  special_request: "Special Request",
  additional_notes: "Additional Notes",
  payment_information: "Payment Information",
  total_payment: "Total Payment",
  total_room_price: "Total room price",
  tax_and_service: "Tax and Service",
  process_payment: "Process Payment",
  non_smooking_room: "Non Smooking Room",
  large_bed: "Large Bed",
  late_check_in: "Late Check_in",
  twin_bed: "Twin Bed",
  early_check_in: "Early Check_in",
  high_floor: "High Floor",
  air_transfer: "Air Transfer",
  i_have_special_request: "I have special request",
  i_am_not_stay: "I am not staying at the hotel. I am making this booking for someone else",
  optional: "optional",
  term_agree_info: "By clicking this button, you acknowledge that you have read and agreed to the",
  and: "and",
  of_hoterip: "of Hoterip",
  agent_information: "Agent Information",
  please_fill_customer_data: "Please fill your customer data",

  // ******* Payment page ******* //
  paymentMethod2c2p: "Credit Card/Bank Transfer/Wallet, etc",
  credit_card: "Credit Card",
  internet_banking: "Internet Banking",
  pay_with: "Pay with",
  payNow: "Pay Now",
  indonesian_only: "Indonesian only",
  name_on_card: "Name on card",
  card_number: "Card number",
  expiry_month: "Expiry month",
  expiry_year: "Expiry year",
  cvv: "CVV",
  digit_credit_card: "16 digit card number",
  digit_cvv: "3 digit CVC",
  are_you_sure_to_pay: "Are you sure to pay with",
  cancel: "Cancel",
  continue: "Continue",
  please_choose_bank_name: "Please choose a bank name",
  insert_password: "Insert password",

  // ******* Process payment page ******* //
  payment_code: "Payment Code",
  payment_code_bca: "Payment Code Bank BCA",
  transfer_amount: "Total payment amount",
  please_transfer_to: "Please Transfer to",
  payment_instruction: "Payment Instruction",
  to_complete_payment: "To complete the payment",
  you_have: "You have",
  hours: "Hours",
  seconds: "Seconds",
  payment_info: "Payment Info",
  payment_info_text:
    "After you finish the payment, within 5-10 minutes, you will receive an email with the booking detail and the hotel voucher to your email address you registered. Please show the hotel voucher upon your hotel check-in. Thank you for choosing HIS Hotels. We welcome your visit us again.",
  customer_service: "Customer Service",
  customer_service_text: "If you are still not clear, please contact using the online chat or on +62 361 721064.",
  choose: "Choose",
  choose_text: 'Type your payment code on the "No Telepon" field',
  danamon_text: 'Type in your first 4 digits of your payment code on the "Kode Area" field',
  your_booking_has_expired: "Your booking has expired",
  your_payment_was_successfull: "Your Payment Was Successfull",
  your_payment_failed: "We can not process your payment",
  your_payment_failed_text: "Please contact Hoterip Customer Service for further assistance",

  // ******* Progress Booking ******* //
  complete_booking_form: "Complete Booking Form",
  choose_payment_method: "Choose Payment Method",
  voucher_send: "Voucher Send",

  // ******* SideBar Booking ******* //
  booking_summary: "Booking Summary",
  breakfast: "Breakfast",
  room_name: "Room name",
  extrabeds: "Extra Beds",
  price_details: "Price Details",
  taxes: "Taxes",
  nights: "Nights",
  booking_for: "Booking For",
  included: "Included",
  excluded: "Excluded",
  tax: "Tax",

  // **************** Help ******* //
  contact_person: "Contact Person",
  hotel_name: "Hotel Name",
  how_to_make_a_booking: "How To Make a Booking",
  about_hoterip: "About HIS Hotels",
  additional_benefit: "Additional Benefit",
  company_name: "Company Name",
  siup: "SIUP",
  initial_capital: "Initial Capital",
  opening_year: "Opening Year",
  address: "Address",
  total_employee: "Total Employee",
  iata_number: "IATA Number",
  bank_partner: "Bank Partners",

  // ******** Member Page ********* //
  profile: "Profile",
  picture: "Picture",
  display_name: "Display Name",
  home_phone: "Home Phone",
  mobile_phone: "Mobile Phone",
  currency: "Currency",
  language: "Language",
  timezone: "Time Zone",
  newsletter: "Newsletter",
  save: "Save",
  change_password: "Change Password",
  old_password: "Old Password",
  new_password: "New Password",
  confirm_new_password: "Confirm New Password",
  bookings: "Bookings",
  home: "Home",
  iwouldlike: "I would like to receive your special offers and promotions",
  confirmed: "Confirmed",
  cancelled: "Cancelled",
  booking_detail: "Booking detail",
  total: "Total",
  booking_id: "Booking ID",
  guest_name: "Guest name",
  booking_date: "Booking date",
  check_in_date: "Check-in date",
  check_out_date: "Check-out date",
  you_can_send: "You can send message to hotel by writing using this form.",
  write_message_here: "Write your message here...",

  // ******** SEO  ********* //
  lowest_price_and_special_promotion: "Lowest Price and Special Promotion for ",
  in: " in ",
  minute_by_car_from_airport: " minute by car from airport with ",
  etc: "so on",
  discount_hotel: ", discount hotel, ",
  online_reservation: " online booking, ",
  real_time_booking: " real time booking, ",
  cheap_hotel: " cheap hotel ",
  special_promotion: ", special promotions ",

  // ************** Page City ******* //
  find_hotel_in: "Find Hotel in",
  hotel_available: "hotels available",
  popular_hotels_in: "Popular Hotels in",
  specialPromotion_hotel_in: "Hotel Special Deal",
  best_hotel_in_each_area: "Best Hotel in Each Area in",
  list_hotel_in: "List Hotels in",
  hotels_in: "Hotels in",
  about: "About",

  pax_passport: "Pax Passport",
  city_destination_area_or_hotel_name: "City, Destination Area or Hotel Name",
  recomended_hotel: "Recomended Hotel",
  our_partnership: "Our Partnership",
  deposit: "Deposit",
  my_bookings: "My Bookings",
  alphabetical: "Alphabetical",
  enter_a_hotel_name: "Enter a hotel name",
  guest_review: "guest review",
  number_of_nights: "Number of Nights",
  fullname: "Full Name",
  your_balance: "Your balance",
  cancel_this_booking: "Cancel this booking",
  deposit_information: "Deposit Information",
  deposit_currency: "Deposit Currency",
  total_deposit: "Total Deposit",
  used_deposit: "Used Deposit",
  balance_deposit: "Balance Deposit",
  user_detail: "User detail",
  travel_agent_information: "Travel Agent Information",
  contact_person_name: "Contact Person Name",
  sex: "Sex",
  male: "Male",
  female: "Female",
  position: "Position",
  iata_asita_number: "IATA/ASITA Number",
  please_select: "Please Select",
  company_address: "Company Address",
  district: "District",
  city: "City",
  country: "Country",
  website: "Website"
};

module.exports = { data };
