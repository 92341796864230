import React, { Component } from "react";

import { connect } from 'react-redux'
import BookingSidebar from "../../components/BookingSidebar";
import Layout from "../../components/Layout";
import ProgressBooking from "../../modules/ProgressBooking";
import { Tab, NavItem, Nav, Modal } from "react-bootstrap";
import Trans from "../../locale/Translate.js";
import * as Env from "../../Env";
import { Link } from "react-router";

import mastercard_small from "../../assets/images/mastercard_small.png";
import visa_small from "../../assets/images/visa_small.png";
import comodo_secure from "../../assets/images/comodo_secure_seal_76x26_transp.png";
import img_2c2p from "../../assets/images/2c2p.png";

var axios = require("axios");
var moment = require("moment");

class PaymentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      url_iframe: "",
      name_on_card: "",
      card_number: "",
      exp_month: "",
      exp_year: "",
      cvc: "",
      token_id: "",
      payment_method: "",
      alert_message: "",
      submitted: false,
      balance_deposit: 0,
      deposit_currency_code: "IDR",
      getNotif: "",
      data: {
        hotel: {
          hotel_text: {
            name: null
          },
          photo: null,
          address: null,
          telephone: null,
          fax: null,
          star: null
        },
        selected_campaign: {
          campaign: {
            total_price_before_tax: 0,
            total_tax_price: 0,
            total_price: 0,
            total_price_idr: 0,
            items_data: [],
            surcharges: []
          },
          room: {
            selected_text: {
              name: null
            }
          },
          cancellation: {},
          items: {}
        },
        params: {
          check_in: moment().format("YYYY-MM-DD"),
          check_out: moment()
            .add("1 days")
            .format("YYYY-MM-DD"),
          number_of_rooms: 0
        },
        booking_pending_id: null
      },
      capacity: [{ number_of_adults: 1, number_of_children: 0 }]
    };

    this.close = this.close.bind(this);
    this.creditCard = this.creditCard.bind(this);

    this.showNotification = this.showNotification.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.pay_with_deposit = this.pay_with_deposit.bind(this);

    this.reduceSurcharge = this.reduceSurcharge.bind(this);
    this.reduceCapacity = this.reduceCapacity.bind(this);
  }

  showNotification() {
    this.setState({ show_notification: true });
  }

  closeNotification() {
    this.setState({ show_notification: false });
  }

  close() {
    this.setState({ showModal: false });
  }

  onChange(text) {
    let newText = "";
    let numbers = "0123456789";

    for (var i = 0; i < text.length; i++) {
      if (numbers.indexOf(text[i]) > -1) {
        newText += text[i];
      }
    }

    return newText;
  }

  pay_with_deposit(event) {
    event.preventDefault();

    this.setState({
      payment_method: "deposit",
      submitted: true,
      getNotif: ""
    });

    let url_hotel = this.state.data.hotel.url_segment;
    let params = {
      payment_method: "deposit",
      booking_pending_id: this.state.data.booking_pending_id
    };

    var self = this;

    axios
      .post(Env.url.api_url + "hotels/bookings/" + url_hotel + "/payment", params, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Accept-Language": localStorage.getItem("language"),
          "Accept-Currency": this.props.selectedCurrency
        }
      })
      .then(response => {
        if (response.data.meta.success) {
          this.props.router.push({
            pathname: "/voucher-send",
            state: this.state.data.data_user
          });
        }
      })
      .catch(error => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            self.setState({
              getNotif: error.response.data.meta.messages,
              show_notification: false
            });
          }
        }
        Env.expired(error);
      });
  }

  creditCard(event) {
    event.preventDefault();

    this.setState({
      payment_method: "credit-card",
      submitted: true
    });

    var self = this;
    var Veritrans = window.Veritrans;
    Veritrans.url = Env.url.veritrans_url;
    Veritrans.client_key = Env.url.veritrans_client_key;

    let { name_on_card, card_number, exp_month, exp_year, cvc } = this.state;

    let sendPayment = () => {
      let params = {
        payment_method: "credit-card",
        booking_pending_id: self.state.data.booking_pending_id,
        token_id: self.state.token_id,
        name_on_card: self.state.name_on_card
      };

      let url_hotel = self.state.data.hotel.url_segment;

      axios
        .post(Env.url.api_url + "hotels/bookings/" + url_hotel + "/payment", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Accept-Language": localStorage.getItem("language"),
            "Accept-Currency": this.props.selectedCurrency
          }
        })
        .then(response => {
          if (response.data.meta.success) {
            if (response.data.meta.code === 200) {
              self.props.router.push({
                pathname: "/voucher-send",
                state: self.state.data.data_user
              });
            } else {
              self.props.router.push({
                pathname: "/payment-failed"
              });
            }
          } else {
            self.props.router.push({
              pathname: "/payment-failed"
            });
          }
        })
        .catch(error => {
          Env.expired(error);
        });
    };

    let veritrans_response = response => {
      if (response.redirect_url) {
        self.setState({
          url_iframe: response.redirect_url,
          showModal: true
        });
      } else if (response.status_code === "200") {
        // this.close();
        self.setState({ token_id: response.token_id });
        sendPayment();
      } else {
        // console.log(response.status_code);
        // console.log(response.status_message);
      }
    };

    let card = () => {
      return {
        card_number: self.state.card_number,
        card_exp_year: self.state.exp_year,
        card_exp_month: self.state.exp_month,
        card_cvv: self.state.cvc,
        secure: true,
        bank: "bni",
        gross_amount: self.state.data.selected_campaign.campaign.total_price_idr
      };
    };

    if (name_on_card && card_number && exp_month && exp_year && cvc) {
      Veritrans.token(card, veritrans_response);
    }
  }

  reduceSurcharge(value, type) {
    let data;

    if (type === "adult") {
      data = value.reduce((prevVal, currVal) => Number(prevVal) + Number(currVal.adult_price), 0);
    }

    if (type === "child") {
      data = value.reduce((prevVal, currVal) => Number(prevVal) + Number(currVal.child_price), 0);
    }

    return data;
  }

  reduceCapacity(value, type) {
    let data;

    if (type === "adults") {
      data = value.reduce((prevVal, currVal) => Number(prevVal) + Number(currVal.number_of_adults), 0);
    }

    if (type === "childs") {
      data = value.reduce((prevVal, currVal) => Number(prevVal) + Number(currVal.number_of_children), 0);
    }

    return data;
  }

  fetchDeposit() {
    var that = this;
    axios
      .get(Env.url.api_url + "hotels/users/profile", {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") }
      })
      .then(function (response) {
        if (response.data.meta.success) {
          that.setState({
            balance_deposit: response.data.data.data.balance_deposit,
            deposit_currency_code: response.data.data.data.deposit_currency_code
          });
        }
      })
      .catch(function (error) {
        Env.expired(error);
      });
  }

  async on2c2pPayment() {
    const { room } = this.state.data.selected_campaign;
    const { check_in, check_out, number_of_rooms } = this.state.data.params;
    const desc =
      room.name +
      ",(" +
      number_of_rooms +
      " " +
      Trans.data.rooms +
      " x " +
      parseFloat(moment(check_out).diff(moment(check_in), "days")) +
      " " +
      Trans.data.nights +
      ")";

    const params = {
      hotel_slug: this.state.data.hotel.url_segment,
      booking_pending_id: this.state.data.booking_pending_id,
      desc: desc
    };

    try {
      const response = await axios.post(Env.url.api_url + "hotels/bookings/payment/generate2c2predirect", params, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Accept-Language": localStorage.getItem("language"),
          "Accept-Currency": this.props.selectedCurrency
        }
      });

      window.location.href = response.data.redirect_url;

      // const data = response.data;
      // let form2c2p = document.getElementById("paymentRequestForm");

      // form2c2p.version.value = data.version;
      // form2c2p.merchant_id.value = data.merchant_id;
      // form2c2p.currency.value = data.currency;
      // form2c2p.result_url_1.value = data.result_url_1;
      // form2c2p.result_url_2.value = data.result_url_2;
      // form2c2p.enable_store_card.value = data.enable_store_card;
      // form2c2p.request_3ds.value = data.request_3ds;
      // form2c2p.payment_option.value = data.payment_option;
      // form2c2p.hash_value.value = data.hash_value;
      // form2c2p.payment_description.value = data.payment_description;
      // form2c2p.order_id.value = data.order_id;
      // form2c2p.amount.value = data.amount;
      // form2c2p.submit();
    } catch (error) {
      console.log(error);
      this.props.router.push({
        pathname: "/payment-failed"
      });
    }
  }

  componentDidMount() {
    this.setState({
      data: this.props.location.state.data,
      capacity: this.props.location.state.data.capacity
    });

    this.fetchDeposit();
  }

  renderForm2c2pPayment() {
    return (
      <form action={Env.url.payment_request_2c2p} method="POST" name="paymentRequestForm" id="paymentRequestForm">
        {/* <input type='hidden' name='paymentRequest' value=''/> */}
        <input type="hidden" name="version" value="" />
        <input type="hidden" name="merchant_id" value="" />
        <input type="hidden" name="currency" value="" />
        <input type="hidden" name="result_url_1" value="" />
        <input type="hidden" name="result_url_2" value="" />
        <input type="hidden" name="hash_value" value="" />
        <input type="hidden" name="enable_store_card" value="" />
        <input type="hidden" name="request_3ds" value="" />
        <input type="hidden" name="payment_option" value="" />
        <input type="hidden" name="payment_description" value="" />
        <input type="hidden" name="order_id" value="" />
        <input type="hidden" name="amount" value="" />
      </form>
    );
  }

  render() {
    let {
      name_on_card,
      card_number,
      exp_month,
      exp_year,
      cvc,
      submitted,
      capacity,
      balance_deposit,
      deposit_currency_code,
      show_notification,
      getNotif
    } = this.state;
    let { campaign, room } = this.state.data.selected_campaign;
    let { check_in, check_out, number_of_rooms } = this.state.data.params;
    let lang = localStorage.getItem("language");

    // surcharge description
    let surchargeDesc;
    // if (campaign.surcharges.length) {
    //   surchargeDesc = campaign.surcharges[0].description.description;
    // }

    return (
      <Layout>
        <div className="container">
          <section className="booking">
            <div className="row">
              <ProgressBooking value={2} />
            </div>
            <div className="row">
              <div className="col-sm-9 col-md-9">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <div className="sidebar-container">
                    <div className="left-sidebar-name">
                      <Nav bsStyle="pills" stacked>
                        <NavItem disabled className="heading-selectPay">
                          <h3>HoteripPay</h3>
                        </NavItem>
                        <NavItem eventKey="first" style={{ width: "100%" }}>
                          {Trans.data.paymentMethod2c2p}
                        </NavItem>
                        <NavItem eventKey="seconds" style={{ width: "100%" }}>
                          {Trans.data.deposit}
                        </NavItem>
                      </Nav>
                    </div>
                    <div className="content-sidebar-list">
                      <Tab.Content animation>
                        {/* /.Credit Card */}
                        {/* <Tab.Pane eventKey="first">
                          <div className="credit-card">
                            <div className="row">
                              <div className="col-sm-5">
                                <h4 style={{ marginTop: "0" }}>{Trans.data.credit_card}</h4>
                              </div>
                              <div className="col-sm-7 text-right">
                                <img src={comodo_secure} role="presentation" />
                                <img src={mastercard_small} role="presentation" />{" "}
                                <img src={visa_small} role="presentation" />
                              </div>
                            </div>
                            <div className="borderer"></div>
                            <div className="row">
                              <div className="col-sm-12 well">
                                <form id="form-inline">
                                  <div className={`form-group ${submitted && !name_on_card ? "has-error" : null}`}>
                                    <label>
                                      {Trans.data.name_on_card} <span className="required">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      value={name_on_card}
                                      onChange={event => this.setState({ name_on_card: event.target.value })}
                                      className="form-control"
                                      name="card-name"
                                      placeholder={Trans.data.name_on_card}
                                    />
                                    {submitted && !name_on_card ? (
                                      <span className="help-block">Name on card is required!</span>
                                    ) : null}
                                  </div>
                                  <div className={`form-group ${submitted && !card_number ? "has-error" : null}`}>
                                    <label>
                                      {Trans.data.card_number} <span className="required">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      value={card_number}
                                      onChange={event =>
                                        this.setState({ card_number: this.onChange(event.target.value) })
                                      }
                                      className="form-control"
                                      name="card-number"
                                      maxLength="16"
                                      autoComplete="off"
                                      placeholder={Trans.data.digit_credit_card}
                                    />
                                    {submitted && !card_number ? (
                                      <span className="help-block">Card number is required!</span>
                                    ) : null}
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <div className={`form-group ${submitted && !exp_month ? "has-error" : null}`}>
                                        <label>
                                          {Trans.data.expiry_month} <span className="required">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          value={exp_month}
                                          onChange={event =>
                                            this.setState({ exp_month: this.onChange(event.target.value) })
                                          }
                                          className="form-control"
                                          name="expiry-month"
                                          maxLength={2}
                                          placeholder="MM"
                                        />
                                        {submitted && !exp_month ? (
                                          <span className="help-block">Expiry month is required!</span>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-sm-4">
                                      <div className={`form-group ${submitted && !exp_year ? "has-error" : null}`}>
                                        <label>
                                          {Trans.data.expiry_year} <span className="required">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          value={exp_year}
                                          onChange={event =>
                                            this.setState({ exp_year: this.onChange(event.target.value) })
                                          }
                                          className="form-control"
                                          name="expiry-year"
                                          maxLength={4}
                                          placeholder="YYYY"
                                        />
                                        {submitted && !exp_year ? (
                                          <span className="help-block">Expiry year is required!</span>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-sm-4">
                                      <div className={`form-group ${submitted && !cvc ? "has-error" : null}`}>
                                        <label>
                                          {Trans.data.cvv} <span className="required">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          value={cvc}
                                          onChange={event => this.setState({ cvc: this.onChange(event.target.value) })}
                                          className="form-control"
                                          name="card-back-cvv"
                                          maxLength={3}
                                          placeholder={Trans.data.digit_cvv}
                                        />
                                        {submitted && !cvc ? (
                                          <span className="help-block">CVC is required!</span>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="borders"></div>
                            <table className="table table-borderless table-margin">
                              <caption style={{ captionSide: "top" }}>
                                <h4>{Trans.data.price_details}</h4>
                              </caption>
                              <tbody>
                                <tr>
                                  <td>
                                    {room.selected_text.name},(
                                    {number_of_rooms +
                                      " " +
                                      Trans.data.rooms +
                                      " x " +
                                      parseFloat(moment(check_out).diff(moment(check_in), "days")) +
                                      " " +
                                      Trans.data.nights}
                                    )
                                  </td>
                                  <td className="text-right">{Env.format(campaign.total_price_before_tax)}</td>
                                </tr>
                                {campaign.surcharges.length ? (
                                  <tr>
                                    <td>
                                      {Trans.data.additional_cost + " " + surchargeDesc}, <br />(
                                      {this.reduceCapacity(capacity, "adults")} {Trans.data.adult} x{" "}
                                      {Env.format(this.reduceSurcharge(campaign.surcharges, "adult"))})
                                      {this.reduceCapacity(capacity, "childs") === 0
                                        ? null
                                        : ` & ( ${this.reduceCapacity(capacity, "childs")} ${
                                            Trans.data.children
                                          } x ${Env.format(this.reduceSurcharge(campaign.surcharges, "child"))})`}
                                    </td>
                                    <td className="text-right">
                                      {Env.format(campaign.surcharges_total_exchange_price)}
                                    </td>
                                  </tr>
                                ) : null}
                                <tr>
                                  <td>{Trans.data.tax}</td>
                                  <td className="text-right">{Env.format(campaign.total_tax_price)}</td>
                                </tr>
                                <tr className="border-top-table">
                                  <td>{Trans.data.total_payment}</td>
                                  <td className="text-right" width="50%">
                                    <strong className="total-price">{Env.format(campaign.total_price)}</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="row">
                              <div className="col-sm-12 text-right">
                                {lang === "ja" ? (
                                  <div>
                                    このボタンをクリックしたら私がHoterip（ホテリッピ）の
                                    <Link to="/term" target="_blank">
                                      利用条件
                                    </Link>
                                    と
                                    <Link to="/privacy-policy" target="_blank">
                                      個人情報保護方針
                                    </Link>
                                    を同意します
                                  </div>
                                ) : (
                                  <div>
                                    <span className="term-condition">
                                      {Trans.data.term_agree_info}{" "}
                                      <Link to="/term" target="_blank">
                                        {Trans.data.term_condition}
                                      </Link>{" "}
                                      {Trans.data.and}{" "}
                                      <Link to="/privacy-policy" target="_blank">
                                        {Trans.data.privacy_policy}
                                      </Link>{" "}
                                      {Trans.data.of_hoterip}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="col-sm-12 text-right">
                                <button className="btn btn-promo" type="submit" onClick={this.creditCard}>
                                  <i className="fa fa-shield" aria-hidden="true"></i>{" "}
                                  {lang === "ja"
                                    ? `${Trans.data.credit_card + Trans.data.pay_with}`
                                    : `${Trans.data.pay_with + " " + Trans.data.credit_card}`}
                                </button>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane> */}
                        <Tab.Pane eventKey="first">
                          <div className="row">
                            <div className="col-sm-5">
                              <h4 style={{ marginTop: "0" }}></h4>
                            </div>
                            <div className="col-sm-7 text-right">
                              <img src={img_2c2p} role="presentation" />
                            </div>
                          </div>
                          <div className="borderer"></div>
                          {/* <div className="row" style={{ marginBottom: "15px" }}>
                            <div className="col-sm-6" style={{ fontSize: "14px" }}>
                              {Trans.data.deposit_currency}
                            </div>
                            <div className="col-sm-6" style={{ textAlign: "right" }}>
                              {deposit_currency_code}
                            </div>
                          </div> */}
                          {/* <div className="row">
                            <div className="col-sm-6" style={{ fontSize: "18px" }}>
                              <strong>{Trans.data.your_balance}</strong>
                            </div>
                            <div className="col-sm-6" style={{ textAlign: "right" }}>
                              {Env.formatWithLabel(balance_deposit, deposit_currency_code)}
                            </div>
                          </div> */}
                          {/* <div className="borders"></div> */}
                          <table className="table table-borderless table-margin">
                            <caption style={{ captionSide: "top" }}>
                              <h4>{Trans.data.price_details}</h4>
                            </caption>
                            <tbody>
                              <tr>
                                <td>
                                  {room.name},(
                                  {number_of_rooms +
                                    " " +
                                    Trans.data.rooms +
                                    " x " +
                                    parseFloat(moment(check_out).diff(moment(check_in), "days")) +
                                    " " +
                                    Trans.data.nights}
                                  )
                                </td>
                                <td className="text-right">{Env.formatCurrency(this.state.data.selected_campaign.price_total, this.props.selectedCurrency)}</td>
                              </tr>
                              {/* {campaign.surcharges.length ? (
                                <tr>
                                  <td>
                                    {Trans.data.additional_cost + " " + surchargeDesc}, <br />(
                                    {this.reduceCapacity(capacity, "adults")} {Trans.data.adult} x{" "}
                                    {Env.format(this.reduceSurcharge(campaign.surcharges, "adult"))})
                                    {this.reduceCapacity(capacity, "childs") === 0
                                      ? null
                                      : ` & ( ${this.reduceCapacity(capacity, "childs")} ${
                                          Trans.data.children
                                        } x ${Env.format(this.reduceSurcharge(campaign.surcharges, "child"))})`}
                                  </td>
                                  <td className="text-right">{Env.format(campaign.surcharges_total_exchange_price)}</td>
                                </tr>
                              ) : null} */}
                              {/* <tr>
                                <td>{Trans.data.tax}</td>
                                <td className="text-right">{Env.format(campaign.total_tax_price)}</td>
                              </tr> */}
                              <tr className="border-top-table">
                                <td>{Trans.data.total_payment}</td>
                                <td className="text-right" width="50%">
                                  <strong className="total-price">{Env.formatCurrency(this.state.data.selected_campaign.price_total, this.props.selectedCurrency)}</strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="row">
                            <div className="col-sm-12 text-right">
                              {lang === "ja" ? (
                                <div>
                                  このボタンをクリックしたら私がHoterip（ホテリッピ）の
                                  <Link to="/term" target="_blank">
                                    利用条件
                                  </Link>
                                  と
                                  <Link to="/privacy-policy" target="_blank">
                                    個人情報保護方針
                                  </Link>
                                  を同意します
                                </div>
                              ) : (
                                <div>
                                  <span className="term-condition">
                                    {Trans.data.term_agree_info}{" "}
                                    <Link to="/term" target="_blank">
                                      {Trans.data.term_condition}
                                    </Link>{" "}
                                    {Trans.data.and}{" "}
                                    <Link to="/privacy-policy" target="_blank">
                                      {Trans.data.privacy_policy}
                                    </Link>{" "}
                                    {Trans.data.of_hoterip}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-sm-12 text-right">
                              <button className="btn btn-promo" onClick={() => this.on2c2pPayment()}>
                                <i className="fa fa-shield" aria-hidden="true"></i> {Trans.data.payNow}
                              </button>
                            </div>
                          </div>
                          {getNotif ? (
                            <div className="alert alert-danger" role="alert" style={{ marginTop: "20px" }}>
                              <strong>Alert</strong> {getNotif}
                            </div>
                          ) : null}
                        </Tab.Pane>
                        {/* /.Deposit */}
                        <Tab.Pane eventKey="seconds">
                          <div className="row">
                            <div className="col-sm-5">
                              <h4 style={{ marginTop: "0" }}>{Trans.data.deposit}</h4>
                            </div>
                            <div className="col-sm-7 text-right">
                              <img src={comodo_secure} role="presentation" />
                            </div>
                          </div>
                          <div className="borderer"></div>
                          <div className="row" style={{ marginBottom: "15px" }}>
                            <div className="col-sm-6" style={{ fontSize: "14px" }}>
                              {Trans.data.deposit_currency}
                            </div>
                            <div className="col-sm-6" style={{ textAlign: "right" }}>
                              {deposit_currency_code}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6" style={{ fontSize: "18px" }}>
                              <strong>{Trans.data.your_balance}</strong>
                            </div>
                            <div className="col-sm-6" style={{ textAlign: "right" }}>
                              {Env.formatWithLabel(balance_deposit, deposit_currency_code)}
                            </div>
                          </div>
                          <div className="borders"></div>
                          <table className="table table-borderless table-margin">
                            <caption style={{ captionSide: "top" }}>
                              <h4>{Trans.data.price_details}</h4>
                            </caption>
                            <tbody>
                              <tr>
                                <td>
                                  {room.name},(
                                  {number_of_rooms +
                                    " " +
                                    Trans.data.rooms +
                                    " x " +
                                    parseFloat(moment(check_out).diff(moment(check_in), "days")) +
                                    " " +
                                    Trans.data.nights}
                                  )
                                </td>
                                <td className="text-right">{Env.formatCurrency(this.state.data.selected_campaign.price_total, this.props.selectedCurrency)}</td>
                              </tr>
                              {/* {campaign.surcharges.length ? (
                                <tr>
                                  <td>
                                    {Trans.data.additional_cost + " " + surchargeDesc}, <br />(                                    
                                    {this.reduceCapacity(capacity, "adults")} {Trans.data.adult} x{" "}
                                    {Env.format(this.reduceSurcharge(campaign.surcharges, "adult"))})
                                    {this.reduceCapacity(capacity, "childs") === 0
                                      ? null
                                      : ` & ( ${this.reduceCapacity(capacity, "childs")} ${
                                          Trans.data.children
                                        } x ${Env.format(this.reduceSurcharge(campaign.surcharges, "child"))})`}
                                  </td>
                                  <td className="text-right">{Env.format(campaign.surcharges_total_exchange_price)}</td>
                                </tr>
                              ) : null} */}
                              {/* <tr>
                                <td>{Trans.data.tax}</td>
                                <td className="text-right">{Env.format(campaign.total_tax_price)}</td>
                              </tr> */}
                              <tr className="border-top-table">
                                <td>{Trans.data.total_payment}</td>
                                <td className="text-right" width="50%">
                                  <strong className="total-price">{Env.formatCurrency(this.state.data.selected_campaign.price_total, this.props.selectedCurrency)}</strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="row">
                            <div className="col-sm-12 text-right">
                              {lang === "ja" ? (
                                <div>
                                  このボタンをクリックしたら私がHoterip（ホテリッピ）の
                                  <Link to="/term" target="_blank">
                                    利用条件
                                  </Link>
                                  と
                                  <Link to="/privacy-policy" target="_blank">
                                    個人情報保護方針
                                  </Link>
                                  を同意します
                                </div>
                              ) : (
                                <div>
                                  <span className="term-condition">
                                    {Trans.data.term_agree_info}{" "}
                                    <Link to="/term" target="_blank">
                                      {Trans.data.term_condition}
                                    </Link>{" "}
                                    {Trans.data.and}{" "}
                                    <Link to="/privacy-policy" target="_blank">
                                      {Trans.data.privacy_policy}
                                    </Link>{" "}
                                    {Trans.data.of_hoterip}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-sm-12 text-right">
                              <button className="btn btn-promo" onClick={this.showNotification}>
                                <i className="fa fa-shield" aria-hidden="true"></i>{" "}
                                {lang === "ja"
                                  ? `${Trans.data.deposit + Trans.data.pay_with}`
                                  : `${Trans.data.pay_with + " " + Trans.data.deposit}`}
                              </button>
                            </div>
                          </div>
                          {getNotif ? (
                            <div className="alert alert-danger" role="alert" style={{ marginTop: "20px" }}>
                              <strong>Alert</strong> {getNotif}
                            </div>
                          ) : null}
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              </div>

              <BookingSidebar
                dataBooking={this.props.location.state.data}
                capacity={this.props.location.state.data.capacity}
              />
            </div>
          </section>
        </div>

        {/* Modal credit card */}
        <Modal show={this.state.showModal} onHide={this.close} aria-labelledby="contained-modal-title-lg">
          <Modal.Body>
            <iframe
              src={this.state.url_iframe}
              onLoad={this.onLoadHandler}
              scrolling="auto"
              width="100%"
              height="450"
              style={{ border: "1px solid #DDD" }}
            />
          </Modal.Body>
        </Modal>

        {/* Modal deposit */}
        <Modal
          className="modal-notification"
          show={show_notification}
          onHide={this.closeNotification}
          bsSize="small"
          backdrop="static"
          aria-labelledby="contained-modal-title-sm"
        >
          <Modal.Body>
            <p>
              <i className="fa fa-info-circle fa-2x" aria-hidden="true"></i>
            </p>
            <p>
              {lang === "ja"
                ? `${Trans.data.deposit + Trans.data.are_you_sure_to_pay}`
                : `${Trans.data.are_you_sure_to_pay + " " + Trans.data.deposit + "?"}`}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="btn-group btn-group-justified" role="group">
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn-lg btn-default bg-primary"
                  style={{ color: "#FFF", border: "1px solid #fff" }}
                  onClick={this.closeNotification}
                >
                  {Trans.data.cancel}
                </button>
              </div>
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn-default btn-lg btn-success"
                  style={{ color: "#FFF", border: "1px solid #fff" }}
                  onClick={this.pay_with_deposit}
                >
                  {Trans.data.continue}
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  const { selectedCurrency } = state.currencyReducer
  return {
    selectedCurrency
  }
}

export default connect(mapStateToProps)(PaymentForm)
